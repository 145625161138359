import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { CHAT_TYPE } from '../../../common/constants/messages';
import { ChevronLeft } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';

const Chat = ({
  contractId,
  userId,
  aldCustomerMessages,
  aldSupplierMessages,
  canViewAldSupplierChat,
  canViewAldCustomerChat,

  postAldCustomerMessage,
  postAldSupplierMessage,
  getAllAldCustomerMessages,
  getAllAldSupplierMessages,
}) => {
  const getDefaultSelectedChatState = (canViewSupplier, canViewCustomer) => {
    if (canViewSupplier && canViewCustomer) return null;
    if (canViewSupplier && !canViewCustomer) return CHAT_TYPE.ALD_SUPPLIER;
    if (!canViewSupplier && canViewCustomer) return CHAT_TYPE.ALD_CUSTOMER;

    return null;
  };

  const [isOpened, setIsOpened] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [selectedChatType, setSelectedChatType] = useState(
    getDefaultSelectedChatState(canViewAldSupplierChat, canViewAldCustomerChat),
  );

  const scrollableRef = useRef(null);

  useEffect(() => {
    if (!scrollableRef.current) return;
    scrollableRef.current.scrollTo(0, scrollableRef.current.scrollHeight);
  });

  let messages = [];
  if (selectedChatType === CHAT_TYPE.ALD_CUSTOMER)
    messages = aldCustomerMessages;
  if (selectedChatType === CHAT_TYPE.ALD_SUPPLIER)
    messages = aldSupplierMessages;

  return (
    <>
      {isOpened && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 50,
            right: isMobile ? 'auto' : 50,
            zIndex: 1200,
            width: '350px',
            height: isMobile ? '80%' : '500px',
            background: '#FFFFFF',
            boxShadow:
              '0px 0px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.12)',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              height: '40px',
              backgroundColor: '#5664D2',
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {selectedChatType !== null &&
              canViewAldCustomerChat &&
              canViewAldSupplierChat && (
                <ChevronLeft
                  sx={{
                    fill: 'white',
                    ml: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setSelectedChatType(null)}
                />
              )}
            <Typography
              variant="h6"
              sx={{ pl: '25px', color: 'white', marginRight: 'auto' }}
            >
              Chat
            </Typography>

            <CloseIcon
              onClick={() => setIsOpened(false)}
              sx={{ fill: 'white', mr: '16px', ml: '16px', cursor: 'pointer' }}
            />
          </Box>

          {selectedChatType === null && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: '5px',
              }}
            >
              <Box
                sx={{
                  height: '60px',
                  borderRadius: '16px',
                  background: 'rgba(86, 100, 210, 0.06)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '16px',
                  color: 'rgba(37, 56, 88, 0.86)',
                  fontFamily: 'Roboto',
                  m: '5px',
                  cursor: 'pointer',
                }}
                variant="outlined"
                onClick={async () => {
                  await getAllAldCustomerMessages(contractId);
                  setSelectedChatType(CHAT_TYPE.ALD_CUSTOMER);
                }}
              >
                Customer
              </Box>
              <Box
                sx={{
                  height: '60px',
                  borderRadius: '16px',
                  background: 'rgba(86, 100, 210, 0.06)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '16px',
                  color: 'rgba(37, 56, 88, 0.86)',
                  fontFamily: 'Roboto',
                  m: '5px',
                  cursor: 'pointer',
                }}
                variant="outlined"
                onClick={async () => {
                  await getAllAldSupplierMessages(contractId);
                  setSelectedChatType(CHAT_TYPE.ALD_SUPPLIER);
                }}
              >
                Supplier
              </Box>
            </Box>
          )}
          {selectedChatType !== null && (
            <Box
              sx={{ flex: 1, mt: 1, overflowY: 'scroll' }}
              ref={scrollableRef}
            >
              {messages &&
                messages.map((message, index) => {
                  const isMyMessage = message.senderModel.userId === userId;
                  const isPreviousMessageHasSameAuthor =
                    index !== 0 &&
                    messages[index - 1].senderModel.userId ===
                      message.senderModel.userId;
                  const sx = {
                    maxWidth: '75%',
                  };

                  if (isMyMessage) {
                    sx.ml = 'auto';
                  } else {
                    sx.mr = 'auto';
                  }
                  return (
                    <Box sx={sx} key={message.id}>
                      {!isMyMessage && !isPreviousMessageHasSameAuthor && (
                        <Box>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              ml: 2,
                            }}
                          >
                            {`${message.senderModel.fullName}`}
                          </Typography>
                        </Box>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          position: 'relative',
                          p: 1.5,
                          m: 1.5,
                          mt: 0,
                          borderRadius: '10px',
                          background: isMyMessage
                            ? 'rgba(86, 100, 210, 0.12)'
                            : '#F2F7FC',
                        }}
                      >
                        <Typography
                          sx={{
                            width: '100%',
                            fontSize: '14px',
                          }}
                        >
                          {message.text}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: '9px',
                            ml: 1,
                            position: 'absolute',
                            bottom: 0,
                            right: 5,
                            color: '#7B869A',
                          }}
                        >
                          {moment(message.createdOnUtc).format(
                            'DD/MM/YYYY HH:mm',
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          )}

          {selectedChatType !== null && (
            <Box
              sx={{
                border: '1px solid #EDEDED',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 1.5,
                borderBottomLeftRadius: 'inherit',
                borderBottomRightRadius: 'inherit',
              }}
            >
              <TextField
                sx={{
                  flex: 1,
                  mr: 1,
                }}
                placeholder="Message..."
                onChange={(e) => setMessageText(e.target.value)}
                value={messageText}
              />

              <Button
                sx={{ width: '97px', height: '38px' }}
                variant="contained"
                onClick={async () => {
                  const action =
                    selectedChatType === CHAT_TYPE.ALD_CUSTOMER
                      ? postAldCustomerMessage
                      : postAldSupplierMessage;
                  await action(contractId, messageText);
                  setMessageText('');
                }}
                disabled={/^\s*$/.test(messageText)}
              >
                Send
              </Button>
            </Box>
          )}
        </Box>
      )}

      <IconButton
        color="primary"
        variant="contained"
        onClick={() => setIsOpened(!isOpened)}
        sx={{}}
      >
        <ChatIcon />
      </IconButton>
    </>
  );
};

export default Chat;
