import { connect } from 'react-redux';
import { redirectToAppVehiclesListNewBookedCarsCarDetails } from '../../../../store/redirect/slice';
import {
  getVehicleFlowStatesLogs,
  getVehicles,
} from '../../../../store/vehicles/slice';
import NewBookedCars from './NewBookedCars';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListNewBookedCarsCarDetails,
  getVehicleFlowStatesLogs,
  getVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBookedCars);
