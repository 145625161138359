import React, { useEffect } from 'react';

function PasswordlessAuth({
  isAuthenticated,
  user = {},
  email = '',
  entranceToken = '',
  carId = '',
  passwordlessAuth = () => {},
  redirectToAppVehiclesListCustomerCarsCarDetails = () => {},
  redirectToAppVehiclesList = () => {},
  logout = () => {},
}) {
  const { username } = user;
  useEffect(async () => {
    if (isAuthenticated && username !== email) {
      await logout();
    }

    await passwordlessAuth(email, entranceToken);

    if (carId !== '00000000-0000-0000-0000-000000000000') {
      await redirectToAppVehiclesListCustomerCarsCarDetails(carId);
    } else {
      await redirectToAppVehiclesList();
    }
  });

  return <></>;
}

export default PasswordlessAuth;
