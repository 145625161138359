export const SUPPLIERS_TYPES = {
  WASH: 0,
  NAF: 1,
  WORKSHOP: 2,
};

export const SUPPLIER_REPAIR_STATUS = {
  NEED_REPAIR: 0,
  REPAIRED: 1,
};
