export const SUB_FLOW_STATE_ENUM = {
  CONTRACT_EXPIRED: 0,

  IMPORTED: 101,
  CREATED_MANUALLY: 102,
  READY_FOR_RETURN: 103,

  WAITING_UNTIL_CUSTOMER_BOOKS_DATE_AND_TIME: 201,
  WAITING_START_RETURN: 202,
  WAITING_UNTIL_CUSTOMER_UPDATE_MILEAGE_BEFORE_RETURN: 203,
  READY_FOR_UPLOAD_PHOTO_BEFORE_RETURN: 204,

  IN_TRANSPORT_TO_DEALER_LOCATION: 301,
  READY_FOR_RETURN_TO_ALD_FROM_DEALER: 302,

  IN_TRANSPORT_TO_OTHER_LOCATION: 401,
  READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION: 402,

  IN_TRANSPORT_TO_ALD_LOCATION: 501,
  WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN: 502,
  READY_FOR_UPLOAD_PHOTO_AFTER_RETURN: 503,
  READY_FOR_QR_GENERATING: 504,

  QR_GENERATING: 601,
  WAITING_UNTIL_QR_PLACES_ON_THE_CAR: 602,
  READY_FOR_ACCESSORIES_CHECK: 603,

  DELIVERED_TO_CHECK_MISSING_ACCESSORIES: 701,
  IN_PROCESS_CHECK_MISSING_ACCESSORIES: 702,
  READY_FOR_WASH: 703,

  DELIVERED_TO_WASH: 801,
  IN_PROCESS_WASH: 802,
  READY_FOR_NAF_ASSESSMENT: 803,

  DELIVERED_TO_NAF_ASSESSMENT: 901,
  IN_PROCESS_NAF_ASSESSMENT: 902,
  READY_FOR_ALD_ASSESSMENT: 903,

  DELIVERED_TO_ALD_ASSESSMENT: 1001,
  IN_PROCESS_ALD_ASSESSMENT: 1002,
  READY_FOR_WORKSHOP: 1003,

  DELIVERED_TO_WORKSHOP: 1101,
  IN_PROCESS_WORKSHOP: 1102,
  READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE: 1103,

  READY_FOR_SALES: 1104,

  DELIVERED_TO_SALES: 1201,
};

export const CONTRACT_CAR_IMAGE_TYPES = {
  DEFAULT_IMAGE: 0,
  IMAGE_BEFORE_RETURN: 1,
  IMAGE_AFTER_RETURN: 2,
};

export const getLocationByStatusFlow = (status) => {
  const locations = {
    [SUB_FLOW_STATE_ENUM.CONTRACT_EXPIRED]: 'Expired',
    [SUB_FLOW_STATE_ENUM.IMPORTED]: 'Imported',
    [SUB_FLOW_STATE_ENUM.CREATED_MANUALLY]: 'Created manually',
    [SUB_FLOW_STATE_ENUM.READY_FOR_RETURN]: 'Customer',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_BOOKS_DATE_AND_TIME]:
      'Customer',
    [SUB_FLOW_STATE_ENUM.WAITING_START_RETURN]: 'Customer',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_UPDATE_MILEAGE_BEFORE_RETURN]:
      'Customer',
    [SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_BEFORE_RETURN]: 'Customer',
    [SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_DEALER_LOCATION]: 'Customer',
    [SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_DEALER]: 'Customer',
    [SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_OTHER_LOCATION]: 'Customer',
    [SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION]:
      'Customer',
    [SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION]: 'Customer',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN]:
      'FLEET',
    [SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_AFTER_RETURN]: 'FLEET',
    [SUB_FLOW_STATE_ENUM.READY_FOR_QR_GENERATING]: 'FLEET',
    [SUB_FLOW_STATE_ENUM.QR_GENERATING]: 'FLEET',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR]: 'FLEET',
    [SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK]: 'FLEET',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_CHECK_MISSING_ACCESSORIES]: 'Accessories',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_CHECK_MISSING_ACCESSORIES]: 'Accessories',
    [SUB_FLOW_STATE_ENUM.READY_FOR_WASH]: 'Accessories',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH]: 'Wash',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH]: 'Wash',
    [SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT]: 'Wash',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT]: '3rd Party Assessment',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT]: '3rd Party assessment',
    [SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT]: '3rd Party assessment',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT]: 'FLEET assessment',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT]: 'FLEET assessment',
    [SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP]: 'FLEET assessment',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP]: 'Workshop',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP]: 'Workshop',
    [SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE]:
      'Workshop',
    [SUB_FLOW_STATE_ENUM.READY_FOR_SALES]: 'Sales',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_SALES]: 'Sales',
  };
  return locations[status];
};

export const getProcessStatusByStatusFlow = (status) => {
  const states = {
    [SUB_FLOW_STATE_ENUM.CONTRACT_EXPIRED]: 'Done',
    [SUB_FLOW_STATE_ENUM.IMPORTED]: 'Ready',
    [SUB_FLOW_STATE_ENUM.CREATED_MANUALLY]: 'Ready',
    [SUB_FLOW_STATE_ENUM.READY_FOR_RETURN]: 'Ready',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_BOOKS_DATE_AND_TIME]:
      'In process',
    [SUB_FLOW_STATE_ENUM.WAITING_START_RETURN]: 'In process',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_UPDATE_MILEAGE_BEFORE_RETURN]:
      'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_BEFORE_RETURN]: 'In process',
    [SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_DEALER_LOCATION]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_DEALER]: 'In process',
    [SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_OTHER_LOCATION]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION]:
      'In process',
    [SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION]: 'Done',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN]:
      'Ready',
    [SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_AFTER_RETURN]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_QR_GENERATING]: 'In process',
    [SUB_FLOW_STATE_ENUM.QR_GENERATING]: 'In process',
    [SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK]: 'Done',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_CHECK_MISSING_ACCESSORIES]: 'Ready',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_CHECK_MISSING_ACCESSORIES]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_WASH]: 'Done',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH]: 'Ready',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT]: 'Done',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT]: 'Ready',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT]: 'Done',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT]: 'Ready',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP]: 'Done',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP]: 'Ready',
    [SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP]: 'In process',
    [SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE]: 'Done',
    [SUB_FLOW_STATE_ENUM.READY_FOR_SALES]: 'Ready',
    [SUB_FLOW_STATE_ENUM.DELIVERED_TO_SALES]: 'Done',
  };

  const styles = {
    Ready: {
      background: '#5664D2',
      color: 'white',
    },
    'In process': {
      background: '#FFB547',
      color: 'white',
    },
    Done: {
      background: '#4CAF50',
      color: 'white',
    },
  };

  const state = states[status];
  const style = styles[state];

  return { state, style };
};

export const CAR_RETURN_STATUS = {
  NO: 0,
  IN_TRANSPORT: 1,
  YES: 2,
};

export const CAR_RETURN_STATUS_TRANSLATION = {
  [CAR_RETURN_STATUS.NO]: 'No',
  [CAR_RETURN_STATUS.IN_TRANSPORT]: 'In transport',
  [CAR_RETURN_STATUS.YES]: 'Yes',
};

export const SALES_CHANNELS_ENUM = {
  NONE: 0,
  AUCTION: 1,
  RETAIL: 2,
  FLEX: 3,
  OUTLET: 4,
  WHOLESALE: 5,
  COMMISSION: 6,
  BUYBACK: 7,
};
