import { Typography } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';

function Reports({ t = () => {} }) {
  return (
    <>
      <Typography variant="h4">{t('reports.title')}</Typography>
    </>
  );
}

export default withTranslation()(Reports);
