import Box from '@mui/system/Box';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import CustomDataGridV3 from '../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import { CustomDataGridToolbar } from '../AllCars/AllCars';
import {
  getCarsSortEnumByField,
  getSortEnumBySortTypeString,
} from '../../../../common/helpers/vehicles';

function DealerCars({
  t = () => {},
  redirectToAppVehiclesListDealersCarsCarDetails = () => {},
  getVehicles = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('vehiclesList.tabs.newReturnedCars.car'),
    },
    {
      field: 'carModel',
      headerName: t('vehiclesList.tabs.newReturnedCars.model'),
    },
    {
      field: 'plateNo',
      headerName: t('vehiclesList.tabs.newReturnedCars.numbers'),
    },
    {
      field: 'endDate',
      headerName: t('vehiclesList.tabs.newReturnedCars.endDate'),
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      endDate: moment(car.endDate).format('DD/MM/YYYY'),
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sortModel, setSortModel] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListDealersCarsCarDetails(params.row.id);
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        paginationCallback={async (offset, limit, plateNo, _sortModel) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [],
              undefined,
              plateNo,
              getCarsSortEnumByField(_sortModel.field),
              getSortEnumBySortTypeString(_sortModel.sort),
            );
            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[
          plateNumberSearchValue,
          sortModel,
        ]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
        onSortModelChange={(sortChangeData) => {
          if (sortChangeData && sortChangeData.length > 0) {
            setSortModel(sortChangeData[0]);
          }
        }}
      />
    </Box>
  );
}

export default withTranslation()(DealerCars);
