import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BreadcrumbsGeneratorContainer from '../../../common/components/BreadcrubmsGenerator/BreadcrumbsGenerator.container';
import {
  ROUTING_PATH_NAMES,
  ROUTING_URLS,
} from '../../../common/constants/constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import { Redirect, Route, Switch } from 'react-router';
import CustomersTabs, { CUSTOMERS_TABS } from './Tabs/Tabs';
import B2CContainer from './B2C/B2C.container';
import B2BContainer from './B2B/B2B.container';
import ExpandableButton from '../../../common/components/ExpandableButton/ExpandableButton';
import CreateNewB2CCustomerContainer from './CreateNewCustomer/B2C/B2C.container';
import CreateNewB2BCustomerContainer from './CreateNewCustomer/B2B/B2B.container';
import B2BCustomerDetailsContainer from './B2B/Details/Details.container';
import B2CCustomerDetailsContainer from './B2C/Details/Details.container';
import { pathTester } from '../../../common/helpers/routing';

function Customers({
  t = () => {},
  pathname = {},
  redirectBackByBreadcrumb = () => {},
  redirectToAppCustomersB2CCreateNewCustomer = () => {},
  redirectToAppCustomersB2BCreateNewCustomer = () => {},
  // redirectToAppCustomersRentalCreateNewCustomer = () => {},
  // redirectToAppCustomersFlexCreateNewCustomer = () => {},
}) {
  // todo: refactor to use pathTester instead
  const isB2CCustomersTabSelected = pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2C,
  );
  const isB2BCustomersTabSelected = pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2B,
  );
  const isRentalTabSelected = pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__RENTAL,
  );
  const isFlexTabSelected = pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__FLEX,
  );
  const isCreateCustomerTabSelected =
    pathname.includes(
      ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2C__CREATE_NEW_CUSTOMER,
    ) ||
    pathname.includes(
      ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2B__CREATE_NEW_CUSTOMER,
    ) ||
    pathname.includes(
      ROUTING_URLS.APP
        .CONTROL_CATEGORIES__CUSTOMERS__RENTAL__CREATE_NEW_CUSTOMER,
    ) ||
    pathname.includes(
      ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__FLEX__CREATE_NEW_CUSTOMER,
    );
  const isCustomerDetailsTabSelected =
    pathTester.isB2BCustomerDetailsCustomerInfo(pathname) ||
    pathTester.isB2BCustomerDetailsCustomerVehiclesList(pathname) ||
    pathTester.isB2BCustomerDetailsCustomerUsers(pathname) ||
    pathTester.isB2BCustomerDetailsUsersCreateNewAdminUser(pathname) ||
    pathTester.isB2BCustomerDetailsUsersCreateNewUser(pathname) ||
    pathTester.isB2BCustomerDetailsUsersUserDetails(pathname) ||
    pathTester.isB2CCustomerDetailsCustomerInfo(pathname) ||
    pathTester.isB2CCustomerDetailsCustomerVehiclesList(pathname) ||
    pathTester.isB2CCustomerDetailsCustomerUsers(pathname) ||
    pathTester.isB2CCustomerDetailsUsersUserDetails(pathname);

  let selectedTabId = 0;
  if (isB2CCustomersTabSelected) selectedTabId = CUSTOMERS_TABS.B2C_CUSTOMERS;
  if (isB2BCustomersTabSelected) selectedTabId = CUSTOMERS_TABS.B2B_CUSTOMERS;
  if (isRentalTabSelected) selectedTabId = CUSTOMERS_TABS.RENTAL;
  if (isFlexTabSelected) selectedTabId = CUSTOMERS_TABS.FLEX;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 1, md: 2 },
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">
            {t('controlCategories.customers.title')}
          </Typography>

          <BreadcrumbsGeneratorContainer />
        </Box>

        <Box sx={{ display: 'flex', ml: 'auto' }}>
          {!isCreateCustomerTabSelected && !isCustomerDetailsTabSelected && (
            <ExpandableButton
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              items={[
                {
                  text: 'B2C',
                  onClick: () => {
                    redirectToAppCustomersB2CCreateNewCustomer();
                  },
                },
                {
                  text: 'B2B',
                  onClick: () => {
                    redirectToAppCustomersB2BCreateNewCustomer();
                  },
                },
                // {
                //   text: 'Rental',
                //   onClick: () => {
                //     redirectToAppCustomersRentalCreateNewCustomer();
                //   },
                // },
                // {
                //   text: 'Flex',
                //   onClick: () => {
                //     redirectToAppCustomersFlexCreateNewCustomer();
                //   },
                // },
              ]}
            >
              {t('controlCategories.customers.createNewCustomer')}
            </ExpandableButton>
          )}

          {(isCreateCustomerTabSelected || isCustomerDetailsTabSelected) && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{ mr: 1 }}
              onClick={() => {
                redirectBackByBreadcrumb();
              }}
            >
              {t('common.buttons.back')}
            </Button>
          )}
        </Box>
      </Box>

      <Switch>
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}`}
          render={() => {
            return (
              <>
                <CustomersTabs selectedTabId={selectedTabId} />
                <B2CContainer />
              </>
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER}`}
          render={() => {
            return (
              <>
                <CreateNewB2CCustomerContainer />
              </>
            );
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/:customerId`}
          render={({
            match: {
              params: { customerId },
            },
          }) => {
            return (
              <>
                <B2CCustomerDetailsContainer customerId={customerId} />
              </>
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}`}
          render={() => {
            return (
              <>
                <CustomersTabs selectedTabId={selectedTabId} />
                <B2BContainer />
              </>
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER}`}
          render={() => {
            return (
              <>
                <CreateNewB2BCustomerContainer />
              </>
            );
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/:customerId`}
          render={({
            match: {
              params: { customerId },
            },
          }) => {
            return (
              <>
                <B2BCustomerDetailsContainer customerId={customerId} />
              </>
            );
          }}
        />

        <Route
          exact
          path={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__RENTAL}
          render={() => {
            return (
              <>
                <CustomersTabs selectedTabId={selectedTabId} />
              </>
            );
          }}
        />

        <Route
          exact
          path={
            ROUTING_URLS.APP
              .CONTROL_CATEGORIES__CUSTOMERS__RENTAL__CREATE_NEW_CUSTOMER
          }
          render={() => {
            return <>Create new rental user</>;
          }}
        />

        <Route
          exact
          path={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__FLEX}
          render={() => {
            return (
              <>
                <CustomersTabs selectedTabId={selectedTabId} />
              </>
            );
          }}
        />

        <Route
          exact
          path={
            ROUTING_URLS.APP
              .CONTROL_CATEGORIES__CUSTOMERS__FLEX__CREATE_NEW_CUSTOMER
          }
          render={() => {
            return <>Create new flex user</>;
          }}
        />

        <Route
          path={
            ROUTING_URLS.APP.CONTROL_CATEGORIES__DEALERS__CREATE_NEW_CUSTOMER
          }
          render={() => {
            return <></>;
          }}
        />

        <Route
          path={ROUTING_URLS.APP.CONTROL_CATEGORIES}
          render={() => {
            return (
              <Redirect
                to={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2C}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

export default withTranslation()(Customers);
