import {
  Button,
  FormControl,
  FormLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CustomDataGridV3 from '../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import {
  getLocationByStatusFlow,
  getProcessStatusByStatusFlow,
} from '../../../../common/constants/vehicles';
import { appendDaysWord } from '../../../../common/helpers/common';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  getCarsSortEnumByField,
  getSortEnumBySortTypeString,
} from '../../../../common/helpers/vehicles';
import { getEstimationColorByEnumValue } from '../CarDetails/SupplierLogs/helpers';
import { getChannelNameByEnum } from '../CarDetails/helpers';

export function CustomDataGridToolbar({
  plateNumberSearchValue = '',
  setPlateNumberSearchValue = () => {},
  setOffset = () => {},
  setPage = () => {},
}) {
  return (
    <GridToolbarContainer>
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          ml: 'auto',
        }}
      >
        <FormLabel>Plate No:</FormLabel>

        <TextField
          sx={{ ml: 1 }}
          InputProps={{
            sx: {
              borderRadius: '10px',
              '& .MuiInputBase-input': {
                py: 0.5,
              },
            },
          }}
          value={plateNumberSearchValue}
          onChange={(e) => {
            setOffset(0);
            setPlateNumberSearchValue(e.target.value);
            setPage(1);
          }}
        />
      </FormControl>
    </GridToolbarContainer>
  );
}

function AllCars({
  t = () => {},
  redirectToAppVehiclesListAllCarsCarDetails = () => {},
  getVehicles = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.car'),
    },
    {
      field: 'carModel',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.model'),
    },
    {
      field: 'plateNo',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.numbers'),
    },
    {
      field: 'arrivedToALD',
      headerName: t('common.titles.arrivedToALD'),
    },
    {
      field: 'lastUpdate',
      headerName: t('common.titles.lastUpdate'),
    },
    {
      field: 'location',
      headerName: t('common.titles.stage'),
      renderCell: (params = {}) => {
        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              minWidth: 0,
              px: 1,
            }}
          >
            <Tooltip
              title={getLocationByStatusFlow(params.row.currentStateEnum)}
            >
              <Typography noWrap>
                {getLocationByStatusFlow(params.row.currentStateEnum)}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: '_status',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.status'),
      renderCell: (params = {}) => {
        const { state } = getProcessStatusByStatusFlow(
          params.row.currentStateEnum,
        );
        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              minWidth: 0,
              px: 1,
            }}
          >
            <Button
              color={getEstimationColorByEnumValue(params.row.workshopColor)}
              variant="contained"
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                noWrap
                sx={{
                  width: '100%',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                {state || ''}
              </Typography>
            </Button>
          </Box>
        );
      },
    },
    {
      field: 'salesChannel',
      headerName: 'Sales channel',
      valueGetter: (params = {}) => {
        return getChannelNameByEnum(params.row.salesChannel);
      },
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      lastUpdate: appendDaysWord(car.lastUpdate),
      arrivedToALD: car.arrivedToALD
        ? moment(car.arrivedToALD).format('DD/MM/YYYY')
        : '-',
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sortModel, setSortModel] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { xs: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListAllCarsCarDetails(params.row.id);
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        pageSize={100}
        paginationCallback={async (offset, limit, plateNo, _sortModel) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [],
              undefined,
              plateNo,
              getCarsSortEnumByField(_sortModel.field),
              getSortEnumBySortTypeString(_sortModel.sort),
            );
            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[
          plateNumberSearchValue,
          sortModel,
        ]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
        onSortModelChange={(sortChangeData) => {
          if (sortChangeData && sortChangeData.length > 0) {
            setSortModel(sortChangeData[0]);
          }
        }}
      />
    </Box>
  );
}

export default withTranslation()(AllCars);
