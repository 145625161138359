import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../../../common/components/DataGrid/DataGrid';
import AddIcon from '@mui/icons-material/Add';

function WashSupplierUsers({
  t = () => {},
  supplierId = '',
  supplierUsers = [],
  getSupplierUsers = () => {},
  redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser = () => {},
  redirectToAppSuppliersWashSupplierDetailsUsersUserDetails = () => {},
}) {
  useEffect(async () => {
    try {
      await getSupplierUsers(supplierId);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const rows = supplierUsers.map((u) => {
    return { ...u, address: (u.address && u.address.name) || '-' };
  });

  const columns = [
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'displayName',
      headerName: t('controlCategories.suppliers.wash.details.users.name'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'email',
      headerName: t('controlCategories.suppliers.wash.details.users.email'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'telephone',
      headerName: t(
        'controlCategories.suppliers.wash.details.users.phoneNumber',
      ),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'address',
      headerName: t('controlCategories.suppliers.wash.details.users.address'),
    },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ mb: 2, ml: 'auto' }}
        onClick={() => {
          redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser(
            supplierId,
          );
        }}
      >
        {t('controlCategories.suppliers.createNewUser')}
      </Button>

      <Box
        sx={{
          backgroundColor: 'background.default',
          flex: 1,
          width: '100%',
          borderRadius: '16px',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <CommonDataGrid
          rows={rows}
          columns={columns}
          checkboxSelection
          noDataToShowMessage={t('common.messages.noDataToShow')}
          selectRowHandler={(rowData) => {
            redirectToAppSuppliersWashSupplierDetailsUsersUserDetails({
              supplierId,
              supplierUserId: rowData.id,
            });
          }}
        />
      </Box>
    </Box>
  );
}

export default withTranslation()(WashSupplierUsers);
