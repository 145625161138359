import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { withTranslation } from 'react-i18next';

export const DEALER_DETAILS_TABS = {
  DEALER_INFO: 0,
  USERS: 1,
};

function DealerDetailsTabs({
  t = () => {},
  dealerId = '',
  selectedTabId = DEALER_DETAILS_TABS.DEALER_INFO,
  redirectToAppDealersDealerDetailsDealerInfo = () => {},
  redirectToAppDealersDealerDetailsDealerUsers = () => {},
}) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{
          borderBottom: '1px solid #E0E0E0',
          mb: 2,
          '& .MuiTab-root': {
            flex: 1,
          },
        }}
      >
        <Tab
          onClick={() => {
            redirectToAppDealersDealerDetailsDealerInfo(dealerId);
          }}
          id={DEALER_DETAILS_TABS.DEALER_INFO}
          label={t('controlCategories.dealers.dealerInfo')}
        />

        <Tab
          onClick={() => {
            redirectToAppDealersDealerDetailsDealerUsers(dealerId);
          }}
          id={DEALER_DETAILS_TABS.USERS}
          label={t('common.titles.users')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(DealerDetailsTabs);
