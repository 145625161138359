import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { withTranslation } from 'react-i18next';

export const WORKSHOP_SUPPLIER_DETAILS_TABS = {
  SUPPLIERS_INFO: 0,
  USERS: 1,
};

function WorkshopSupplierDetailsTabs({
  t = () => {},
  supplierId = '',
  selectedTabId = WORKSHOP_SUPPLIER_DETAILS_TABS.SUPPLIERS_INFO,
  redirectToAppSuppliersWorkshopSupplierDetailsInfo = () => {},
  redirectToAppSuppliersWorkshopSupplierDetailsUsers = () => {},
}) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{
          borderBottom: '1px solid #E0E0E0',
          mb: 2,
          '& .MuiTab-root': {
            flex: 1,
          },
        }}
      >
        <Tab
          onClick={() => {
            redirectToAppSuppliersWorkshopSupplierDetailsInfo(supplierId);
          }}
          id={WORKSHOP_SUPPLIER_DETAILS_TABS.SUPPLIERS_INFO}
          label={t('controlCategories.suppliers.wash.details.info.title')}
        />

        <Tab
          onClick={() => {
            redirectToAppSuppliersWorkshopSupplierDetailsUsers(supplierId);
          }}
          id={WORKSHOP_SUPPLIER_DETAILS_TABS.USERS}
          label={t('controlCategories.suppliers.wash.details.users.title')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(WorkshopSupplierDetailsTabs);
