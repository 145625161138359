import { connect } from 'react-redux';
import { getBookingByContractId } from '../../../../store/booking/slice';
import { redirectToAppVehiclesList } from '../../../../store/redirect/slice';
import {
  getContractById,
  getContractImages,
  getSalesChannelEnums,
  getVehicleById,
  getVehicleContractInfo,
  getVehicleFlowPossibleStates,
  getVehicleFlowState,
  getVehicleFlowStatesLogs,
  updateContract,
  _setCurrentVehicle,
} from '../../../../store/vehicles/slice';
import CarStatusInfo from './CarStatusInfo';

const mapStateToProps = (state) => {
  const { currentVehicle, salesChannelEnums, contracts } = state.vehicles;
  const vehicle = currentVehicle;

  if (vehicle) {
    const { currentVehicleFlowState, contractsImages } = state.vehicles;
    const currentVehicleContractImages = contractsImages[vehicle.contractId];
    const contractInfo = contracts[vehicle.id];

    return {
      vehicle: {
        ...vehicle,
        dealerContactPerson: {
          phone: '-',
          email: '-',
          name: '-',
        },
        currentVehicleFlowState,
        currentVehicleContractImages,
      },
      accessRights: state.auth.user.accessRights,
      salesChannelEnums,
      contractInfo,
    };
  }

  return {
    vehicle: {},
    accessRights: state.auth.user.accessRights,
    salesChannelEnums,
  };
};

const mapDispatchToProps = {
  getVehicleFlowState,
  getVehicleFlowPossibleStates,
  getVehicleFlowStatesLogs,
  getContractImages,
  getBookingByContractId,
  redirectToAppVehiclesList,
  _setCurrentVehicle,
  getVehicleById,
  updateContract,
  getContractById,
  getSalesChannelEnums,
  getVehicleContractInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarStatusInfo);
