import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const AuthGuard = (props) => {
  const {
    children,
    isAuthenticated,
    location,
    requestedLocation,
    setRequestedLocation,
  } = props;

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Redirect to="/auth/signin" />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Redirect to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node,
};

export default AuthGuard;
