import { createSlice } from '@reduxjs/toolkit';
import { B2B_CUSTOMER_USERS_ROLES } from '../../App/MainLayout/Customers/B2B/Details/Users/constants';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const vehiclesSlice = createSlice({
  name: 'customers',
  initialState: {
    b2cCustomers: [],
    b2bCustomers: [],
    B2BCustomersUsers: {},
    B2CCustomersUsers: {},
    rental: [],
    flex: [],
    brandings: [],
    b2cCustomersBrandings: [],
    // Customer of the current b2b user/admin who is logged in the app
    appropriateCustomer: {},
  },
  reducers: {
    _b2cCustomersFetchSucceeded: (state, action) => {
      state.b2cCustomers = action.payload;
    },
    _b2cCustomerCreateSucceeded: (state, action) => {
      state.b2cCustomers = [...state.b2cCustomers, action.payload];
    },
    _b2cCustomerUsersFetchSucceeded: (state, action) => {
      const { customerId, users } = action.payload;
      state.B2CCustomersUsers[customerId] = users;
    },
    _b2cCustomersBrandingsFetchSucceeded: (state, action) => {
      state.b2cCustomersBrandings = action.payload;
    },

    _b2bCustomersFetchSucceeded: (state, action) => {
      state.b2bCustomers = action.payload;
    },
    _b2bCustomerCreateSucceeded: (state, action) => {
      state.b2bCustomers = [...state.b2bCustomers, action.payload];
    },
    _b2bCustomerUsersFetchSucceeded: (state, action) => {
      const { customerId, users } = action.payload;
      state.B2BCustomersUsers[customerId] = users;
    },
    _b2bCustomersBrandingsFetchSucceeded: (state, action) => {
      state.brandings = action.payload;
    },
    _b2bCustomerFetchAsB2BCustomerUserSucceeded: (state, action) => {
      state.appropriateCustomer = action.payload;
    },
  },
});

export const {
  _b2cCustomersFetchSucceeded,
  _b2cCustomerCreateSucceeded,
  _b2cCustomerUsersFetchSucceeded,
  _b2cCustomersBrandingsFetchSucceeded,
  _b2bCustomersFetchSucceeded,
  _b2bCustomerCreateSucceeded,
  _b2bCustomerUsersFetchSucceeded,
  _b2bCustomersBrandingsFetchSucceeded,
  _b2bCustomerFetchAsB2BCustomerUserSucceeded,
} = vehiclesSlice.actions;

export const customersReducer = vehiclesSlice.reducer;

// #region B2C Customers
export const getAllB2CCustomers = () => async (dispatch) => {
  try {
    const response = await api.customers.b2c.getAll();
    await dispatch(_b2cCustomersFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewB2CCustomer = (customerData) => async (dispatch) => {
  try {
    const response = await api.customers.b2c.createNew(customerData);

    await dispatch(
      createNewB2CCustomerAdminUser({
        customerB2CId: response.id,
        email: customerData.email,
        displayName: customerData.name,
        postCode: customerData.postalCode,
        city: customerData.city,
        address: customerData.address,
        telephone: customerData.telephone,
        role: 7,
      }),
    );

    await dispatch(_b2cCustomerCreateSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewB2CCustomerAdminUser = (data) => async () => {
  try {
    await api.customers.b2c.createNewUser(data);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getAllB2CCustomerUsers = (customerId) => async (dispatch) => {
  try {
    const response = await api.customers.b2c.getAllUsers(customerId);
    await dispatch(
      _b2cCustomerUsersFetchSucceeded({ customerId, users: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getB2CCustomersBrandings = () => async (dispatch) => {
  try {
    const response = await api.customers.b2c.getBrandings();
    await dispatch(_b2cCustomersBrandingsFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
// #endregion

// #region B2B Customers
export const getAllB2BCustomers = () => async (dispatch) => {
  try {
    const response = await api.customers.b2b.getAll();
    await dispatch(_b2bCustomersFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewB2BCustomer = (customerData) => async (dispatch) => {
  try {
    const response = await api.customers.b2b.createNew(customerData);

    await dispatch(
      createNewB2BCustomerAdminUser({
        customerB2BId: response.id,
        email: customerData.email,
        displayName: customerData.name,
        postCode: customerData.postalCode,
        city: customerData.city,
        address: customerData.address,
        telephone: customerData.telephone,
        role: B2B_CUSTOMER_USERS_ROLES.ADMIN,
      }),
    );

    await dispatch(_b2bCustomerCreateSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewB2BCustomerAdminUser = (data) => async () => {
  try {
    await api.customers.b2b.createNewAdminUser(data);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewB2BCustomerUser = (data) => async () => {
  try {
    await api.customers.b2b.createNewUser(data);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getAllB2BCustomerUsers = (customerId) => async (dispatch) => {
  try {
    const response = await api.customers.b2b.getAllUsers(customerId);
    await dispatch(
      _b2bCustomerUsersFetchSucceeded({ customerId, users: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getCustomersBrandings = () => async (dispatch) => {
  try {
    const response = await api.customers.b2b.getBrandings();
    await dispatch(_b2bCustomersBrandingsFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getCustomerAsB2BCustomerUser = () => async (dispatch) => {
  try {
    const response = await api.customers.b2b.getCustomerAsB2BCustomerUser();
    await dispatch(_b2bCustomerFetchAsB2BCustomerUserSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
// #endregion
