import Box from '@mui/system/Box';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../common/components/DataGrid/DataGrid';

function B2BAdminCustomersList({
  t = () => {},
  customers = [],
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo = () => {},
}) {
  const columns = [
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'companyName',
      headerName: t('controlCategories.customers.b2b.customerName'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'email',
      headerName: t('controlCategories.customers.b2b.email'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'address',
      headerName: t('controlCategories.customers.b2b.address'),
    },
  ];

  const rows = customers.map((customer) => {
    return {
      ...customer,
      companyName: customer.name || '-',
      email: (customer.contactPerson && customer.contactPerson.email) || '-',
      address: (customer.address && customer.address.name) || '-',
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CommonDataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        noDataToShowMessage={t('common.messages.noDataToShow')}
        selectRowHandler={(rowData) => {
          redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo(rowData.id);
        }}
      />
    </Box>
  );
}

export default withTranslation()(B2BAdminCustomersList);
