import { connect } from 'react-redux';
import {
  changeVehicleBooking,
  switchToNextVehicleFlowState,
} from '../../../../../store/vehicles/slice';
import WaitingStartReturn from './WaitingStartReturn';

const mapStateToProps = (state, ownProps) => {
  const {
    bookings: { bookings },
  } = state;
  const {
    vehicle: { id: carId },
  } = ownProps;
  const currentVehicleBooking = bookings[carId];

  return {
    currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
    currentVehicleBooking,
  };
};

const mapDispatchToProps = {
  switchToNextVehicleFlowState,
  changeVehicleBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(WaitingStartReturn);
