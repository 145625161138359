import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const bookingSlice = createSlice({
  name: 'bookings',
  initialState: {
    bookings: {}, // map by carId
    locations: {},
  },
  reducers: {
    _bookingLocationsFetchSucceeded: (state, action) => {
      const { contractId, locations } = action.payload;
      state.locations[contractId] = locations;
    },
    _bookingFetchSucceeded: (state, action) => {
      const booking = action.payload;
      state.bookings[booking.carId] = booking;
    },
  },
});

export const bookingReducer = bookingSlice.reducer;

export const { _bookingLocationsFetchSucceeded, _bookingFetchSucceeded } =
  bookingSlice.actions;

export const loadBookingLocations = (contractId) => async (dispatch) => {
  const response = await api.booking.getLocations(contractId);
  if (response.error) {
    throw new Error(response.errors.Message);
  }
  dispatch(
    _bookingLocationsFetchSucceeded({ contractId, locations: response }),
  );
};

export const bookToOther = (bookingData) => async (dispatch) => {
  const response = await api.booking.createWithOther(bookingData);

  if (response.error) {
    throw new Error(response.errors.Message);
  }

  dispatch(_bookingFetchSucceeded(response));
};

export const bookToAldOrDealerLocation = (bookingData) => async (dispatch) => {
  const response = await api.booking.create(bookingData);

  if (response.error) {
    throw new Error(response.errors.Message);
  }

  dispatch(_bookingFetchSucceeded(response));
};

export const setBookingContactPerson = (contactPersonData) => async () => {
  try {
    await api.booking.addContactPerson(contactPersonData);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getBookingByContractId = (contractId) => async (dispatch) => {
  const response = await api.booking.getByContractId(contractId);

  if (response.error) {
    throw new Error(response.errors.Message);
  }

  dispatch(_bookingFetchSucceeded(response));
};
