import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import ImageLoader from '../ImageLoader/ImageLoader';

function GridGallery({
  maxRenderingImagesCount = 6,
  uploadIds = [],
  sx = {},
  setFsUploadIds = () => {},
}) {
  const [imagesHeight, setImagesHeight] = useState(0);
  const imagesContainerRef = useRef(null);

  useEffect(() => {
    if (!imagesHeight && imagesContainerRef.current) {
      setImagesHeight(imagesContainerRef.current.clientWidth / 4); // to make resolution as 3/4
    }
  }, [uploadIds]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}
      >
        {uploadIds.length !== 0 && (
          <Box
            ref={imagesContainerRef}
            sx={{
              m: 'auto',
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              pt: 1,
            }}
          >
            {uploadIds.map((uploadId, index) => {
              return index > maxRenderingImagesCount - 1 ? null : (
                <Box
                  key={index}
                  sx={{
                    width: '33.3%',
                    height: `${imagesHeight}px`,
                    borderRadius: '8px',
                    position: 'relative',
                    cursor: 'pointer',
                    pr: 1,
                    pb: 1,
                  }}
                  onClick={() => setFsUploadIds(uploadIds)}
                >
                  <ImageLoader
                    uploadId={uploadId}
                    sx={{
                      ...(index === maxRenderingImagesCount - 1 &&
                      uploadIds.length > maxRenderingImagesCount
                        ? { filter: 'brightness(0.6)' }
                        : {}),
                    }}
                  />

                  {index === maxRenderingImagesCount - 1 &&
                    uploadIds.length > maxRenderingImagesCount && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          width: '100%',
                          height: '100%',
                          borderRadius: 'inherit',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => setFsUploadIds(uploadIds)}
                      >
                        {`+${uploadIds.length - maxRenderingImagesCount}`}
                      </Box>
                    )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
}

export default GridGallery;
