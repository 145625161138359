import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { THEMES } from '../common/constants/constants';
import { lightShadows, darkShadows } from './shadows';

const baseOptions = {
  breakpoints: {
    values: {
      xxs: 0, // small phone
      xs: 321, // phone
      sm: 600, // tablets
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screens
    },
  },
  direction: 'ltr',
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 64,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '6px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
          width: '100%',
        },
        '#root': {
          height: '100%',
          width: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        iconButtonContainer: {
          display: 'none',
        },
        columnHeader: {
          '&:focus': {
            outline: 'none',
          },
        },
        root: {
          border: 'none',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        '& .CustomDataGrid': {
          backgroundColor: '#ff1fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
          },
        },
      },
      MuiIconButton: {
        variants: [
          {
            props: {
              variant: 'contained',
              color: 'primary',
            },
            style: {
              backgroundColor: '#5664d2',
              color: '#ffffff',
              boxShadow:
                '0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
              '&:hover': {
                backgroundColor: 'rgb(60, 70, 147)',
              },
            },
          },
          {
            props: {
              variant: 'contained-active',
              color: 'primary',
            },
            style: {
              backgroundColor: '#ffffff',
              color: '#5664d2',
              boxShadow:
                '0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
              '&:hover': {
                backgroundColor: '#f7f7f7',
              },
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: {
              variant: 'contained',
              color: 'secondary',
            },
            style: {
              backgroundColor: '#ffffff',
              color: '#42526E',
              boxShadow:
                '0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
              '&:hover': {
                backgroundColor: '#ffffff',
              },
            },
          },
          {
            props: {
              variant: 'contained-active',
              color: 'primary',
            },
            style: {
              backgroundColor: '#ffffff',
              color: '#5664d2',
              boxShadow:
                '0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
              '&:hover': {
                backgroundColor: '#f7f7f7',
              },
            },
          },
        ],
      },
    },
    palette: {
      action: {
        active: '#6b778c',
      },
      background: {
        default: '#ffffff',
        paper: '#F4F5F7',
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#5664d2',
      },
      secondary: {
        contrastText: '#ffffff',
        main: '#42526E',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
      separator: '#e0e0e0',
      carStates: {
        inProgress: {
          main: '#5664d2',
          text: '#5664d2',
        },
        washing: {
          main: '#FFB547',
          text: '#ffffff',
        },
        ready: {
          main: '#4caf50',
          text: '#ffffff',
        },
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(merge({}, baseOptions, themeOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

// createTheme({
//   components:{

//   }
// })
