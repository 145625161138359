import { connect } from 'react-redux';
import UserDetails from './UserDetails';

const mapStateToProps = (state, ownProps) => {
  const {
    aldUsers: { aldUsers },
  } = state;
  const { aldUserId } = ownProps;
  const aldUser = aldUsers.find((u) => u.id === aldUserId);

  return {
    aldUser,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
