import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { CircularProgress, ClickAwayListener, TextField } from '@mui/material';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

async function loadData() {
  await sleep(1000);
}

export default function SearchWithResultsField({
  onChange = async () => {
    await loadData();
  },
  sx = {},
  PopperProps = {},
  TextFieldProps = {},
  popperContentRenderer = () => {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'global-plate-search-popper' : undefined;

  const [searchValue, setSearchValue] = React.useState('');
  const [searchResultsWidth, setSearchResultsWidth] = React.useState(0);

  const clearSearch = () => {
    setSearchValue('');
  };

  React.useEffect(() => {
    if (anchorEl) {
      setSearchResultsWidth(anchorEl.offsetWidth);
    }
  }, [anchorEl]);

  const [loading, setLoading] = React.useState(false);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ width: '100%', ...sx }}>
        <TextField
          fullWidth
          InputProps={{
            sx: {
              borderBottomLeftRadius: open ? 0 : '16px',
              borderBottomRightRadius: open ? 0 : '16px',
              boxShadow: open
                ? '0px 0px 0px 1.5px rgba(0, 0, 0, 0.08);'
                : 'unset',
              zIndex: open ? 999999 : 'unset',
              '& input': {
                py: 0.7,
                px: 2,
              },
              '& fieldset': {
                ...(open ? { border: '0 !important' } : {}),
              },
            },
          }}
          value={searchValue}
          onChange={async (e) => {
            setSearchValue(e.target.value);
            if (e.target.value !== '') {
              handleClick(e);
            } else {
              handleClose();
            }
            try {
              setLoading(true);
              await onChange(e);
            } catch (error) {
              console.error(error);
            } finally {
              setLoading(false);
            }
          }}
          onFocus={(e) => {
            if (e.target.value !== '') {
              handleClick(e);
            }
          }}
          {...TextFieldProps}
        />

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          {...PopperProps}
          sx={{
            width: `${searchResultsWidth}px`,
            backgroundColor: 'background.default',
            borderBottomLeftRadius: '16px',
            borderBottomRightRadius: '16px',
            boxShadow: '0px 0px 0px 1.5px rgba(0, 0, 0, 0.08);',
            zIndex: 999999,
            p: 2,
            display: 'flex',
            ...PopperProps.sx,
          }}
        >
          {loading && <CircularProgress sx={{ m: 'auto' }} />}
          {!loading &&
            popperContentRenderer({ closeResults: handleClose, clearSearch })}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
