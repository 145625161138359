import { connect } from 'react-redux';
import CustomerInfo from './Info';

const mapStateToProps = (state, ownProps) => {
  const { b2cCustomers } = state.customers;
  const { customerId } = ownProps;
  const customer = b2cCustomers.find((c) => c.id === customerId);

  return {
    customer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfo);
