import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';
import { getVehicleAldAssessment } from '../vehicles/slice';

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState: {
    suppliers: [],
    suppliersUsers: {}, // by supplier id
    appropriateSupplier: {},
  },
  reducers: {
    _suppliersFetchSucceeded: (state, action) => {
      const { suppliers } = action.payload;
      state.suppliers = suppliers;
    },
    _supplierCreateSucceeded: () => {},
    _supplierUsersFetchSucceeded: (state, action) => {
      const { users, supplierId } = action.payload;
      state.suppliersUsers[supplierId] = users;
    },
    _supplierFetchAsSupplierUserSucceeded: (state, action) => {
      state.appropriateSupplier = action.payload;
    },
  },
});

export const suppliersReducer = suppliersSlice.reducer;
export const {
  _suppliersFetchSucceeded,
  _supplierCreateSucceeded,
  _supplierUsersFetchSucceeded,
  _supplierFetchAsSupplierUserSucceeded,
} = suppliersSlice.actions;

export const getAllSuppliers = () => async (dispatch) => {
  try {
    const response = await api.suppliers.getAll();
    await dispatch(_suppliersFetchSucceeded({ suppliers: response }));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewSupplier = (data) => async (dispatch) => {
  try {
    const response = await api.suppliers.createNew(data);
    await dispatch(_supplierCreateSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getSupplierUsers = (supplierId) => async (dispatch) => {
  try {
    const response = await api.suppliers.getSupplierUsers(supplierId);
    await dispatch(
      _supplierUsersFetchSucceeded({ supplierId, users: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewSupplierUser = (data) => async () => {
  try {
    await api.suppliers.createNewSupplierUser(data);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const pickUpAssessmentRepair =
  (vehicleId, repairId) => async (dispatch) => {
    try {
      await api.suppliers.pickUpAssessmentRepair(repairId);
      dispatch(getVehicleAldAssessment(vehicleId));
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const selectRepair = (repairId) => async () => {
  const response = await api.repairs.select(repairId);
  if (response.error) {
    throw new Error(response.errors.message);
  }
};

export const completeRepairs = (contractId) => async () => {
  const response = await api.repairs.complete(contractId);
  if (response.error) {
    throw new Error(response.errors.message);
  }
};

export const getSupplierAsSupplierUser = () => async (dispatch) => {
  try {
    const response = await api.suppliers.getSupplierAsSupplierUser();
    dispatch(_supplierFetchAsSupplierUserSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
