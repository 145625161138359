import { connect } from 'react-redux';
import DealerDetails from './DealerDetails';

const mapStateToProps = (state, ownProps) => {
  const {
    dealers: { dealers },
  } = state;
  const { dealerId } = ownProps;
  const dealer = (dealers || []).find((u) => u.id === dealerId);

  return {
    dealer,
    pathname: state.router.location.pathname,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DealerDetails);
