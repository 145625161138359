export class HttpRequestClientError extends Error {
  /**
   *
   * @param {string} message
   * @param {Array<string>} errors
   * @param {string} httpStatusCode
   */
  constructor(message, errors, httpStatusCode) {
    super(message);
    this.errors = errors;
    this.httpStatusCode = httpStatusCode;
  }
}
