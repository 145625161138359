import { Collapse, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  CheckCircle,
  ExpandLess,
  ExpandMore,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import DropDownWithoutLabel from '../../../../../common/components/DropDownInput/DropDownWithoutLabel';
import DeleteButton from '../../../../../common/components/DeleteButton/DeleteButton';
import React, { useState } from 'react';
import EditAssessmentSheetContainer from './EditAssessmentSheet.container';
import { getMenuValues } from '../../../../../utils/dropDownInput';
import {
  ASSESSMENT_SUPPLIER_STATUS,
  getUnusedDamageTypes,
  SUPPLIER_CONFIRM_STATUS,
} from '../../../../../common/constants/assessment';

const EditAssessmentRepair = ({
  isEditingBlocked,
  vehicleId,
  repair,
  damageTypes = {},
  damageTypesArray = [],
  assessment = {},
  suppliers = [],

  assignToSupplierGroup,
  unassignSupplier,
  assignSupplier,
  createNewSheet,
  deleteSheet,
}) => {
  const [expandedSheetIds, setExpandedSheetIds] = useState([]);

  const handleExpandSheet = (sheetId) =>
    setExpandedSheetIds([...expandedSheetIds, sheetId]);
  const handleRollupSheet = (sheetId) =>
    setExpandedSheetIds(expandedSheetIds.filter((x) => x !== sheetId));

  const unusedDamageTypes = getUnusedDamageTypes(
    damageTypesArray,
    assessment,
  ).filter((x) => x.damageTypeGroup.id === repair.damageTypeGroupId);

  const damageTypesValues = getMenuValues(unusedDamageTypes);

  const suppliersValues = getMenuValues(
    suppliers.filter((x) =>
      x.damageTypeGroups.some((y) => y.id === repair.damageTypeGroupId),
    ),
  );

  const isAnyDamagesExists = repair.assessmentSheets.some((sheet) =>
    sheet.damageDetails.some(
      (damage) =>
        damage.repairStatus === ASSESSMENT_SUPPLIER_STATUS.NEED_REPAIR,
    ),
  );

  return (
    <>
      {isAnyDamagesExists && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: '30px',
            height: '50px',
          }}
        >
          <Box sx={{ width: '45%' }}>
            <Typography
              sx={{
                fontSize: '14px',
                ml: '5px',
                color: '#253858',
              }}
            >
              Assign supplier group
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                background: '#F1F2F5',
                borderRadius: '15px',
                height: '56px',
              }}
            >
              <Box
                sx={{
                  m: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: isEditingBlocked ? 'default' : 'pointer',
                }}
                onClick={() => {
                  if (isEditingBlocked) {
                    return;
                  }
                  const fn =
                    repair.supplierConfirmStatus ===
                    SUPPLIER_CONFIRM_STATUS.WAITING_FOR_CONFIRMATION
                      ? unassignSupplier
                      : assignToSupplierGroup;
                  fn(vehicleId, repair.id);
                }}
              >
                {repair.supplierConfirmStatus ===
                SUPPLIER_CONFIRM_STATUS.WAITING_FOR_CONFIRMATION ? (
                  <CheckCircle />
                ) : (
                  <RadioButtonUnchecked />
                )}
              </Box>

              <Typography
                sx={{ fontSize: '14px', color: 'rgba(66, 82, 110, 0.5)' }}
              >
                Assign to supplier group
              </Typography>
            </Box>
          </Box>
          <Typography>or</Typography>
          <Box sx={{ width: '45%' }}>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#253858',
                ml: '5px',
              }}
            >
              Assign supplier
            </Typography>
            <DropDownWithoutLabel
              onChange={(ev) =>
                assignSupplier(vehicleId, repair.id, ev.target.value)
              }
              disabled={isEditingBlocked}
              value={repair.supplierId || ''}
              values={suppliersValues}
              sx={{ width: '100%' }}
            />
          </Box>
        </Box>
      )}

      {repair.assessmentSheets.map((sheet) => {
        const damageType = damageTypes[sheet.damageTypeId];
        const isExpanded = expandedSheetIds.includes(sheet.id);
        return (
          <React.Fragment key={sheet.id}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                background: 'rgba(86, 100, 210, 0.1)',
                borderTopLeftRadius: '15px',
                borderTopRightRadius: '15px',
                mt: '10px',
                height: '50px',
              }}
            >
              <Typography sx={{ ml: '15px', mr: 'auto' }}>
                {damageType.name}
              </Typography>
              {isExpanded && (
                <DeleteButton
                  sx={{
                    background: 'white',
                    height: '38px',
                    width: '38px',
                    minWidth: '38px',
                  }}
                  disabled={isEditingBlocked}
                  onClick={() => deleteSheet(vehicleId, repair.id, sheet.id)}
                />
              )}
              {isExpanded ? (
                <ExpandLess
                  sx={{
                    ml: '20px',
                    mr: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleRollupSheet(sheet.id)}
                />
              ) : (
                <ExpandMore
                  sx={{
                    ml: '20px',
                    mr: '20px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleExpandSheet(sheet.id)}
                />
              )}
            </Box>
            <Collapse in={isExpanded}>
              <Box sx={{ p: '25px', background: 'rgba(241, 242, 245, 0.2)' }}>
                <EditAssessmentSheetContainer
                  sheet={sheet}
                  vehicleId={vehicleId}
                  repairId={repair.id}
                  isEditingBlocked={isEditingBlocked}
                  approvalState={assessment.approvalState}
                />
              </Box>
            </Collapse>
          </React.Fragment>
        );
      })}

      {!isEditingBlocked && (
        <Box sx={{ mt: '10px' }}>
          {damageTypesValues && damageTypesValues.length > 0 && (
            <>
              <Typography sx={{ fontSize: '14px', ml: '5px' }}>
                Add new damage type
              </Typography>
              <DropDownWithoutLabel
                sx={{ width: '100%' }}
                value=""
                values={damageTypesValues}
                onChange={(ev) =>
                  createNewSheet(vehicleId, repair.id, ev.target.value, '')
                }
              />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default EditAssessmentRepair;
