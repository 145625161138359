import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { downloadBlob } from '../../../store/appCommon/slice';

function ImageLoader({ sx = {}, uploadId, src, download, ...otherProps }) {
  useEffect(async () => {
    if (uploadId) {
      if (!src) {
        await download(uploadId);
      }
    }
  }, [uploadId]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 'inherit',
        ...sx,
      }}
      component="img"
      src={src}
      {...otherProps}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  const { uploadId } = ownProps;

  return {
    src: state.app.cachedUploads[uploadId],
  };
};

const mapDispatchToProps = {
  download: downloadBlob,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageLoader);
