import { combineReducers } from '@reduxjs/toolkit';
import { appCommonReducer } from './appCommon/slice';
import { authReducer } from './auth/slice';
import { connectRouter } from 'connected-react-router';
import { vehiclesReducer } from './vehicles/slice';
import { customersReducer } from './customers/slice';
import { dealersReducer } from './dealers/slice';
import { redirectReducer } from './redirect/slice';
import { damageTypesReducer } from './damageTypes/slice';
import { suppliersReducer } from './suppliers/slice';
import { uploadReducer } from './upload/slice';
import { bookingReducer } from './booking/slice';
import { damageTypeGroupsReducer } from './damageTypeGroups/slice';
import { messagesReducer } from './messages/slice';
import { aldUsersReducer } from './aldUsers/slice';
import { repairsReducer } from './repairs/slice';
import { dashboardReducer } from './dashboard/slice';
import { backPathReducer } from './backPath/slice';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appCommonReducer,
    auth: authReducer,
    vehicles: vehiclesReducer,
    customers: customersReducer,
    dealers: dealersReducer,
    suppliers: suppliersReducer,
    redirect: redirectReducer,
    damageTypes: damageTypesReducer,
    damageTypeGroups: damageTypeGroupsReducer,
    aldUsers: aldUsersReducer,
    uploads: uploadReducer,
    bookings: bookingReducer,
    messages: messagesReducer,
    repairs: repairsReducer,
    dashboard: dashboardReducer,
    backPath: backPathReducer,
  });

export default createRootReducer;
