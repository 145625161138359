import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Button } from '@mui/material';

const DeleteButton = ({ onClick, sx, disabled, ...rest }) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={{ width: '56px', height: '56px', borderRadius: '16px', ...sx }}
    >
      <DeleteIcon {...rest} sx={{ fill: '#42526E' }} />
    </Button>
  );
};

export default DeleteButton;
