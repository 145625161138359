import { createSlice } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';
import { THEMES } from '../../common/constants/constants';
import { api } from '../../core/api';

export const appCommonSlice = createSlice({
  name: 'app',
  initialState: {
    isAppInitialized: false,
    settings: {
      theme: THEMES.LIGHT,
      responsiveFontSizes: false,
    },
    isNavOpened: !isMobile,
    fsUploadIds: [],
    onDeleteHandler: null,
    cachedUploads: {},
  },
  reducers: {
    _appInitialized: (state) => {
      state.isAppInitialized = true;
    },
    _prerenderConfigurationsStarted: () => {},
    _navEnabled: (state) => {
      state.isNavOpened = true;
    },
    _navDisabled: (state) => {
      state.isNavOpened = false;
    },
    _setFsUploadIds: (state, action) => {
      state.fsUploadIds = action.payload.fsUploadIds;
      state.onDeleteHandler = action.payload.onDeleteHandler;
    },
    _addCachedUpload: (state, action) => {
      state.cachedUploads[action.payload.uploadId] = action.payload.upload;
    },
  },
});

export const {
  _prerenderConfigurationsStarted,
  _appInitialized,
  _navEnabled,
  _navDisabled,
  _setFsUploadIds,
  _addCachedUpload,
} = appCommonSlice.actions;

export const appCommonReducer = appCommonSlice.reducer;

export const toggleNav = () => (dispatch, getState) => {
  const {
    app: { isNavOpened },
  } = getState();
  if (isNavOpened) {
    dispatch(_navDisabled());
  } else {
    dispatch(_navEnabled());
  }
};

export const hideNav = () => (dispatch) => {
  dispatch(_navDisabled());
};

export const setFsUploadIds =
  (fsUploadIds, onDeleteHandler = null) =>
  (dispatch) => {
    dispatch(_setFsUploadIds({ fsUploadIds, onDeleteHandler }));
  };

export const downloadBlob = (uploadId) => async (dispatch) => {
  try {
    const response = await api.download.image(uploadId);
    const src = URL.createObjectURL(response);
    dispatch(_addCachedUpload({ uploadId, upload: src }));
  } catch (error) {
    console.error(error);
  }
};
