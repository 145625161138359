import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const CarsIcon = createSvgIcon(
  <svg viewBox="0 0 18 14">
    <path
      fillOpacity="0.86"
      d="M8.99984 0.333496H3.1665C1.78317 0.333496 0.666504 1.45016 0.666504 2.8335V9.50016C0.666504 10.8835 1.78317 12.0002 3.1665 12.0002L2.33317 12.8335V13.6668H3.1665L4.83317 11.9752L6.49984 12.0002V7.8335H2.33317V1.9835L9.83317 2.00016V3.66683H11.4998V2.8335C11.4998 1.45016 10.3832 0.333496 8.99984 0.333496ZM3.1665 8.66683C3.62484 8.66683 3.99984 9.04183 3.99984 9.50016C3.99984 9.9585 3.62484 10.3335 3.1665 10.3335C2.70817 10.3335 2.33317 9.9585 2.33317 9.50016C2.33317 9.04183 2.70817 8.66683 3.1665 8.66683ZM16.1415 5.05016C16.0248 4.71683 15.7082 4.50016 15.3332 4.50016H9.3415C8.95817 4.50016 8.64984 4.71683 8.52484 5.05016L7.33317 8.47516L7.3415 13.0668C7.3415 13.3835 7.59984 13.6668 7.9165 13.6668H8.43317C8.74984 13.6668 8.99984 13.3502 8.99984 13.0335V12.0002H15.6665V13.0335C15.6665 13.3502 15.9248 13.6668 16.2415 13.6668H16.7498C17.0665 13.6668 17.3248 13.3835 17.3248 13.0668L17.3332 11.9252V8.47516L16.1415 5.05016ZM9.3415 5.3335H15.3332L16.1915 7.8335H8.48317L9.3415 5.3335ZM8.99984 10.3335C8.5415 10.3335 8.1665 9.9585 8.1665 9.50016C8.1665 9.04183 8.5415 8.66683 8.99984 8.66683C9.45817 8.66683 9.83317 9.04183 9.83317 9.50016C9.83317 9.9585 9.45817 10.3335 8.99984 10.3335ZM15.6665 10.3335C15.2082 10.3335 14.8332 9.9585 14.8332 9.50016C14.8332 9.04183 15.2082 8.66683 15.6665 8.66683C16.1248 8.66683 16.4998 9.04183 16.4998 9.50016C16.4998 9.9585 16.1248 10.3335 15.6665 10.3335Z"
    />
  </svg>,
  'Cars',
);

export default CarsIcon;
