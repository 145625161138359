import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ImageLoader from '../../ImageLoader/ImageLoader';
import { useEffect, useState } from 'react';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';

function FullScreenGalleryCarousel({
  uploadIds = [],
  sx = {},
  setFsUploadIds = () => {},
  onDeleteHandler = null,
}) {
  const [selectedImageUploadId, setSelectedImageUploadId] = useState(null);
  const [rotatingAngle, setRotatingAngle] = useState(0);

  useEffect(() => {
    if (uploadIds.length > 0 && !selectedImageUploadId) {
      handleSelectImageUploadId(uploadIds[0]);
    }
  }, [uploadIds]);

  const handleSelectImageUploadId = (uploadId) => {
    setSelectedImageUploadId(uploadId);
    setRotatingAngle(0);
  };

  const handleRotate = (value = null) => {
    if (value !== null) {
      setRotatingAngle(value);
      return;
    }
    if (rotatingAngle === 270) {
      setRotatingAngle(0);
      return;
    }
    setRotatingAngle(rotatingAngle + 90);
  };

  return (
    <>
      {uploadIds.length > 0 && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1100,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            ...sx,
          }}
        >
          <CloseIcon
            onClick={() => {
              setFsUploadIds([]);
              setSelectedImageUploadId(null);
            }}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              top: '30px',
              right: '30px',
              fill: 'white',
              zIndex: 1,
            }}
          />

          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              pt: 1,
            }}
          >
            {selectedImageUploadId && (
              <Box sx={{ position: 'relative' }}>
                <ImageLoader
                  uploadId={selectedImageUploadId}
                  sx={{
                    width: { xs: '100%', md: 'unset' },
                    objectFit: { xs: 'contain', md: 'cover' },
                    transform: `rotate(${rotatingAngle}deg)`,
                  }}
                />
                {onDeleteHandler && (
                  <DeleteOutlined
                    sx={{
                      position: 'absolute',
                      bottom: '25px',
                      left: 'calc(100% - 60px)',
                      height: '30px',
                      width: '30px',
                      fill: 'white',
                      cursor: 'pointer',
                      boxShadow: '4px 4px 10px rgb(0 0 0 / 70%)',
                    }}
                    onClick={async () => {
                      handleSelectImageUploadId(null);
                      await onDeleteHandler(selectedImageUploadId);
                      setFsUploadIds(
                        uploadIds.filter((x) => x !== selectedImageUploadId),
                        onDeleteHandler,
                      );
                    }}
                  />
                )}
                <WifiProtectedSetupIcon
                  sx={{
                    fill: 'white',
                    position: 'absolute',
                    bottom: '70px',
                    left: 'calc(100% - 60px)',
                    height: '30px',
                    width: '30px',
                    cursor: 'pointer',
                    boxShadow: '4px 4px 10px rgb(0 0 0 / 70%)',
                  }}
                  onClick={() => handleRotate()}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              mt: 'auto',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                maxWidth: '100%',
                overflowX: 'auto',
                display: 'grid',
                gridAutoFlow: 'column',
                padding: '10px',
                gridGap: '20px',
                webkitOverflowScrolling: 'touch',
              }}
            >
              {uploadIds.map((uId, index) => {
                return (
                  <Box sx={{ position: 'relative' }} key={index}>
                    <ImageLoader
                      uploadId={uId}
                      sx={{
                        height: '150px',
                        width: 'unset',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleSelectImageUploadId(uId);
                      }}
                    />
                    {onDeleteHandler && (
                      <DeleteOutlined
                        sx={{
                          position: 'absolute',
                          bottom: '15px',
                          left: 'calc(100% - 30px)',
                          height: '20px',
                          width: '20px',
                          fill: 'white',
                          cursor: 'pointer',
                        }}
                        onClick={async () => {
                          if (uId === selectedImageUploadId) {
                            handleSelectImageUploadId(null);
                          }
                          await onDeleteHandler(uId);
                          setFsUploadIds(
                            uploadIds.filter((x) => x !== uId),
                            onDeleteHandler,
                          );
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default FullScreenGalleryCarousel;
