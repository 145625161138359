export const takeResponseModelStringPropValue = (str, lang = 'en') => {
  try {
    const langMap = JSON.parse(str);
    return langMap[lang];
  } catch (e) {
    return str;
  }
};

export const byAccessRightsExecutor = async ({
  actionsByAccessRights = [],
  fallBackAction = () => {},
  userAccessRights = {},
}) => {
  /* eslint-disable-next-line */
  for (const rightsCase of actionsByAccessRights) {
    if (
      rightsCase.accessRights.every((accessRight) => {
        return userAccessRights[accessRight];
      })
    ) {
      /* eslint-disable-next-line */
      return await rightsCase.action();
    }
  }

  /* eslint-disable-next-line */
  return await fallBackAction();
};

// export const enhancedIsMobile = () => {
//   return isMobile || /(mar|marie|noh|jny)[-\w ]+/i.test(navigator.userAgent);
// };

export const appendDaysWord = (daysCount) => {
  return daysCount === 1 ? `${daysCount} day` : `${daysCount} days`;
};
