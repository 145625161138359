import { connect } from 'react-redux';
import { getSupplierUsers } from '../../../../../store/suppliers/slice';
import WashSupplierDetails from './Details';

const mapStateToProps = (state, ownProps) => {
  const { supplierId } = ownProps;
  const { suppliers } = state.suppliers;

  const supplier = suppliers.find((s) => s.id === supplierId);

  return {
    supplier,
    pathname: state.router.location.pathname,
  };
};

const mapDispatchToProps = { getSupplierUsers };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WashSupplierDetails);
