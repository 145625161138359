import { connect } from 'react-redux';
import {
  redirectBackByBreadcrumb,
  redirectToAppAldUsersCreateNewUser,
  redirectToAppAldUsersUserDetailsUserInfo,
} from '../../../store/redirect/slice';
import AldUsers from './AldUsers';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  redirectToAppAldUsersUserDetailsUserInfo,
  redirectToAppAldUsersCreateNewUser,
  redirectBackByBreadcrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(AldUsers);
