import Box from '@mui/system/Box';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import moment from 'moment';
import { appendDaysWord } from '../../../../common/helpers/common';
import {
  CAR_OWNER_ENUM,
  getSupplierCarStatusColorByCarStateEnum,
  getSupplierCarStatusTextByCarStateEnum,
} from './helpers';
import CustomDataGridV3 from '../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import { CustomDataGridToolbar } from '../AllCars/AllCars';
import {
  getCarsSortEnumByField,
  getSortEnumBySortTypeString,
} from '../../../../common/helpers/vehicles';

function SupplierCars({
  t = () => {},
  redirectToAppVehiclesListSupplierCarsCarDetails = () => {},
  getVehicles = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('vehiclesList.tabs.newReturnedCars.car'),
    },
    {
      field: 'carModel',
      headerName: t('vehiclesList.tabs.newReturnedCars.model'),
    },
    {
      field: 'plateNo',
      headerName: t('vehiclesList.tabs.newReturnedCars.numbers'),
    },
    {
      field: 'arrivedToALD',
      headerName: t('common.titles.arrivedToALD'),
    },
    {
      field: 'owner',
      headerName: t('common.titles.owner'),
    },
    {
      field: 'lastUpdate',
      headerName: t('common.titles.lastUpdate'),
    },
    {
      headerName: t('common.titles.status'),
      renderCell: (params = {}) => (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            minWidth: 0,
            px: 1,
          }}
        >
          <Button
            color={getSupplierCarStatusColorByCarStateEnum(
              params.row.currentStateEnum,
            )}
            variant="contained"
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              cursor: 'default',
            }}
          >
            <Typography
              noWrap
              sx={{
                width: '100%',
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              {getSupplierCarStatusTextByCarStateEnum(
                params.row.currentStateEnum,
                t,
              )}
            </Typography>
          </Button>
        </Box>
      ),
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      arrivedToALD: car.arrivedToALD
        ? moment(car.arrivedToALD).format('DD/MM/YYYY')
        : '-',
      lastUpdate: appendDaysWord(car.lastUpdate),
      owner: car.owner === CAR_OWNER_ENUM.ALD ? 'FLEET' : 'NFFLEET',
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sortModel, setSortModel] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListSupplierCarsCarDetails(params.row.id);
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        paginationCallback={async (offset, limit, plateNo, _sortModel) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [],
              undefined,
              plateNo,
              getCarsSortEnumByField(_sortModel.field),
              getSortEnumBySortTypeString(_sortModel.sort),
            );
            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[
          plateNumberSearchValue,
          sortModel,
        ]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
        onSortModelChange={(sortChangeData) => {
          if (sortChangeData && sortChangeData.length > 0) {
            setSortModel(sortChangeData[0]);
          }
        }}
      />
    </Box>
  );
}

export default withTranslation()(SupplierCars);
