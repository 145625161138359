import { connect } from 'react-redux';
import {
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers,
} from '../../../../../store/redirect/slice';
import Tabs from './Tabs';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
