import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const AppLogo = createSvgIcon(
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="40.000000pt"
    height="30.000000pt"
    viewBox="0 0 40.000000 30.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M77 260 c-14 -16 -34 -30 -44 -30 -15 0 -16 -2 -3 -10 12 -7 12 -12
2 -22 -16 -16 -15 -78 3 -102 20 -27 59 -14 86 30 19 32 21 41 11 67 -10 25
-9 35 3 54 20 30 35 29 35 -1 0 -13 6 -26 13 -28 22 -9 37 3 37 29 0 30 20 27
36 -6 16 -32 6 -59 -27 -77 -16 -8 -36 -28 -44 -44 l-16 -30 40 0 c22 0 43 -4
46 -10 12 -19 40 -10 53 17 15 33 15 68 1 95 -9 16 -8 23 2 29 11 6 9 9 -7 9
-11 0 -30 14 -42 30 -21 28 -27 30 -91 30 -65 0 -71 -2 -94 -30z"
      />
    </g>
  </svg>,
  'ALD',
);

export default AppLogo;
