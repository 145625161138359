export const parseServerErrors = (responseData) => {
  const linearErrorsArray = [];

  if (responseData.Errors) {
    const keys = Object.keys(responseData.Errors);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const someErrorsGroupArray = responseData.Errors[key];
      someErrorsGroupArray.forEach((error) => {
        linearErrorsArray.push(error);
      });
    }
  } else if (responseData.Message) {
    linearErrorsArray.push(responseData.Message);
  }

  return linearErrorsArray;
};
