import { connect } from 'react-redux';
import { fetchAllContractRepairs } from '../../../../../store/repairs/slice';
import { selectRepair } from '../../../../../store/suppliers/slice';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import DeliveredToWorkshop from './DeliveredToWorkshop';

const mapStateToProps = (state, ownProps) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
  damageTypeGroups: state.damageTypeGroups.groupsLookup,
  repairs: state.repairs.contractRepairs[ownProps.vehicle.contractId] || [],
});

const mapDispatchToProps = {
  fetchAllContractRepairs,
  switchToNextVehicleFlowState,
  selectRepair,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveredToWorkshop);
