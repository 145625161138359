import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { ROUTING_PATH_NAMES } from '../../../../common/constants/constants';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export const SUPPLIERS_TABS = {
  WASH: 0,
  NAF: 1,
  WORKSHOP: 2,
};

function SuppliersTabs({ t = () => {}, selectedTabId = SUPPLIERS_TABS.WASH }) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{ borderBottom: '1px solid #E0E0E0', mb: 1 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          LinkComponent={RouterLink}
          to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}`}
          id={SUPPLIERS_TABS.WASH}
          label={t('controlCategories.suppliers.wash.title')}
        />

        <Tab
          LinkComponent={RouterLink}
          to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}`}
          id={SUPPLIERS_TABS.NAF}
          label={t('controlCategories.suppliers.naf.title')}
        />

        <Tab
          LinkComponent={RouterLink}
          to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}`}
          id={SUPPLIERS_TABS.WORKSHOP}
          label={t('controlCategories.suppliers.workshop.title')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(SuppliersTabs);
