import { withTheme } from '@emotion/react';
import { Checkbox, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Box } from '@mui/system';
import EditIcon from '../../../../../icons/EditIcon';
import { ACCESSORY_STATUS } from '../../../../../common/constants/accessories';

const AccessoryItem = ({
  accessory: { name, description, accessoryRepairStatus },
  onCheck,
  onDelete,
  onEdit,
}) => {
  return (
    <Box
      sx={{
        maxWidth: '300px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#F2F3FC',
        borderRadius: '14px',
        p: '14px',
        m: '6px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '70%' }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            fontFamily: 'Roboto',
            letterSpacing: '0.1px',
            lineHeight: '157%',
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          variant="h7"
        >
          {name}
        </Typography>
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          variant="h7"
        >
          {description}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '50%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Checkbox
            onClick={(e) => onCheck(e.target.checked)}
            checked={accessoryRepairStatus === ACCESSORY_STATUS.NEED_REPAIR}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <EditIcon
            onClick={onEdit}
            sx={{
              m: '9px',
              width: '24px',
              height: '24px',
              opacity: '60%',
              cursor: 'pointer',
            }}
          />
          <DeleteIcon
            onClick={onDelete}
            sx={{
              m: '9px',
              width: '24px',
              height: '24px',
              opacity: '60%',
              cursor: 'pointer',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withTheme(AccessoryItem);
