import { appCommonConfig } from '../../../config';
import { jwtApiClient } from '../../../core/api';
import { _prerenderConfigurationsStarted } from '../../appCommon/slice';
import {
  fetchUserData,
  _authenticateSucceeded,
  _logoutSucceeded,
  _tokenRefreshSucceeded,
} from '../slice';

export const authMiddleware = ({ dispatch }) => {
  return (next) => async (action) => {
    switch (action.type) {
      case _prerenderConfigurationsStarted.type: {
        jwtApiClient.setHostUrl(appCommonConfig.apiHostUrl);

        break;
      }

      case _tokenRefreshSucceeded.type: {
        const { jwtToken } = action.payload;
        jwtApiClient.setJwtToken(jwtToken);

        break;
      }

      case _authenticateSucceeded.type: {
        const { jwtToken } = action.payload;
        jwtApiClient.setJwtToken(jwtToken);

        dispatch(fetchUserData(action.payload));

        break;
      }

      case _logoutSucceeded.type: {
        jwtApiClient.clearJwtToken();

        break;
      }

      default:
        break;
    }

    return await next(action);
  };
};
