import { connect } from 'react-redux';
import BreadcrumbsGenerator from './BreadcrumbsGenerator';

const mapStateToProps = (state) => ({
  location: state.router.location,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BreadcrumbsGenerator);
