import { connect } from 'react-redux';
import { getAllB2BCustomers } from '../../../../store/customers/slice';
import { redirectToAppCustomersB2BCustomerDetailsCustomerInfo } from '../../../../store/redirect/slice';
import B2BCustomers from './B2B';

const mapStateToProps = (state) => {
  return {
    customers: state.customers.b2bCustomers,
  };
};

const mapDispatchToProps = {
  redirectToAppCustomersB2BCustomerDetailsCustomerInfo,
  getAllB2BCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(B2BCustomers);
