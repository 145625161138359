import { connect } from 'react-redux';
import { loadBookingLocations } from '../../../../../store/booking/slice';
import WaitingBooksDateAndTime from './WaitingBooksDateAndTime';

const mapStateToProps = (state, ownProps) => {
  const { vehicle } = ownProps;
  const { locations } = state.bookings;

  return {
    locations: locations[vehicle.contractId],
  };
};

const mapDispatchToProps = {
  loadBookingLocations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WaitingBooksDateAndTime);
