import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';
import objFromArray from '../../utils/objFromArray';

export const damageTypesSlice = createSlice({
  name: 'damageTypes',
  initialState: {
    damageTypes: [],
    damageTypesLookup: {},
  },
  reducers: {
    _damageTypesFetchSucceeded: (state, action) => {
      const { damageTypes } = action.payload;
      state.damageTypes = damageTypes;
      state.damageTypesLookup = objFromArray(damageTypes);
    },
  },
});

export const damageTypesReducer = damageTypesSlice.reducer;
export const { _damageTypesFetchSucceeded } = damageTypesSlice.actions;

export const getAllDamageTypes = () => async (dispatch) => {
  try {
    const response = await api.damageTypes.getAll();
    await dispatch(_damageTypesFetchSucceeded({ damageTypes: response }));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
