import { Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTheme } from '@mui/styles';

function CustomPagination({ page = 0, totalPages = 0, onChange = () => {} }) {
  return (
    <Pagination
      sx={{ m: 'auto' }}
      color="primary"
      size="large"
      page={page}
      count={totalPages || 1}
      onChange={onChange}
    />
  );
}

function CustomDataGridV3({
  rows = [],
  columns = [],
  paginationData = { currentPage: 0, totalPages: 0, totalCount: 0 },
  paginationCallback = () => {},
  sx = {},
  components = {},
  additionalPaginationCallbackTriggerData = [],
  componentsProps = {},
  loading = false,
  pageSize,
  sortingMode = 'server',
  onSortModelChange = () => {},
  onRowClick = () => {},
}) {
  const [limit, setLimit] = useState(pageSize || 0);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(1);

  const params = [offset, limit, ...additionalPaginationCallbackTriggerData];
  const paginationCallbackDebounce = useCallback(
    _.debounce(paginationCallback, 500),
    [paginationCallback],
  );

  // on params change
  useEffect(() => {
    if (limit !== 0) {
      paginationCallbackDebounce(...params);
    }
  }, [offset, limit, ...additionalPaginationCallbackTriggerData]);

  const handleSortModelChange = React.useCallback(
    (sortModel) => {
      onSortModelChange(sortModel);
      // Here you save the data you need from the sort model
    },
    [onSortModelChange],
  );

  const enhancedComponentsProps = {};
  Object.keys(componentsProps).forEach((k) => {
    enhancedComponentsProps[k] = { ...componentsProps[k], setOffset, setPage };
  });

  const theme = useTheme();
  const colWidth = `${100 / columns.length}%`;

  return (
    <DataGrid
      onRowClick={onRowClick}
      sortingMode={sortingMode}
      onSortModelChange={handleSortModelChange}
      loading={loading}
      pagination
      disableSelectionOnClick
      sx={{
        [theme.breakpoints.up('md')]: {
          '& .MuiDataGrid-main': {
            '& .MuiDataGrid-columnHeaders': {
              '& .MuiDataGrid-columnHeadersInner': {
                width: '100%',
                ' div[role="row"]': {
                  width: '100%',
                  '& .MuiDataGrid-columnHeader': {
                    '&:not(:last-child)': {
                      width: `${colWidth} !important`,
                      minWidth: `${colWidth} !important`,
                      maxWidth: `${colWidth} !important`,
                    },
                  },
                },
              },
            },
            '& .MuiDataGrid-virtualScroller': {
              '& .MuiDataGrid-virtualScrollerContent': {
                width: '100% !important',
                '& .MuiDataGrid-virtualScrollerRenderZone': {
                  width: '100%',
                  '& .MuiDataGrid-row': {
                    userSelect: 'none',
                    cursor: 'pointer',
                    width: '100%',
                    '& .MuiDataGrid-cell[role="cell"]': {
                      '&:not(:last-child)': {
                        minWidth: `${colWidth} !important`,
                        maxWidth: `${colWidth} !important`,
                      },
                      '&:focus-within': {
                        outline: 'none !important',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ...sx,
      }}
      rows={rows}
      columns={columns}
      components={{
        Pagination: CustomPagination,
        ...components,
      }}
      {...(pageSize
        ? {}
        : {
            autoPageSize: true,
            onPageSizeChange: (pSize) => {
              setLimit(pSize);
            },
          })}
      componentsProps={{
        pagination: {
          onChange: (e, p) => {
            setPage(p);
            setOffset((p - 1) * limit);
          },
          page,
          totalPages: paginationData.totalPages,
        },
        ...enhancedComponentsProps,
      }}
    />
  );
}

export default CustomDataGridV3;
