import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Divider from '@mui/material/Divider';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useMounted from '../../../../common/hooks/useMounted';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';

const CAR_CREATING_TABS = {
  CAR_INFO: 0,
  ACCESSORIES: 1,
  NAF_ASSESSMENT: 2,
};

function AddNewCar({ t = () => {} }) {
  const mounted = useMounted();
  const [selectedTab, selectTab] = useState(CAR_CREATING_TABS.CAR_INFO);

  return (
    <Formik
      initialValues={{}}
      validationSchema={Yup.object().shape({
        car: Yup.string().required('Car field is required'),
        model: Yup.string().required('Model field is required'),
        number: Yup.string().required('Number field is required'),
        year: Yup.string().required('Year field is required'),
        mileage: Yup.string().required('Mileage field is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          // todo: api call

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            flex: 1,
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.default',
              flex: 5,
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="h5" sx={{ p: 1, pl: 2 }}>
              {t('vehiclesList.createNewCar.addCarInfo')}
            </Typography>
            <Divider />

            <Tabs value={selectedTab}>
              <Tab
                sx={{ flex: 1 }}
                id={CAR_CREATING_TABS.CAR_INFO}
                label={t('vehiclesList.createNewCar.tabs.carInfo.title')}
                onClick={() => {
                  selectTab(CAR_CREATING_TABS.CAR_INFO);
                }}
              />
              <Tab
                sx={{ flex: 1 }}
                id={CAR_CREATING_TABS.ACCESSORIES}
                label={t('vehiclesList.createNewCar.tabs.accessories.title')}
                onClick={() => {
                  selectTab(CAR_CREATING_TABS.ACCESSORIES);
                }}
              />
              <Tab
                sx={{ flex: 1 }}
                id={CAR_CREATING_TABS.NAF_ASSESSMENT}
                label={t('vehiclesList.createNewCar.tabs.nafAssessment.title')}
                onClick={() => {
                  selectTab(CAR_CREATING_TABS.NAF_ASSESSMENT);
                }}
              />
            </Tabs>

            <Divider />

            <Box
              sx={{
                display:
                  selectedTab === CAR_CREATING_TABS.CAR_INFO ? 'flex' : 'none',
                flexDirection: 'column',
                px: 20,
                py: 5,
                flex: 1,
                overflow: 'auto',
              }}
            >
              <TextField
                autoFocus
                error={Boolean(touched.car && errors.car)}
                fullWidth
                helperText={touched.car && errors.car}
                label={t('vehiclesList.createNewCar.tabs.carInfo.car')}
                margin="normal"
                name="car"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.car}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.model && errors.model)}
                fullWidth
                helperText={touched.model && errors.model}
                label={t('vehiclesList.createNewCar.tabs.carInfo.model')}
                margin="normal"
                name="model"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.model}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.number && errors.number)}
                fullWidth
                helperText={touched.number && errors.number}
                label={t('vehiclesList.createNewCar.tabs.carInfo.number')}
                margin="normal"
                name="number"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.number}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.year && errors.year)}
                fullWidth
                helperText={touched.year && errors.year}
                label={t('vehiclesList.createNewCar.tabs.carInfo.year')}
                margin="normal"
                name="year"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.year}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.mileage && errors.mileage)}
                fullWidth
                helperText={touched.mileage && errors.mileage}
                label={t('vehiclesList.createNewCar.tabs.carInfo.mileage')}
                margin="normal"
                name="mileage"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.mileage}
                variant="outlined"
              />
            </Box>
          </Box>

          <Box
            sx={{
              flex: 2,
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              ml: 2,
              height: '100%',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'background.default',
                borderRadius: '16px',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                mb: 4,
              }}
            >
              <Typography variant="h5" sx={{ p: 1, pl: 2 }}>
                {t('vehiclesList.createNewCar.addCarPhoto')}
              </Typography>
              <Divider />

              {/* <FileDropzone /> */}
            </Box>

            <Button variant="contained" color="primary" disabled={isSubmitting}>
              {t('vehiclesList.createNewCar.saveNewCar')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

export default withTranslation()(AddNewCar);
