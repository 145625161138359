import { connect } from 'react-redux';
import {
  addImageToSheet,
  removeImageFromSheet,
  changeDamageDetailName,
  changeDamageDetailPrice,
  changeDamageDetailStatus,
  changeSheetMeasurements,
  createNewDamageDetail,
  deleteDamageDetail,
} from '../../../../../store/vehicles/slice';
import { setFsUploadIds } from '../../../../../store/appCommon/slice';
import EditAssessmentSheet from './EditAssessmentSheet';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  changeDamageDetailName,
  changeDamageDetailPrice,
  changeSheetMeasurements,
  changeDamageDetailStatus,
  createNewDamageDetail,
  deleteDamageDetail,
  addImageToSheet,
  removeImageFromSheet,
  setFsUploadIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAssessmentSheet);
