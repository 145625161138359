import { B2B_CUSTOMER_USERS_ROLES } from './constants';

export const getCustomerUserRoleNameByRole = (
  t = () => {},
  role = B2B_CUSTOMER_USERS_ROLES.USER,
) => {
  switch (role) {
    case B2B_CUSTOMER_USERS_ROLES.ADMIN:
      return t('controlCategories.customers.b2b.details.users.admin');
    case B2B_CUSTOMER_USERS_ROLES.USER:
      return t('controlCategories.customers.b2b.details.users.user');

    default:
      return t('controlCategories.customers.b2b.details.users.user');
  }
};
