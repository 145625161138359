import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';
import objFromArray from '../../utils/objFromArray';

export const damageTypeGroupsSlice = createSlice({
  name: 'damageTypeGroups',
  initialState: {
    groups: [],
    groupsLookup: {},
  },
  reducers: {
    _damageTypeGroupsFetchSucceeded: (state, action) => {
      const { groups } = action.payload;
      state.groups = groups;
      state.groupsLookup = objFromArray(groups);
    },
  },
});

export const damageTypeGroupsReducer = damageTypeGroupsSlice.reducer;

export const { _damageTypeGroupsFetchSucceeded } =
  damageTypeGroupsSlice.actions;

export const getAllDamageTypeGroups = () => async (dispatch) => {
  try {
    const response = await api.damageTypeGroups.getAll();
    dispatch(_damageTypeGroupsFetchSucceeded({ groups: response }));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
