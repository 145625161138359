import { connect } from 'react-redux';
import { getAllB2BCustomerUsers } from '../../../../../../store/customers/slice';
import {
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser,
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser,
  redirectToAppCustomersB2BCustomerDetailsUsersUserDetails,
} from '../../../../../../store/redirect/slice';
import CustomerUsers from './Users';

const mapStateToProps = (state, ownProps) => {
  const { customerId } = ownProps;

  return {
    customerUsers: state.customers.B2BCustomersUsers[customerId],
  };
};

const mapDispatchToProps = {
  getAllB2BCustomerUsers,
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser,
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser,
  redirectToAppCustomersB2BCustomerDetailsUsersUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUsers);
