import { connect } from 'react-redux';
import {
  createNewB2BCustomer,
  getCustomersBrandings,
} from '../../../../../store/customers/slice';
import { redirectBackByBreadcrumb } from '../../../../../store/redirect/slice';
import CreateNewB2BCustomerForm from './B2B';

const mapStateToProps = (state) => ({
  customerBrandings: state.customers.brandings,
});

const mapDispatchToProps = {
  createNewB2BCustomer,
  redirectBackByBreadcrumb,
  getCustomersBrandings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewB2BCustomerForm);
