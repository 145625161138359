import { connect } from 'react-redux';
import {
  assignSupplier,
  assignToSupplierGroup,
  createNewSheet,
  deleteSheet,
  unassignSupplier,
} from '../../../../../store/vehicles/slice';
import EditAssessmentRepair from './EditAssessmentRepair';

const mapStateToProps = (state) => {
  return {
    damageTypes: state.damageTypes.damageTypesLookup,
    damageTypesArray: state.damageTypes.damageTypes,
    suppliers: state.suppliers.suppliers,
  };
};

const mapDispatchToProps = {
  assignToSupplierGroup,
  unassignSupplier,
  assignSupplier,
  createNewSheet,
  deleteSheet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditAssessmentRepair);
