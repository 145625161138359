import { connect } from 'react-redux';
import { getAllDealerUsers } from '../../../../../store/dealers/slice';
import {
  redirectToAppDealersDealerDetailsDealerUsersCreateNewUser,
  redirectToAppDealersDealerDetailsDealerUsersUserInfo,
} from '../../../../../store/redirect/slice';

import DealerUsers from './Users';

const mapStateToProps = (state, ownProps) => {
  const { dealerId } = ownProps;

  return {
    dealerUsers: state.dealers.dealerUsers[dealerId],
  };
};

const mapDispatchToProps = {
  getAllDealerUsers,
  redirectToAppDealersDealerDetailsDealerUsersCreateNewUser,
  redirectToAppDealersDealerDetailsDealerUsersUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerUsers);
