import { connect } from 'react-redux';
import { redirectToAppVehiclesListSupplierCarsCarDetails } from '../../../../store/redirect/slice';
import { getVehicles } from '../../../../store/vehicles/slice';
import SupplierCars from './SupplierCars';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListSupplierCarsCarDetails,
  getVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupplierCars);
