import { connect } from 'react-redux';
import { SUPPLIERS_TYPES } from '../../../../common/constants/suppliers';
import { redirectToAppSuppliersNAFSupplierDetailsInfo } from '../../../../store/redirect/slice';
import { getAllSuppliers } from '../../../../store/suppliers/slice';
import NAFSuppliers from './NAF';

const mapStateToProps = (state) => ({
  suppliers: state.suppliers.suppliers.filter(
    (s) => s.supplierType === SUPPLIERS_TYPES.NAF,
  ),
});

const mapDispatchToProps = {
  redirectToAppSuppliersNAFSupplierDetailsInfo,
  getAllSuppliers,
};

export default connect(mapStateToProps, mapDispatchToProps)(NAFSuppliers);
