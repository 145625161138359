import { connect } from 'react-redux';
import { redirectToAppVehiclesListInProcessCarsCarDetails } from '../../../../store/redirect/slice';
import {
  getVehicleFlowStatesLogs,
  getVehicles,
} from '../../../../store/vehicles/slice';
import AlreadyInProcessCars from './AlreadyInProcessCars';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListInProcessCarsCarDetails,
  getVehicleFlowStatesLogs,
  getVehicles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlreadyInProcessCars);
