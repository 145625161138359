import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Button,
  Divider,
  FormHelperText,
  MenuItem,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMounted from '../../../../../common/hooks/useMounted';
import { withTranslation } from 'react-i18next';
import { withTheme } from '@emotion/react';
import CommonFormTitle from '../../../../../common/components/FormTitle/FormTitle';

function CreateNewB2BCustomerForm({
  t = () => {},
  theme = {},
  createNewB2BCustomer = () => {},
  redirectBackByBreadcrumb = () => {},
  getCustomersBrandings = () => {},
  customerBrandings = [],
}) {
  const mounted = useMounted();

  useEffect(() => {
    getCustomersBrandings();
  }, []);

  return (
    <Formik
      initialValues={{
        name: '',
        additionalName: '',
        customerNumber: '',
        organizationNumber: '',
        customerTypeId: '',
        contactName: '',
        postalCode: '',
        city: '',
        address: '',
        email: '',
        phoneNumber: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name field is required'),
        additionalName: Yup.string().required(
          'Additional name field is required',
        ),
        customerNumber: Yup.string().required(
          'Customer number field is required',
        ),
        organizationNumber: Yup.string().required(
          'Organization number field is required',
        ),
        customerTypeId: Yup.string().required(
          'Customer type id field is required',
        ),
        contactName: Yup.string().required('Contact name field is required'),
        postalCode: Yup.string().required('Postal code field is required'),
        city: Yup.string().required('City field is required'),
        address: Yup.string().required('Address field is required'),
        email: Yup.string()
          .email('Email must be valid')
          .required('Email field is required'),
        phoneNumber: Yup.string().required('Phone number field is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await createNewB2BCustomer({
            name: values.name,
            additionalName: values.additionalName,
            customerNo: values.customerNumber,
            organizationNo: values.organizationNumber,
            customerTypeId: values.customerTypeId,
            contactName: values.contactName,
            postCode: values.postalCode,
            city: values.city,
            address: values.address,
            email: values.email,
            telephone: values.phoneNumber,
          });
          redirectBackByBreadcrumb();

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            maxHeight: '100%',
          }}
        >
          {
            // #region Customer info
          }
          <CommonFormTitle
            text={t('controlCategories.customers.b2b.create.customerInfo')}
            icon={<InfoOutlinedIcon />}
          />

          <Divider />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 2.5,
              py: 1,
              paddingBottom: 3,
              backgroundColor: theme.palette.background.default,
              borderBottomLeftRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                overflow: 'auto',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label={t('controlCategories.customers.b2b.create.name')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(
                    touched.additionalName && errors.additionalName,
                  )}
                  fullWidth
                  helperText={touched.additionalName && errors.additionalName}
                  label={t(
                    'controlCategories.customers.b2b.create.additionalName',
                  )}
                  margin="normal"
                  name="additionalName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.additionalName}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.contactName && errors.contactName)}
                  fullWidth
                  helperText={touched.contactName && errors.contactName}
                  label={t(
                    'controlCategories.customers.b2b.create.contactName',
                  )}
                  margin="normal"
                  name="contactName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.contactName}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(
                    touched.customerNumber && errors.customerNumber,
                  )}
                  fullWidth
                  helperText={touched.customerNumber && errors.customerNumber}
                  label={t(
                    'controlCategories.customers.b2b.create.customerNumber',
                  )}
                  margin="normal"
                  name="customerNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.customerNumber}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(
                    touched.organizationNumber && errors.organizationNumber,
                  )}
                  fullWidth
                  helperText={
                    touched.organizationNumber && errors.organizationNumber
                  }
                  label={t(
                    'controlCategories.customers.b2b.create.organizationNumber',
                  )}
                  margin="normal"
                  name="organizationNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.organizationNumber}
                  variant="outlined"
                />

                <TextField
                  select
                  label={t(
                    'controlCategories.customers.b2b.create.customerTypeId',
                  )}
                  value={values.customerTypeId}
                  name="customerTypeId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched.customerTypeId && errors.customerTypeId}
                  variant="outlined"
                  margin="normal"
                >
                  {customerBrandings.map((branding) => (
                    <MenuItem key={branding.id} value={branding.id}>
                      {branding.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box
                sx={{
                  ml: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  fullWidth
                  helperText={touched.postalCode && errors.postalCode}
                  label={t('controlCategories.customers.b2b.create.postalCode')}
                  margin="normal"
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.postalCode}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  label={t('controlCategories.customers.b2b.create.city')}
                  margin="normal"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.city}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.address && errors.address)}
                  fullWidth
                  helperText={touched.address && errors.address}
                  label={t('controlCategories.customers.b2b.create.address')}
                  margin="normal"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.address}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('controlCategories.customers.b2b.create.email')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  label={t(
                    'controlCategories.customers.b2b.create.phoneNumber',
                  )}
                  margin="normal"
                  name="phoneNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.phoneNumber}
                  variant="outlined"
                />
              </Box>
            </Box>

            {errors.submit && (
              <Box sx={{ mt: 1 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
          {
            // #endregion
          }

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isSubmitting ||
              !values.name ||
              !values.additionalName ||
              !values.customerNumber ||
              !values.organizationNumber ||
              !values.customerTypeId ||
              !values.contactName ||
              !values.postalCode ||
              !values.city ||
              !values.address ||
              !values.email ||
              !values.phoneNumber
            }
            sx={{ width: '25%', ml: 'auto', mt: 3 }}
          >
            {t('common.buttons.save')}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default withTranslation()(withTheme(CreateNewB2BCustomerForm));
