import { STEP_STATES } from './constants';

export const getStepColorByStepIndexAndActiveStepIndex = (
  stepIndex,
  activeStepIndex,
  { doneStepColor, activeStepColor, todoStepColor },
) => {
  if (stepIndex < activeStepIndex) {
    return doneStepColor;
  }
  if (stepIndex > activeStepIndex) {
    return todoStepColor;
  }
  if (stepIndex === activeStepIndex) {
    return activeStepColor;
  }

  return todoStepColor;
};

export const getStepColorByStepState = (
  stepState,
  { doneStepColor, activeStepColor, todoStepColor },
) => {
  switch (stepState) {
    case STEP_STATES.DONE:
      return doneStepColor;
    case STEP_STATES.IN_PROGRESS:
      return activeStepColor;
    case STEP_STATES.TODO:
      return todoStepColor;

    default:
      return todoStepColor;
  }
};
