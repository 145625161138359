import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../../../common/components/DataGrid/DataGrid';

function CustomerUsers({
  t = () => {},
  customerId = '',
  customerUsers = [],
  getAllB2BCustomerUsers = () => {},
  redirectToAppCustomersB2CCustomerDetailsUsersUserDetails = () => {},
}) {
  useEffect(async () => {
    try {
      await getAllB2BCustomerUsers(customerId);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const rows = customerUsers.map((u) => {
    return {
      ...u,
      address: (u.address && u.address.name) || '-',
      telephone: u.telephone || '-',
    };
  });
  const columns = [
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'displayName',
      headerName: t('controlCategories.customers.b2b.details.users.name'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'email',
      headerName: t('controlCategories.customers.b2b.details.users.email'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'telephone',
      headerName: t(
        'controlCategories.customers.b2b.details.users.phoneNumber',
      ),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'address',
      headerName: t('controlCategories.customers.b2b.details.users.address'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      headerName: t('controlCategories.customers.b2b.details.users.role'),
      renderRowCell: () => {
        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              minWidth: '100px',
              px: 1,
            }}
          >
            <Typography>
              {t('controlCategories.customers.b2b.details.users.admin')}
            </Typography>
          </Box>
        );
      },
      filterPredicate: (rowData, filterValue) => {
        return t('controlCategories.customers.b2b.details.users.admin')
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
    },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.default',
          flex: 1,
          width: '100%',
          borderRadius: '16px',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <CommonDataGrid
          rows={rows}
          columns={columns}
          checkboxSelection
          noDataToShowMessage={t('common.messages.noDataToShow')}
          selectRowHandler={(rowData) => {
            redirectToAppCustomersB2CCustomerDetailsUsersUserDetails({
              customerId,
              customerUserId: rowData.id,
            });
          }}
        />
      </Box>
    </Box>
  );
}

export default withTranslation()(CustomerUsers);
