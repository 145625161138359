import { Button, Menu, MenuItem } from '@mui/material';
import { alpha, styled } from '@mui/system';
import React, { useRef } from 'react';

const ExpandableButtonMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#ffffff',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
    transitionDuration: '0s !important',

    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiDivider-root': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiMenuItem-root': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      borderTop: `1px solid ${theme.palette.divider}`,

      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function ExpandableButton({
  color = 'primary',
  variant = 'contained',
  startIcon = null,
  endIcon = null,
  items = [],
  sx = {},
  children,
}) {
  const ref = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isButtonExpanded = Boolean(anchorEl);

  const handleMenuPopupOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuPopupClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        ref={ref}
        color={color}
        variant={`${variant}${isButtonExpanded ? '-active' : ''}`}
        startIcon={startIcon}
        endIcon={endIcon}
        sx={{
          ...sx,
          borderTopLeftRadius: isButtonExpanded ? 18 : 64,
          borderTopRightRadius: isButtonExpanded ? 18 : 64,
          borderBottomLeftRadius: isButtonExpanded ? 0 : 64,
          borderBottomRightRadius: isButtonExpanded ? 0 : 64,
        }}
        onClick={handleMenuPopupOpen}
      >
        {children}
      </Button>

      <ExpandableButtonMenu
        anchorEl={anchorEl}
        open={isButtonExpanded}
        onClose={handleMenuPopupClose}
        sx={{
          '& .MuiPaper-root': {
            width: (ref.current && ref.current.clientWidth) || 100,
          },
        }}
      >
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuPopupClose();
                item.onClick();
              }}
              disableRipple
            >
              {item.text}
            </MenuItem>
          );
        })}
      </ExpandableButtonMenu>
    </>
  );
}

export default ExpandableButton;
