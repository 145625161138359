import { Button, Divider, FormHelperText, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { withTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMounted from '../../../../common/hooks/useMounted';
import CommonFormTitle from '../../../../common/components/FormTitle/FormTitle';

function CreateNewDealer({
  t = () => {},
  createNewDealer = () => {},
  redirectToAppDealers = () => {},
}) {
  const mounted = useMounted();

  return (
    <Formik
      initialValues={{
        name: '',
        organizationNo: '',
        postCode: '',
        city: '',
        address: '',
        email: '',
        telephone: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name field is required'),
        organizationNo: Yup.string().required(
          'Organization number field is required',
        ),
        postCode: Yup.string().required('Postal code field is required'),
        city: Yup.string().required('City field is required'),
        address: Yup.string().required('Address field is required'),
        email: Yup.string()
          .email('Email must be valid')
          .required('Email field is required'),
        telephone: Yup.string().required('Phone field is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await createNewDealer({
            name: values.name,
            organizationNo: values.organizationNo,
            postCode: values.postCode,
            city: values.city,
            address: values.address,
            email: values.email,
            phone: values.telephone,
          });
          await redirectToAppDealers();

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <CommonFormTitle
            text={t('controlCategories.dealers.createNewDealer')}
            icon={<InfoOutlinedIcon />}
            sx={{ mt: 3 }}
          />

          <Divider />

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              px: 2.5,
              py: 1,
              paddingBottom: 3,
              backgroundColor: 'background.default',
              borderBottomLeftRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
              overflow: 'hidden',
            }}
          >
            <Box sx={{ display: 'flex', flex: 1, overflow: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label={t('common.titles.name')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(
                    touched.organizationNo && errors.organizationNo,
                  )}
                  fullWidth
                  helperText={touched.organizationNo && errors.organizationNo}
                  label={t('common.titles.organizationNo')}
                  margin="normal"
                  name="organizationNo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.organizationNo}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('common.titles.email')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.telephone && errors.telephone)}
                  fullWidth
                  helperText={touched.telephone && errors.telephone}
                  label={t('common.titles.telephone')}
                  margin="normal"
                  name="telephone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.telephone}
                  variant="outlined"
                />
              </Box>

              <Box
                sx={{
                  ml: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.postCode && errors.postCode)}
                  fullWidth
                  helperText={touched.postCode && errors.postCode}
                  label={t('common.titles.postCode')}
                  margin="normal"
                  name="postCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.postCode}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  label={t('common.titles.city')}
                  margin="normal"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.city}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.address && errors.address)}
                  fullWidth
                  helperText={touched.address && errors.address}
                  label={t('common.titles.address')}
                  margin="normal"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.address}
                  variant="outlined"
                />
              </Box>
            </Box>

            {errors.submit && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>

          <Button
            variant="contained"
            color="primary"
            disabled={
              isSubmitting ||
              !values.name ||
              !values.organizationNo ||
              !values.telephone ||
              !values.postCode ||
              !values.city ||
              !values.email ||
              !values.address
            }
            sx={{ width: '25%', ml: 'auto', mt: 3 }}
            type="submit"
          >
            {t('common.buttons.create')}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default withTranslation()(CreateNewDealer);
