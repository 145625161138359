import { isMobile } from 'react-device-detect';
import {
  redirectToAppAldUsers,
  redirectToAppCustomers,
  redirectToAppDashboard,
  redirectToAppDealers,
  redirectToAppInbox,
  redirectToAppReports,
  redirectToAppSuppliers,
  redirectToAppVehiclesList,
} from '../redirect/slice';
import { hideNav } from './slice';

export const appCommonMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case redirectToAppDashboard.type:
      case redirectToAppAldUsers.type:
      case redirectToAppVehiclesList.type:
      case redirectToAppDealers.type:
      case redirectToAppCustomers.type:
      case redirectToAppSuppliers.type:
      case redirectToAppReports.type:
      case redirectToAppInbox.type: {
        if (isMobile) {
          dispatch(hideNav());
        }

        break;
      }

      default:
        break;
    }

    return await next(action);
  };
