// import { Search, Notifications, Settings } from '@mui/icons-material';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import AppLogo from '../../../icons/AppLogo';
import MenuIcon from '@mui/icons-material/Menu';
import SearchWithResultsField from '../../../common/components/Search/Search';
import {
  appendDaysWord,
  byAccessRightsExecutor,
} from '../../../common/helpers/common';

function Header({
  logoUrl,
  logout = () => {},
  redirectToSignIn,
  toggleNav = () => {},
  getVehicles = () => {},
  userAccessRights = {},
  redirectToAppVehiclesListAllCarsCarDetails = () => {},
  redirectToAppVehiclesListSupplierCarsCarDetails = () => {},
  redirectToAppVehiclesListCustomerCarsCarDetails = () => {},
  redirectToAppVehiclesListDealersCarsCarDetails = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  return (
    <Box
      sx={{
        alignItems: 'center',
        borderBottom: '2px solid #E0E0E0',
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '0 0 64px',
        width: '100%',
        pr: 1,
        pl: 0.5,
      }}
    >
      <IconButton
        id="nav-bar-menu-icon"
        color="secondary"
        onClick={() => {
          toggleNav();
        }}
        sx={{ mr: 1 }}
      >
        <MenuIcon />
      </IconButton>

      {logoUrl && (
        <img
          src={logoUrl}
          style={{
            height: 40,
            overflow: 'hidden',
            marginRight: 'auto',
            objectFit: 'contain',
          }}
          alt="app-logo"
        />
      )}
      {!logoUrl && (
        <AppLogo
          viewBox="0 0 40 30"
          sx={{
            height: 30,
            width: 40,
            overflow: 'unset',
            marginRight: 'auto',
          }}
        />
      )}

      <SearchWithResultsField
        sx={{ px: 2 }}
        TextFieldProps={{ placeholder: 'Plate No...' }}
        popperContentRenderer={({ closeResults, clearSearch }) => {
          return (
            <List
              sx={{ p: 0, width: '100%', maxHeight: '500px', overflow: 'auto' }}
            >
              {paginatedCars.items.map((vehicle) => {
                return (
                  <ListItem
                    sx={{ p: 0 }}
                    onClick={() => {
                      byAccessRightsExecutor({
                        actionsByAccessRights: [
                          {
                            accessRights: [
                              'canViewReadyForSalesCars',
                              'canViewDealerOrOtherLocationCars',
                              'canViewInProcessCars',
                              'canViewReadyForSalesCars',
                              'canViewAssessmentApprovesCars',
                            ],
                            action: () => {
                              redirectToAppVehiclesListAllCarsCarDetails(
                                vehicle.id,
                              );
                            },
                          },
                          {
                            accessRights: ['canViewSupplierCars'],
                            action: () => {
                              redirectToAppVehiclesListSupplierCarsCarDetails(
                                vehicle.id,
                              );
                            },
                          },
                          {
                            accessRights: ['canViewCustomerCars'],
                            action: () => {
                              redirectToAppVehiclesListCustomerCarsCarDetails(
                                vehicle.id,
                              );
                            },
                          },
                          {
                            accessRights: ['canViewDealerCars'],
                            action: () => {
                              redirectToAppVehiclesListDealersCarsCarDetails(
                                vehicle.id,
                              );
                            },
                          },
                        ],
                        userAccessRights,
                      });
                      closeResults();
                      clearSearch();
                    }}
                  >
                    <ListItemButton>
                      <ListItemText
                        primary={vehicle.carBrand}
                        sx={{ minWidth: 0, flex: 1 }}
                      />
                      <ListItemText
                        primary={vehicle.carModel}
                        sx={{ minWidth: 0, flex: 1 }}
                      />
                      <ListItemText
                        primary={vehicle.plateNo}
                        sx={{ minWidth: 0, flex: 1 }}
                      />
                      <ListItemText
                        primary={`Last update: ${appendDaysWord(
                          vehicle.lastUpdate,
                        )}`}
                        sx={{ minWidth: 0, flex: 1 }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          );
        }}
        onChange={async (e) => {
          if (e.target.value !== '') {
            const result = await getVehicles(
              0,
              50,
              [],
              undefined,
              e.target.value,
            );
            setPaginatedCars(result);
          }
        }}
      />

      {/* <IconButton color="secondary">
        <Search />
      </IconButton>

      <IconButton color="secondary">
        <Notifications />
      </IconButton>

      <IconButton color="secondary">
        <Settings />
        </IconButton> */}

      <Button
        sx={{
          backgroundColor: '#F4F5F7',
          color: '#7B869A',
          fontWeight: 'normal',
          px: 2,
        }}
        onClick={async () => {
          await logout();
          await redirectToSignIn();
        }}
      >
        Logout
      </Button>
    </Box>
  );
}

export default Header;
