import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { ROUTING_PATH_NAMES } from '../../../common/constants/constants';

function VehicleByQRRedirect({
  qrId,
  vehicle = null,
  getVehicleByQRId = () => {},
}) {
  useEffect(async () => {
    await getVehicleByQRId(qrId);
  }, [vehicle]);

  if (vehicle) {
    const { id } = vehicle;

    return (
      <Redirect
        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${id}/${ROUTING_PATH_NAMES.STATUS_INFO}`}
      />
    );
  }
  return <></>;
}

export default VehicleByQRRedirect;
