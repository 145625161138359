import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import GridGalleryContainer from '../../../../../common/components/GridGallery/GridGallery.container';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';
import useMounted from '../../../../../common/hooks/useMounted';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

function ReadyForReturnToALDFromOther({
  t = () => {},
  contractImages = [],
  switchToNextVehicleFlowState = () => {},
  setBookingContactPerson = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  currentVehicleBooking = {},
  getVehicleFlowState = () => {},
}) {
  const mounted = useMounted();
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;
  const { id: bookingId } = currentVehicleBooking;

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          Ready for return to FLEET from “Other”
        </Typography>

        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          Input contact person data and press the button to start return to
          FLEET
        </Typography>
      </Box>

      <GridGalleryContainer
        sx={{ flex: 'unset' }}
        maxRenderingImagesCount={3}
        uploadIds={contractImages.map((imgData) => {
          return imgData.uploadId;
        })}
      />

      <Formik
        initialValues={{
          submit: null,
          contactName: '',
          phone: '',
        }}
        validationSchema={Yup.object().shape({
          contactName: Yup.string().required('Contact name is required'),
          phone: Yup.string().required('Phone is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await setBookingContactPerson({
              id: bookingId,
              contactPersonName: values.contactName,
              contactPersonTelephone: values.phone,
            });
            await switchToNextVehicleFlowState(
              id,
              currentStateId,
              SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION,
            );
            await getVehicleFlowState(id, contractId);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          handleSubmit,
          isSubmitting,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'auto',
              minWidth: 0,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <TextField
                error={Boolean(touched.contactName && errors.contactName)}
                fullWidth
                helperText={touched.contactName && errors.contactName}
                label={t('vehiclesList.carStatus.booking.contactName')}
                margin="normal"
                name="contactName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.contactName}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.phone && errors.phone)}
                fullWidth
                helperText={touched.phone && errors.phone}
                label={t('vehiclesList.carStatus.booking.phone')}
                margin="normal"
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.phone}
                variant="outlined"
              />

              {errors.submit && (
                <Box sx={{ mt: 1 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Button
                disabled={isSubmitting}
                sx={{ width: '100%', mt: 'auto' }}
                color="primary"
                variant="contained"
                type="submit"
              >
                Start return to FLEET
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default withTranslation()(ReadyForReturnToALDFromOther);
