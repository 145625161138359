import { connect } from 'react-redux';
import { redirectToAppVehiclesList } from '../../../../../store/redirect/slice';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import WashInProcess from './WashInProcess';

const mapStateToProps = (state) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
  userAccessRights: state.auth.user.accessRights,
});

const mapDispatchToProps = {
  switchToNextVehicleFlowState,
  redirectToAppVehiclesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(WashInProcess);
