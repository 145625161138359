import { connect } from 'react-redux';
import { bookToAldOrDealerLocation } from '../../../../../../store/booking/slice';
import BookingReturnToDealer from './BookingReturnToDealer';

const mapStateToProps = () => ({});

const mapDispatchToProps = { bookToAldOrDealerLocation };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookingReturnToDealer);
