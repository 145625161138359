import { connect } from 'react-redux';
import {
  changeVehicleBooking,
  switchToNextVehicleFlowState,
  updateVehicleMileage,
} from '../../../../../store/vehicles/slice';
import UpdateMileageBeforeReturn from './UpdateMileageBeforeReturn';

const mapStateToProps = (state) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
});

const mapDispatchToProps = {
  updateVehicleMileage,
  switchToNextVehicleFlowState,
  changeVehicleBooking,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateMileageBeforeReturn);
