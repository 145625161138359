import { connect } from 'react-redux';
import {
  redirectToAppDealersDealerDetailsDealerInfo,
  redirectToAppDealersDealerDetailsDealerUsers,
} from '../../../../../store/redirect/slice';
import Tabs from './Tabs';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppDealersDealerDetailsDealerInfo,
  redirectToAppDealersDealerDetailsDealerUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
