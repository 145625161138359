import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { withTranslation } from 'react-i18next';

export const NAF_SUPPLIER_DETAILS_TABS = {
  SUPPLIERS_INFO: 0,
  USERS: 1,
};

function NAFSupplierDetailsTabs({
  t = () => {},
  supplierId = '',
  selectedTabId = NAF_SUPPLIER_DETAILS_TABS.SUPPLIERS_INFO,
  redirectToAppSuppliersNAFSupplierDetailsInfo = () => {},
  redirectToAppSuppliersNAFSupplierDetailsUsers = () => {},
}) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{
          borderBottom: '1px solid #E0E0E0',
          mb: 2,
          '& .MuiTab-root': {
            flex: 1,
          },
        }}
      >
        <Tab
          onClick={() => {
            redirectToAppSuppliersNAFSupplierDetailsInfo(supplierId);
          }}
          id={NAF_SUPPLIER_DETAILS_TABS.SUPPLIERS_INFO}
          label={t('controlCategories.suppliers.naf.details.info.title')}
        />

        <Tab
          onClick={() => {
            redirectToAppSuppliersNAFSupplierDetailsUsers(supplierId);
          }}
          id={NAF_SUPPLIER_DETAILS_TABS.USERS}
          label={t('controlCategories.suppliers.naf.details.users.title')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(NAFSupplierDetailsTabs);
