import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const DeltaArrowIcon = createSvgIcon(
  <svg viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5108 13.3335H6.48912C5.92829 13.3335 5.42246 12.9985 5.16829 12.4594C4.88079 11.8485 4.96412 11.126 5.38329 10.6177L8.89496 6.36854C9.45496 5.6877 10.545 5.6877 11.105 6.36854L14.6158 10.6169C15.0358 11.126 15.12 11.8485 14.8316 12.4594C14.5775 12.9985 14.0716 13.3335 13.5108 13.3335Z"
    />
    <mask
      id="mask0_6393_7944"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="5"
      width="12"
      height="9"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5108 13.3335H6.48912C5.92829 13.3335 5.42246 12.9985 5.16829 12.4594C4.88079 11.8485 4.96412 11.126 5.38329 10.6177L8.89496 6.36854C9.45496 5.6877 10.545 5.6877 11.105 6.36854L14.6158 10.6169C15.0358 11.126 15.12 11.8485 14.8316 12.4594C14.5775 12.9985 14.0716 13.3335 13.5108 13.3335Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_6393_7944)">
      <rect width="20" height="20" />
    </g>
  </svg>,
  'DeltaArrow',
);

export default DeltaArrowIcon;
