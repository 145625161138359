import { connect } from 'react-redux';
import { redirectToAppSuppliersWash } from '../../../../../store/redirect/slice';
import { createNewSupplier } from '../../../../../store/suppliers/slice';
import CreateNewWashSupplier from './Wash';

const mapStateToProps = () => ({});

const mapDispatchToProps = { createNewSupplier, redirectToAppSuppliersWash };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewWashSupplier);
