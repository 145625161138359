import { connect } from 'react-redux';
import { redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo } from '../../../../store/redirect/slice';
import B2BAdminCustomersList from './List';

const mapStateToProps = (state) => {
  return {
    customers: state.customers.appropriateCustomer,
  };
};

const mapDispatchToProps = {
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(B2BAdminCustomersList);
