import { connect } from 'react-redux';
import { redirectToAppVehiclesListAllCarsCarDetails } from '../../../../store/redirect/slice';
import { getVehicles } from '../../../../store/vehicles/slice';
import AllCars from './AllCars';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListAllCarsCarDetails,
  getVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCars);
