import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const DriverLicenseIcon = createSvgIcon(
  <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6912_10379)">
      <path
        d="M27.2382 4.23633H2.76176C1.21857 4.23633 0 5.48965 0 6.99809V23.002C0 24.5451 1.25332 25.7637 2.76176 25.7637H27.2382C28.7814 25.7637 30 24.5104 30 23.0019V6.99809C30 5.45484 28.7467 4.23633 27.2382 4.23633ZM28.2422 23.0019C28.2422 23.556 27.7935 24.0059 27.2382 24.0059H2.76176C2.2077 24.0059 1.75781 23.5571 1.75781 23.0019V6.99809C1.75781 6.44402 2.20652 5.99414 2.76176 5.99414H27.2382C27.7923 5.99414 28.2422 6.44285 28.2422 6.99809V23.0019Z"
        fillOpacity="0.82"
      />
      <path
        d="M11.7621 17.1506C12.2728 16.5788 12.584 15.8252 12.584 15C12.584 13.2177 11.1339 11.7676 9.35158 11.7676C7.56922 11.7676 6.1192 13.2177 6.1192 15C6.1192 15.8252 6.43039 16.5788 6.94104 17.1505C5.60258 17.9665 4.70703 19.4402 4.70703 21.1191C4.70703 21.6045 5.10055 21.998 5.58594 21.998H13.1172C13.6026 21.998 13.9961 21.6045 13.9961 21.1191C13.9961 19.4402 13.1005 17.9666 11.7621 17.1506ZM9.35158 13.5254C10.1647 13.5254 10.8262 14.1869 10.8262 15C10.8262 15.8131 10.1647 16.4746 9.35158 16.4746C8.53848 16.4746 7.87701 15.8131 7.87701 15C7.87701 14.1869 8.53848 13.5254 9.35158 13.5254ZM6.6016 20.2403C6.97426 19.077 8.06615 18.2325 9.35158 18.2325C10.637 18.2325 11.7288 19.0769 12.1016 20.2403H6.6016Z"
        fillOpacity="0.82"
      />
      <path
        d="M24.4141 12.709H17.8242C17.3388 12.709 16.9453 13.1025 16.9453 13.5879C16.9453 14.0733 17.3388 14.4668 17.8242 14.4668H24.4141C24.8995 14.4668 25.293 14.0733 25.293 13.5879C25.293 13.1025 24.8995 12.709 24.4141 12.709Z"
        fillOpacity="0.82"
      />
      <path
        d="M24.4141 8.00195H5.58594C5.10055 8.00195 4.70703 8.39547 4.70703 8.88086C4.70703 9.36625 5.10055 9.75977 5.58594 9.75977H24.4141C24.8995 9.75977 25.293 9.36625 25.293 8.88086C25.293 8.39547 24.8995 8.00195 24.4141 8.00195Z"
        fillOpacity="0.82"
      />
      <path
        d="M24.4141 16.4746H17.8242C17.3388 16.4746 16.9453 16.8681 16.9453 17.3535C16.9453 17.8389 17.3388 18.2324 17.8242 18.2324H24.4141C24.8995 18.2324 25.293 17.8389 25.293 17.3535C25.293 16.8681 24.8995 16.4746 24.4141 16.4746Z"
        fillOpacity="0.82"
      />
      <path
        d="M22.5312 20.2402H17.8242C17.3388 20.2402 16.9453 20.6337 16.9453 21.1191C16.9453 21.6045 17.3388 21.998 17.8242 21.998H22.5312C23.0166 21.998 23.4101 21.6045 23.4101 21.1191C23.4101 20.6337 23.0166 20.2402 22.5312 20.2402Z"
        fillOpacity="0.82"
      />
    </g>
    <defs>
      <clipPath id="clip0_6912_10379">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'DriverLicense',
);

export default DriverLicenseIcon;
