import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import PersonIcon from '../../../../icons/Person';
import DealerInfoContainer from './Info/Info.container';
import { Route, Switch } from 'react-router';
import { ROUTING_PATH_NAMES } from '../../../../common/constants/constants';
import { DEALER_DETAILS_TABS } from './Tabs/Tabs';
import { pathTester } from '../../../../common/helpers/routing';
import DealerDetailsTabsContainer from './Tabs/Tabs.container';
import DealerUsersContainer from './Users/Users.container';
import CreateNewDealerUserContainer from './Users/CreateNewUser/CreateNewUser.container';
import DealerUserDetailsContainer from './Users/UserDetails/UserDetails.container';

function DealerDetails({ dealer = null, t = () => {}, pathname = '' }) {
  if (dealer) {
    useEffect(async () => {}, []);

    const { name = '-' } = dealer;

    const isDealersDealerInfoTabSelected =
      pathTester.isDealersDealerDetailsDealerInfo(pathname);
    const isDealersDealerUsersTabSelected =
      pathTester.isDealersDealerDetailsDealerUsers(pathname);
    const isDealersDealerUsersUserDetailsTabSelected =
      pathTester.isDealersDealerDetailsDealerUsersUserInfo(pathname);
    const isDealersDealerUsersCreateNewUserTabSelected =
      pathTester.isDealersDealerDetailsDealerUsersCreateNewUser(pathname);

    let selectedTab = -1;
    if (isDealersDealerInfoTabSelected) {
      selectedTab = DEALER_DETAILS_TABS.DEALER_INFO;
    }
    if (isDealersDealerUsersTabSelected) {
      selectedTab = DEALER_DETAILS_TABS.USERS;
    }

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
          p: { xs: 2 },
        }}
      >
        {!isDealersDealerUsersCreateNewUserTabSelected &&
          !isDealersDealerUsersUserDetailsTabSelected && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{
                    mr: 3,
                    width: 102,
                    height: 102,
                    backgroundColor: 'primary.main',
                  }}
                >
                  <PersonIcon />
                </Avatar>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4">{name}</Typography>

                  <Typography>
                    {t('controlCategories.dealers.dealer')}
                  </Typography>
                </Box>
              </Box>

              <DealerDetailsTabsContainer
                selectedTabId={selectedTab}
                dealerId={dealer.id}
              />
            </>
          )}

        <Switch>
          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/:dealerId/${ROUTING_PATH_NAMES.INFO}`}
            render={({
              match: {
                params: { dealerId },
              },
            }) => {
              return (
                <>
                  <DealerInfoContainer dealerId={dealerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/:dealerId/${ROUTING_PATH_NAMES.USERS}`}
            render={({
              match: {
                params: { dealerId },
              },
            }) => {
              return (
                <>
                  <DealerUsersContainer dealerId={dealerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/:dealerId/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}`}
            render={({
              match: {
                params: { dealerId },
              },
            }) => {
              return (
                <>
                  <CreateNewDealerUserContainer dealerId={dealerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/:dealerId/${ROUTING_PATH_NAMES.USERS}/:dealerUserId`}
            render={({
              match: {
                params: { dealerId, dealerUserId },
              },
            }) => {
              return (
                <>
                  <DealerUserDetailsContainer
                    dealerId={dealerId}
                    dealerUserId={dealerUserId}
                  />
                </>
              );
            }}
          />
        </Switch>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t('controlCategories.dealers.noSpecifiedDealerFound')}
      </Typography>
    </Box>
  );
}

export default withTranslation()(DealerDetails);
