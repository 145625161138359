import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../../common/components/DataGrid/DataGrid';
import {
  getEstimationColorByEnumValue,
  getRepairStatusNameByStatus,
} from './helpers';

function SupplierLogs({ t = () => {}, suppliersLogs = [] }) {
  const columns = [
    {
      field: 'supplierName',
      headerName: t('vehiclesList.carDetails.repairStatus.supplierName'),
    },
    {
      field: 'email',
      headerName: t('vehiclesList.carDetails.repairStatus.email'),
    },
    {
      field: 'telephone',
      headerName: t('vehiclesList.carDetails.repairStatus.phone'),
    },
    {
      field: 'damageTypeGroupName',
      headerName: t('vehiclesList.carDetails.repairStatus.damageTypeGroupName'),
    },
    {
      field: 'daysSpent',
      headerName: t('vehiclesList.carDetails.repairStatus.daysSpent'),
    },
    {
      field: 'status',
      headerName: t('vehiclesList.carDetails.repairStatus.status'),
      renderRowCell: ({ rowData }) => {
        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              minWidth: 0,
              px: 1,
            }}
          >
            <Button
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
              }}
              variant="contained"
              color={getEstimationColorByEnumValue(rowData.color)}
              onClick={() => {}}
            >
              <Typography
                noWrap
                sx={{
                  width: '100%',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                {getRepairStatusNameByStatus(rowData.status)}
              </Typography>
            </Button>
          </Box>
        );
      },
      filterPredicate: (rowData, filterValue) => {
        const repairStatus = getRepairStatusNameByStatus(rowData.status);
        return repairStatus.toLowerCase().includes(filterValue.toLowerCase());
      },
    },
  ];

  const rows = suppliersLogs.map((log) => {
    return {
      ...log,
      telephone: (log.contactPerson && log.contactPerson.phone) || '-',
      email: (log.contactPerson && log.contactPerson.email) || '-',
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CommonDataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        noDataToShowMessage={t('common.messages.noDataToShow')}
        shouldRenderRowSelectButton={false}
        selectRowHandler={null}
      />
    </Box>
  );
}

export default withTranslation()(SupplierLogs);
