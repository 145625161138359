import { STEP_STATES } from '../../../../common/components/HorizontalStepper/constants';
import { CAR_STATES } from './constants';

export const getCarStatusButtonColorByStatus = (carStatus) => {
  switch (carStatus) {
    case CAR_STATES.IN_PROGRESS:
      return 'carStates.inProgress.main';
    case CAR_STATES.READY:
      return 'carStates.ready.main';
    case CAR_STATES.WASHING:
      return 'carStates.washing.main';

    default:
      return 'carStates.inProgress.main';
  }
};

export const getCarStatusButtonTextColorByStatus = (carStatus) => {
  switch (carStatus) {
    case CAR_STATES.IN_PROGRESS:
      return 'carStates.inProgress.text';
    case CAR_STATES.READY:
      return 'carStates.ready.text';
    case CAR_STATES.WASHING:
      return 'carStates.washing.text';

    default:
      return 'carStates.inProgress.text';
  }
};

export const getMajorStateStatusByLogs = (
  logs = [],
  minSubStateEnumValue,
  maxSubStateEnumValue,
) => {
  if (logs.some((stateLog) => stateLog.stateEnum > maxSubStateEnumValue)) {
    return STEP_STATES.DONE;
  }

  if (
    logs.some((stateLog) => stateLog.stateEnum >= minSubStateEnumValue) &&
    !logs.some((stateLog) => stateLog.stateEnum > maxSubStateEnumValue)
  ) {
    return STEP_STATES.IN_PROGRESS;
  }

  return STEP_STATES.TODO;
};

export const getSubStateStatusByLogs = (
  logs = [],
  subStateEnumValue,
  maxSubStateEnumValue,
) => {
  if (!logs.some((stateLog) => stateLog.stateEnum === subStateEnumValue)) {
    return STEP_STATES.TODO;
  }

  if (
    logs.some((stateLog) => stateLog.stateEnum === subStateEnumValue) &&
    !logs.some((stateLog) => stateLog.stateEnum > subStateEnumValue)
  ) {
    return STEP_STATES.IN_PROGRESS;
  }

  if (logs.some((stateLog) => stateLog.stateEnum > maxSubStateEnumValue)) {
    return STEP_STATES.DONE;
  }
  return STEP_STATES.IN_PROGRESS;
};
