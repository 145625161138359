import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { RETURN_TO_OTHER_BOOKING_STEPS } from '../helpers';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Formik } from 'formik';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as Yup from 'yup';
import useMounted from '../../../../../../common/hooks/useMounted';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { RETURN_BOOKING_TYPE } from '../../../../../../common/constants/booking';

const BookingReturnToOther = ({
  t = () => {},
  onBackButtonClicked = () => {},
  bookToOther = () => {},
  getVehicleFlowState = () => {},
  vehicle = {},
}) => {
  const [currentSubStep, setCurrentSubStep] = useState(
    RETURN_TO_OTHER_BOOKING_STEPS.CHOOSING_AREA,
  );
  const mounted = useMounted();
  const [dateAndTime, setDateAndTime] = useState(moment());

  const { contractId, id: carId } = vehicle;

  return (
    <Box
      sx={{
        p: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Formik
        initialValues={{
          companyName: '',
          city: '',
          street: '',
          postalCode: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required('Company name is required'),
          city: Yup.string().required('City is required'),
          street: Yup.string().required('Street is required'),
          postalCode: Yup.string().required('Postal code is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await bookToOther({
              contractId,
              carId,
              returnBookingType: RETURN_BOOKING_TYPE.RETURN_TO_OTHER,
              bookingDate: dateAndTime.utc(),
              companyName: values.companyName,
              address: values.street,
              postCode: values.postalCode,
              city: values.city,
            });
            await getVehicleFlowState(carId, contractId);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'auto',
              minWidth: 0,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                overflow: 'auto',
                display:
                  currentSubStep === RETURN_TO_OTHER_BOOKING_STEPS.CHOOSING_AREA
                    ? 'flex'
                    : 'none',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 2, fontWeight: 500 }} variant="h6">
                {t('vehiclesList.carStatus.booking.chooseArea')}
              </Typography>

              <TextField
                error={Boolean(touched.companyName && errors.companyName)}
                fullWidth
                helperText={touched.companyName && errors.companyName}
                label={t('vehiclesList.carStatus.booking.companyName')}
                margin="normal"
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.companyName}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.city && errors.city)}
                fullWidth
                helperText={touched.city && errors.city}
                label={t('vehiclesList.carStatus.booking.city')}
                margin="normal"
                name="city"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.city}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.street && errors.street)}
                fullWidth
                helperText={touched.street && errors.street}
                label={t('vehiclesList.carStatus.booking.street')}
                margin="normal"
                name="street"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.street}
                variant="outlined"
              />

              <TextField
                error={Boolean(touched.postalCode && errors.postalCode)}
                fullWidth
                helperText={touched.postalCode && errors.postalCode}
                label={t('vehiclesList.carStatus.booking.postalCode')}
                margin="normal"
                name="postalCode"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.postalCode}
                variant="outlined"
              />

              <Box
                sx={{
                  mt: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<ChevronLeftIcon />}
                  sx={{
                    width: '100%',
                    mt: 'auto',
                    mr: 4,
                    '& .MuiButton-startIcon': {
                      position: 'absolute',
                      left: '10px',
                    },
                  }}
                  onClick={() => {
                    onBackButtonClicked();
                  }}
                >
                  {t('common.buttons.back')}
                </Button>

                <Button
                  disabled={
                    !values.companyName ||
                    !values.city ||
                    !values.street ||
                    !values.postalCode
                  }
                  variant="contained"
                  startIcon={<ChevronRightIcon />}
                  sx={{
                    width: '100%',
                    mt: 'auto',
                    '& .MuiButton-startIcon': {
                      position: 'absolute',
                      right: '10px',
                    },
                  }}
                  onClick={() => {
                    setCurrentSubStep(
                      RETURN_TO_OTHER_BOOKING_STEPS.BOOKING_DATE_AND_TIME,
                    );
                  }}
                >
                  {t('common.buttons.next')}
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                width: '100%',
                height: '100%',
                display:
                  currentSubStep ===
                  RETURN_TO_OTHER_BOOKING_STEPS.BOOKING_DATE_AND_TIME
                    ? 'flex'
                    : 'none',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 2, fontWeight: 500 }} variant="h6">
                {t('vehiclesList.carStatus.booking.chooseDateAndTime')}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>Date</Typography>

                <MobileDatePicker
                  cancelText=""
                  inputFormat="DD/MM/YYYY"
                  value={dateAndTime}
                  onChange={(newDate) => {
                    setDateAndTime(newDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={moment()}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 'auto',
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>Time</Typography>

                <MobileTimePicker
                  ampm={false}
                  value={dateAndTime}
                  onChange={(newTime) => {
                    setDateAndTime(newTime);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>

              <Box
                sx={{
                  mt: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<ChevronLeftIcon />}
                  sx={{
                    width: '100%',
                    mt: 'auto',
                    mr: 4,
                    '& .MuiButton-startIcon': {
                      position: 'absolute',
                      left: '10px',
                    },
                  }}
                  onClick={() => {
                    setCurrentSubStep(
                      RETURN_TO_OTHER_BOOKING_STEPS.CHOOSING_AREA,
                    );
                  }}
                >
                  {t('common.buttons.back')}
                </Button>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{ width: '100%', mt: 'auto' }}
                >
                  {t('common.buttons.confirm')}
                </Button>
              </Box>
            </Box>

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default withTranslation()(BookingReturnToOther);
