import { connect } from 'react-redux';
import App from './App';

const mapStateToProps = (state) => ({
  isAppInitialized: state.app.isAppInitialized,
  settings: state.app.settings,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
