import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import CompanyIcon from '../../../../../icons/Company';
import WashSuppliersInfoContainer from './Info/Info.container';
import WashSuppliersUsersContainer from './Users/Users.container';
import { WASH_SUPPLIER_DETAILS_TABS } from './Tabs/Tabs';
import WashSupplierDetailsTabsContainer from './Tabs/Tabs.container';
import { Route, Switch } from 'react-router';
import { ROUTING_PATH_NAMES } from '../../../../../common/constants/constants';
import { pathTester } from '../../../../../common/helpers/routing';
import CreateNewSupplierUserContainer from './Users/CreateNewUser/CreateNewUser.container';
import SupplierUserDetailsContainer from './Users/UserDetails/UserDetails.container';

function WashSupplierDetails({
  supplier = null,
  t = () => {},
  pathname = {},
  getSupplierUsers = () => {},
}) {
  if (supplier) {
    useEffect(async () => {
      try {
        await getSupplierUsers(supplier.id);
      } catch (error) {
        console.error(error);
      }
    }, []);

    const { name = '-' } = supplier;
    const isSupplierInfoTabSelected =
      pathTester.isSuppliersWashSupplierDetailsInfo(pathname);
    const isWashSupplierUsersTabSelected =
      pathTester.isSuppliersWashSupplierDetailsUsers(pathname);
    const isWashSupplierUsersUserDetailsTabSelected =
      pathTester.isSuppliersWashSupplierDetailsUsersUserDetails(pathname);
    const isWashSupplierUsersCreateNewUserTabSelected =
      pathTester.isSuppliersWashSupplierDetailsUsersCreateNewUser(pathname);

    let selectedTab = 0;
    if (isSupplierInfoTabSelected) {
      selectedTab = WASH_SUPPLIER_DETAILS_TABS.SUPPLIERS_INFO;
    }
    if (isWashSupplierUsersTabSelected) {
      selectedTab = WASH_SUPPLIER_DETAILS_TABS.USERS;
    }

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
          p: { xs: 2 },
        }}
      >
        {!isWashSupplierUsersCreateNewUserTabSelected &&
          !isWashSupplierUsersUserDetailsTabSelected && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{
                    mr: 3,
                    width: 102,
                    height: 102,
                    backgroundColor: 'primary.main',
                  }}
                >
                  <CompanyIcon />
                </Avatar>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4">{name}</Typography>

                  <Typography>
                    {t('controlCategories.suppliers.wash.details.washSupplier')}
                  </Typography>
                </Box>
              </Box>

              <WashSupplierDetailsTabsContainer
                selectedTabId={selectedTab}
                supplierId={supplier.id}
              />
            </>
          )}

        <Switch>
          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/:supplierId/${ROUTING_PATH_NAMES.SUPPLIER_INFO}`}
            render={({
              match: {
                params: { supplierId },
              },
            }) => {
              return (
                <>
                  <WashSuppliersInfoContainer supplierId={supplierId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/:supplierId/${ROUTING_PATH_NAMES.USERS}`}
            render={({
              match: {
                params: { supplierId },
              },
            }) => {
              return (
                <>
                  <WashSuppliersUsersContainer supplierId={supplierId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/:supplierId/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER}`}
            render={({
              match: {
                params: { supplierId },
              },
            }) => {
              return (
                <>
                  <CreateNewSupplierUserContainer supplierId={supplierId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/:supplierId/${ROUTING_PATH_NAMES.USERS}/:supplierUserId`}
            render={({
              match: {
                params: { supplierId, supplierUserId },
              },
            }) => {
              return (
                <>
                  <SupplierUserDetailsContainer
                    supplierId={supplierId}
                    supplierUserId={supplierUserId}
                  />
                </>
              );
            }}
          />
        </Switch>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t('controlCategories.suppliers.noSpecifiedSupplierFound')}
      </Typography>
    </Box>
  );
}

export default withTranslation()(WashSupplierDetails);
