export const ESTIMATION_ENUM = {
  GREEN: 1,
  YELLOW: 5,
  RED: 10,
};
export const REPAIR_STATUS_ENUM = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
};

export const getRepairStatusNameByStatus = (status) => {
  switch (status) {
    case REPAIR_STATUS_ENUM.NOT_STARTED:
      return 'WAITING...';
    case REPAIR_STATUS_ENUM.IN_PROGRESS:
      return 'IN PROGRESS';
    case REPAIR_STATUS_ENUM.COMPLETED:
      return 'DONE';

    default:
      return 'DONE';
  }
};

export const getEstimationColorByEnumValue = (estimationEnumValue) => {
  switch (estimationEnumValue) {
    case ESTIMATION_ENUM.GREEN:
      return 'success';

    case ESTIMATION_ENUM.YELLOW:
      return 'warning';

    case ESTIMATION_ENUM.RED:
      return 'error';

    default:
      return 'secondary';
  }
};
