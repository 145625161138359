import { push, goBack } from 'connected-react-router';
import {
  ROUTING_URLS,
  ROUTING_PATH_NAMES,
} from '../../common/constants/constants';
import { getBreadcrumbBackPath } from '../../common/helpers/routing';
import {
  redirectToAppAldUsers,
  redirectToAppCustomers,
  redirectToAppDashboard,
  redirectToAppDealers,
  redirectToAppInbox,
  redirectToAppPickupCalendar,
  redirectToAppReports,
  redirectToAppSupplierGroups,
  redirectToAppSuppliers,
  redirectToAppSupplierUsersList,
  redirectToAppVehiclesList,
  redirectToAppVehiclesListCustomerLocationCars,
  redirectToAppVehiclesListNewBookedCars,
  redirectToAppVehiclesListInProcessCars,
  redirectToAppVehiclesListReadyForSalesCars,
  redirectToAppVehiclesPlanning,
  redirectToAppWorkshop,
  redirectToSignIn,
  redirectBack,
  redirectToAppVehiclesListCustomerLocationCarsCarDetails,
  redirectToAppVehiclesListNewBookedCarsCarDetails,
  redirectToAppVehiclesListInProcessCarsCarDetails,
  redirectToAppVehiclesListReadyForSalesCarsCarDetails,
  redirectToAppCustomersB2CCreateNewCustomer,
  redirectToAppCustomersB2BCreateNewCustomer,
  redirectToAppCustomersRentalCreateNewCustomer,
  redirectToAppCustomersFlexCreateNewCustomer,
  redirectToAppCustomersB2BCustomerDetails,
  redirectToAppCustomersB2BCustomerDetailsCustomerInfo,
  redirectToAppCustomersB2BCustomerDetailsCustomerVehicles,
  redirectToAppCustomersB2BCustomerDetailsCustomerUsers,
  redirectBackByBreadcrumb,
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser,
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser,
  redirectToAppCustomersB2BCustomerDetailsUsersUserDetails,
  redirectToRequestedLocation,
  redirectToAppVehiclesListCarStatus,
  redirectToAppSuppliersWash,
  redirectToAppSuppliersNAF,
  redirectToAppSuppliersWorkshop,
  redirectToAppSuppliersWashCreateNew,
  redirectToAppSuppliersNAFCreateNew,
  redirectToAppSuppliersWorkshopCreateNew,
  redirectToAppSuppliersWashSupplierDetailsInfo,
  redirectToAppSuppliersWashSupplierDetailsUsers,
  redirectToAppSuppliersNAFSupplierDetailsInfo,
  redirectToAppSuppliersNAFSupplierDetailsUsers,
  redirectToAppSuppliersWorkshopSupplierDetailsInfo,
  redirectToAppSuppliersWorkshopSupplierDetailsUsers,
  redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWashSupplierDetailsUsersUserDetails,
  redirectToAppSuppliersNAFSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersNAFSupplierDetailsUsersUserDetails,
  redirectToAppSuppliersWorkshopSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWorkshopSupplierDetailsUsersUserDetails,
  redirectToAppVehiclesListSupplierCarsCarDetails,
  redirectToAppVehiclesListCustomerCarsCarDetails,
  redirectToAppAldUsersCreateNewUser,
  redirectToAppAldUsersUserDetailsUserInfo,
  redirectToAppVehiclesListAssessmentApprovesCars,
  redirectToAppVehiclesListAssessmentApprovesCarsCarDetails,
  redirectToAppDealersCreateNew,
  redirectToAppDealersDealerDetailsDealerInfo,
  redirectToAppDealersDealerDetailsDealerUsers,
  redirectToAppDealersDealerDetailsDealerUsersCreateNewUser,
  redirectToAppDealersDealerDetailsDealerUsersUserInfo,
  redirectToAppVehiclesListDealersCarsCarDetails,
  redirectToAppCustomersB2CCustomerDetails,
  redirectToAppCustomersB2CCustomerDetailsCustomerInfo,
  redirectToAppCustomersB2CCustomerDetailsCustomerVehicles,
  redirectToAppCustomersB2CCustomerDetailsCustomerUsers,
  redirectToAppCustomersB2CCustomerDetailsUsersUserDetails,
  redirectToAppCustomersB2CCustomerDetailsCreateNewCustomerAdminUser,
  redirectToAppB2BAdminCustomers,
  redirectToAppB2BAdminCustomersCustomerDetails,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewAdminUser,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewUser,
  redirectToAppDashboardToday,
  redirectToAppDashboardLastWeek,
  redirectToAppDashboardLastMonth,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersUserDetails,
  redirectToAppVehiclesListAllCars,
  redirectToAppVehiclesListAllCarsCarDetails,
} from './slice';

export const redirectMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case redirectToSignIn.type: {
        dispatch(push(ROUTING_URLS.AUTH.SIGN_IN));
        break;
      }

      case redirectToAppDashboard.type: {
        dispatch(
          push(`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}`),
        );
        break;
      }
      case redirectToAppDashboardToday.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.TODAY}`,
          ),
        );
        break;
      }
      case redirectToAppDashboardLastWeek.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_WEEK}`,
          ),
        );
        break;
      }
      case redirectToAppDashboardLastMonth.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_MONTH}`,
          ),
        );
        break;
      }

      // #region ALD users
      case redirectToAppAldUsers.type: {
        dispatch(
          push(`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}`),
        );
        break;
      }
      case redirectToAppAldUsersCreateNewUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_ALD_USER}`,
          ),
        );
        break;
      }
      case redirectToAppAldUsersUserDetailsUserInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}/${action.payload}/${ROUTING_PATH_NAMES.ALD_USER_INFO}`,
          ),
        );
        break;
      }
      // #endregion

      // #region Vehicles list
      case redirectToAppVehiclesList.type: {
        dispatch(push(ROUTING_URLS.APP.VEHICLES_LIST));
        break;
      }
      case redirectToAppVehiclesListCustomerLocationCars.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListCustomerLocationCarsCarDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListNewBookedCars.type: {
        dispatch(push(ROUTING_URLS.APP.VEHICLES_LIST__NEW_RETURNED_CARS));
        break;
      }
      case redirectToAppVehiclesListNewBookedCarsCarDetails.type: {
        dispatch(
          push(
            `${ROUTING_URLS.APP.VEHICLES_LIST__NEW_RETURNED_CARS__CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListInProcessCars.type: {
        dispatch(push(ROUTING_URLS.APP.VEHICLES_LIST__ALREADY_IN_PROCESS_CARS));
        break;
      }
      case redirectToAppVehiclesListInProcessCarsCarDetails.type: {
        dispatch(
          push(
            `${ROUTING_URLS.APP.VEHICLES_LIST__ALREADY_IN_PROCESS_CARS__CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListReadyForSalesCars.type: {
        dispatch(push(ROUTING_URLS.APP.VEHICLES_LIST__READY_FOR_SALES_CARS));
        break;
      }
      case redirectToAppVehiclesListReadyForSalesCarsCarDetails.type: {
        dispatch(
          push(
            `${ROUTING_URLS.APP.VEHICLES_LIST__READY_FOR_SALES_CARS__CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListAllCars.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALL}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListAllCarsCarDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALL}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }

      case redirectToAppVehiclesListSupplierCarsCarDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.SUPPLIER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListCustomerCarsCarDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListDealersCarsCarDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.DEALER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListAssessmentApprovesCars.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}`,
          ),
        );
        break;
      }
      case redirectToAppVehiclesListAssessmentApprovesCarsCarDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }

      case redirectToAppVehiclesListCarStatus.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${action.payload}/${ROUTING_PATH_NAMES.STATUS_INFO}`,
          ),
        );
        break;
      }
      // #endregion

      case redirectToAppVehiclesPlanning.type: {
        dispatch(push(ROUTING_URLS.APP.VEHICLES_PLANNING));
        break;
      }

      case redirectToAppSupplierUsersList.type: {
        dispatch(push(ROUTING_URLS.APP.SUPPLIER_USERS_LIST));
        break;
      }

      case redirectToAppPickupCalendar.type: {
        dispatch(push(ROUTING_URLS.APP.PICKUP_CALENDAR));
        break;
      }

      case redirectToAppWorkshop.type: {
        dispatch(push(ROUTING_URLS.APP.WORKSHOP));
        break;
      }

      // #region Dealers
      case redirectToAppDealers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}`,
          ),
        );
        break;
      }
      case redirectToAppDealersCreateNew.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/${ROUTING_PATH_NAMES.CREATE_NEW}`,
          ),
        );
        break;
      }
      case redirectToAppDealersDealerDetailsDealerInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/${action.payload}/${ROUTING_PATH_NAMES.INFO}`,
          ),
        );
        break;
      }
      case redirectToAppDealersDealerDetailsDealerUsers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/${action.payload}/${ROUTING_PATH_NAMES.USERS}`,
          ),
        );
        break;
      }
      case redirectToAppDealersDealerDetailsDealerUsersCreateNewUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}`,
          ),
        );
        break;
      }

      case redirectToAppDealersDealerDetailsDealerUsersUserInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/${action.payload.dealerId}/${ROUTING_PATH_NAMES.USERS}/${action.payload.dealerUserId}`,
          ),
        );
        break;
      }

      // #endregion

      // #region Customers
      case redirectToAppCustomers.type: {
        dispatch(push(ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS));
        break;
      }

      // #region B2C Customers
      case redirectToAppCustomersB2CCreateNewCustomer.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2CCustomerDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2CCustomerDetailsCustomerInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${action.payload}/${ROUTING_PATH_NAMES.CUSTOMER_INFO}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2CCustomerDetailsCustomerVehicles.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${action.payload}/${ROUTING_PATH_NAMES.VEHICLES_LIST}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2CCustomerDetailsCustomerUsers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${action.payload}/${ROUTING_PATH_NAMES.USERS}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2CCustomerDetailsUsersUserDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${action.payload.customerId}/${ROUTING_PATH_NAMES.USERS}/${action.payload.customerUserId}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2CCustomerDetailsCreateNewCustomerAdminUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER_ADMIN_USER}`,
          ),
        );
        break;
      }
      // #endregion

      // #region B2B Customers
      case redirectToAppCustomersB2BCreateNewCustomer.type: {
        dispatch(
          push(
            ROUTING_URLS.APP
              .CONTROL_CATEGORIES__CUSTOMERS__B2B__CREATE_NEW_CUSTOMER,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2BCustomerDetails.type: {
        dispatch(
          push(
            `${ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2B__CUSTOMER_DETAILS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2BCustomerDetailsCustomerInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${action.payload}/${ROUTING_PATH_NAMES.CUSTOMER_INFO}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2BCustomerDetailsCustomerVehicles.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${action.payload}/${ROUTING_PATH_NAMES.VEHICLES_LIST}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2BCustomerDetailsCustomerUsers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${action.payload}/${ROUTING_PATH_NAMES.USERS}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2BCustomerDetailsUsersUserDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${action.payload.customerId}/${ROUTING_PATH_NAMES.USERS}/${action.payload.customerUserId}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER_ADMIN_USER}`,
          ),
        );
        break;
      }
      case redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}`,
          ),
        );
        break;
      }
      // #endregion

      // #region Rental Customers
      case redirectToAppCustomersRentalCreateNewCustomer.type: {
        dispatch(
          push(
            ROUTING_URLS.APP
              .CONTROL_CATEGORIES__CUSTOMERS__RENTAL__CREATE_NEW_CUSTOMER,
          ),
        );
        break;
      }
      // #endregion

      // #region Flex Customers
      case redirectToAppCustomersFlexCreateNewCustomer.type: {
        dispatch(
          push(
            ROUTING_URLS.APP
              .CONTROL_CATEGORIES__CUSTOMERS__FLEX__CREATE_NEW_CUSTOMER,
          ),
        );
        break;
      }
      // #endregion

      // #endregion

      // #region Customer users

      case redirectToAppB2BAdminCustomers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}`,
          ),
        );
        break;
      }
      case redirectToAppB2BAdminCustomersCustomerDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/${action.payload}`,
          ),
        );
        break;
      }
      case redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/${action.payload}/${ROUTING_PATH_NAMES.CUSTOMER_INFO}`,
          ),
        );
        break;
      }
      case redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/${action.payload}/${ROUTING_PATH_NAMES.USERS}`,
          ),
        );
        break;
      }
      case redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersUserDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/${action.payload.customerId}/${ROUTING_PATH_NAMES.USERS}/${action.payload.customerUserId}`,
          ),
        );
        break;
      }

      case redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewAdminUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_ADMIN}`,
          ),
        );
        break;
      }
      case redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}`,
          ),
        );
        break;
      }

      // #endregion

      // #region Suppliers
      case redirectToAppSuppliers.type: {
        dispatch(push(ROUTING_URLS.APP.CONTROL_CATEGORIES__SUPPLIERS));
        break;
      }
      // #region WashSuppliers
      case redirectToAppSuppliersWash.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWashCreateNew.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}`,
          ),
        );
        break;
      }

      case redirectToAppSuppliersWashSupplierDetailsInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/${action.payload}/${ROUTING_PATH_NAMES.SUPPLIER_INFO}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWashSupplierDetailsUsers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/${action.payload}/${ROUTING_PATH_NAMES.USERS}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWashSupplierDetailsUsersUserDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/${action.payload.supplierId}/${ROUTING_PATH_NAMES.USERS}/${action.payload.supplierUserId}`,
          ),
        );
        break;
      }

      // #endregion

      // #region NafSuppliers
      case redirectToAppSuppliersNAF.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersNAFCreateNew.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}`,
          ),
        );
        break;
      }
      // redirectToAppSuppliersNAFSupplierDetails: () => {},
      case redirectToAppSuppliersNAFSupplierDetailsInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/${action.payload}/${ROUTING_PATH_NAMES.SUPPLIER_INFO}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersNAFSupplierDetailsUsers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/${action.payload}/${ROUTING_PATH_NAMES.USERS}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersNAFSupplierDetailsUsersCreateNewUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersNAFSupplierDetailsUsersUserDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/${action.payload.supplierId}/${ROUTING_PATH_NAMES.USERS}/${action.payload.supplierUserId}`,
          ),
        );
        break;
      }
      // #endregion

      // #region WorkshopSuppliers
      case redirectToAppSuppliersWorkshop.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWorkshopCreateNew.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}`,
          ),
        );
        break;
      }
      // redirectToAppSuppliersWorkshopSupplierDetails: () => {},
      case redirectToAppSuppliersWorkshopSupplierDetailsInfo.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/${action.payload}/${ROUTING_PATH_NAMES.SUPPLIER_INFO}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWorkshopSupplierDetailsUsers.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/${action.payload}/${ROUTING_PATH_NAMES.USERS}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWorkshopSupplierDetailsUsersCreateNewUser.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/${action.payload}/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER}`,
          ),
        );
        break;
      }
      case redirectToAppSuppliersWorkshopSupplierDetailsUsersUserDetails.type: {
        dispatch(
          push(
            `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/${action.payload.supplierId}/${ROUTING_PATH_NAMES.USERS}/${action.payload.supplierUserId}`,
          ),
        );
        break;
      }
      // #endregion
      // #endregion

      case redirectToAppSupplierGroups.type: {
        dispatch(push(ROUTING_URLS.APP.CONTROL_CATEGORIES__SUPPLIER_GROUPS));
        break;
      }
      case redirectToAppReports.type: {
        dispatch(push(ROUTING_URLS.APP.REPORTS));
        break;
      }
      case redirectToAppInbox.type: {
        dispatch(push(ROUTING_URLS.APP.INBOX));
        break;
      }

      case redirectToRequestedLocation.type: {
        const { requestedLocation } = getState().redirect;
        if (requestedLocation) {
          dispatch(push(requestedLocation));
        } else {
          dispatch(push(ROUTING_URLS.APP.DASHBOARD));
        }
        break;
      }

      case redirectBack.type: {
        dispatch(goBack());
        break;
      }
      case redirectBackByBreadcrumb.type: {
        const {
          router: {
            location: { pathname },
          },
        } = getState();
        const backPath = getBreadcrumbBackPath(pathname);
        if (backPath) dispatch(push(backPath));
        else {
          dispatch(goBack());
        }
        break;
      }

      default:
        break;
    }

    return await next(action);
  };
