import { connect } from 'react-redux';
import { redirectToAppVehiclesListCustomerCarsCarDetails } from '../../../../store/redirect/slice';
import { getVehicles } from '../../../../store/vehicles/slice';
import CustomerCars from './CustomerCars';

const mapStateToProps = (state) => {
  return {
    cars: state.vehicles.vehicles,
  };
};

const mapDispatchToProps = {
  redirectToAppVehiclesListCustomerCarsCarDetails,
  getVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCars);
