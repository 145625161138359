import { connect } from 'react-redux';
import { createNewAldUser } from '../../../../store/aldUsers/slice';
import { redirectToAppAldUsers } from '../../../../store/redirect/slice';
import CreateNewUser from './CreateNewUser';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppAldUsers,
  createNewAldUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewUser);
