import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const GuestGuard = ({ isAuthenticated, children }) => {
  if (isAuthenticated) {
    return <Redirect to="/dashboard/account" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node,
};

export default GuestGuard;
