import { withTheme } from '@emotion/react';
import { Box } from '@mui/system';
import React from 'react';

function AuthLayout({ children }) {
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'center',
          width: '100%',
          minHeight: 0,
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default withTheme(AuthLayout);
