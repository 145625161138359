import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { pathTester } from '../../../common/helpers/routing';
import SuppliersTabs, { SUPPLIERS_TABS } from './Tabs/Tabs';
import BreadcrumbsGeneratorContainer from '../../../common/components/BreadcrubmsGenerator/BreadcrumbsGenerator.container';
import ExpandableButton from '../../../common/components/ExpandableButton/ExpandableButton';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ROUTING_PATH_NAMES } from '../../../common/constants/constants';
import { Redirect, Route, Switch } from 'react-router';
import CreateNewWashSupplierContainer from './CreateNewSupplier/Wash/Wash.container';
import CreateNewNAFSupplierContainer from './CreateNewSupplier/NAF/NAF.container';
import CreateNewWorkshopSupplierContainer from './CreateNewSupplier/Workshop/Workshop.container';
import WashSuppliersContainer from './Wash/Wash.container';
import NAFSuppliersContainer from './NAF/NAF.container';
import WorkshopSuppliersContainer from './Workshop/Workshop.container';
import WashSupplierDetailsContainer from './Wash/Details/Details.container';
import WorkshopSupplierDetailsContainer from './Workshop/Details/Details.container';
import NAFSupplierDetailsContainer from './NAF/Details/Details.container';

function Suppliers({
  t = () => {},
  pathname = '',
  redirectBackByBreadcrumb = () => {},
  redirectToAppSuppliersWashCreateNew = () => {},
  redirectToAppSuppliersNAFCreateNew = () => {},
  redirectToAppSuppliersWorkshopCreateNew = () => {},
}) {
  const isSuppliersWashTabSelected = pathTester.isSuppliersWash(pathname);
  const isSuppliersNAFTabSelected = pathTester.isSuppliersNAF(pathname);
  const isSuppliersWorkshopTabSelected =
    pathTester.isSuppliersWorkshop(pathname);

  const isCreateSupplierTabSelected =
    pathTester.isSuppliersWashCreateNew(pathname) ||
    pathTester.isSuppliersNAFCreateNew(pathname) ||
    pathTester.isSuppliersWorkshopCreateNew(pathname);

  const isSupplierDetailsTabSelected =
    pathTester.isSuppliersWashSupplierDetailsInfo(pathname) ||
    pathTester.isSuppliersWashSupplierDetailsUsers(pathname) ||
    pathTester.isSuppliersWashSupplierDetailsUsersCreateNewUser(pathname) ||
    pathTester.isSuppliersWashSupplierDetailsUsersUserDetails(pathname) ||
    pathTester.isSuppliersNAFSupplierDetailsInfo(pathname) ||
    pathTester.isSuppliersNAFSupplierDetailsUsers(pathname) ||
    pathTester.isSuppliersNAFSupplierDetailsUsersCreateNewUser(pathname) ||
    pathTester.isSuppliersNAFSupplierDetailsUsersUserDetails(pathname) ||
    pathTester.isSuppliersWorkshopSupplierDetailsInfo(pathname) ||
    pathTester.isSuppliersWorkshopSupplierDetailsUsers(pathname) ||
    pathTester.isSuppliersWorkshopSupplierDetailsUsersCreateNewUser(pathname) ||
    pathTester.isSuppliersWorkshopSupplierDetailsUsersUserDetails(pathname);

  let selectedTabId = 0;
  if (isSuppliersWashTabSelected) selectedTabId = SUPPLIERS_TABS.WASH;
  if (isSuppliersNAFTabSelected) selectedTabId = SUPPLIERS_TABS.NAF;
  if (isSuppliersWorkshopTabSelected) selectedTabId = SUPPLIERS_TABS.WORKSHOP;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 1, md: 2 },
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">
            {t('controlCategories.suppliers.title')}
          </Typography>

          <BreadcrumbsGeneratorContainer />
        </Box>

        <Box sx={{ display: 'flex', ml: 'auto', flexShrink: 0 }}>
          {!isCreateSupplierTabSelected && !isSupplierDetailsTabSelected && (
            <ExpandableButton
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              items={[
                {
                  text: 'WASH',
                  onClick: () => {
                    redirectToAppSuppliersWashCreateNew();
                  },
                },
                {
                  text: '3rd Party',
                  onClick: () => {
                    redirectToAppSuppliersNAFCreateNew();
                  },
                },
                {
                  text: 'WORKSHOP',
                  onClick: () => {
                    redirectToAppSuppliersWorkshopCreateNew();
                  },
                },
              ]}
            >
              {t('controlCategories.suppliers.createNewSupplier')}
            </ExpandableButton>
          )}

          {(isCreateSupplierTabSelected || isSupplierDetailsTabSelected) && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                redirectBackByBreadcrumb();
              }}
            >
              {t('common.buttons.back')}
            </Button>
          )}
        </Box>
      </Box>

      <Switch>
        {
          // #region WASH
        }
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}`}
          render={() => {
            return (
              <>
                <SuppliersTabs selectedTabId={selectedTabId} />
                <WashSuppliersContainer />
              </>
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}`}
          render={() => {
            return <CreateNewWashSupplierContainer />;
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/:supplierId`}
          render={({
            match: {
              params: { supplierId },
            },
          }) => {
            return <WashSupplierDetailsContainer supplierId={supplierId} />;
          }}
        />

        {
          // #endregion
        }

        {
          // #region NAF
        }
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}`}
          render={() => {
            return (
              <>
                <SuppliersTabs selectedTabId={selectedTabId} />
                <NAFSuppliersContainer />
              </>
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}`}
          render={() => {
            return <CreateNewNAFSupplierContainer />;
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/:supplierId`}
          render={({
            match: {
              params: { supplierId },
            },
          }) => {
            return <NAFSupplierDetailsContainer supplierId={supplierId} />;
          }}
        />
        {
          // #endregion
        }

        {
          // #region WORKSHOP
        }
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}`}
          render={() => {
            return (
              <>
                <SuppliersTabs selectedTabId={selectedTabId} />
                <WorkshopSuppliersContainer />
              </>
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}`}
          render={() => {
            return <CreateNewWorkshopSupplierContainer />;
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/:supplierId`}
          render={({
            match: {
              params: { supplierId },
            },
          }) => {
            return <WorkshopSupplierDetailsContainer supplierId={supplierId} />;
          }}
        />
        {
          // #endregion
        }

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}`}
          render={() => {
            return (
              <Redirect
                to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}`}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

export default withTranslation()(Suppliers);
