import { connect } from 'react-redux';
import { redirectToAppVehiclesList } from '../../../../../store/redirect/slice';
import { fetchAllContractRepairs } from '../../../../../store/repairs/slice';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import WorkshopCompleted from './WorkshopCompleted';

const mapStateToProps = (state) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
  userAccessRights: state.auth.user.accessRights,
});

const mapDispatchToProps = {
  switchToNextVehicleFlowState,
  redirectToAppVehiclesList,
  fetchAllContractRepairs,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopCompleted);
