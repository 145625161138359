import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../../store/appCommon/slice';
import FullScreenGalleryCarousel from './FullScreenGalleryCarousel';

const mapStateToProps = (state) => ({
  uploadIds: state.app.fsUploadIds,
  onDeleteHandler: state.app.onDeleteHandler,
});

const mapDispatchToProps = {
  setFsUploadIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FullScreenGalleryCarousel);
