import { connect } from 'react-redux';
import SupplierUserDetails from './UserDetails';

const mapStateToProps = (state, ownProps) => {
  const { supplierId, supplierUserId } = ownProps;
  const {
    suppliers: { suppliersUsers },
  } = state;

  const supplierUser = (suppliersUsers[supplierId] || []).find(
    (user) => user.id === supplierUserId,
  );

  return {
    supplierUser,
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplierUserDetails);
