import { connect } from 'react-redux';
import Dashboard from './Dashboard';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
