import { ROUTING_PATH_NAMES } from '../constants/constants';

// #region Dashboard
const dashboardReg = new RegExp(
  `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}$`,
);
const dashboardTodayReg = new RegExp(
  `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.TODAY}$`,
);
const dashboardLastWeekReg = new RegExp(
  `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_WEEK}$`,
);
const dashboardLastMonthReg = new RegExp(
  `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_MONTH}$`,
);
// #endregion

// #region Ald users
const aldUsersCreateNewUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS})/${ROUTING_PATH_NAMES.CREATE_NEW_ALD_USER}$`,
);
const aldUsersUserDetailsUserInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.ALD_USER_INFO}$`,
);
// #endregion

// #region Vehicles list
const vehiclesListCustomerCarsCarDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
const vehiclesListSupplierCarsCarDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.SUPPLIER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
const vehiclesListDealerCarsCarDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.DEALER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
const vehiclesListCarStateReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.STATUS_INFO})$`,
);
// #endregion

// #region Dealers
const dealersCreateNewDealerReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS})/${ROUTING_PATH_NAMES.CREATE_NEW}$`,
);
const dealersDealerDetailsDealerInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.INFO}$`,
);
const dealersDealerDetailsDealerUsersReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}$`,
);
const dealersDealerDetailsDealerUsersUserInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
const dealersDealerDetailsDealerUsersCreateNewUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}$`,
);
// #endregion

// #region Customers

// #region B2C Customers
const b2cCustomerDetailsReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
const b2cCustomerDetailsCustomerInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.CUSTOMER_INFO}$`,
);
const b2cCustomerDetailsCustomerVehiclesListReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.VEHICLES_LIST}$`,
);
const b2cCustomerDetailsCustomerUsersReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}$`,
);
const b2cCustomerDetailsUsersCreateNewAdminUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS})/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER_ADMIN_USER}$`,
);
const b2cCustomerDetailsUsersCreateNewUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS})/${ROUTING_PATH_NAMES.CREATE_NEW_USER}$`,
);
const b2cCustomerDetailsUsersUserDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
// #endregion

// #region B2B Customers
const b2bCustomerDetailsReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
const b2bCustomerDetailsCustomerInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.CUSTOMER_INFO}$`,
);
const b2bCustomerDetailsCustomerVehiclesListReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.VEHICLES_LIST}$`,
);
const b2bCustomerDetailsCustomerUsersReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}$`,
);
const b2bCustomerDetailsUsersCreateNewAdminUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS})/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER_ADMIN_USER}$`,
);
const b2bCustomerDetailsUsersCreateNewUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS})/${ROUTING_PATH_NAMES.CREATE_NEW_USER}$`,
);
const b2bCustomerDetailsUsersUserDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
// #endregion

// #region Customer users
const b2bAdminCustomersReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}$`,
);
const b2bAdminCustomersCustomerDetailsReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*`,
);
const b2bAdminCustomersCustomerDetailsCustomersInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.CUSTOMER_INFO}$`,
);
const b2bAdminCustomersCustomerDetailsCustomerUsersReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}$`,
);
const b2bAdminCustomersCustomerDetailsCustomerUsersUserDetailsReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
const b2bAdminCustomersCustomerDetailsCustomerUsersCreateNewAdminReg =
  new RegExp(
    `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_ADMIN}$`,
  );
const b2bAdminCustomersCustomerDetailsCustomerUsersCreateNewUserReg =
  new RegExp(
    `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}$`,
  );
// #endregion
// #endregion

// #region Suppliers
const suppliersWashTabReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}$`,
);
const suppliersWashCreateReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}$`,
);
const suppliersWashDetailsInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.SUPPLIER_INFO}$`,
);
const supplierWashDetailsUsersReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}$`,
);
const supplierWashDetailsUsersCreateNewUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER}$`,
);
const supplierWashDetailsUsersUserDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WASH})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);

const suppliersNAFTabReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}$`,
);
const suppliersNAFCreateReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}$`,
);
const suppliersNAFDetailsInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.SUPPLIER_INFO}$`,
);
const supplierNAFDetailsUsersReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}$`,
);
const supplierNAFDetailsUsersCreateNewUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER}$`,
);
const supplierNAFDetailsUsersUserDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.NAF})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);

const suppliersWorkshopTabReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}$`,
);
const suppliersWorkshopCreateReg = new RegExp(
  `^/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER}$`,
);
const suppliersWorkshopDetailsInfoReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.SUPPLIER_INFO}$`,
);
const supplierWorkshopDetailsUsersReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}$`,
);
const supplierWorkshopDetailsUsersCreateNewUserReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER}$`,
);
const supplierWorkshopDetailsUsersUserDetailsReg = new RegExp(
  `^(/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}/${ROUTING_PATH_NAMES.WORKSHOP})/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*/${ROUTING_PATH_NAMES.USERS}/([a-zA-Z0-9]+-)*([a-zA-Z0-9]+)*$`,
);
// #endregion

export const pathTester = {
  // #region Dashboard
  isDashboard: (path = '') => {
    return dashboardReg.test(path);
  },
  isDashboardToday: (path = '') => {
    return dashboardTodayReg.test(path);
  },
  isDashboardLastWeek: (path = '') => {
    return dashboardLastWeekReg.test(path);
  },
  isDashboardLastMonth: (path = '') => {
    return dashboardLastMonthReg.test(path);
  },
  // #endregion

  // #region Ald users
  isAldUsersCreateNewUser: (path = '') => {
    return aldUsersCreateNewUserReg.test(path);
  },
  isAldUsersUserDetailsUserInfo: (path = '') => {
    return aldUsersUserDetailsUserInfoReg.test(path);
  },
  // #endregion

  // #region Vehicles list
  isVehiclesListCustomerCarsCarDetails: (path = '') => {
    return vehiclesListCustomerCarsCarDetailsReg.test(path);
  },
  isVehiclesListSupplierCarsCarDetails: (path = '') => {
    return vehiclesListSupplierCarsCarDetailsReg.test(path);
  },
  isVehiclesListDealerCarsCarDetails: (path = '') => {
    return vehiclesListDealerCarsCarDetailsReg.test(path);
  },
  isVehiclesListVehicleState: (path = '') => {
    return vehiclesListCarStateReg.test(path);
  },
  // #endregion

  // #region Dealers
  isDealersCreateNew: (path = '') => {
    return dealersCreateNewDealerReg.test(path);
  },
  isDealersDealerDetailsDealerInfo: (path = '') => {
    return dealersDealerDetailsDealerInfoReg.test(path);
  },
  isDealersDealerDetailsDealerUsers: (path = '') => {
    return dealersDealerDetailsDealerUsersReg.test(path);
  },
  isDealersDealerDetailsDealerUsersUserInfo: (path = '') => {
    return dealersDealerDetailsDealerUsersUserInfoReg.test(path);
  },
  isDealersDealerDetailsDealerUsersCreateNewUser: (path = '') => {
    return dealersDealerDetailsDealerUsersCreateNewUserReg.test(path);
  },
  // #endregion

  // #region Customers
  // #region B2C Customers
  isB2CCustomerDetails: (path = '') => {
    return b2cCustomerDetailsReg.test(path);
  },
  isB2CCustomerDetailsCustomerInfo: (path = '') => {
    return b2cCustomerDetailsCustomerInfoReg.test(path);
  },
  isB2CCustomerDetailsCustomerVehiclesList: (path = '') => {
    return b2cCustomerDetailsCustomerVehiclesListReg.test(path);
  },
  isB2CCustomerDetailsCustomerUsers: (path = '') => {
    return b2cCustomerDetailsCustomerUsersReg.test(path);
  },
  isB2CCustomerDetailsUsersCreateNewAdminUser: (path = '') => {
    return b2cCustomerDetailsUsersCreateNewAdminUserReg.test(path);
  },
  isB2CCustomerDetailsUsersCreateNewUser: (path = '') => {
    return b2cCustomerDetailsUsersCreateNewUserReg.test(path);
  },
  isB2CCustomerDetailsUsersUserDetails: (path = '') => {
    return b2cCustomerDetailsUsersUserDetailsReg.test(path);
  },
  // #endregion

  // #region B2B Customers
  isB2BCustomerDetails: (path = '') => {
    return b2bCustomerDetailsReg.test(path);
  },
  isB2BCustomerDetailsCustomerInfo: (path = '') => {
    return b2bCustomerDetailsCustomerInfoReg.test(path);
  },
  isB2BCustomerDetailsCustomerVehiclesList: (path = '') => {
    return b2bCustomerDetailsCustomerVehiclesListReg.test(path);
  },
  isB2BCustomerDetailsCustomerUsers: (path = '') => {
    return b2bCustomerDetailsCustomerUsersReg.test(path);
  },
  isB2BCustomerDetailsUsersCreateNewAdminUser: (path = '') => {
    return b2bCustomerDetailsUsersCreateNewAdminUserReg.test(path);
  },
  isB2BCustomerDetailsUsersCreateNewUser: (path = '') => {
    return b2bCustomerDetailsUsersCreateNewUserReg.test(path);
  },
  isB2BCustomerDetailsUsersUserDetails: (path = '') => {
    return b2bCustomerDetailsUsersUserDetailsReg.test(path);
  },

  // #endregion
  // #endregion

  // #region Customer users
  isB2BAdminCustomers: (path = '') => {
    return b2bAdminCustomersReg.test(path);
  },
  isB2BAdminCustomersCustomerDetails: (path = '') => {
    return b2bAdminCustomersCustomerDetailsReg.test(path);
  },
  isB2BAdminCustomersCustomerDetailsCustomerInfo: (path = '') => {
    return b2bAdminCustomersCustomerDetailsCustomersInfoReg.test(path);
  },
  isB2BAdminCustomersCustomerDetailsCustomerUsers: (path = '') => {
    return b2bAdminCustomersCustomerDetailsCustomerUsersReg.test(path);
  },
  isB2BAdminCustomersCustomerDetailsCustomerUsersUserDetails: (path = '') => {
    return b2bAdminCustomersCustomerDetailsCustomerUsersUserDetailsReg.test(
      path,
    );
  },
  isB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewAdmin: (
    path = '',
  ) => {
    return b2bAdminCustomersCustomerDetailsCustomerUsersCreateNewAdminReg.test(
      path,
    );
  },
  isB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewUser: (path = '') => {
    return b2bAdminCustomersCustomerDetailsCustomerUsersCreateNewUserReg.test(
      path,
    );
  },

  // #endregion

  // #region Suppliers
  isSuppliersWash: (path = '') => {
    return suppliersWashTabReg.test(path);
  },
  isSuppliersWashCreateNew: (path = '') => {
    return suppliersWashCreateReg.test(path);
  },
  isSuppliersWashSupplierDetailsInfo: (path = '') => {
    return suppliersWashDetailsInfoReg.test(path);
  },
  isSuppliersWashSupplierDetailsUsers: (path = '') => {
    return supplierWashDetailsUsersReg.test(path);
  },
  isSuppliersWashSupplierDetailsUsersCreateNewUser: (path = '') => {
    return supplierWashDetailsUsersCreateNewUserReg.test(path);
  },
  isSuppliersWashSupplierDetailsUsersUserDetails: (path = '') => {
    return supplierWashDetailsUsersUserDetailsReg.test(path);
  },

  isSuppliersNAF: (path = '') => {
    return suppliersNAFTabReg.test(path);
  },
  isSuppliersNAFCreateNew: (path = '') => {
    return suppliersNAFCreateReg.test(path);
  },
  isSuppliersNAFSupplierDetailsInfo: (path = '') => {
    return suppliersNAFDetailsInfoReg.test(path);
  },
  isSuppliersNAFSupplierDetailsUsers: (path = '') => {
    return supplierNAFDetailsUsersReg.test(path);
  },
  isSuppliersNAFSupplierDetailsUsersCreateNewUser: (path = '') => {
    return supplierNAFDetailsUsersCreateNewUserReg.test(path);
  },
  isSuppliersNAFSupplierDetailsUsersUserDetails: (path = '') => {
    return supplierNAFDetailsUsersUserDetailsReg.test(path);
  },

  isSuppliersWorkshop: (path = '') => {
    return suppliersWorkshopTabReg.test(path);
  },
  isSuppliersWorkshopCreateNew: (path = '') => {
    return suppliersWorkshopCreateReg.test(path);
  },
  isSuppliersWorkshopSupplierDetailsInfo: (path = '') => {
    return suppliersWorkshopDetailsInfoReg.test(path);
  },
  isSuppliersWorkshopSupplierDetailsUsers: (path = '') => {
    return supplierWorkshopDetailsUsersReg.test(path);
  },
  isSuppliersWorkshopSupplierDetailsUsersCreateNewUser: (path = '') => {
    return supplierWorkshopDetailsUsersCreateNewUserReg.test(path);
  },
  isSuppliersWorkshopSupplierDetailsUsersUserDetails: (path = '') => {
    return supplierWorkshopDetailsUsersUserDetailsReg.test(path);
  },
  // #endregion
};

// todo: refactor
export const getBreadcrumbBackPath = (path) => {
  // #region B2B Customers
  const res = b2bCustomerDetailsCustomerInfoReg.exec(path);
  if (res) {
    return res[1];
  }
  const res2 = b2bCustomerDetailsCustomerVehiclesListReg.exec(path);
  if (res2) {
    return res2[1];
  }
  const res3 = b2bCustomerDetailsCustomerUsersReg.exec(path);
  if (res3) {
    return res3[1];
  }
  const res4 = b2bCustomerDetailsUsersCreateNewAdminUserReg.exec(path);
  if (res4) {
    return res4[1];
  }
  const res5 = b2bCustomerDetailsUsersCreateNewUserReg.exec(path);
  if (res5) {
    return res5[1];
  }
  const res6 = b2bCustomerDetailsUsersUserDetailsReg.exec(path);
  if (res6) {
    return res6[1];
  }
  // #endregion

  // #region Suppliers
  const res7 = suppliersWashDetailsInfoReg.exec(path);
  if (res7) {
    return res7[1];
  }
  const res8 = suppliersNAFDetailsInfoReg.exec(path);
  if (res8) {
    return res8[1];
  }
  const res9 = suppliersWorkshopDetailsInfoReg.exec(path);
  if (res9) {
    return res9[1];
  }
  const res10 = supplierWashDetailsUsersReg.exec(path);
  if (res10) {
    return res10[1];
  }
  const res11 = supplierNAFDetailsUsersReg.exec(path);
  if (res11) {
    return res11[1];
  }
  const res12 = supplierWorkshopDetailsUsersReg.exec(path);
  if (res12) {
    return res12[1];
  }
  // #endregion

  const res13 = vehiclesListCustomerCarsCarDetailsReg.exec(path);
  if (res13) {
    return res13[1];
  }
  const res14 = vehiclesListSupplierCarsCarDetailsReg.exec(path);
  if (res14) {
    return res14[1];
  }

  // #region ALD Users
  const res15 = aldUsersCreateNewUserReg.exec(path);
  if (res15) {
    return res15[1];
  }

  const res16 = aldUsersUserDetailsUserInfoReg.exec(path);
  if (res16) {
    return res16[1];
  }
  // #endregion

  // #region Dealers
  const res17 = dealersDealerDetailsDealerInfoReg.exec(path);
  if (res17) {
    return res17[1];
  }
  const res18 = dealersDealerDetailsDealerUsersReg.exec(path);
  if (res18) {
    return res18[1];
  }
  // #endregion

  // #region B2C Customers
  const res19 = b2cCustomerDetailsCustomerInfoReg.exec(path);
  if (res19) {
    return res19[1];
  }
  const res20 = b2cCustomerDetailsCustomerVehiclesListReg.exec(path);
  if (res20) {
    return res20[1];
  }
  const res21 = b2cCustomerDetailsCustomerUsersReg.exec(path);
  if (res21) {
    return res21[1];
  }
  const res22 = b2cCustomerDetailsUsersUserDetailsReg.exec(path);
  if (res22) {
    return res22[1];
  }
  // #endregion

  // #region B2B Admin Customers
  const res23 = b2bAdminCustomersCustomerDetailsCustomersInfoReg.exec(path);
  if (res23) {
    return res23[1];
  }
  const res24 = b2bAdminCustomersCustomerDetailsCustomerUsersReg.exec(path);
  if (res24) {
    return res24[1];
  }
  // #endregion
  return null;
};
