export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE',
};

export const LANGUAGE_CODES = {
  EN: 'en',
};

export const ROUTING_PATH_NAMES = {
  AUTH: 'auth',
  SIGN_IN: 'signin',
  SIGN_UP: 'signup',
  PASSWORDLESS_AUTH: 'password-less-auth',

  APP: 'app',

  DASHBOARD: 'dashboard',
  TODAY: 'today',
  LAST_WEEK: 'last-week',
  LAST_MONTH: 'last-month',
  ALD_USERS: 'ald-users',
  CREATE_NEW_ALD_USER: 'create-new-ald-user',
  ALD_USER_INFO: 'ald-user-info',

  VEHICLES_LIST: 'vehicles-list',
  CUSTOMER_LOCATION_CARS: 'customer-location-cars',
  NEW_BOOKED_CARS: 'new-booked-cars',
  ALREADY_IN_PROCESS_CARS: 'already-in-process-cars',
  READY_FOR_SALES_CARS: 'ready-for-sales-cars',
  SUPPLIER_CARS: 'supplier-cars',
  CUSTOMER_CARS: 'customer-cars',
  DEALER_CARS: 'dealer-cars',
  ASSESSMENT_APPROVES: 'assessment-approves',
  ADD_NEW_CAR: 'add-new-car',
  CAR_DETAILS: 'car-details',
  STATUS_INFO: 'status-info',

  VEHICLES_PLANNING: 'vehicles-planning',
  SUPPLIER_USERS_LIST: 'supplier-users-list',
  PICKUP_CALENDAR: 'pickup-calendar',
  ASSESSMENT: 'assessment',
  EDIT_ASSESSMENT: 'edit-assessment',

  CONTROL_CATEGORIES: 'control-categories',
  DEALERS: 'dealers',
  CREATE_NEW_DEALER: 'create-new-dealer',
  CUSTOMERS: 'customers',
  B2C: 'b2c',
  B2B: 'b2b',
  RENTAL: 'rental',
  FLEX: 'flex',
  CREATE_NEW_CUSTOMER: 'create-new-customer',
  CUSTOMER_DETAILS: 'customer-details',
  CUSTOMER_INFO: 'customer-info',
  CARS: 'cars',
  USERS: 'users',
  CREATE_NEW_CUSTOMER_ADMIN_USER: 'create-new-admin-user',

  B2BADMIN_CUSTOMERS: 'b2bAdmin-customers',

  SUPPLIERS: 'suppliers',
  WASH: 'wash',
  NAF: '3rd Party',
  WORKSHOP: 'workshop',
  CREATE_NEW_SUPPLIER: 'create-new-supplier',
  CREATE_NEW_SUPPLIER_USER: 'create-new-supplier-user',
  SUPPLIER_INFO: 'supplier-info',
  SUPPLIER_USERS: 'supplier-users',
  SUPPLIER_GROUPS: 'supplier-groups',

  REPORTS: 'reports',
  INBOX: 'inbox',

  CREATE_NEW: 'create-new',
  INFO: 'info',
  CREATE_NEW_USER: 'create-new-user',
  CREATE_NEW_ADMIN: 'create-new-admin',
  ALL: 'all',
};

export const ROUTING_URLS = {
  AUTH: {
    SIGN_IN: `/${ROUTING_PATH_NAMES.AUTH}/${ROUTING_PATH_NAMES.SIGN_IN}`,
    SIGN_UP: `/${ROUTING_PATH_NAMES.AUTH}/${ROUTING_PATH_NAMES.SIGN_UP}`,
  },
  APP: {
    DASHBOARD: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}`,
    ALD_USERS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}`,

    VEHICLES_LIST: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}`,
    VEHICLES_LIST__NEW_RETURNED_CARS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}`,
    VEHICLES_LIST__NEW_RETURNED_CARS__CAR_DETAILS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    VEHICLES_LIST__ALREADY_IN_PROCESS_CARS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}`,
    VEHICLES_LIST__ALREADY_IN_PROCESS_CARS__CAR_DETAILS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    VEHICLES_LIST__READY_FOR_SALES_CARS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}`,
    VEHICLES_LIST__READY_FOR_SALES_CARS__CAR_DETAILS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    VEHICLES_LIST__ADD_NEW_CAR: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ADD_NEW_CAR}`,

    VEHICLES_PLANNING: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_PLANNING}`,
    SUPPLIER_USERS_LIST: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.SUPPLIER_USERS_LIST}`,
    PICKUP_CALENDAR: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.PICKUP_CALENDAR}`,
    WORKSHOP: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.WORKSHOP}`,
    ASSESSMENT: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ASSESSMENT}`,

    CONTROL_CATEGORIES: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}`,
    CONTROL_CATEGORIES__DEALERS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}`,
    CONTROL_CATEGORIES__DEALERS__CREATE_NEW_CUSTOMER: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/${ROUTING_PATH_NAMES.CREATE_NEW_DEALER}`,
    CONTROL_CATEGORIES__CUSTOMERS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2C: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2C__CREATE_NEW_CUSTOMER: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B__CREATE_NEW_CUSTOMER: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B__CUSTOMER_DETAILS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B__CUSTOMER_DETAILS__CUSTOMER_INFO: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${ROUTING_PATH_NAMES.CUSTOMER_INFO}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B__CUSTOMER_DETAILS__CARS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${ROUTING_PATH_NAMES.CARS}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B__CUSTOMER_DETAILS__USERS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${ROUTING_PATH_NAMES.USERS}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B__CUSTOMER_DETAILS__CREATE_NEW_CUSTOMER_ADMIN: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER_ADMIN}`,
    CONTROL_CATEGORIES__CUSTOMERS__B2B__CUSTOMER_DETAILS__CREATE_NEW_USER: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}`,
    CONTROL_CATEGORIES__CUSTOMERS__RENTAL: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.RENTAL}`,
    CONTROL_CATEGORIES__CUSTOMERS__RENTAL__CREATE_NEW_CUSTOMER: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.RENTAL}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER}`,
    CONTROL_CATEGORIES__CUSTOMERS__FLEX: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.FLEX}`,
    CONTROL_CATEGORIES__CUSTOMERS__FLEX__CREATE_NEW_CUSTOMER: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.FLEX}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER}`,
    CONTROL_CATEGORIES__SUPPLIERS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIERS}`,
    CONTROL_CATEGORIES__SUPPLIER_GROUPS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.SUPPLIER_GROUPS}`,

    REPORTS: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.REPORTS}`,
    INBOX: `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.INBOX}`,
  },
};
