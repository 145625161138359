import { Box } from '@mui/system';
import Info from '@mui/icons-material/Info';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Divider, Typography } from '@mui/material';
import DeleteButton from '../../../../../common/components/DeleteButton/DeleteButton';
import React, { useEffect, useState } from 'react';
import EditAssessmentRepairContainer from './EditAssessmentRepair.container';
import DropDownWithoutLabel from '../../../../../common/components/DropDownInput/DropDownWithoutLabel';
import { getUnusedDamageTypeGroups } from '../../../../../common/constants/assessment';
import { getMenuValues } from '../../../../../utils/dropDownInput';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

const EditAssessment = ({
  assessment = { repairs: [] },
  damageTypeGroups = {},
  damageTypeGroupsArray = [],
  vehicleId,
  createNewRepair = () => {},
  deleteRepair = () => {},
  vehicle,
  _setCurrentVehicle = () => {},
  getVehicleById = () => {},
  getVehicleAldAssessment = () => {},
  getVehicleNafAssessment = () => {},
  getAllSuppliers = () => {},
}) => {
  useEffect(() => {
    getVehicleById(vehicleId).then((data) => _setCurrentVehicle(data));

    getVehicleAldAssessment(vehicleId).catch((error) => {
      console.error(error);
    });

    getVehicleNafAssessment(vehicleId).catch((error) => {
      console.error(error);
    });
    return () => {
      _setCurrentVehicle(undefined);
    };
  }, [vehicleId]);

  useEffect(() => {
    getAllSuppliers();
  }, []);

  // useEffect(() => {
  //   if (vehicle) {

  //   }
  // }, [vehicle]);

  const [expandedDamageTypeGroupIds, setExpandedDamageTypeGroupIds] = useState(
    [],
  );

  const handleExpandDamageTypeGroup = (groupId) =>
    setExpandedDamageTypeGroupIds([...expandedDamageTypeGroupIds, groupId]);
  const handleRollupDamageTypeGroup = (groupId) =>
    setExpandedDamageTypeGroupIds(
      expandedDamageTypeGroupIds.filter((x) => x !== groupId),
    );

  const unusedDamageTypeGroups = getUnusedDamageTypeGroups(
    damageTypeGroupsArray,
    assessment,
  );
  const damageTypeGroupValues = getMenuValues(unusedDamageTypeGroups);

  if (vehicle) {
    const { currentStateEnum } = vehicle;
    const isEditingBlocked =
      currentStateEnum > SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT;

    return (
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          width: '100%',
          flex: 1,
          overflow: 'auto',
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            m: '10px',
          }}
        >
          <Box
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              background: '#F2F3FC',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: '10px',
            }}
          >
            <Info
              sx={{
                fill: 'rgba(66, 82, 110, 0.82)',
              }}
            />
          </Box>
          <Typography
            variant="h5"
            sx={{
              color: 'rgba(66, 82, 110, 0.82)',
            }}
          >
            FLEET Assessment
          </Typography>
        </Box>

        <Divider />

        {assessment &&
          assessment.repairs.map((repair) => {
            const group = damageTypeGroups[repair.damageTypeGroupId];
            const isExpanded = expandedDamageTypeGroupIds.includes(
              repair.damageTypeGroupId,
            );

            return (
              <React.Fragment key={repair.id}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    background: 'rgba(86, 100, 210, 0.1)',
                    my: 1,
                    p: 1,
                    height: '72px',
                  }}
                >
                  <Typography
                    sx={{
                      mr: 'auto',
                      ml: '30px',
                    }}
                  >
                    {group.name}
                  </Typography>
                  {isExpanded && (
                    <DeleteButton
                      disabled={isEditingBlocked}
                      sx={{ background: 'white' }}
                      onClick={() => deleteRepair(vehicleId, repair.id)}
                    />
                  )}
                  {isExpanded ? (
                    <ExpandLess
                      sx={{
                        ml: '20px',
                        mr: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleRollupDamageTypeGroup(repair.damageTypeGroupId)
                      }
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        ml: '20px',
                        mr: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleExpandDamageTypeGroup(repair.damageTypeGroupId)
                      }
                    />
                  )}
                </Box>
                <Collapse in={isExpanded}>
                  <Box sx={{ p: 4, pb: 1 }}>
                    <EditAssessmentRepairContainer
                      repair={repair}
                      assessment={assessment}
                      vehicleId={vehicleId}
                      isEditingBlocked={isEditingBlocked}
                    />
                  </Box>
                </Collapse>
              </React.Fragment>
            );
          })}

        {!isEditingBlocked && (
          <Box sx={{ px: 2 }}>
            {damageTypeGroupValues && damageTypeGroupValues.length > 0 && (
              <>
                <Typography sx={{ fontSize: '14px', ml: '5px' }}>
                  Add new damage group
                </Typography>
                <DropDownWithoutLabel
                  sx={{ width: '100%' }}
                  value=""
                  values={damageTypeGroupValues}
                  onChange={(ev) =>
                    createNewRepair(vehicleId, assessment.id, ev.target.value)
                  }
                />
              </>
            )}
          </Box>
        )}
      </Box>
    );
  }

  return null;
};

export default EditAssessment;
