import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Formik } from 'formik';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import useMounted from '../../../../../../common/hooks/useMounted';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { RETURN_BOOKING_TYPE } from '../../../../../../common/constants/booking';

const BookingReturnToDealer = ({
  t = () => {},
  onBackButtonClicked = () => {},
  bookToAldOrDealerLocation = () => {},
  getVehicleFlowState = () => {},
  vehicle = {},
  locationId,
}) => {
  const mounted = useMounted();
  const [dateAndTime, setDateAndTime] = useState(moment());

  const { contractId, id: carId } = vehicle;

  return (
    <Box
      sx={{
        p: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Formik
        initialValues={{
          submit: null,
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await bookToAldOrDealerLocation({
              contractId,
              carId,
              returnLocationId: locationId,
              returnBookingType: RETURN_BOOKING_TYPE.RETURN_TO_DEALER,
              bookingDate: dateAndTime.utc(),
            });
            await getVehicleFlowState(carId, contractId);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleSubmit, isSubmitting }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'auto',
              minWidth: 0,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 2, fontWeight: 500 }} variant="h6">
                {t('vehiclesList.carStatus.booking.chooseDateAndTime')}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>Date</Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <MobileDatePicker
                    cancelText=""
                    inputFormat="DD/MM/YYYY"
                    value={dateAndTime}
                    onChange={(newDate) => {
                      setDateAndTime(newDate);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={moment()}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ fontWeight: 500 }}>Time</Typography>

                <MobileTimePicker
                  ampm={false}
                  value={dateAndTime}
                  onChange={(newTime) => {
                    setDateAndTime(newTime);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>

              {errors.submit && (
                <Box sx={{ mt: 1 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box
                sx={{
                  mt: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="outlined"
                  startIcon={<ChevronLeftIcon />}
                  sx={{
                    width: '100%',
                    mt: 'auto',
                    mr: 4,
                    '& .MuiButton-startIcon': {
                      position: 'absolute',
                      left: '10px',
                    },
                  }}
                  onClick={() => {
                    onBackButtonClicked();
                  }}
                >
                  {t('common.buttons.back')}
                </Button>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{ width: '100%', mt: 'auto' }}
                >
                  {t('common.buttons.confirm')}
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default withTranslation()(BookingReturnToDealer);
