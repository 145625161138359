import { Divider, ImageList, ImageListItem, Typography } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import _ from 'lodash';
import ImageLoader from '../../../../../common/components/ImageLoader/ImageLoader';

function NAFAssessment({
  assessment = null,
  damageTypes,
  damageTypeGroups,
  setFsUploadIds = () => {},
}) {
  if (!assessment) return <></>;

  const groups = _.groupBy(
    assessment.assessmentSheets,
    (x) => x.damageTypeGroupId,
  );
  const repairs = Object.keys(groups).map((key) => ({
    sheets: groups[key],
    damageTypeGroupId: key,
  }));

  let price = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const sheet of assessment.assessmentSheets) {
    // eslint-disable-next-line no-restricted-syntax
    for (const details of sheet.damageDetails) {
      price += details.price;
    }
  }

  return (
    <>
      {repairs.map((repair) => {
        if (repair.sheets.length === 0) {
          return <React.Fragment key={`${new Date()}`} />;
        }
        return (
          <Box sx={{ m: '15px' }} key={repair.damageTypeGroupId}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography>
                {damageTypeGroups[repair.damageTypeGroupId]?.name}
              </Typography>
            </Box>
            <Divider />
            {repair.sheets.map((damage) => {
              const sheetPrice = damage.damageDetails.reduce(
                (acc, curr) => acc + curr.price,
                0,
              );
              return (
                <React.Fragment key={damage.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      p: 2,
                      pr: 0,
                      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                  >
                    <Box
                      sx={{
                        flex: 2,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography sx={{ mb: 2 }}>
                        {damageTypes[damage.damageTypeId]?.name}
                      </Typography>
                      {damage.damageDetails.map((details) => (
                        <Typography
                          key={details.id}
                          sx={{ fontSize: '12px', mb: 4 }}
                        >
                          {details.name}
                        </Typography>
                      ))}
                      <Typography sx={{ fontWeight: 500 }}>
                        Repair price: {sheetPrice}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        flex: 2,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mr: '20px',
                      }}
                    >
                      {damage.images && damage.images.length > 0 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <ImageList rowHeight={105} gap={10}>
                            {damage.images.map((image, imageIndex) => {
                              if (imageIndex > 1)
                                return <React.Fragment key={image} />;

                              return (
                                <ImageListItem
                                  key={image}
                                  cols={1}
                                  rows={1}
                                  sx={{}}
                                  onClick={() => setFsUploadIds(damage.images)}
                                >
                                  <ImageLoader
                                    sx={{
                                      borderRadius: '6px',
                                      maxWidth: '100px',
                                      maxHeight: '100px',
                                    }}
                                    loading="lazy"
                                    uploadId={image}
                                  />
                                </ImageListItem>
                              );
                            })}
                            {damage.images && damage.images.length > 2 && (
                              <Typography
                                onClick={() => setFsUploadIds(damage.images)}
                                sx={{ fontSize: '12px', cursor: 'pointer' }}
                              >
                                + {damage.images.length - 2} pictures
                              </Typography>
                            )}
                          </ImageList>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                </React.Fragment>
              );
            })}
          </Box>
        );
      })}
      <Typography>Sum of repair: {price}</Typography>
    </>
  );
}

export default withTranslation()(NAFAssessment);
