import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../common/components/DataGrid/DataGrid';

function DealersList({
  t = () => {},
  dealers = [],
  redirectToAppDealersDealerDetailsDealerInfo = () => {},
  getAllDealers = () => {},
}) {
  useEffect(() => {
    getAllDealers();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: t('common.titles.name'),
    },
    {
      field: 'email',
      headerName: t('common.titles.email'),
    },
    {
      field: 'phone',
      headerName: t('common.titles.telephone'),
    },
    {
      field: 'organizationNo',
      headerName: t('common.titles.organizationNo'),
    },
  ];

  const rows = dealers.map((aldUser) => {
    return {
      ...aldUser,
      address: (aldUser.address && aldUser.address.name) || '-',
      contactPersonName:
        (aldUser.contactPerson && aldUser.contactPerson.name) || '-',
      email: (aldUser.contactPerson && aldUser.contactPerson.email) || '-',
      phone: (aldUser.contactPerson && aldUser.contactPerson.phone) || '-',
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CommonDataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        noDataToShowMessage={t('common.messages.noDataToShow')}
        selectRowHandler={(rowData) => {
          redirectToAppDealersDealerDetailsDealerInfo(rowData.id);
        }}
      />
    </Box>
  );
}

export default withTranslation()(DealersList);
