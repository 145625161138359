import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { withTranslation } from 'react-i18next';

export const B2C_CUSTOMER_DETAILS_TABS = {
  CUSTOMER_INFO: 0,
  CARS: 1,
  USERS: 2,
};

function B2BCustomerDetailsTabs({
  t = () => {},
  customerId = '',
  selectedTabId = B2C_CUSTOMER_DETAILS_TABS.CUSTOMER_INFO,
  redirectToAppCustomersB2CCustomerDetailsCustomerInfo = () => {},
  redirectToAppCustomersB2CCustomerDetailsCustomerVehicles = () => {},
  redirectToAppCustomersB2CCustomerDetailsCustomerUsers = () => {},
}) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{
          borderBottom: '1px solid #E0E0E0',
          mb: 2,
          '& .MuiTab-root': {
            flex: 1,
          },
        }}
      >
        <Tab
          onClick={() => {
            redirectToAppCustomersB2CCustomerDetailsCustomerInfo(customerId);
          }}
          id={B2C_CUSTOMER_DETAILS_TABS.CUSTOMER_INFO}
          label={t('common.titles.customerInfo')}
        />

        <Tab
          onClick={() => {
            redirectToAppCustomersB2CCustomerDetailsCustomerVehicles(
              customerId,
            );
          }}
          id={B2C_CUSTOMER_DETAILS_TABS.CARS}
          label={t('common.titles.vehiclesList')}
        />

        <Tab
          onClick={() => {
            redirectToAppCustomersB2CCustomerDetailsCustomerUsers(customerId);
          }}
          id={B2C_CUSTOMER_DETAILS_TABS.USERS}
          label={t('common.titles.users')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(B2BCustomerDetailsTabs);
