import { connect } from 'react-redux';
import {
  redirectToAppCustomersB2CCustomerDetailsCustomerInfo,
  redirectToAppCustomersB2CCustomerDetailsCustomerUsers,
  redirectToAppCustomersB2CCustomerDetailsCustomerVehicles,
} from '../../../../../../store/redirect/slice';
import Tabs from './Tabs';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppCustomersB2CCustomerDetailsCustomerInfo,
  redirectToAppCustomersB2CCustomerDetailsCustomerVehicles,
  redirectToAppCustomersB2CCustomerDetailsCustomerUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
