import { connect } from 'react-redux';
import { hideNav } from '../../../store/appCommon/slice';
import {
  redirectToAppAldUsers,
  redirectToAppCustomers,
  redirectToAppDashboard,
  redirectToAppDealers,
  redirectToAppInbox,
  redirectToAppPickupCalendar,
  redirectToAppReports,
  redirectToAppSupplierGroups,
  redirectToAppSuppliers,
  redirectToAppSupplierUsersList,
  redirectToAppVehiclesList,
  redirectToAppVehiclesPlanning,
  redirectToAppWorkshop,
  redirectToAppB2BAdminCustomers,
  redirectToAppVehiclesListAllCars,
} from '../../../store/redirect/slice';
import Nav from './Nav';

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    userName: state.auth.user.displayName,
    roleDescription: state.auth.user.roleDescription,
    isNavOpened: state.app.isNavOpened,
    appropriateSupplier: state.suppliers.appropriateSupplier,
  };
};

const mapDispatchToProps = {
  redirectToAppDashboard,
  redirectToAppAldUsers,
  redirectToAppVehiclesList,
  redirectToAppVehiclesPlanning,
  redirectToAppSupplierUsersList,
  redirectToAppPickupCalendar,
  redirectToAppWorkshop,
  redirectToAppDealers,
  redirectToAppCustomers,
  redirectToAppSuppliers,
  redirectToAppSupplierGroups,
  redirectToAppReports,
  redirectToAppInbox,
  hideNav,
  redirectToAppB2BAdminCustomers,
  redirectToAppVehiclesListAllCars,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
