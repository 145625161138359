import Box from '@mui/system/Box';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../common/components/DataGrid/DataGrid';

function B2CCustomers({
  t = () => {},
  customers = [],
  redirectToAppCustomersB2CCustomerDetailsCustomerInfo = () => {},
  getAllB2CCustomers = () => {},
}) {
  useEffect(() => {
    getAllB2CCustomers();
  }, [getAllB2CCustomers]);

  const columns = [
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'name',
      headerName: t('controlCategories.customers.b2c.customerName'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'email',
      headerName: t('controlCategories.customers.b2c.email'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'address',
      headerName: t('controlCategories.customers.b2c.address'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'car',
      headerName: t('controlCategories.customers.b2c.car'),
    },
  ];

  const rows = customers.map((customer) => {
    return {
      ...customer,
      email: (customer.contactPerson && customer.contactPerson.email) || '-',
      address: (customer.address && customer.address.name) || '-',
      car: customer.cars.length > 0 ? customer.cars[0] : '-',
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CommonDataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        noDataToShowMessage={t('common.messages.noDataToShow')}
        selectRowHandler={(rowData) => {
          redirectToAppCustomersB2CCustomerDetailsCustomerInfo(rowData.id);
        }}
      />
    </Box>
  );
}

export default withTranslation()(B2CCustomers);
