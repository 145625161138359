import { connect } from 'react-redux';
import { getDashboardLastMonthStatistic } from '../../../../store/dashboard/slice';
import LastMonth from './LastMonth';

const mapStateToProps = (state) => {
  const { lastMonthStatistic } = state.dashboard;

  return {
    statistic: lastMonthStatistic,
  };
};

const mapDispatchToProps = { getDashboardLastMonthStatistic };

export default connect(mapStateToProps, mapDispatchToProps)(LastMonth);
