import { SALES_CHANNELS_ENUM } from '../../../../common/constants/vehicles';

export const getChannelColorByEnum = (enm) => {
  switch (enm) {
    case SALES_CHANNELS_ENUM.NONE:
      return 'black';
    case SALES_CHANNELS_ENUM.AUCTION:
      return '#195EC7';
    case SALES_CHANNELS_ENUM.RETAIL:
      return '#FFB547';
    case SALES_CHANNELS_ENUM.FLEX:
      return '#569DD2';
    case SALES_CHANNELS_ENUM.OUTLET:
      return '#4CAF50';
    case SALES_CHANNELS_ENUM.WHOLESALE:
      return '#A5C000';
    case SALES_CHANNELS_ENUM.COMMISSION:
      return '#C7008F';
    case SALES_CHANNELS_ENUM.BUYBACK:
      return '#0AB9AE';

    default:
      return 'black';
  }
};

export const getChannelNameByEnum = (enm) => {
  switch (enm) {
    case SALES_CHANNELS_ENUM.NONE:
      return 'None';
    case SALES_CHANNELS_ENUM.AUCTION:
      return 'Auction';
    case SALES_CHANNELS_ENUM.RETAIL:
      return 'Retail';
    case SALES_CHANNELS_ENUM.FLEX:
      return 'Flex';
    case SALES_CHANNELS_ENUM.OUTLET:
      return 'Outlet';
    case SALES_CHANNELS_ENUM.WHOLESALE:
      return 'Wholesale';
    case SALES_CHANNELS_ENUM.COMMISSION:
      return 'Commission';
    case SALES_CHANNELS_ENUM.BUYBACK:
      return 'Buyback';

    default:
      return 'None';
  }
};
