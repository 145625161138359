import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const MailIcon = createSvgIcon(
  <svg viewBox="0 0 20 20">
    <g id="Icon/Fill/email">
      <path
        id="Mask"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8385 5.00016L10.4177 8.7235C10.1619 8.86933 9.84854 8.86933 9.59271 8.7235L4.17188 5.00016H15.8385ZM15.8385 3.3335H4.17188C2.79354 3.3335 1.67188 4.45516 1.67188 5.8335V14.1668C1.67188 15.5452 2.79354 16.6668 4.17188 16.6668H15.8385C17.2169 16.6668 18.3385 15.5452 18.3385 14.1668V5.8335C18.3385 4.45516 17.2169 3.3335 15.8385 3.3335Z"
        fillOpacity="0.86"
      />
      <mask
        id="mask0_256:2628"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="3"
        width="18"
        height="14"
      >
        <path
          id="Mask_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8385 5.00016L10.4177 8.7235C10.1619 8.86933 9.84854 8.86933 9.59271 8.7235L4.17188 5.00016H15.8385ZM15.8385 3.3335H4.17188C2.79354 3.3335 1.67188 4.45516 1.67188 5.8335V14.1668C1.67188 15.5452 2.79354 16.6668 4.17188 16.6668H15.8385C17.2169 16.6668 18.3385 15.5452 18.3385 14.1668V5.8335C18.3385 4.45516 17.2169 3.3335 15.8385 3.3335Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_256:2628)">
        <g id="&#240;&#159;&#142;&#168; Color">
          <rect id="Base" width="20" height="20" fillOpacity="0.86" />
        </g>
      </g>
    </g>
  </svg>,
  'Mail',
);

export default MailIcon;
