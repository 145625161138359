import { Box, Typography } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router';
import BreadcrumbsGeneratorContainer from '../../../common/components/BreadcrubmsGenerator/BreadcrumbsGenerator.container';
import { ROUTING_PATH_NAMES } from '../../../common/constants/constants';
import { pathTester } from '../../../common/helpers/routing';
import DashboardTabs, { DASHBOARD_TABS } from './Tabs/Tabs';
import TodayStatisticContainer from './Today/Today.container';
import LastWeekStatisticContainer from './LastWeek/LastWeek.container';
import LastMonthStatisticContainer from './LastMonth/LastMonth.container';

function Dashboard({ t = () => {}, pathname = '' }) {
  const isDashboardTodayTab = pathTester.isDashboardToday(pathname);
  const isDashboardLastWeekTab = pathTester.isDashboardLastWeek(pathname);
  const isDashboardLastMonthTab = pathTester.isDashboardLastMonth(pathname);
  let selectedTabId = 0;
  if (isDashboardTodayTab) selectedTabId = DASHBOARD_TABS.TODAY;
  if (isDashboardLastWeekTab) selectedTabId = DASHBOARD_TABS.LAST_WEEK;
  if (isDashboardLastMonthTab) selectedTabId = DASHBOARD_TABS.LAST_MONTH;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 1, md: 2 },
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">{t('dashboard.title')}</Typography>

          <BreadcrumbsGeneratorContainer />
        </Box>
      </Box>

      <Switch>
        {
          // #region Today
        }
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.TODAY}`}
          render={() => {
            return (
              <>
                <DashboardTabs selectedTabId={selectedTabId} />
                <TodayStatisticContainer />
              </>
            );
          }}
        />
        {
          // #endregion
        }

        {
          // #region Last week
        }
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_WEEK}`}
          render={() => {
            return (
              <>
                <DashboardTabs selectedTabId={selectedTabId} />
                <LastWeekStatisticContainer />
              </>
            );
          }}
        />
        {
          // #endregion
        }

        {
          // #region Last month
        }
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_MONTH}`}
          render={() => {
            return (
              <>
                <DashboardTabs selectedTabId={selectedTabId} />
                <LastMonthStatisticContainer />
              </>
            );
          }}
        />
        {
          // #endregion
        }

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}`}
          render={() => {
            return (
              <Redirect
                to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.TODAY}`}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

export default withTranslation()(Dashboard);
