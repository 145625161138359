import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import '../i18n';
import RTL from '../common/components/RTL';
import useScrollReset from '../common/hooks/useScrollReset';
import { createCustomTheme } from '../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import LoadingScreen from '../common/components/LoadingScreen';
import { Box } from '@mui/system';
import Content from './Content/Content';
import { SnackbarProvider } from 'notistack';

const App = ({ isAppInitialized = false, settings = {} }) => {
  useScrollReset();

  const theme = createCustomTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <CssBaseline />
          <Toaster position="top-center" />

          <Box
            bgcolor="background.paper"
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}
          >
            {!isAppInitialized ? <LoadingScreen /> : <Content />}
          </Box>
        </SnackbarProvider>
      </RTL>
    </ThemeProvider>
  );
};

App.propTypes = {
  settings: PropTypes.object,
  isAppInitialized: PropTypes.bool,
};

export default App;
