import { connect } from 'react-redux';
import { updateVehicleAccessories } from '../../../../../store/vehicles/slice';
import MissingEquipmentCheck from './MissingEquipmentCheck';

const mapStateToProps = (state, ownProps) => ({
  accessories: state.vehicles.accessories[ownProps.vehicle.id],
});

const mapDispatchToProps = {
  updateVehicleAccessories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MissingEquipmentCheck);
