import { connect } from 'react-redux';
import { getBookingByContractId } from '../../../../../store/booking/slice';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import ReturningToOther from './ReturningToOther';

const mapStateToProps = (state, ownProps) => {
  const {
    bookings: { bookings },
  } = state;
  const {
    vehicle: { id: carId },
  } = ownProps;
  const currentVehicleBooking = bookings[carId];

  return {
    currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
    currentVehicleBooking,
  };
};

const mapDispatchToProps = {
  switchToNextVehicleFlowState,
  getBookingByContractId,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturningToOther);
