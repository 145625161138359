import React, { useState } from 'react';
import { createPortal } from 'react-dom';
// import { CssBaseline } from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

// const theme = createTheme({
//   overrides: {
//     MuiCssBaseline: {
//       styleOverrides: {
//         body: {
//           width: '100%',
//           height: '100%',
//           margin: 0,
//         },
//         html: {
//           width: '100%',
//           height: '100%',
//           margin: 0,
//         },
//       },
//     },
//   },
// });

export const CustomPrintFrame = ({
  children,
  styleSelector,
  title,
  ...props
}) => {
  const [contentRef, setContentRef] = useState(null);
  const doc = contentRef?.contentWindow?.document;
  const mountNode = doc?.body;

  return (
    <iframe id="myFrame" title={title} {...props} ref={setContentRef}>
      {mountNode && createPortal(<>{children}</>, mountNode)}
    </iframe>
  );
};
