import { connect } from 'react-redux';
import { bookToAldOrDealerLocation } from '../../../../../../store/booking/slice';
import BookingReturnToAld from './BookingReturnToAld';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  bookToAldOrDealerLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingReturnToAld);
