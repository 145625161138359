import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef, useEffect, useState } from 'react';
import { getStepColorByStepState } from './helpers';

function CommonHorizontalStepper({
  steps = [],
  doneStepColor = '#5664d2',
  activeStepColor = '#569ED2',
  todoStepColor = '#C3C8D3',
  sx = {},
  stepSeparatorRenderer,
  stepContentRenderer,
}) {
  const stepsContainerRef = useRef(null);
  const [subStepsConnectorHeight, setSubStepsConnectorHeight] = useState(null);

  useEffect(() => {
    if (!subStepsConnectorHeight && stepsContainerRef.current) {
      setSubStepsConnectorHeight(stepsContainerRef.current.clientHeight);
    }
  });

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...sx }}
    >
      <Box
        className="StepperContentRow"
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}
      >
        {steps.map((step, stepIndex) => {
          const stepColor = getStepColorByStepState(step.state, {
            doneStepColor,
            activeStepColor,
            todoStepColor,
          });
          return (
            <Box
              className="StepperContentRowItem"
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 0,
              }}
              key={stepIndex}
            >
              {!stepContentRenderer && (
                <Typography
                  sx={{
                    mb: 1,
                    color: stepColor,
                    fontSize: '10px',
                    fontWeight: '700',
                    textAlign: 'center',
                  }}
                  noWrap
                >
                  {step.label}
                </Typography>
              )}

              {stepContentRenderer && stepContentRenderer(step)}
            </Box>
          );
        })}
      </Box>

      <Box
        className="StepperSeparatorsRow"
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        ref={stepsContainerRef}
      >
        {steps.map((step, stepIndex) => {
          const stepColor = getStepColorByStepState(step.state, {
            doneStepColor,
            activeStepColor,
            todoStepColor,
          });
          let renderedStep =
            stepSeparatorRenderer && stepSeparatorRenderer(step);
          if (renderedStep) {
            renderedStep = React.cloneElement(renderedStep, {
              ...renderedStep.props,
              sx: { ...renderedStep.props.sx, zIndex: 1 },
            });
          }

          return (
            <Box
              key={stepIndex}
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              {!stepSeparatorRenderer && (
                <Box
                  sx={{
                    zIndex: 1,
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    backgroundColor: stepColor,
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    borderRadius: '50%',
                  }}
                />
              )}

              {!!stepSeparatorRenderer && renderedStep}

              {stepIndex !== 0 && (
                <div
                  style={{
                    width: '100%',
                    bottom: '50%',
                    left: '-50%',
                    position: 'absolute',
                    borderTop: `1px solid ${stepColor}`,
                  }}
                />
              )}

              {step.renderSubStepsChainLine && (
                <div
                  style={{
                    height: subStepsConnectorHeight || 0,
                    top: '50%',
                    right: 0,
                    left: 0,
                    margin: 'auto',
                    position: 'absolute',
                    width: '0.5px',
                    backgroundColor: stepColor,
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default CommonHorizontalStepper;
