import { createSlice } from '@reduxjs/toolkit';
import { CHAT_TYPE, MESSAGE_TYPE } from '../../common/constants/messages';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    aldCustomerMessages: {},
    aldSupplierMessages: {},
  },
  reducers: {
    _aldCustomerMessagesFetchSucceeded: (state, action) => {
      const { contractId, messages } = action.payload;
      state.aldCustomerMessages[contractId] = messages;
    },
    _aldSupplierMessagesFetchSucceeded: (state, action) => {
      const { contractId, messages } = action.payload;
      state.aldSupplierMessages[contractId] = messages;
    },
    _aldCustomerMessageFetchSucceeded: (state, action) => {
      const { contractId, message } = action.payload;
      const messages = state.aldCustomerMessages[contractId];
      const index = messages.findIndex((x) => x.id === message.id);
      messages[index] = message;
    },
    _aldSupplierMessageFetchSucceeded: (state, action) => {
      const { contractId, message } = action.payload;
      const messages = state.aldSupplierMessages[contractId];
      const index = messages.findIndex((x) => x.id === message.id);
      messages[index] = message;
    },
    _aldCustomerMessagePostSucceeded: (state, action) => {
      const { contractId, message } = action.payload;
      state.aldCustomerMessages[contractId]?.push(message);
    },
    _aldSupplierMessagePostSucceeded: (state, action) => {
      const { contractId, message } = action.payload;
      state.aldSupplierMessages[contractId]?.push(message);
    },
  },
});

export const messagesReducer = messagesSlice.reducer;

export const {
  _aldCustomerMessagesFetchSucceeded,
  _aldCustomerMessageFetchSucceeded,
  _aldCustomerMessagePostSucceeded,
  _aldSupplierMessagesFetchSucceeded,
  _aldSupplierMessageFetchSucceeded,
  _aldSupplierMessagePostSucceeded,
} = messagesSlice.actions;

export const postAldSupplierMessage =
  (contractId, text) => async (dispatch) => {
    try {
      const message = {
        contractId,
        text,
        type: MESSAGE_TYPE.TEXT,
        chatType: CHAT_TYPE.ALD_SUPPLIER,
      };
      const response = await api.messages.send(message);
      dispatch(
        _aldSupplierMessagePostSucceeded({ contractId, message: response }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const postAldCustomerMessage = (contractId, text) => async () => {
  try {
    const message = {
      contractId,
      text,
      type: MESSAGE_TYPE.TEXT,
      chatType: CHAT_TYPE.ALD_CUSTOMER,
    };
    await api.messages.send(message);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getAllAldSupplierMessages = (contractId) => async (dispatch) => {
  try {
    const response = await api.messages.getAllAldSupplier(contractId);
    dispatch(
      _aldSupplierMessagesFetchSucceeded({ contractId, messages: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getAllAldCustomerMessages = (contractId) => async (dispatch) => {
  try {
    const response = await api.messages.getAllAldCustomer(contractId);
    dispatch(
      _aldCustomerMessagesFetchSucceeded({ contractId, messages: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
