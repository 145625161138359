import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../store/appCommon/slice';
import GridGallery from './GridGallery';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setFsUploadIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(GridGallery);
