import { connect } from 'react-redux';
import { createNewB2BCustomerAdminUser } from '../../../../../../../store/customers/slice';
import { redirectBackByBreadcrumb } from '../../../../../../../store/redirect/slice';
import CreateNewAdmin from './CreateNewAdmin';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createNewB2BCustomerAdminUser,
  redirectBackByBreadcrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewAdmin);
