import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import PersonIcon from '../../../../icons/Person';
import AldUserInfoContainer from './Info/Info.container';
import { Route, Switch } from 'react-router';
import { ROUTING_PATH_NAMES } from '../../../../common/constants/constants';

function AldUserDetails({ aldUser = null, t = () => {} }) {
  if (aldUser) {
    useEffect(async () => {}, []);

    const { displayName = '-' } = aldUser;

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar
            sx={{
              mr: 3,
              width: 102,
              height: 102,
              backgroundColor: 'primary.main',
            }}
          >
            <PersonIcon />
          </Avatar>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4">{displayName}</Typography>

            <Typography>{t('aldUsers.aldUser')}</Typography>
          </Box>
        </Box>

        <Switch>
          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}/:aldUserId/${ROUTING_PATH_NAMES.ALD_USER_INFO}`}
            render={({
              match: {
                params: { aldUserId },
              },
            }) => {
              return (
                <>
                  <AldUserInfoContainer aldUserId={aldUserId} />
                </>
              );
            }}
          />
        </Switch>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t('aldUsers.noSpecifiedAldUserFound')}
      </Typography>
    </Box>
  );
}

export default withTranslation()(AldUserDetails);
