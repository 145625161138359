import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

function DeliveredToWorkshop({
  switchToNextVehicleFlowState = () => {},
  selectRepair = () => {},
  fetchAllContractRepairs = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  damageTypeGroups = {},
  repairs = [],
  getVehicleFlowState = () => {},
}) {
  useEffect(() => {
    if (repairs.length <= 0) {
      fetchAllContractRepairs(vehicle.contractId);
    }
  }, []);

  const [selectedRepairId, setSelectedRepairId] = useState(null);

  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          Workshop
        </Typography>

        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          To begin the Workshop press “Start”
        </Typography>
      </Box>

      {repairs && repairs.length > 0 && (
        <FormControl sx={{ m: '5px' }}>
          <FormLabel>Select repair group</FormLabel>
          <RadioGroup
            onChange={(ev) => setSelectedRepairId(ev.target.value)}
            value={selectedRepairId || repairs.at(0).id}
          >
            {repairs.map((repair) => {
              const group = damageTypeGroups[repair.damageTypeGroupId] || {};
              return (
                <FormControlLabel
                  key={repair.id}
                  value={repair.id}
                  control={<Radio />}
                  label={group.name || ''}
                  color="info"
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      )}

      <Button
        disabled={!(repairs.length > 0)}
        sx={{ width: '100%', minHeight: '35px' }}
        color="primary"
        variant="outlined"
        onClick={async () => {
          await selectRepair(selectedRepairId || repairs.at(0).id);
          await switchToNextVehicleFlowState(
            id,
            currentStateId,
            SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP,
          );
          await getVehicleFlowState(id, contractId);
        }}
      >
        Start
      </Button>
    </Box>
  );
}

export default DeliveredToWorkshop;
