import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';
import { byAccessRightsExecutor } from '../../../../../common/helpers/common';

function WorkshopCompleted({
  t = () => {},
  switchToNextVehicleFlowState = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  getVehicleFlowState = () => {},
  userAccessRights = {},
  redirectToAppVehiclesList = () => {},
  fetchAllContractRepairs = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'success.main' }}>
          Workshop completed
        </Typography>

        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          Press “Next” to continue
        </Typography>
      </Box>

      <Button
        sx={{ width: '100%', mt: 'auto' }}
        color="primary"
        variant="outlined"
        onClick={async () => {
          await switchToNextVehicleFlowState(
            id,
            currentStateId,
            SUB_FLOW_STATE_ENUM.READY_FOR_SALES,
          );

          await fetchAllContractRepairs(contractId);

          await getVehicleFlowState(id, contractId, async () => {
            await byAccessRightsExecutor({
              actionsByAccessRights: [
                {
                  accessRights: [
                    'canAutomaticallyReturnToCarsListAfterWorkFinished',
                  ],
                  action: async () => {
                    await redirectToAppVehiclesList();
                    enqueueSnackbar(
                      t('common.messages.jobFinishedCanNoMoreAvailableForYou'),
                      { variant: 'success' },
                    );
                  },
                },
              ],
              userAccessRights,
            });
          });
        }}
      >
        Next
      </Button>
    </Box>
  );
}

export default withTranslation()(WorkshopCompleted);
