/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Tooltip,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  LocalOfferOutlined,
  Speed,
} from '@mui/icons-material';
import QRIcon from '../../../../icons/QR';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { withTheme } from '@emotion/react';
import { styled } from '@mui/system';
import DetailsIcon from '../../../../icons/Details';
import GalleryIcon from '../../../../icons/Gallery';
// import HardDriveIcon from '../../../../icons/HardDrive';
import FileAddIcon from '../../../../icons/FileAdd';
import FileTextIcon from '../../../../icons/FileText';
import CheckmarkSquareIcon from '../../../../icons/CheckmarkSquare';
import RepeatIcon from '../../../../icons/Repeat';
import moment from 'moment';
import NAFAssessment from './NAFAssessment/NAFAssessment.container';
import ALDAssessment from './ALDAssessment/ALDAssessment.container';
import EditIcon from '../../../../icons/EditIcon';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomPrintFrame } from '../../../../common/components/Frame/Frame';
import WithAccessRightsRendererContainer from '../../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import SupplierPickupAssessmentContainer from './SupplierPickupAssessment/SupplierPickupAssessment.container';
import {
  CAR_RETURN_STATUS_TRANSLATION,
  SUB_FLOW_STATE_ENUM,
} from '../../../../common/constants/vehicles';
import { ACCESSORY_STATUS } from '../../../../common/constants/accessories';
import {
  getMajorStateStatusByLogs,
  getSubStateStatusByLogs,
} from '../AlreadyInProcessCars/helpers';
import CommonTimeline from '../../../../common/components/Timeline/Timeline';
import { STEP_STATES } from '../../../../common/components/HorizontalStepper/constants';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BuildIcon from '@mui/icons-material/Build';
import SupplierLogs from './SupplierLogs/SupplierLogs';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import GridGalleryContainer from '../../../../common/components/GridGallery/GridGallery.container';
import { isMobile } from 'react-device-detect';
import { CAR_OWNER_ENUM } from '../SupplierCars/helpers';
import DriverLicenseIcon from '../../../../icons/DriverLicense';
import { MicroDashboardItem } from './components/MicroDashboardItem';
import { getChannelColorByEnum, getChannelNameByEnum } from './helpers';
import { ROUTING_PATH_NAMES } from '../../../../common/constants/constants';

const CarDetailsStyledList = styled(List)(({ theme }) => ({
  '&.MuiList-root': {
    flex: 1,
    overflow: 'auto',
    paddingTop: 0,
  },
  '& .MuiListItem-root': {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    borderRadius: '16px',

    ':first-of-type': {
      marginTop: 0,
    },
  },
  '& .MuiListItemButton-root': {
    width: '100%',
    flex: '0 0 80px',
    borderRadius: '16px',
    boxShadow:
      '0px 0px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.12)',
  },
  '& .MuiListItemIcon-root': {
    padding: theme.spacing(1),
    borderRadius: '50%',

    '&.ExpandIcon': {
      backgroundColor: '#ffffff',
      color: 'primary.main',
      border: `1px solid ${theme.palette.separator}`,
    },
  },
  '& .MuiCollapse-root': {
    width: '100%',

    '& .CollapseContent': {
      display: 'flex',
      flexDirection: 'column',

      '& .CollapseContentRow': {
        display: 'flex',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        '& .CollapseContentRowTitle': {
          flex: 2,
        },
        '& .CollapseContentRowContent': {
          flex: 7,
          fontWeight: 400,
        },
      },
    },
  },
}));

function CarDetails({
  t = () => {},
  vehicle = null,
  vehicleId = null,
  customerInfo = {},
  contractInfo = {},
  nafAssessment = null,
  aldAssessment = null,
  getVehicleQR = () => {},
  qrSrc = null,
  currentVehicleQRLoadingInProcess = false,
  vehiclesStateLogs = {},
  returnInfo = {},
  currentVehicleContractImages = [],
  suppliersLogs = [],
  clearVehicleQR = () => {},
  getVehicleNafAssessment = () => {},
  getVehicleAldAssessment = () => {},
  getVehicleCustomerInfo = () => {},
  getVehicleContractInfo = () => {},
  redirectToAppVehiclesListCarStatus = () => {},
  acceptAssessment = () => {},
  forceApproveAssessment = () => {},
  declineAssessment = () => {},
  getVehicleFlowStatesLogs = () => {},
  getVehicleReturnInfo = () => {},
  getVehicleRepairStatuses = () => {},
  getContractImages = () => {},
  getVehicleById = () => {},
  _setCurrentVehicle = () => {},
  getSalesChannelEnums = () => {},
  salesChannelEnums = [],
  updateContract = () => {},
  getContractById = () => {},
  accessRights = {},
  redirectTo = () => {},
  pathname = '',
  getVehicleAccessories = () => {},
  accessories,
}) {
  const [isCarInfoOpened, setCarInfoOpened] = useState(false);
  const [isContractDetailsOpened, setContractDetailsOpened] = useState(false);
  const [isAccessoriesOpened, setAccessoriesOpened] = useState(false);
  const [isGalleryOpened, setGalleryOpened] = useState(false);
  const [isCustomerInfoOpened, setCustomerInfoOpened] = useState(false);
  const [isReturnInfoOpened, setReturnInfoOpened] = useState(false);
  const [isDetailedStatusOpened, setDetailedStatusOpened] = useState(false);
  const [isNAFAssessmentOpened, setNAFAssessmentOpened] = useState(false);
  const [isALDAssessmentOpened, setALDAssessmentOpened] = useState(false);
  const [isSupplierLogsOpened, setSupplierLogsOpened] = useState(false);
  // const [isSalesInfoOpened, setSalesInfoOpened] = useState(false);
  const [isQRModalOpened, setQRModalOpened] = useState(false);

  const [isSalesChannelChangeLoading, setIsSalesChannelChangeLoading] =
    useState(false);

  useEffect(() => {
    getVehicleById(vehicleId).then((data) => _setCurrentVehicle(data));
    return () => {
      _setCurrentVehicle(undefined);
    };
  }, [vehicleId]);

  useEffect(() => {
    if (vehicle) {
      getSalesChannelEnums().catch((error) => {
        console.error(error);
      });

      getVehicleCustomerInfo(vehicleId).catch((error) => {
        console.error(error);
      });

      getVehicleContractInfo(vehicleId).catch((error) => {
        console.error(error);
      });

      getVehicleAccessories(vehicleId).catch((error) => {
        console.error(error);
      });

      getVehicleReturnInfo(vehicleId).catch((error) => {
        console.error(error);
      });

      getContractImages(vehicle.contractId).catch((error) => {
        console.error(error);
      });

      getVehicleAldAssessment(vehicleId).catch((error) => {
        console.error(error);
      });

      getVehicleNafAssessment(vehicleId).catch((error) => {
        console.error(error);
      });

      getVehicleRepairStatuses(vehicleId).catch((error) => {
        console.error(error);
      });
    }

    return () => {
      clearVehicleQR();
    };
  }, [vehicle]);

  if (vehicle) {
    const {
      id = '',
      contractId,
      carBrand = '-',
      carModel = '-',
      endDate,
      plateNo = '-',
      fuelType = '-',
      // customer = '-',
      currentStateEnum,
      mileage = '-',
      salesChannel = '-',
    } = vehicle;

    const {
      contractNo,
      contractType,
      kmsYear,
      rentalPeriodInMonths,
      startPeriod,
    } = contractInfo;
    const {
      name: customerName,
      address: { name: addressName } = {},
      contactPerson: { name: contactPersonName } = {},
      customerType = '-',
      carOwner = '-',
    } = customerInfo;

    const { dateTime: returnDateTime = null, carReturnStatus } = returnInfo;
    const returnAddress = returnInfo.address && returnInfo.address.name;
    const returnDriver = returnInfo.driver;

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowY: 'scroll',
          p: { xs: 2 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Tooltip title={vehicle.carBrand}>
            <Typography variant="h5" sx={{ mr: 1 }} noWrap>
              {vehicle.carBrand}
            </Typography>
          </Tooltip>

          <Typography
            variant="h5"
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
          >
            {vehicle.carModel}
          </Typography>

          <Tooltip title={vehicle.plateNo}>
            <Typography variant="h5" sx={{ fontWeight: 300 }} noWrap>
              {vehicle.plateNo}
            </Typography>
          </Tooltip>

          <Button
            sx={{ ml: 'auto', mr: 1 }}
            variant="outlined"
            onClick={() => {
              redirectToAppVehiclesListCarStatus(id);
            }}
          >
            {t('vehiclesList.carStatus.statusInfo').toUpperCase()}
          </Button>

          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewCarQR'],
                content: (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<QRIcon />}
                      onClick={async () => {
                        setQRModalOpened(true);
                        if (!qrSrc) {
                          await getVehicleQR(id, contractId);
                        }
                      }}
                    >
                      QR
                    </Button>

                    <Modal
                      open={isQRModalOpened}
                      onClose={() => {
                        setQRModalOpened(false);
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: { xs: '92%', md: '60%' },
                          height: '70%',
                          backgroundColor: 'background.default',
                          borderRadius: '16px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            pl: 2,
                            pr: 1,
                            py: 1,
                          }}
                        >
                          <Typography variant="h5" sx={{ mr: 'auto' }}>
                            QR
                          </Typography>

                          <IconButton
                            color="secondary"
                            onClick={() => {
                              setQRModalOpened(false);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>

                        <Divider />

                        <Box
                          sx={{
                            flex: 1,
                            minHeight: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {!currentVehicleQRLoadingInProcess && (
                            <CustomPrintFrame
                              style={{ height: '100%', border: 'none' }}
                              id="qr-print-frame"
                              onLoad={() => {
                                const doc =
                                  window.frames['qr-print-frame'].contentWindow
                                    .document;

                                const style = doc.createElement('style');
                                style.textContent = `html,body {width:100%;height:100%;margin:0;display: flex;flex-direction: column;font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"}`;

                                window.frames[
                                  'qr-print-frame'
                                ].contentWindow.document.head.appendChild(
                                  style,
                                );
                              }}
                            >
                              <img
                                alt="Vehicle QR"
                                style={{
                                  minHeight: 0,
                                  minWidth: 0,
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                }}
                                src={qrSrc}
                                title="Vehicle QR"
                              />
                              <h4 style={{ textAlign: 'center', marginTop: 0 }}>
                                {plateNo}
                              </h4>
                            </CustomPrintFrame>
                          )}

                          {currentVehicleQRLoadingInProcess && (
                            <CircularProgress />
                          )}
                        </Box>

                        <Button
                          variant="contained"
                          sx={{ m: 5, mx: 'auto', width: '30%' }}
                          onClick={() => {
                            window.frames['qr-print-frame'].focus();

                            // increase text size on print
                            const doc =
                              window.frames['qr-print-frame'].contentWindow
                                .document;
                            const style = doc.createElement('style');
                            style.textContent = `
                                h4 {
                                  font-size: xxx-large;
                                }
                            `;
                            window.frames[
                              'qr-print-frame'
                            ].contentWindow.document.head.appendChild(style);

                            // calling print
                            window.frames[
                              'qr-print-frame'
                            ].contentWindow.print();

                            // after print closed
                            window.frames[
                              'qr-print-frame'
                            ].contentWindow.document.head.removeChild(style);
                          }}
                        >
                          Print QR
                        </Button>
                      </Box>
                    </Modal>
                  </>
                ),
              },
            ]}
            fallBackContent={null}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: { xs: 'wrap', md: 'unset' },
            mb: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1,
            }}
          >
            <MicroDashboardItem
              Icon={DriverLicenseIcon}
              title="Car owner"
              value={carOwner === CAR_OWNER_ENUM.NF_FLEET ? 'NFFLEET' : 'FLEET'}
            />

            <MicroDashboardItem
              Icon={FileTextIcon}
              title="Contract type"
              value={contractType}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flex: 1,
            }}
          >
            <MicroDashboardItem Icon={Speed} title="Mileage" value={mileage} />

            <WithAccessRightsRendererContainer
              contentByAccessRights={[
                {
                  accessRights: ['canViewSalesChannel'],
                  content: (
                    <MicroDashboardItem
                      sx={{
                        mr: { xs: 0, md: 0 },
                      }}
                      Icon={LocalOfferOutlined}
                      title="Sales channel"
                      value={getChannelNameByEnum(salesChannel)}
                      ValueSx={{ color: getChannelColorByEnum(salesChannel) }}
                      loading={isSalesChannelChangeLoading}
                      expandable={accessRights.canEditSalesChannel}
                      popperContentRenderer={({ handleClose }) => {
                        return (
                          <List
                            sx={{
                              p: 0,
                              width: '100%',
                              maxHeight: '200px',
                              overflow: 'auto',
                            }}
                          >
                            {salesChannelEnums.map((obj, index) => {
                              return (
                                <ListItem
                                  key={index}
                                  sx={{ p: 0 }}
                                  onClick={async () => {
                                    setIsSalesChannelChangeLoading(true);
                                    const contractData = await getContractById(
                                      contractId,
                                    );
                                    await updateContract(contractId, {
                                      ...contractData,
                                      salesChannel: obj.salesChannel,
                                    });
                                    const newVehicleData = await getVehicleById(
                                      vehicleId,
                                    );
                                    _setCurrentVehicle(newVehicleData);
                                    handleClose();
                                    setIsSalesChannelChangeLoading(false);
                                  }}
                                >
                                  <ListItemButton>
                                    <ListItemText
                                      primary={getChannelNameByEnum(
                                        obj.salesChannel,
                                      )}
                                      sx={{
                                        color: getChannelColorByEnum(
                                          obj.salesChannel,
                                        ),
                                      }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                          </List>
                        );
                      }}
                    />
                  ),
                },
              ]}
              fallBackContent={null}
            />
          </Box>
        </Box>

        <CarDetailsStyledList>
          {
            // #region Car Info
          }
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor: isCarInfoOpened
                  ? 'primary.main'
                  : 'background.default',
                color: isCarInfoOpened ? 'background.default' : 'text.primary',
                ':hover': {
                  backgroundColor: isCarInfoOpened
                    ? 'primary.main'
                    : 'background.default',
                },
              }}
              onClick={() => {
                setCarInfoOpened(!isCarInfoOpened);
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: isCarInfoOpened ? '#ffffff' : 'primary.main',
                  color: isCarInfoOpened ? 'primary.main' : '#ffffff',
                }}
              >
                <InfoOutlinedIcon />
              </ListItemIcon>

              <ListItemText
                primary={t('vehiclesList.carDetails.carInfo.title')}
                primaryTypographyProps={{
                  fontWeight: 400,
                }}
              />

              <ListItemIcon className="ExpandIcon">
                {isCarInfoOpened ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>

            <Collapse in={isCarInfoOpened}>
              <Box className="CollapseContent">
                <Box className="CollapseContentRow">
                  <Typography
                    variant="h6"
                    className="CollapseContentRowTitle"
                    sx={{ minWidth: '50%' }}
                  >
                    {t('vehiclesList.carDetails.carInfo.model')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {carModel}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography
                    variant="h6"
                    className="CollapseContentRowTitle"
                    sx={{ minWidth: '50%' }}
                  >
                    {t('vehiclesList.carDetails.carInfo.brand')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {carBrand}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography
                    variant="h6"
                    className="CollapseContentRowTitle"
                    sx={{ minWidth: '50%' }}
                  >
                    {t('vehiclesList.carDetails.carInfo.plateNumber')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {plateNo}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography
                    variant="h6"
                    className="CollapseContentRowTitle"
                    sx={{ minWidth: '50%' }}
                  >
                    {t('vehiclesList.carDetails.carInfo.fuel')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {fuelType}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </ListItem>
          {
            // #endregion
          }

          {
            // #region Contract Info
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewCarContractInfo'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        backgroundColor: isContractDetailsOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isContractDetailsOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isContractDetailsOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={() => {
                        setContractDetailsOpened(!isContractDetailsOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isContractDetailsOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isContractDetailsOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <FileTextIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t(
                          'vehiclesList.carDetails.contractDetails.title',
                        )}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      <ListItemIcon className="ExpandIcon">
                        {isContractDetailsOpened ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isContractDetailsOpened}>
                      <Box className="CollapseContent">
                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ flex: '1 !important' }}
                          >
                            Contract no
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                            sx={{ flex: '1 !important' }}
                          >
                            {contractNo}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ flex: '1 !important' }}
                          >
                            Contract type
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                            sx={{ flex: '1 !important' }}
                          >
                            {contractType}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ flex: '1 !important' }}
                          >
                            {t(
                              'vehiclesList.carDetails.contractDetails.yearlyKilometers',
                            )}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                            sx={{ flex: '1 !important' }}
                          >
                            {kmsYear}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ flex: '1 !important' }}
                          >
                            {t(
                              'vehiclesList.carDetails.contractDetails.rentalPeriod',
                            )}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                            sx={{ flex: '1 !important' }}
                          >
                            {rentalPeriodInMonths}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ flex: '1 !important' }}
                          >
                            {t(
                              'vehiclesList.carDetails.contractDetails.contactStartDate',
                            )}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                            sx={{ flex: '1 !important' }}
                          >
                            {moment(startPeriod).format('DD/MM/yyyy')}
                          </Typography>
                        </Box>
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />

          {
            // #endregion
          }

          {
            // #region Accessories
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewCarAccessories'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      disabled={!accessories || accessories.length === 0}
                      sx={{
                        backgroundColor: isAccessoriesOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isAccessoriesOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isAccessoriesOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={() => {
                        setAccessoriesOpened(!isAccessoriesOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isAccessoriesOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isAccessoriesOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <DetailsIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t('vehiclesList.carDetails.accessories.title')}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      <ListItemIcon className="ExpandIcon">
                        {isAccessoriesOpened ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isAccessoriesOpened}>
                      <Box className="CollapseContent">
                        {accessories &&
                          accessories.map((a, accessoryIndex) => (
                            <React.Fragment key={accessoryIndex}>
                              <Box className="CollapseContentRow">
                                <Typography
                                  variant="h7"
                                  className="CollapseContentRowTitle"
                                >
                                  {a.name.length > 50
                                    ? `${a.name.substring(0, 50)} ...`
                                    : a.name}
                                </Typography>
                                <Box
                                  sx={{
                                    flex: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  {a.accessoryRepairStatus ===
                                    ACCESSORY_STATUS.OK && (
                                    <CheckmarkSquareIcon color="primary" />
                                  )}
                                  {a.accessoryRepairStatus ===
                                    ACCESSORY_STATUS.NEED_REPAIR && (
                                    <HighlightOffIcon sx={{ fill: 'red' }} />
                                  )}
                                </Box>
                              </Box>
                              {accessoryIndex < accessories.length - 1 && (
                                <Divider />
                              )}
                            </React.Fragment>
                          ))}
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />

          {
            // #endregion
          }

          {
            // #region Gallery
          }
          <ListItem disablePadding>
            <ListItemButton
              disabled={currentVehicleContractImages.length === 0}
              sx={{
                backgroundColor: isGalleryOpened
                  ? 'primary.main'
                  : 'background.default',
                color: isGalleryOpened ? 'background.default' : 'text.primary',
                ':hover': {
                  backgroundColor: isGalleryOpened
                    ? 'primary.main'
                    : 'background.default',
                  color: isGalleryOpened
                    ? 'background.default'
                    : 'text.primary',
                  ':hover': {
                    backgroundColor: isGalleryOpened
                      ? 'primary.main'
                      : 'background.default',
                  },
                },
              }}
              onClick={() => {
                setGalleryOpened(!isGalleryOpened);
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: isGalleryOpened ? '#ffffff' : 'primary.main',
                  color: isGalleryOpened ? 'primary.main' : '#ffffff',
                }}
              >
                <GalleryIcon />
              </ListItemIcon>

              <ListItemText
                primary={t('vehiclesList.carDetails.gallery.title')}
                primaryTypographyProps={{
                  fontWeight: 400,
                }}
              />

              <ListItemIcon className="ExpandIcon">
                {isGalleryOpened ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>

            <Collapse in={isGalleryOpened}>
              <Box className="CollapseContent" sx={{ px: 3, py: 2 }}>
                <GridGalleryContainer
                  sx={{ flex: 'unset' }}
                  maxRenderingImagesCount={3}
                  uploadIds={currentVehicleContractImages.map(
                    (imgData) => imgData.uploadId,
                  )}
                />
              </Box>
            </Collapse>
          </ListItem>
          {
            // #endregion
          }

          {
            // #region CustomerInfo
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewCarCustomerInfo'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        backgroundColor: isCustomerInfoOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isCustomerInfoOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isCustomerInfoOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={() => {
                        setCustomerInfoOpened(!isCustomerInfoOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isCustomerInfoOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isCustomerInfoOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <FileTextIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t(
                          'vehiclesList.carDetails.customerInfo.title',
                        )}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      <ListItemIcon className="ExpandIcon">
                        {isCustomerInfoOpened ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isCustomerInfoOpened}>
                      <Box className="CollapseContent">
                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ mr: '10px' }}
                          >
                            {t(
                              'vehiclesList.carDetails.customerInfo.customerName',
                            )}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {customerName}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ mr: '10px' }}
                          >
                            {t(
                              'vehiclesList.carDetails.customerInfo.contractNumber',
                            )}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {contractNo}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ mr: '10px' }}
                          >
                            {t('vehiclesList.carDetails.customerInfo.endDate')}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {endDate
                              ? moment(endDate).format('DD/MM/YYYY')
                              : '-'}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ mr: '10px' }}
                          >
                            {t('vehiclesList.carDetails.customerInfo.address')}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {addressName || '-'}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ mr: '10px' }}
                          >
                            {t(
                              'vehiclesList.carDetails.customerInfo.contactName',
                            )}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {contactPersonName || '-'}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ mr: '10px' }}
                          >
                            Customer type
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {customerType || '-'}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                            sx={{ mr: '10px' }}
                          >
                            Car owner
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {carOwner === CAR_OWNER_ENUM.NF_FLEET
                              ? 'NFFLEET'
                              : 'FLEET'}
                          </Typography>
                        </Box>
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />
          {
            // #endregion
          }

          {
            // #region Return Info
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewCarReturnInfo'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      disabled={!returnAddress && !returnDriver}
                      sx={{
                        backgroundColor: isReturnInfoOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isReturnInfoOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isReturnInfoOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={() => {
                        if (!isReturnInfoOpened) {
                          getVehicleReturnInfo(vehicleId);
                        }
                        setReturnInfoOpened(!isReturnInfoOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isReturnInfoOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isReturnInfoOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <RepeatIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t('vehiclesList.carDetails.returnInfo.title')}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      <ListItemIcon className="ExpandIcon">
                        {isReturnInfoOpened ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isReturnInfoOpened}>
                      <Box className="CollapseContent">
                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                          >
                            {t('vehiclesList.carDetails.returnInfo.dateTime')}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {moment(returnDateTime).format('DD/MM/YYYY HH:mm')}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                          >
                            {t('vehiclesList.carDetails.returnInfo.location')}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {returnAddress || '-'}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                          >
                            {t('vehiclesList.carDetails.returnInfo.driver')}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {returnDriver || '-'}
                          </Typography>
                        </Box>
                        <Divider />

                        <Box className="CollapseContentRow">
                          <Typography
                            variant="h6"
                            className="CollapseContentRowTitle"
                          >
                            {t('vehiclesList.carDetails.returnInfo.status')}
                          </Typography>
                          <Typography
                            variant="h6"
                            className="CollapseContentRowContent"
                          >
                            {CAR_RETURN_STATUS_TRANSLATION[carReturnStatus] ||
                              '-'}
                          </Typography>
                        </Box>
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />

          {
            // #endregion
          }

          {
            // #region Detailed Status
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewCarDetailedStatus'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      sx={{
                        backgroundColor: isDetailedStatusOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isDetailedStatusOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isDetailedStatusOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={() => {
                        getVehicleFlowStatesLogs(
                          vehicle.id,
                          vehicle.contractId,
                        );
                        setDetailedStatusOpened(!isDetailedStatusOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isDetailedStatusOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isDetailedStatusOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <CheckmarkSquareIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t(
                          'vehiclesList.carDetails.detailedStatus.title',
                        )}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      <ListItemIcon className="ExpandIcon">
                        {isDetailedStatusOpened ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isDetailedStatusOpened}>
                      <Box className="CollapseContent">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            backgroundColor: 'rgba(239, 240, 251, 0.4)',
                            height: '380px',
                            overflowX: 'auto',
                          }}
                        >
                          {vehiclesStateLogs[vehicle.id] &&
                            vehiclesStateLogs[vehicle.id].length > 0 && (
                              <CommonTimeline
                                sx={{ px: 1, py: 2 }}
                                steps={[
                                  {
                                    label: t(
                                      'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.title',
                                    ).toUpperCase(),
                                    state: getMajorStateStatusByLogs(
                                      vehiclesStateLogs[vehicle.id],
                                      SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_BOOKS_DATE_AND_TIME,
                                      SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                    ),
                                    subSteps: [
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.waitingForBooking',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_BOOKS_DATE_AND_TIME,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_BOOKS_DATE_AND_TIME,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.mileageUpdate',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_UPDATE_MILEAGE_BEFORE_RETURN,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_UPDATE_MILEAGE_BEFORE_RETURN,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.photosUpload',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_BEFORE_RETURN,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_BEFORE_RETURN,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.transportToDealer',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_DEALER_LOCATION,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_DEALER_LOCATION,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.dealerLocation',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_DEALER,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_DEALER,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.transportToOther',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_OTHER_LOCATION,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_OTHER_LOCATION,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.otherLocation',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.customerLocation.transportToAld',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION,
                                        )?.stateChangedDate,
                                      },
                                    ],
                                    renderSubStepsChainLine: true,
                                  },
                                  {
                                    label: t(
                                      'vehiclesList.tabs.alreadyInProcessCars.carStatuses.deliveredToAld.title',
                                    ).toUpperCase(),
                                    state: getMajorStateStatusByLogs(
                                      vehiclesStateLogs[vehicle.id],
                                      SUB_FLOW_STATE_ENUM.WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN,
                                      SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                    ),
                                    subSteps: [
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.deliveredToAld.mileageUpdate',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN,
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.deliveredToAld.photosUpload',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_AFTER_RETURN,
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_AFTER_RETURN,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.deliveredToAld.photosReview',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_QR_GENERATING,
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_QR_GENERATING,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.deliveredToAld.qrGenerating',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.QR_GENERATING,
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.QR_GENERATING,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.deliveredToAld.qrPlacement',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.deliveredToAld.qrFinished',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK,
                                          SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK,
                                        )?.stateChangedDate,
                                      },
                                    ],
                                    renderSubStepsChainLine: true,
                                  },
                                  {
                                    label: t(
                                      'vehiclesList.tabs.alreadyInProcessCars.carStatuses.accessoriesCheck.title',
                                    ).toUpperCase(),
                                    state: getMajorStateStatusByLogs(
                                      vehiclesStateLogs[vehicle.id],
                                      SUB_FLOW_STATE_ENUM.DELIVERED_TO_CHECK_MISSING_ACCESSORIES,
                                      SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
                                    ),
                                    subSteps: [
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.accessoriesCheck.waitingStart',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.DELIVERED_TO_CHECK_MISSING_ACCESSORIES,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.DELIVERED_TO_CHECK_MISSING_ACCESSORIES,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.accessoriesCheck.inProgress',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_PROCESS_CHECK_MISSING_ACCESSORIES,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_PROCESS_CHECK_MISSING_ACCESSORIES,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.accessoriesCheck.finished',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
                                        )?.stateChangedDate,
                                      },
                                    ],
                                    renderSubStepsChainLine: true,
                                  },
                                  {
                                    label: t(
                                      'vehiclesList.tabs.alreadyInProcessCars.carStatuses.wash.title',
                                    ).toUpperCase(),
                                    state: getMajorStateStatusByLogs(
                                      vehiclesStateLogs[vehicle.id],
                                      SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH,
                                      SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
                                    ),
                                    subSteps: [
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.wash.waitingStart',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.wash.inProgress',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.wash.finished',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
                                        )?.stateChangedDate,
                                      },
                                    ],
                                    renderSubStepsChainLine: true,
                                  },
                                  {
                                    label: t(
                                      'vehiclesList.tabs.alreadyInProcessCars.carStatuses.assessment.title',
                                    ).toUpperCase(),
                                    state: getMajorStateStatusByLogs(
                                      vehiclesStateLogs[vehicle.id],
                                      SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT,
                                      SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                    ),
                                    subSteps: [
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.assessment.waitingStartNAF',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.assessment.inProgressNAF',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.assessment.finishedNAF',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.assessment.waitingStartALD',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.assessment.inProgressALD',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.assessment.finishedALD',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                                        )?.stateChangedDate,
                                      },
                                    ],
                                    renderSubStepsChainLine: true,
                                  },
                                  {
                                    label: t(
                                      'vehiclesList.tabs.alreadyInProcessCars.carStatuses.workshop.title',
                                    ).toUpperCase(),
                                    state: getMajorStateStatusByLogs(
                                      vehiclesStateLogs[vehicle.id],
                                      SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP,
                                      SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
                                    ),
                                    subSteps: [
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.workshop.waitingStart',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.workshop.inProgress',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP,
                                        )?.stateChangedDate,
                                      },
                                      {
                                        label: t(
                                          'vehiclesList.tabs.alreadyInProcessCars.carStatuses.workshop.finished',
                                        ),
                                        state: getSubStateStatusByLogs(
                                          vehiclesStateLogs[vehicle.id],
                                          SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
                                          SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
                                        ),
                                        dateFinished: vehiclesStateLogs[
                                          vehicle.id
                                        ].find(
                                          (log) =>
                                            log.stateEnum ===
                                            SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
                                        )?.stateChangedDate,
                                      },
                                    ],
                                    renderSubStepsChainLine: true,
                                  },
                                ]}
                                mainTimelineStepSeparatorRenderer={(step) => {
                                  switch (step.state) {
                                    case STEP_STATES.DONE:
                                      return (
                                        <Box
                                          sx={{
                                            width: '11px',
                                            height: '11px',
                                            borderRadius: '50%',
                                            backgroundColor: 'primary.main',
                                          }}
                                        />
                                      );

                                    case STEP_STATES.IN_PROGRESS:
                                      return (
                                        <Box
                                          sx={{
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '50%',
                                            backgroundColor: '#569ED2',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <DirectionsCarIcon
                                            htmlColor="#ffffff"
                                            sx={{
                                              width: '70%',
                                              height: '70%',
                                            }}
                                          />
                                        </Box>
                                      );

                                    default:
                                      return (
                                        <Box
                                          sx={{
                                            width: '11px',
                                            height: '11px',
                                            borderRadius: '50%',
                                            backgroundColor: '#C3C8D3',
                                          }}
                                        />
                                      );
                                  }
                                }}
                                subTimelineStepSeparatorRenderer={(step) => {
                                  switch (step.state) {
                                    case STEP_STATES.DONE:
                                      return (
                                        <Box
                                          sx={{
                                            mx: 1,
                                            width: '11px',
                                            height: '11px',
                                            borderRadius: '50%',
                                            backgroundColor: 'primary.main',
                                          }}
                                        />
                                      );

                                    case STEP_STATES.IN_PROGRESS:
                                      return (
                                        <Box
                                          sx={{
                                            mx: 1,
                                            width: '11px',
                                            height: '11px',
                                            borderRadius: '50%',
                                            backgroundColor: '#569ED2',
                                          }}
                                        />
                                      );

                                    default:
                                      return (
                                        <Box
                                          sx={{
                                            mx: 1,
                                            width: '11px',
                                            height: '11px',
                                            borderRadius: '50%',
                                            backgroundColor: '#C3C8D3',
                                          }}
                                        />
                                      );
                                  }
                                }}
                                subTimelineStepContentRenderer={(step) => {
                                  return (
                                    <Typography
                                      sx={{
                                        fontSize: '10px',
                                        color:
                                          step.state !== STEP_STATES.TODO
                                            ? 'rgba(37, 56, 88, 0.8)'
                                            : '#C3C8D3',
                                        letterSpacing: '0.1px',
                                      }}
                                      noWrap
                                      title={step.label}
                                    >
                                      {step.label}
                                    </Typography>
                                  );
                                }}
                                subTimelineStepOppositeContentRenderer={(
                                  step,
                                ) => {
                                  return (
                                    <Typography
                                      sx={{
                                        fontSize: '10px',
                                        color: 'rgba(37, 56, 88, 0.8)',
                                        textAlign: 'right',
                                      }}
                                      noWrap
                                      title={moment(step.dateFinished).format(
                                        'DD.MM.YYYY HH:mm',
                                      )}
                                    >
                                      {!!step.dateFinished && (
                                        <>
                                          {moment(step.dateFinished).format(
                                            'DD.MM.YYYY',
                                          )}
                                          <br />
                                          <span
                                            style={{
                                              fontSize: '8px',
                                              fontWeight: 500,
                                              fontStyle: 'italic',
                                              color: '#569ED2',
                                            }}
                                          >
                                            {moment(step.dateFinished).format(
                                              'HH:mm',
                                            )}
                                          </span>
                                        </>
                                      )}
                                    </Typography>
                                  );
                                }}
                              />
                            )}

                          {!vehiclesStateLogs[vehicle.id] && (
                            <Box
                              sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Typography>Loading...</Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />

          {
            // #endregion
          }

          {
            // #region NAF Assessment
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewNafAssessment'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      disabled={!nafAssessment}
                      sx={{
                        backgroundColor: isNAFAssessmentOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isNAFAssessmentOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isNAFAssessmentOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={async () => {
                        if (!isNAFAssessmentOpened)
                          await getVehicleNafAssessment(vehicle.id);
                        setNAFAssessmentOpened(!isNAFAssessmentOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isNAFAssessmentOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isNAFAssessmentOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <FileTextIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t(
                          'vehiclesList.carDetails.NAFAssessment.title',
                        )}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      <ListItemIcon className="ExpandIcon">
                        {isNAFAssessmentOpened ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isNAFAssessmentOpened}>
                      <Box className="CollapseContent" sx={{ p: 2 }}>
                        {nafAssessment && (
                          <>
                            <NAFAssessment assessment={nafAssessment} />
                          </>
                        )}
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />
          {
            // #endregion
          }

          {
            // #region ALD Assessment
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewAldAssessment'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      disabled={!aldAssessment}
                      sx={{
                        backgroundColor: isALDAssessmentOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isALDAssessmentOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isALDAssessmentOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={async () => {
                        if (!isALDAssessmentOpened)
                          await getVehicleAldAssessment(vehicle.id);
                        setALDAssessmentOpened(!isALDAssessmentOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isALDAssessmentOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isALDAssessmentOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <FileAddIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t(
                          'vehiclesList.carDetails.ALDAssessment.title',
                        )}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      {!isMobile && (
                        <WithAccessRightsRendererContainer
                          contentByAccessRights={[
                            {
                              accessRights: ['canUpdateAldAssessment'],
                              content: (
                                <>
                                  {isALDAssessmentOpened && aldAssessment && (
                                    <ListItemIcon
                                      className="EditIcon"
                                      sx={{ mr: '20px' }}
                                    >
                                      <EditIcon
                                        onClick={async () => {
                                          if (!aldAssessment) return;
                                          redirectTo(
                                            `${pathname}/${ROUTING_PATH_NAMES.EDIT_ASSESSMENT}`,
                                          );
                                        }}
                                        sx={{ fill: 'white' }}
                                      />
                                    </ListItemIcon>
                                  )}
                                </>
                              ),
                            },
                          ]}
                          fallBackContent={null}
                        />
                      )}

                      <ListItemIcon className="ExpandIcon">
                        {isALDAssessmentOpened ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isALDAssessmentOpened}>
                      <Box className="CollapseContent" sx={{ p: 2 }}>
                        {aldAssessment && (
                          <>
                            <WithAccessRightsRendererContainer
                              contentByAccessRights={[
                                {
                                  accessRights: ['canPickupAldAssessmentSheet'],
                                  content: (
                                    <SupplierPickupAssessmentContainer
                                      vehicleId={vehicleId}
                                      assessment={aldAssessment}
                                    />
                                  ),
                                },
                                {
                                  accessRights: ['canViewAldAssessment'],
                                  content: (
                                    <ALDAssessment
                                      subFlowState={currentStateEnum}
                                      vehicleId={vehicleId}
                                      assessment={aldAssessment}
                                      acceptAssessment={acceptAssessment}
                                      declineAssessment={declineAssessment}
                                      forceApproveAssessment={
                                        forceApproveAssessment
                                      }
                                    />
                                  ),
                                },
                              ]}
                            />
                          </>
                        )}
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />

          {
            // #endregion
          }

          {
            // #region Suppliers logs
          }
          <WithAccessRightsRendererContainer
            contentByAccessRights={[
              {
                accessRights: ['canViewCarSupplierLogs'],
                content: (
                  <ListItem disablePadding>
                    <ListItemButton
                      disabled={suppliersLogs.length === 0}
                      sx={{
                        backgroundColor: isSupplierLogsOpened
                          ? 'primary.main'
                          : 'background.default',
                        color: isSupplierLogsOpened
                          ? 'background.default'
                          : 'text.primary',
                        ':hover': {
                          backgroundColor: isSupplierLogsOpened
                            ? 'primary.main'
                            : 'background.default',
                        },
                      }}
                      onClick={() => {
                        getVehicleRepairStatuses(vehicleId);
                        setSupplierLogsOpened(!isSupplierLogsOpened);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          backgroundColor: isSupplierLogsOpened
                            ? '#ffffff'
                            : 'primary.main',
                          color: isSupplierLogsOpened
                            ? 'primary.main'
                            : '#ffffff',
                        }}
                      >
                        <BuildIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary={t(
                          'vehiclesList.carDetails.repairStatus.title',
                        )}
                        primaryTypographyProps={{
                          fontWeight: 400,
                        }}
                      />

                      <ListItemIcon className="ExpandIcon">
                        {isSupplierLogsOpened ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                    </ListItemButton>

                    <Collapse in={isSupplierLogsOpened}>
                      <Box className="CollapseContent">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            backgroundColor: 'rgba(239, 240, 251, 0.4)',
                            height: '380px',
                            overflowX: 'auto',
                          }}
                        >
                          <SupplierLogs
                            vehicleId={vehicleId}
                            suppliersLogs={suppliersLogs}
                          />
                        </Box>
                      </Box>
                    </Collapse>
                  </ListItem>
                ),
              },
            ]}
            fallBackContent={null}
          />
          {
            // #endregion
          }

          {
            // #region Sales Info
          }
          {/* <WithAccessRightsRendererContainer
              contentByAccessRights={[
                {
                  accessRights: ['canViewVehicleSalesInfo'],
                  content: (
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          backgroundColor: isSalesInfoOpened
                            ? 'primary.main'
                            : 'background.default',
                          color: isSalesInfoOpened
                            ? 'background.default'
                            : 'text.primary',
                          ':hover': {
                            backgroundColor: isSalesInfoOpened
                              ? 'primary.main'
                              : 'background.default',
                          },
                        }}
                        onClick={() => {
                          setSalesInfoOpened(!isSalesInfoOpened);
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            backgroundColor: isSalesInfoOpened
                              ? '#ffffff'
                              : 'primary.main',
                            color: isSalesInfoOpened
                              ? 'primary.main'
                              : '#ffffff',
                          }}
                        >
                          <HardDriveIcon />
                        </ListItemIcon>

                        <ListItemText
                          primary={t('vehiclesList.carDetails.salesInfo.title')}
                          primaryTypographyProps={{
                            fontWeight: 400,
                          }}
                        />

                        <ListItemIcon className="ExpandIcon">
                          {isSalesInfoOpened ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>
                      </ListItemButton>

                      <Collapse in={isSalesInfoOpened}>
                        <Box className="CollapseContent">
                          <Box className="CollapseContentRow">
                            <Typography
                              variant="h6"
                              className="CollapseContentRowTitle"
                            >
                              In development...
                            </Typography>
                          </Box>
                        </Box>
                      </Collapse>
                    </ListItem>
                  ),
                },
              ]}
              fallBackContent={null}
            /> */}

          {
            // #endregion
          }
        </CarDetailsStyledList>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t('vehiclesList.carDetails.noSpecifiedCarFound')}
      </Typography>
    </Box>
  );
}

export default withTranslation()(withTheme(CarDetails));
