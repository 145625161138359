import { byAccessRightsExecutor } from '../../common/helpers/common';
import { _authenticateSucceeded } from '../auth/slice';
import { getAllDamageTypes } from './slice';

export const damageTypesMiddleware = ({ dispatch }) => {
  return (next) => async (action) => {
    switch (action.type) {
      case _authenticateSucceeded.type: {
        try {
          await byAccessRightsExecutor({
            actionsByAccessRights: [
              {
                accessRights: ['canViewDamageTypes'],
                action: async () => {
                  await dispatch(getAllDamageTypes());
                },
              },
            ],
            userAccessRights: action.payload.accessRights,
          });
        } catch (e) {
          console.error(e);
        }
        break;
      }
      default:
        break;
    }

    return await next(action);
  };
};
