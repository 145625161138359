export const getUserNameAbbreviation = (userName = '') => {
  const nameSplit = userName.split(' ');
  if (nameSplit.length > 2) {
    nameSplit.splice(2);
  }

  let res = '';
  nameSplit.forEach((str = '') => {
    res = res.concat(str.charAt(0).toUpperCase());
  });

  return res;
};
