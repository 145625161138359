import { connect } from 'react-redux';
import { redirectToAppVehiclesListDealersCarsCarDetails } from '../../../../store/redirect/slice';
import { getVehicles } from '../../../../store/vehicles/slice';
import DealerCars from './DealerCars';

const mapStateToProps = () => {};

const mapDispatchToProps = {
  redirectToAppVehiclesListDealersCarsCarDetails,
  getVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealerCars);
