import { createSlice } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

export const redirectSlice = createSlice({
  name: 'redirect',
  initialState: {
    requestedLocation: null,
  },
  reducers: {
    redirectToSignIn: () => {},
    redirectToAppDashboard: () => {},
    redirectToAppDashboardToday: () => {},
    redirectToAppDashboardLastWeek: () => {},
    redirectToAppDashboardLastMonth: () => {},
    redirectToAppAldUsers: () => {},
    redirectToAppAldUsersCreateNewUser: () => {},
    redirectToAppAldUsersUserDetailsUserInfo: () => {},

    redirectToAppVehiclesList: () => {},
    redirectToAppVehiclesListCustomerLocationCars: () => {},
    redirectToAppVehiclesListCustomerLocationCarsCarDetails: () => {},
    redirectToAppVehiclesListNewBookedCars: () => {},
    redirectToAppVehiclesListNewBookedCarsCarDetails: () => {},
    redirectToAppVehiclesListInProcessCars: () => {},
    redirectToAppVehiclesListInProcessCarsCarDetails: () => {},
    redirectToAppVehiclesListReadyForSalesCars: () => {},
    redirectToAppVehiclesListReadyForSalesCarsCarDetails: () => {},
    redirectToAppVehiclesListAssessmentApprovesCars: () => {},
    redirectToAppVehiclesListAssessmentApprovesCarsCarDetails: () => {},
    redirectToAppVehiclesListAllCars: () => {},
    redirectToAppVehiclesListAllCarsCarDetails: () => {},
    redirectToAppVehiclesListCarStatus: () => {},
    redirectToAppVehiclesListSupplierCarsCarDetails: () => {},
    redirectToAppVehiclesListCustomerCarsCarDetails: () => {},
    redirectToAppVehiclesListDealersCarsCarDetails: () => {},

    redirectToAppVehiclesPlanning: () => {},
    redirectToAppSupplierUsersList: () => {},
    redirectToAppPickupCalendar: () => {},
    redirectToAppWorkshop: () => {},
    redirectToAppDealers: () => {},
    redirectToAppDealersCreateNew: () => {},
    redirectToAppDealersDealerDetailsDealerInfo: () => {},
    redirectToAppDealersDealerDetailsDealerUsers: () => {},
    redirectToAppDealersDealerDetailsDealerUsersCreateNewUser: () => {},
    redirectToAppDealersDealerDetailsDealerUsersUserInfo: () => {},

    redirectToAppCustomers: () => {},
    redirectToAppCustomersB2CCreateNewCustomer: () => {},
    redirectToAppCustomersB2CCustomerDetails: () => {},
    redirectToAppCustomersB2CCustomerDetailsCustomerInfo: () => {},
    redirectToAppCustomersB2CCustomerDetailsCustomerVehicles: () => {},
    redirectToAppCustomersB2CCustomerDetailsCustomerUsers: () => {},
    redirectToAppCustomersB2CCustomerDetailsUsersUserDetails: () => {},
    redirectToAppCustomersB2CCustomerDetailsCars: () => {},
    redirectToAppCustomersB2CCustomerDetailsUsers: () => {},
    redirectToAppCustomersB2CCustomerDetailsCreateNewCustomerAdminUser:
      () => {},
    redirectToAppCustomersB2CCustomerDetailsCreateNewCustomerUser: () => {},

    redirectToAppCustomersB2BCreateNewCustomer: () => {},
    redirectToAppCustomersB2BCustomerDetails: () => {},
    redirectToAppCustomersB2BCustomerDetailsCustomerInfo: () => {},
    redirectToAppCustomersB2BCustomerDetailsCustomerVehicles: () => {},
    redirectToAppCustomersB2BCustomerDetailsCustomerUsers: () => {},
    redirectToAppCustomersB2BCustomerDetailsCars: () => {},
    redirectToAppCustomersB2BCustomerDetailsUsers: () => {},
    redirectToAppCustomersB2BCustomerDetailsUsersUserDetails: () => {},
    redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser:
      () => {},
    redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser: () => {},

    redirectToAppCustomersRentalCreateNewCustomer: () => {},

    redirectToAppCustomersFlexCreateNewCustomer: () => {},

    redirectToAppB2BAdminCustomers: () => {},
    redirectToAppB2BAdminCustomersCustomerDetails: () => {},
    redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo: () => {},
    redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers: () => {},
    redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersUserDetails:
      () => {},
    redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewAdminUser:
      () => {},
    redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewUser:
      () => {},

    redirectToAppSuppliers: () => {},
    redirectToAppSuppliersWash: () => {},
    redirectToAppSuppliersWashCreateNew: () => {},
    redirectToAppSuppliersWashSupplierDetails: () => {},
    redirectToAppSuppliersWashSupplierDetailsInfo: () => {},
    redirectToAppSuppliersWashSupplierDetailsUsers: () => {},
    redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser: () => {},
    redirectToAppSuppliersWashSupplierDetailsUsersUserDetails: () => {},
    redirectToAppSuppliersNAF: () => {},
    redirectToAppSuppliersNAFCreateNew: () => {},
    redirectToAppSuppliersNAFSupplierDetails: () => {},
    redirectToAppSuppliersNAFSupplierDetailsInfo: () => {},
    redirectToAppSuppliersNAFSupplierDetailsUsers: () => {},
    redirectToAppSuppliersNAFSupplierDetailsUsersCreateNewUser: () => {},
    redirectToAppSuppliersNAFSupplierDetailsUsersUserDetails: () => {},
    redirectToAppSuppliersWorkshop: () => {},
    redirectToAppSuppliersWorkshopCreateNew: () => {},
    redirectToAppSuppliersWorkshopSupplierDetails: () => {},
    redirectToAppSuppliersWorkshopSupplierDetailsInfo: () => {},
    redirectToAppSuppliersWorkshopSupplierDetailsUsers: () => {},
    redirectToAppSuppliersWorkshopSupplierDetailsUsersCreateNewUser: () => {},
    redirectToAppSuppliersWorkshopSupplierDetailsUsersUserDetails: () => {},

    redirectToAppSupplierGroups: () => {},
    redirectToAppReports: () => {},
    redirectToAppInbox: () => {},
    redirectBack: () => {},
    redirectBackByBreadcrumb: () => {},

    redirectToRequestedLocation: () => {},
    setRequestedLocation: (state, action) => {
      state.requestedLocation = action.payload;
    },
  },
});

export const {
  redirectToSignIn,
  redirectToAppDashboard,
  redirectToAppDashboardToday,
  redirectToAppDashboardLastWeek,
  redirectToAppDashboardLastMonth,
  redirectToAppAldUsers,
  redirectToAppAldUsersCreateNewUser,
  redirectToAppAldUsersUserDetailsUserInfo,

  redirectToAppVehiclesList,
  redirectToAppVehiclesListCustomerLocationCars,
  redirectToAppVehiclesListCustomerLocationCarsCarDetails,
  redirectToAppVehiclesListNewBookedCars,
  redirectToAppVehiclesListNewBookedCarsCarDetails,
  redirectToAppVehiclesListInProcessCars,
  redirectToAppVehiclesListInProcessCarsCarDetails,
  redirectToAppVehiclesListReadyForSalesCars,
  redirectToAppVehiclesListReadyForSalesCarsCarDetails,
  redirectToAppVehiclesListAssessmentApprovesCars,
  redirectToAppVehiclesListAssessmentApprovesCarsCarDetails,
  redirectToAppVehiclesListAllCars,
  redirectToAppVehiclesListAllCarsCarDetails,
  redirectToAppVehiclesListCarStatus,
  redirectToAppVehiclesListSupplierCarsCarDetails,
  redirectToAppVehiclesListCustomerCarsCarDetails,
  redirectToAppVehiclesListDealersCarsCarDetails,

  redirectToAppVehiclesPlanning,
  redirectToAppSupplierUsersList,
  redirectToAppPickupCalendar,
  redirectToAppWorkshop,
  redirectToAppDealers,
  redirectToAppDealersCreateNew,
  redirectToAppDealersDealerDetailsDealerInfo,
  redirectToAppDealersDealerDetailsDealerUsers,
  redirectToAppDealersDealerDetailsDealerUsersCreateNewUser,
  redirectToAppDealersDealerDetailsDealerUsersUserInfo,

  redirectToAppCustomers,

  redirectToAppCustomersB2CCreateNewCustomer,
  redirectToAppCustomersB2CCustomerDetails,
  redirectToAppCustomersB2CCustomerDetailsCustomerInfo,
  redirectToAppCustomersB2CCustomerDetailsCustomerVehicles,
  redirectToAppCustomersB2CCustomerDetailsCustomerUsers,
  redirectToAppCustomersB2CCustomerDetailsUsersUserDetails,
  redirectToAppCustomersB2CCustomerDetailsCars,
  redirectToAppCustomersB2CCustomerDetailsUsers,
  redirectToAppCustomersB2CCustomerDetailsCreateNewCustomerAdminUser,
  redirectToAppCustomersB2CCustomerDetailsCreateNewCustomerUser,

  redirectToAppCustomersB2BCreateNewCustomer,
  redirectToAppCustomersB2BCustomerDetails,
  redirectToAppCustomersB2BCustomerDetailsCustomerInfo,
  redirectToAppCustomersB2BCustomerDetailsCustomerVehicles,
  redirectToAppCustomersB2BCustomerDetailsCustomerUsers,
  redirectToAppCustomersB2BCustomerDetailsUsersUserDetails,
  redirectToAppCustomersB2BCustomerDetailsCars,
  redirectToAppCustomersB2BCustomerDetailsUsers,
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser,
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser,

  redirectToAppCustomersRentalCreateNewCustomer,

  redirectToAppCustomersFlexCreateNewCustomer,

  redirectToAppB2BAdminCustomers,
  redirectToAppB2BAdminCustomersCustomerDetails,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersUserDetails,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewAdminUser,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewUser,

  redirectToAppSuppliers,
  redirectToAppSuppliersWash,
  redirectToAppSuppliersWashCreateNew,
  redirectToAppSuppliersWashSupplierDetails,
  redirectToAppSuppliersWashSupplierDetailsInfo,
  redirectToAppSuppliersWashSupplierDetailsUsers,
  redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWashSupplierDetailsUsersUserDetails,
  redirectToAppSuppliersNAF,
  redirectToAppSuppliersNAFCreateNew,
  redirectToAppSuppliersNAFSupplierDetails,
  redirectToAppSuppliersNAFSupplierDetailsInfo,
  redirectToAppSuppliersNAFSupplierDetailsUsers,
  redirectToAppSuppliersNAFSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersNAFSupplierDetailsUsersUserDetails,
  redirectToAppSuppliersWorkshop,
  redirectToAppSuppliersWorkshopCreateNew,
  redirectToAppSuppliersWorkshopSupplierDetails,
  redirectToAppSuppliersWorkshopSupplierDetailsInfo,
  redirectToAppSuppliersWorkshopSupplierDetailsUsers,
  redirectToAppSuppliersWorkshopSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWorkshopSupplierDetailsUsersUserDetails,

  redirectToAppSupplierGroups,
  redirectToAppReports,
  redirectToAppInbox,
  redirectBack,
  redirectBackByBreadcrumb,
  redirectToRequestedLocation,
  setRequestedLocation,
} = redirectSlice.actions;

export const redirectReducer = redirectSlice.reducer;

export const redirectTo =
  (location = '/') =>
  async (dispatch) => {
    await dispatch(push(location));
  };
