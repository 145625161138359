import JwtDecode from 'jwt-decode';
import { api, jwtApiClient } from '../../../core/api';
import {
  _authenticateSucceeded,
  _initialJwtRefresh,
  _tokenRefreshFailed,
  _tokenRefreshSucceeded,
} from '../slice';

export default function revokeTokenMiddleware({ dispatch, getState }) {
  let isTokenRefreshInProcess = false;
  return (next) => async (action) => {
    const oldAccessToken = jwtApiClient.getJwtToken();

    if (
      action.type === _initialJwtRefresh.type ||
      typeof action === 'function'
    ) {
      if (
        ((!!oldAccessToken &&
          !tokenValidator.isAccessTokenValid(oldAccessToken)) ||
          !oldAccessToken) &&
        !isTokenRefreshInProcess
      ) {
        isTokenRefreshInProcess = true;

        try {
          const response = await api.auth.refreshToken();

          await dispatch(_tokenRefreshSucceeded(response));

          const { isAuthenticated } = getState().auth;
          if (!isAuthenticated) {
            await dispatch(_authenticateSucceeded(response));
          }
        } catch (error) {
          await dispatch(_tokenRefreshFailed());
          // todo: show splash message about session expired
        }

        isTokenRefreshInProcess = false;
      }
    }

    return await next(action);
  };
}

class TokenValidator {
  constructor() {
    this.customValidator = null;
  }

  isTokenExpired(token) {
    const { exp } = JwtDecode(token);
    return Date.now() >= exp * 1000;
  }

  isAccessTokenValid(token) {
    if (this.customValidator) {
      return this.customValidator(token);
    }

    return !!token && !this.isTokenExpired(token);
  }

  useCustomTokenValidator(callback) {
    this.customValidator = callback;
  }
}

export function useMockedTokenValidator() {
  tokenValidator.useCustomTokenValidator(() => true);
}

export const tokenValidator = new TokenValidator();
