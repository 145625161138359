import { connect } from 'react-redux';
import DealerUserDetails from './UserDetails';

const mapStateToProps = (state, ownProps) => {
  const { dealerId, dealerUserId } = ownProps;
  const {
    dealers: { dealerUsers },
  } = state;
  const dealerUser = (dealerUsers[dealerId] || []).find(
    (user) => user.id === dealerUserId,
  );

  return {
    dealerUser,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DealerUserDetails);
