import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    todayStatistic: {
      overviewOfReturn: [],
      overviewOfCars: [],
      overviewOfAssessment: [],
    },
    lastWeekStatistic: {
      overviewOfReturn: [],
      overviewOfCars: [],
      overviewOfAssessment: [],
    },
    lastMonthStatistic: {
      overviewOfReturn: [],
      overviewOfCars: [],
      overviewOfAssessment: [],
    },
  },
  reducers: {
    _dashboardTodayInfoFetchSucceeded: (state, action) => {
      state.todayStatistic = action.payload;
    },
    _dashboardLastWeekInfoFetchSucceeded: (state, action) => {
      state.lastWeekStatistic = action.payload;
    },
    _dashboardLastMonthInfoFetchSucceeded: (state, action) => {
      state.lastMonthStatistic = action.payload;
    },
  },
});

export const {
  _dashboardTodayInfoFetchSucceeded,
  _dashboardLastWeekInfoFetchSucceeded,
  _dashboardLastMonthInfoFetchSucceeded,
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;

export const getDashboardTodayStatistic = () => async (dispatch) => {
  try {
    const response = await api.dashboard.getTodayStatistic();
    await dispatch(_dashboardTodayInfoFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getDashboardLastWeekStatistic = () => async (dispatch) => {
  try {
    const response = await api.dashboard.getLastWeekStatistic();
    await dispatch(_dashboardLastWeekInfoFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getDashboardLastMonthStatistic = () => async (dispatch) => {
  try {
    const response = await api.dashboard.getLastMonthStatistic();
    await dispatch(_dashboardLastMonthInfoFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
