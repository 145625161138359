import { withTheme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import OneByOnePhotosUpload from '../../../../../common/components/OneByOnePhotosUpload/OneByOnePhotosUpload';
import {
  CONTRACT_CAR_IMAGE_TYPES,
  SUB_FLOW_STATE_ENUM,
} from '../../../../../common/constants/vehicles';

function PhotoUploadBeforeReturn({
  theme = {},
  vehicle = {},
  addImageToContract = () => {},
  removeImageFromContract = () => {},
  getContractImages = () => {},
  switchToNextVehicleFlowState = () => {},
  contractImages = [],
  currentVehicleFlowState = {},
  currentVehicleBooking = { returnAddress: {} },
  getVehicleFlowState = () => {},
  setFsUploadIds = () => {},
}) {
  const { contractId, id } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;
  const {
    bookingDate,
    contactPersonName,
    contactPersonTelephone,
    returnAddress: { name: returnAddressString },
  } = currentVehicleBooking;

  return (
    <>
      <Box
        sx={{
          p: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box
          sx={{
            borderRadius: '26px',
            border: `1px solid ${theme.palette.primary.main}`,
            py: 0.5,
            px: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ color: 'primary.main' }}>
            {moment(bookingDate).format('Do MMMM, HH:mm')}
          </Typography>

          {contactPersonName && contactPersonTelephone && (
            <Typography>{`${contactPersonName} ${contactPersonTelephone}`}</Typography>
          )}

          <Typography sx={{ fontStyle: 'italic', fontWeight: 300 }}>
            {returnAddressString}
          </Typography>
        </Box>

        <OneByOnePhotosUpload
          onImageSelected={async (file) => {
            await addImageToContract(
              file,
              contractId,
              CONTRACT_CAR_IMAGE_TYPES.IMAGE_BEFORE_RETURN,
            );
            await getContractImages(contractId);
          }}
          uploadIds={contractImages.map((imgData) => {
            return imgData.uploadId;
          })}
          canSkipPictures
          onSkip={async () => {
            await switchToNextVehicleFlowState(
              id,
              currentStateId,
              SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_DEALER_LOCATION,
            );
            await getVehicleFlowState(id, contractId);
          }}
          onClickImage={() =>
            setFsUploadIds(
              contractImages.map((x) => x.uploadId),
              async (uploadId) => {
                await removeImageFromContract(uploadId);
                await getContractImages(contractId);
              },
            )
          }
        />

        <Button
          sx={{ width: '100%', mt: 1 }}
          color="primary"
          variant="contained"
          disabled={contractImages.length < 6}
          onClick={async () => {
            await switchToNextVehicleFlowState(
              id,
              currentStateId,
              SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_DEALER_LOCATION,
            );
            await getVehicleFlowState(id, contractId);
          }}
        >
          UPLOAD FOR RETURN
        </Button>
      </Box>
    </>
  );
}

export default withTheme(PhotoUploadBeforeReturn);
