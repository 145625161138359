import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    lastUploadId: null,
  },
  reducers: {
    _xlsImportSucceeded: () => {},
    _fileUploadSucceeded: (state, action) => {
      state.lastUploadId = action.payload;
    },
  },
});

export const { _xlsImportSucceeded, _fileUploadSucceeded } =
  uploadSlice.actions;

export const uploadReducer = uploadSlice.reducer;

// actions

export const uploadXlsImport =
  (file, onUploadProgress = () => {}) =>
  async (dispatch) => {
    const uploadResponse = await api.upload.file(file, onUploadProgress);
    if (uploadResponse.error) {
      throw new Error(uploadResponse.errors.message);
    }
    const response = await api.import.xls(uploadResponse.id);
    if (response.error) {
      throw new Error(response.errors.message);
    }

    dispatch(_xlsImportSucceeded());
  };

export const uploadFile =
  (file, onUploadProgress = () => {}) =>
  async () => {
    try {
      await api.upload.file(file, onUploadProgress);
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };
