import { withTheme } from '@emotion/react';
import { ClearOutlined } from '@mui/icons-material';
import { Button, Divider, Modal, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { ACCESSORY_STATUS } from '../../../../../common/constants/accessories';

const EquipmentModal = ({
  isOpened,
  headerTitle,
  buttonTitle,
  onClick,
  onClose,
  name = '',
  description = '',
  accessoryRepairStatus = ACCESSORY_STATUS.OK,
  clearOnClose = false,
}) => {
  const [nameText, setNameText] = useState(name);
  const [descriptionText, setDescriptionText] = useState(description || '');

  useEffect(() => {
    setNameText(name);
    setDescriptionText(description);
  }, [isOpened]);

  const handleNameTextChange = (e) => {
    const text = e.target.value;
    setNameText(text);
  };

  const handleCommentTextChanged = (e) => {
    const text = e.target.value;
    setDescriptionText(text);
  };

  const handleSubmit = () => {
    if (!nameText) return;

    const accessory = {
      name: nameText,
      description: descriptionText,
      accessoryRepairStatus,
    };

    onClick(accessory);
    handleClose();
  };

  const handleClose = () => {
    if (clearOnClose) {
      setNameText('');
      setDescriptionText('');
    }
    onClose();
  };
  return (
    <Modal open={isOpened} onClose={handleClose}>
      <Box
        sx={{
          backgroundColor: 'white',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '16px',
          m: 5,
          mt: { xs: '10%', md: '10%' },
          mb: { xs: '10%', md: '10%' },
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Typography sx={{ p: '10px', textAlign: 'center' }} variant="h5">
            {headerTitle}
          </Typography>
          <ClearOutlined
            sx={{ ml: 'auto', mr: '15px', cursor: 'pointer' }}
            onClick={onClose}
          />
        </Box>
        <Divider sx={{ mb: '23px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: '12px',
          }}
        >
          <TextField
            sx={{ mb: '10px' }}
            placeholder="Enter type"
            onChange={handleNameTextChange}
            value={nameText}
          />
          <TextField
            multiline
            rows={5}
            placeholder="Enter comment"
            onChange={handleCommentTextChanged}
            value={descriptionText}
          />
        </Box>
        <Button
          sx={{ m: '12px 12px 20px 12px' }}
          variant="contained"
          onClick={handleSubmit}
          disabled={!nameText || !descriptionText}
        >
          {buttonTitle}
        </Button>
      </Box>
    </Modal>
  );
};

export default withTheme(EquipmentModal);
