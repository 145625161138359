import { connect } from 'react-redux';
import { getVehicleByQRId } from '../../../store/vehicles/slice';
import VehicleByQRRedirect from './VehicleByQRRedirect';

const mapStateToProps = (state) => {
  const { prefetchedByQRVehicle } = state.vehicles;
  return {
    vehicle: prefetchedByQRVehicle,
  };
};

const mapDispatchToProps = { getVehicleByQRId };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleByQRRedirect);
