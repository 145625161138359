import { connect } from 'react-redux';
import {
  getAllAldCustomerMessages,
  getAllAldSupplierMessages,
  postAldCustomerMessage,
  postAldSupplierMessage,
} from '../../../store/messages/slice';
import Chat from './Chat';

const mapStateToProps = (state, ownProps) => {
  const { vehicleId } = ownProps;
  const {
    vehicles: { contracts },
  } = state;
  const contractId = contracts[vehicleId] ? contracts[vehicleId].id : null;
  const aldCustomerMessages =
    state.messages.aldCustomerMessages[contractId] || [];
  const aldSupplierMessages =
    state.messages.aldSupplierMessages[contractId] || [];
  const userId = state.auth.user.id;

  const { canViewAldSupplierChat, canViewAldCustomerChat } =
    state.auth.user.accessRights;

  return {
    contractId,
    userId,
    aldCustomerMessages,
    aldSupplierMessages,
    canViewAldSupplierChat,
    canViewAldCustomerChat,
  };
};

const mapDispatchToProps = {
  postAldCustomerMessage,
  postAldSupplierMessage,
  getAllAldCustomerMessages,
  getAllAldSupplierMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
