import { connect } from 'react-redux';
import { toggleNav } from '../../../store/appCommon/slice';
import { logout } from '../../../store/auth/slice';
import {
  redirectToAppVehiclesListAllCarsCarDetails,
  redirectToAppVehiclesListSupplierCarsCarDetails,
  redirectToAppVehiclesListCustomerCarsCarDetails,
  redirectToAppVehiclesListDealersCarsCarDetails,
  redirectToSignIn,
} from '../../../store/redirect/slice';
import { getVehicles } from '../../../store/vehicles/slice';
import Header from './Header';

const mapStateToProps = (state) => {
  return {
    logoUrl: state.auth?.logoUrl,
    userAccessRights: state.auth.user.accessRights,
  };
};

const mapDispatchToProps = {
  logout,
  redirectToSignIn,
  toggleNav,
  getVehicles,
  redirectToAppVehiclesListAllCarsCarDetails,
  redirectToAppVehiclesListSupplierCarsCarDetails,
  redirectToAppVehiclesListCustomerCarsCarDetails,
  redirectToAppVehiclesListDealersCarsCarDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
