import {
  Button,
  ImageList,
  ImageListItem,
  Typography,
  Link,
  Collapse,
} from '@mui/material';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { ASSESSMENT_APPROVAL_STATE } from '../../../../../common/constants/assessment';
import { Box } from '@mui/system';
import ChatContainer from '../../../Chat/Chat.container';
import CustomLoadingButton from '../../../../../common/components/LoadingButton/LoadingButton';
import ImageLoader from '../../../../../common/components/ImageLoader/ImageLoader';
import NewCommentBlock from './NewCommentBlock';
import WithAccessRightsRenderer from '../../../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

function ALDAssessment({
  subFlowState,
  damageTypeGroups,
  damageTypes,
  assessment = null,
  t = () => {},
  vehicleId,
  userId,
  acceptAssessment = () => {},
  declineAssessment = () => {},
  forceApproveAssessment = () => {},
  setFsUploadIds = () => {},
  addCommentToSheet = () => {},
  resendAssessmentInfo = () => {},
  // removeCommentFromSheet = () => {}
}) {
  if (!assessment) return <></>;

  const [openedSheetIds, setOpenedSheetIds] = useState([]);
  const [commentInProcessSheetIds, setCommentInProcessSheetIds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickComments = (sheetId) => {
    if (openedSheetIds.includes(sheetId)) {
      setOpenedSheetIds(openedSheetIds.filter((x) => x !== sheetId));
      return;
    }
    setOpenedSheetIds([...openedSheetIds, sheetId]);
  };

  const handleClickLeaveComment = (sheetId) => {
    setCommentInProcessSheetIds([...commentInProcessSheetIds, sheetId]);
  };

  const handleClickCancelComment = (sheetId) => {
    setCommentInProcessSheetIds(
      commentInProcessSheetIds.filter((x) => x !== sheetId),
    );
  };

  const { linkWebNafReport, id: assessmentId, approvalState } = assessment;

  let price = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const repair of assessment.repairs) {
    // eslint-disable-next-line no-restricted-syntax
    for (const sheet of repair.assessmentSheets) {
      // eslint-disable-next-line no-restricted-syntax
      for (const details of sheet.damageDetails) {
        price += details.price;
      }
    }
  }

  return (
    <>
      <>
        {assessment.repairs.map((repair, index) => {
          if (repair.assessmentSheets.length === 0) {
            return <React.Fragment key={index} />;
          }
          return (
            <Box sx={{ m: '15px' }} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography>
                  {damageTypeGroups[repair.damageTypeGroupId]?.name}
                </Typography>
              </Box>
              {repair.assessmentSheets.map((damage, damageIndex) => {
                const isOpened = openedSheetIds.includes(damage.id);
                const isCommentInProcess = commentInProcessSheetIds.includes(
                  damage.id,
                );
                const sheetPrice = damage.damageDetails.reduce(
                  (acc, curr) => acc + curr.price,
                  0,
                );

                return (
                  <Box
                    sx={{
                      border: '1px solid rgba(0, 0, 0, 0.12)',
                      borderRadius: '15px',
                      m: '10px',
                      ml: 0,
                      p: 2,
                      pr: 2,
                    }}
                    key={damageIndex}
                  >
                    <Box
                      sx={{
                        background: isOpened ? '#F7F7FD' : 'white',
                        p: 2,
                        borderRadius: '15px',
                        display: 'flex',
                      }}
                    >
                      <Box
                        sx={{
                          flex: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          maxWidth: '60%',
                        }}
                      >
                        <Typography sx={{ mb: 2 }}>
                          {damageTypes[damage.damageTypeId]?.name}
                        </Typography>
                        {damage.damageDetails.map((details) => (
                          <Typography
                            key={details.id}
                            sx={{
                              fontSize: '12px',
                              mb: 1,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {details.name}
                          </Typography>
                        ))}
                        <Typography sx={{ fontWeight: 500, mt: 2 }}>
                          Repair price: {sheetPrice}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: 2,
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          mr: 1,
                        }}
                      >
                        {damage.images && damage.images.length > 0 && (
                          <>
                            <ImageList rowHeight={105} gap={10}>
                              {damage.images
                                .filter((_, imageIndex) => imageIndex <= 1)
                                .map((image) => {
                                  return (
                                    <ImageListItem
                                      key={image}
                                      cols={1}
                                      rows={1}
                                      sx={{}}
                                      onClick={() =>
                                        setFsUploadIds(damage.images)
                                      }
                                    >
                                      <ImageLoader
                                        sx={{
                                          borderRadius: '6px',
                                          maxWidth: '100px',
                                          maxHeight: '100px',
                                        }}
                                        loading="lazy"
                                        uploadId={image}
                                      />
                                    </ImageListItem>
                                  );
                                })}
                            </ImageList>
                            {damage.images && damage.images.length > 2 && (
                              <Typography
                                onClick={() => setFsUploadIds(damage.images)}
                                sx={{ fontSize: '12px', cursor: 'pointer' }}
                              >
                                + {damage.images.length - 2} pictures
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Typography
                      sx={{
                        ml: 'auto',
                        mr: '10px',
                        width: 'fit-content',
                        color: '#5664D2',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClickComments(damage.id)}
                    >
                      {damage.comments ? damage.comments.length : ''} comments
                    </Typography>
                    <Collapse in={isOpened}>
                      <Box>
                        {damage.comments &&
                          damage.comments.map((comment) => {
                            const isMyComment = comment.userId === userId;

                            return (
                              <Box
                                sx={{
                                  borderRadius: '15px',
                                  p: '10px',
                                  background: 'rgba(86, 100, 210, 0.05)',
                                  maxWidth: '60%',
                                  width: 'fit-content',
                                  m: '10px',
                                  ml: isMyComment ? 'auto' : 0,
                                  mr: isMyComment ? 0 : 'auto',
                                }}
                                key={comment.id}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: 'rgba(66, 82, 110, 0.82)',
                                  }}
                                >
                                  {comment.text}
                                </Typography>
                              </Box>
                            );
                          })}
                      </Box>
                      {(approvalState === ASSESSMENT_APPROVAL_STATE.NONE ||
                        approvalState ===
                          ASSESSMENT_APPROVAL_STATE.DECLINED) && (
                        <WithAccessRightsRenderer
                          contentByAccessRights={[
                            {
                              accessRights: [
                                approvalState ===
                                ASSESSMENT_APPROVAL_STATE.DECLINED
                                  ? 'canAddCommentsIfAssessmentDeclined'
                                  : 'canAddCommentsIfAssessmentPending',
                              ],
                              content: (
                                <>
                                  {!isCommentInProcess && (
                                    <Typography
                                      sx={{
                                        ml: 'auto',
                                        mr: '10px',
                                        width: 'fit-content',
                                        color: '#5664D2',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        handleClickLeaveComment(damage.id)
                                      }
                                    >
                                      Leave comment
                                    </Typography>
                                  )}
                                  {isCommentInProcess && (
                                    <NewCommentBlock
                                      onAddComment={(comment) =>
                                        addCommentToSheet(
                                          vehicleId,
                                          repair.id,
                                          damage.id,
                                          comment,
                                        )
                                      }
                                      onCancel={() =>
                                        handleClickCancelComment(damage.id)
                                      }
                                    />
                                  )}
                                </>
                              ),
                            },
                          ]}
                        />
                      )}
                    </Collapse>
                  </Box>
                );
              })}
            </Box>
          );
        })}

        <Typography>Sum of repair: {price}</Typography>
      </>

      {assessment.repairs.length > 0 && (
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Link
            component="a"
            target="_blank"
            href={linkWebNafReport}
            sx={{ cursor: 'pointer', mr: isMobile ? 0 : 'auto' }}
          >
            View 3rd Party assessment
          </Link>
          <WithAccessRightsRenderer
            contentByAccessRights={[
              {
                accessRights: ['canResendAssessment'],
                content: (
                  <Button
                    disabled={
                      subFlowState <=
                      SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT
                    }
                    variant="contained"
                    sx={{ m: 1 }}
                    onClick={() => {
                      try {
                        resendAssessmentInfo(vehicleId);
                        enqueueSnackbar('Assessment info sent to customer!', {
                          variant: 'success',
                        });
                      } catch (e) {
                        console.error(e);
                      }
                    }}
                  >
                    Resend assessment
                  </Button>
                ),
              },
            ]}
          />
          {approvalState === ASSESSMENT_APPROVAL_STATE.NONE && (
            <WithAccessRightsRenderer
              contentByAccessRights={[
                {
                  accessRights: ['canForceApproveAssessment'],
                  content: (
                    <Button
                      variant="contained"
                      sx={{ m: 1, backgroundColor: 'gray' }}
                      onClick={async () => {
                        await forceApproveAssessment(vehicleId, assessmentId);
                      }}
                    >
                      Force approve
                    </Button>
                  ),
                },
              ]}
            />
          )}
          {approvalState === ASSESSMENT_APPROVAL_STATE.NONE && (
            <>
              <WithAccessRightsRenderer
                contentByAccessRights={[
                  {
                    accessRights: ['canAcceptAssessment'],
                    content: (
                      <CustomLoadingButton
                        variant="contained"
                        color="success"
                        onClick={async () => {
                          await acceptAssessment(vehicleId, assessmentId);
                        }}
                        sx={{ m: 1 }}
                      >
                        {t('common.buttons.accept')}
                      </CustomLoadingButton>
                    ),
                  },
                ]}
              />
              <WithAccessRightsRenderer
                contentByAccessRights={[
                  {
                    accessRights: ['canDeclineAssessment'],
                    content: (
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ m: 1 }}
                        onClick={() => {
                          declineAssessment(vehicleId, assessmentId);
                        }}
                      >
                        {t('common.buttons.decline')}
                      </Button>
                    ),
                  },
                ]}
              />
            </>
          )}
          <ChatContainer vehicleId={vehicleId} />
        </Box>
      )}
    </>
  );
}

export default withTranslation()(ALDAssessment);
