import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Popper,
  Typography,
} from '@mui/material';

export function MicroDashboardItem({
  Icon = () => {
    return null;
  },
  title = '',
  value = '',
  expandable = false,
  loading = false,
  popperContentRenderer = () => {
    return null;
  },
  sx = {},
  ValueSx = {},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOpened = Boolean(anchorEl);
  const [searchResultsWidth, setSearchResultsWidth] = React.useState(0);
  React.useEffect(() => {
    if (anchorEl) {
      setSearchResultsWidth(anchorEl.offsetWidth);
    }
  }, [anchorEl]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          p: { xs: 0, md: 1 },
          pr: { xs: 1 },
          alignItems: 'center',
          border: (theme) => `1px solid ${theme.palette.separator}`,
          borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          backgroundColor: 'background.default',
          mr: { xs: 0, md: 1 },
          position: 'relative',
          userSelect: 'none',
          cursor: expandable ? 'pointer' : 'unset',
          ...sx,
        }}
        onClick={(e) => {
          if (expandable) {
            if (isOpened) {
              handleClose();
            } else {
              handleClick(e);
            }
          }
        }}
      >
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              backgroundColor: 'rgba(255,255,255,0.6)',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              zIndex: 1,
            }}
          >
            <CircularProgress sx={{ m: 'auto' }} />
          </Box>
        )}
        <Icon sx={{ mx: { xs: 1, md: 2 } }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', mr: 'auto' }}>
          <Typography variant="h6" color="secondary">
            {title}
          </Typography>

          <Typography color="secondary" sx={ValueSx}>
            {value}
          </Typography>
        </Box>

        {expandable && isOpened && <ExpandLess sx={{ mx: 2 }} />}
        {expandable && !isOpened && <ExpandMore sx={{ mx: 2 }} />}

        <Popper
          open={isOpened}
          anchorEl={anchorEl}
          sx={{
            width: `${searchResultsWidth}px`,
            backgroundColor: 'background.default',
            borderRadius: '16px',
            boxShadow: '0px 0px 0px 1.5px rgba(0, 0, 0, 0.08);',
            zIndex: 999999,
            p: 2,
            display: 'flex',
          }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ]}
        >
          {popperContentRenderer({ handleClose })}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
