import { connect } from 'react-redux';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import MissingEquipmentSaved from './MissingEquipmentSaved';

const mapStateToProps = (state) => {
  return {
    currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
  };
};

const mapDispatchToProps = {
  switchToNextVehicleFlowState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MissingEquipmentSaved);
