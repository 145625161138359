import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BreadcrumbsGeneratorContainer from '../../../common/components/BreadcrubmsGenerator/BreadcrumbsGenerator.container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Route, Switch } from 'react-router-dom';
import DealersListContainer from './List/List.container';
import WithAccessRightsRendererContainer from '../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import { ROUTING_PATH_NAMES } from '../../../common/constants/constants';
import CreateNewAldUserContainer from './CreateNewUser/CreateNewUser.container';
import { pathTester } from '../../../common/helpers/routing';
import DealerDetailsContainer from './DealerDetails/DealerDetails.container';
import AddIcon from '@mui/icons-material/Add';

function Dealers({
  t = () => {},
  redirectBackByBreadcrumb = () => {},
  redirectToAppDealersCreateNew = () => {},
  pathname,
}) {
  const isCreateDealerTabSelected = pathTester.isDealersCreateNew(pathname);
  const isDealerDetailsTabSelected =
    pathTester.isDealersDealerDetailsDealerInfo(pathname) ||
    pathTester.isDealersDealerDetailsDealerUsers(pathname) ||
    pathTester.isDealersDealerDetailsDealerUsersCreateNewUser(pathname) ||
    pathTester.isDealersDealerDetailsDealerUsersUserInfo(pathname);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 1, md: 2 },
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">
            {t('controlCategories.dealers.title')}
          </Typography>

          <BreadcrumbsGeneratorContainer />
        </Box>

        <Box sx={{ display: 'flex', ml: 'auto' }}>
          {!isCreateDealerTabSelected && !isDealerDetailsTabSelected && (
            <Button
              variant="contained"
              onClick={() => {
                redirectToAppDealersCreateNew();
              }}
              startIcon={<AddIcon />}
            >
              {t('controlCategories.dealers.createNewDealer')}
            </Button>
          )}

          {(isCreateDealerTabSelected || isDealerDetailsTabSelected) && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{ mr: 1 }}
              onClick={() => {
                redirectBackByBreadcrumb();
              }}
            >
              {t('common.buttons.back')}
            </Button>
          )}
        </Box>
      </Box>

      <Switch>
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewDealers'],
                    content: (
                      <>
                        <DealersListContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/${ROUTING_PATH_NAMES.CREATE_NEW}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canCreateDealers'],
                    content: (
                      <>
                        <CreateNewAldUserContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}/:dealerId`}
          render={({
            match: {
              params: { dealerId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewDealers'],
                    content: (
                      <>
                        <DealerDetailsContainer dealerId={dealerId} />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

export default withTranslation()(Dealers);
