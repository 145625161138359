import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  ROUTING_PATH_NAMES,
  ROUTING_URLS,
} from '../../../../common/constants/constants';
import { withTranslation } from 'react-i18next';

export const VEHICLES_LIST_TABS = {
  IN_USE_CARS: 0,
  NEW_BOOKED_CARS: 1,
  ALREADY_IN_PROCESS_CARS: 2,
  READY_FOR_SALES_CARS: 3,
  ASSESSMENT_APPROVES_CARS: 4,
};

function VehiclesListTabs({
  t = () => {},
  selectedTabId = VEHICLES_LIST_TABS.NEW_BOOKED_CARS,
}) {
  return (
    <Tabs
      value={selectedTabId}
      sx={{
        borderBottom: '1px solid #E0E0E0',
        mb: 1,
      }}
      variant="scrollable"
      scrollButtons="auto"
    >
      <Tab
        LinkComponent={RouterLink}
        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}`}
        id={VEHICLES_LIST_TABS.IN_USE_CARS}
        label={t('vehiclesList.tabs.customerLocationCars.title')}
      />

      <Tab
        LinkComponent={RouterLink}
        to={ROUTING_URLS.APP.VEHICLES_LIST__NEW_RETURNED_CARS}
        id={VEHICLES_LIST_TABS.NEW_BOOKED_CARS}
        label={t('vehiclesList.tabs.newReturnedCars.title')}
      />

      <Tab
        LinkComponent={RouterLink}
        to={ROUTING_URLS.APP.VEHICLES_LIST__ALREADY_IN_PROCESS_CARS}
        id={VEHICLES_LIST_TABS.ALREADY_IN_PROCESS_CARS}
        label={t('vehiclesList.tabs.alreadyInProcessCars.title')}
      />

      <Tab
        LinkComponent={RouterLink}
        to={ROUTING_URLS.APP.VEHICLES_LIST__READY_FOR_SALES_CARS}
        id={VEHICLES_LIST_TABS.READY_FOR_SALES_CARS}
        label={t('vehiclesList.tabs.readyForSalesCars.title')}
      />

      <Tab
        LinkComponent={RouterLink}
        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}`}
        id={VEHICLES_LIST_TABS.ASSESSMENT_APPROVES_CARS}
        label={t('common.titles.assessmentApprovesCars')}
      />
    </Tabs>
  );
}

export default withTranslation()(VehiclesListTabs);
