export const getAssessmentApprovalStateTitleByState = (
  assessmentApproveState,
  t = () => {},
) => {
  switch (assessmentApproveState) {
    case ASSESSMENT_APPROVE_STATES.APPROVED:
      return t('common.titles.approved');
    case ASSESSMENT_APPROVE_STATES.FORCE_APPROVED:
      return t('common.titles.forceApproved');
    case ASSESSMENT_APPROVE_STATES.DECLINED:
      return t('common.titles.declined');
    case ASSESSMENT_APPROVE_STATES.PENDING:
      return t('common.titles.pending');

    default:
      return t('common.titles.unknown');
  }
};

export const getAssessmentApprovalStateColorByState = (
  assessmentApproveState,
) => {
  switch (assessmentApproveState) {
    case ASSESSMENT_APPROVE_STATES.APPROVED:
    case ASSESSMENT_APPROVE_STATES.FORCE_APPROVED:
      return 'success';
    case ASSESSMENT_APPROVE_STATES.DECLINED:
      return 'error';
    case ASSESSMENT_APPROVE_STATES.PENDING:
      return 'warning';

    default:
      return `error`;
  }
};

export const ASSESSMENT_APPROVE_STATES = {
  PENDING: 0,
  APPROVED: 1,
  DECLINED: 2,
  FORCE_APPROVED: 3,
};
