import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { getStepColorByStepState } from '../HorizontalStepper/helpers';

function VerticalStepper({
  steps = [],
  doneStepColor = '#5664d2',
  activeStepColor = '#569ED2',
  todoStepColor = '#C3C8D3',
  sx = {},
  isSubStepsStepper = false,
  stepSeparatorRenderer,
  stepContentRenderer,
  stepOppositeContentRenderer,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
    >
      {steps.map((step, stepIndex) => {
        const stepColor = getStepColorByStepState(step.state, {
          doneStepColor,
          activeStepColor,
          todoStepColor,
        });

        let renderedStep = stepSeparatorRenderer && stepSeparatorRenderer(step);
        if (renderedStep) {
          renderedStep = React.cloneElement(renderedStep, {
            ...renderedStep.props,
            sx: { ...renderedStep.props.sx, zIndex: 1 },
          });
        }

        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
            }}
            key={stepIndex}
          >
            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                minWidth: 0,
              }}
            >
              {stepOppositeContentRenderer && stepOppositeContentRenderer(step)}
            </Box>

            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {isSubStepsStepper && stepIndex === 0 && (
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    margin: 'auto',
                    bottom: '50%',
                    height: '50%',
                    width: '0.5px',
                    backgroundColor: stepColor,
                  }}
                />
              )}

              {stepIndex !== 0 && (
                <div
                  style={{
                    position: 'absolute',
                    right: '50%',
                    bottom: '50%',
                    height: '100%',
                    width: '0.5px',
                    backgroundColor: stepColor,
                  }}
                />
              )}

              {!stepSeparatorRenderer && (
                <Box
                  sx={{
                    mx: 1,
                    zIndex: 1,
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    backgroundColor: stepColor,
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    borderRadius: '50%',
                  }}
                />
              )}

              {!!stepSeparatorRenderer && renderedStep}
            </Box>

            <Box
              sx={{
                flex: 1,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                minWidth: 0,
              }}
            >
              {!stepContentRenderer && (
                <Typography
                  sx={{
                    color: stepColor,
                    fontWeight: 400,
                    fontSize: '10px',
                    textAlign: 'center',
                    letterSpacing: '0.1px',
                  }}
                >
                  {step.label}
                </Typography>
              )}

              {stepContentRenderer && stepContentRenderer(step)}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

export default VerticalStepper;
