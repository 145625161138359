import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../../common/components/DataGrid/DataGrid';
import AddIcon from '@mui/icons-material/Add';
import { B2B_CUSTOMER_USERS_ROLES } from '../../../Customers/B2B/Details/Users/constants';

function CustomerUsers({
  t = () => {},
  customerId = '',
  customerUsers = [],
  getAllB2BCustomerUsers = () => {},
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewUser = () => {},
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersUserDetails = () => {},
}) {
  useEffect(async () => {
    try {
      await getAllB2BCustomerUsers(customerId);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const rows = customerUsers.map((u) => {
    return { ...u, address: (u.address && u.address.name) || '-' };
  });
  const columns = [
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'displayName',
      headerName: t('controlCategories.customers.b2b.details.users.name'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'email',
      headerName: t('controlCategories.customers.b2b.details.users.email'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'telephone',
      headerName: t(
        'controlCategories.customers.b2b.details.users.phoneNumber',
      ),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'address',
      headerName: t('controlCategories.customers.b2b.details.users.address'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'role',
      headerName: t('controlCategories.customers.b2b.details.users.role'),
      renderRowCell: ({ rowData: { role = '' } }) => {
        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              minWidth: '100px',
              px: 1,
            }}
          >
            <Typography>
              {role === B2B_CUSTOMER_USERS_ROLES.ADMIN
                ? t('controlCategories.customers.b2b.details.users.admin')
                : t('controlCategories.customers.b2b.details.users.user')}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ mb: 2, ml: 'auto' }}
        onClick={() => {
          redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersCreateNewUser(
            customerId,
          );
        }}
      >
        {t('common.titles.createNewUser')}
      </Button>

      <Box
        sx={{
          backgroundColor: 'background.default',
          flex: 1,
          width: '100%',
          borderRadius: '16px',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <CommonDataGrid
          rows={rows}
          columns={columns}
          checkboxSelection
          noDataToShowMessage={t('common.messages.noDataToShow')}
          selectRowHandler={(rowData) => {
            redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsersUserDetails(
              {
                customerId,
                customerUserId: rowData.id,
              },
            );
          }}
        />
      </Box>
    </Box>
  );
}

export default withTranslation()(CustomerUsers);
