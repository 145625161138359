import React, { useEffect } from 'react';
import StatisticRenderer from '../Today/StatisticRenderer/StatisticRenderer';

function LastMonthStatistic({
  statistic = {
    overviewOfReturn: [],
    overviewOfCars: [],
    overviewOfAssessment: [],
  },
  getDashboardLastMonthStatistic = () => {},
}) {
  useEffect(() => {
    getDashboardLastMonthStatistic();
  }, []);

  return <StatisticRenderer statistic={statistic} />;
}

export default LastMonthStatistic;
