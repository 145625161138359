import { connect } from 'react-redux';
import { getDashboardLastWeekStatistic } from '../../../../store/dashboard/slice';
import LastWeekStatistic from './LastWeek';

const mapStateToProps = (state) => {
  const { lastWeekStatistic } = state.dashboard;

  return {
    statistic: lastWeekStatistic,
  };
};

const mapDispatchToProps = { getDashboardLastWeekStatistic };

export default connect(mapStateToProps, mapDispatchToProps)(LastWeekStatistic);
