import { connect } from 'react-redux';
import { logout, passwordlessAuth } from '../../../store/auth/slice';
import {
  redirectToAppVehiclesList,
  redirectToAppVehiclesListCustomerCarsCarDetails,
} from '../../../store/redirect/slice';
import PasswordlessAuth from './PasswordlessAuth';

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

const mapDispatchToProps = {
  passwordlessAuth,
  redirectToAppVehiclesListCustomerCarsCarDetails,
  redirectToAppVehiclesList,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordlessAuth);
