import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import CommonDataGrid from '../../../../common/components/DataGrid/DataGrid';
import { withTranslation } from 'react-i18next';

function NAFSuppliers({
  t = () => {},
  suppliers = [],
  redirectToAppSuppliersNAFSupplierDetailsInfo = () => {},
  getAllSuppliers = () => {},
}) {
  useEffect(() => {
    getAllSuppliers();
  }, []);

  const columns = [
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'name',
      headerName: t('controlCategories.suppliers.naf.details.info.name'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'phone',
      headerName: t('controlCategories.suppliers.naf.details.info.phoneNumber'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'email',
      headerName: t('controlCategories.suppliers.naf.details.info.email'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'address',
      headerName: t('controlCategories.suppliers.naf.details.info.address'),
    },
  ];

  const rows = suppliers.map((supplier) => {
    return {
      ...supplier,
      name: supplier.name || '-',
      phone: (supplier.contactPerson && supplier.contactPerson.phone) || '-',
      email: (supplier.contactPerson && supplier.contactPerson.email) || '-',
      address: (supplier.address && supplier.address.name) || '-',
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CommonDataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        noDataToShowMessage={t('common.messages.noDataToShow')}
        selectRowHandler={(rowData) => {
          redirectToAppSuppliersNAFSupplierDetailsInfo(rowData.id);
        }}
      />
    </Box>
  );
}

export default withTranslation()(NAFSuppliers);
