import { connect } from 'react-redux';
import WithAccessRightsRenderer from './WithAccessRightsRenderer';

const mapStateToProps = (state) => ({
  userAccessRights: state.auth.user.accessRights,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WithAccessRightsRenderer);
