import {
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { StyledExpandersList } from '../../../../../../common/components/StyledExpandersList/StyledExpandersList';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';

function B2CCustomerInfo({ customer = null, t = () => {} }) {
  if (customer) {
    const {
      name = '-',
      contactPerson = {
        name: '-',
        email: '-',
      },
      customerNo = '-',
      customerTypeBrandingName = '-',
      address = {
        name: '-',
      },
    } = customer;
    const [isCustomerInfoOpened, setCustomerInfoOpened] = useState(true);

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <StyledExpandersList>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor: isCustomerInfoOpened
                  ? 'primary.main'
                  : 'background.default',
                color: isCustomerInfoOpened
                  ? 'background.default'
                  : 'text.primary',
                ':hover': {
                  backgroundColor: isCustomerInfoOpened
                    ? 'primary.main'
                    : 'background.default',
                },
              }}
              onClick={() => {
                setCustomerInfoOpened(!isCustomerInfoOpened);
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: isCustomerInfoOpened
                    ? '#ffffff'
                    : 'primary.main',
                  color: isCustomerInfoOpened ? 'primary.main' : '#ffffff',
                }}
              >
                <InfoOutlinedIcon />
              </ListItemIcon>

              <ListItemText
                primary={t(
                  'controlCategories.customers.b2b.details.customerInfo',
                )}
                primaryTypographyProps={{
                  fontWeight: 400,
                }}
              />

              <ListItemIcon className="ExpandIcon">
                {isCustomerInfoOpened ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>

            <Collapse in={isCustomerInfoOpened}>
              <Box className="CollapseContent">
                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('controlCategories.customers.b2b.customerName')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {name}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.customers.b2b.details.customerNumber',
                    )}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {customerNo}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.customers.b2b.details.customerTypeBrandingName',
                    )}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {customerTypeBrandingName}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('controlCategories.customers.b2b.details.address')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {address.name}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('controlCategories.customers.b2b.details.email')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {contactPerson.email}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </ListItem>
        </StyledExpandersList>
      </Box>
    );
  }

  return <></>;
}

export default withTranslation()(B2CCustomerInfo);
