import { Box } from '@mui/system';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ImageLoader from '../ImageLoader/ImageLoader';

function GalleryCarousel({ uploadIds = [], sx = {}, showThumbs = false }) {
  return (
    <Box
      sx={{
        ...sx,
        '& .carousel-root': {
          height: '100%',

          '& .carousel': {
            height: '100%',

            '& .control-arrow': {
              opacity: 1,
              background: 'rgba(0,0,0,0.2)',
            },
            '& .slider-wrapper': {
              height: '100%',

              '& .slider': {
                height: '100%',
              },
            },
          },
        },
        position: 'relative',
      }}
    >
      <Carousel showThumbs={showThumbs}>
        {uploadIds.map((uploadId, index) => {
          return <ImageLoader key={index} uploadId={uploadId} />;
        })}
      </Carousel>
    </Box>
  );
}

export default GalleryCarousel;
