import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CommonFormTitle from '../../../../../common/components/FormTitle/FormTitle';
import DeltaArrowIcon from '../../../../../icons/DeltaArrow';

function StatisticDataCell({ title = '', value = 0, delta = 0, index }) {
  return (
    /* eslint-disable react/jsx-fragments */
    <React.Fragment>
      {index !== 0 && (
        <Box
          sx={{
            height: '60%',
            flex: '0 0 1px',
            backgroundColor: '#e0e0e0',
          }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '1',
          alignItems: 'center',
        }}
      >
        <Typography
          color="secondary"
          sx={{ fontSize: '13px', textAlign: 'center' }}
        >
          {title.toUpperCase()}
        </Typography>

        <Typography color="primary" sx={{ fontSize: '35px' }}>
          {value}
        </Typography>

        {delta && (
          <Typography
            color={delta < 0 ? 'error.main' : 'success.main'}
            sx={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}
          >
            {`${delta.toFixed(1)} %`}
            <DeltaArrowIcon
              sx={{
                width: '18px',
                height: '18px',
                transform: delta < 0 ? 'rotate(180deg)' : 'none',
              }}
            />
          </Typography>
        )}
      </Box>
    </React.Fragment>
  );
}

function StatisticRenderer({
  t = () => {},
  statistic = {
    overviewOfReturn: [],
    overviewOfCars: [],
    overviewOfAssessment: [],
  },
}) {
  const { overviewOfReturn, overviewOfCars, overviewOfAssessment } = statistic;

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          flex: 1,
          borderRadius: '16px',
          backgroundColor: 'background.default',
          mb: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CommonFormTitle
          text={t('dashboard.overviewOfReturn')}
          sx={{ flex: '0 0 55px' }}
        />
        <Divider />
        <Box
          sx={{
            flex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            py: 1,
          }}
        >
          {overviewOfReturn.map(({ title, value, delta }, index) => {
            return (
              <StatisticDataCell
                title={title}
                value={value}
                delta={delta}
                index={index}
                key={index}
              />
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          borderRadius: '16px',
          backgroundColor: 'background.default',
          mb: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CommonFormTitle
          text={t('dashboard.overviewOfCars')}
          sx={{ flex: '0 0 55px' }}
        />
        <Divider />
        <Box
          sx={{
            flex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            py: 1,
          }}
        >
          {overviewOfCars.map(({ title, value, delta }, index) => {
            return (
              <StatisticDataCell
                title={title}
                value={value}
                delta={delta}
                index={index}
                key={index}
              />
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          borderRadius: '16px',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CommonFormTitle
          text={t('dashboard.overviewOfAssessment')}
          sx={{ flex: '0 0 55px' }}
        />
        <Divider />
        <Box
          sx={{
            flex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            py: 1,
          }}
        >
          {overviewOfAssessment.map(({ title, value, delta }, index) => {
            return (
              <StatisticDataCell
                title={title}
                value={value}
                delta={delta}
                index={index}
                key={index}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default withTranslation()(StatisticRenderer);
