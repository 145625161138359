import { connect } from 'react-redux';
import DealerInfo from './Info';

const mapStateToProps = (state, ownProps) => {
  const {
    dealers: { dealers },
  } = state;
  const { dealerId } = ownProps;
  const dealer = dealers.find((u) => u.id === dealerId);

  return {
    dealer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DealerInfo);
