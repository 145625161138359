import { connect } from 'react-redux';
import {
  redirectBackByBreadcrumb,
  redirectToAppSuppliersNAFCreateNew,
  redirectToAppSuppliersWashCreateNew,
  redirectToAppSuppliersWorkshopCreateNew,
} from '../../../store/redirect/slice';
import Suppliers from './Suppliers';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  redirectBackByBreadcrumb,
  redirectToAppSuppliersWashCreateNew,
  redirectToAppSuppliersNAFCreateNew,
  redirectToAppSuppliersWorkshopCreateNew,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers);
