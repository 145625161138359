class TimeoutProcessor {
  constructor() {
    this.tasks = new Map();
  }

  enqueueTask(key, delay, fn) {
    let timeout = this.tasks.get(key) || null;
    if (timeout !== null) {
      clearTimeout(timeout);
      this.tasks.delete(key);
    }
    timeout = setTimeout(fn, delay);
    this.tasks.set(key, timeout);
  }

  dequeueTask(key) {
    this.tasks.delete(key);
  }
}

export const timeoutProcessor = new TimeoutProcessor();
