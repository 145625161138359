import { ROUTING_PATH_NAMES } from '../../constants/constants';

export const getPageBreadcrumbsByLocationPath = (path = '', t = () => {}) => {
  const pathSplit = path
    .replace('/app', '')
    .split('/')
    .filter((str) => !!str);

  const result = [];
  for (let i = 0; i < pathSplit.length; i++) {
    const pathChapter = pathSplit[i];
    result.push(
      getBreadcrumbTranslatedTextRepresentation(
        pathChapter,
        i - 1 >= 0 ? pathSplit[i - 1] : null,
        t,
      ),
    );
  }
  return result.filter((b) => !!b);
};

const getBreadcrumbTranslatedTextRepresentation = (
  breadcrumb = '',
  previousBreadcrumb = '',
  t = () => {},
) => {
  switch (breadcrumb) {
    case ROUTING_PATH_NAMES.DASHBOARD:
      return t('dashboard.title');
    case ROUTING_PATH_NAMES.TODAY:
      return t('dashboard.today');
    case ROUTING_PATH_NAMES.LAST_WEEK:
      return t('dashboard.last7Days');
    case ROUTING_PATH_NAMES.LAST_MONTH:
      return t('dashboard.last30Days');

    case ROUTING_PATH_NAMES.ALD_USERS:
      return t('aldUsers.title');
    case ROUTING_PATH_NAMES.ALD_USER_INFO:
      return t('aldUsers.userInfo');
    case ROUTING_PATH_NAMES.CREATE_NEW_ALD_USER:
      return t('aldUsers.create.title');

    case ROUTING_PATH_NAMES.VEHICLES_LIST:
      return t('vehiclesList.title');
    case ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS:
      return t('vehiclesList.tabs.customerLocationCars.title');
    case ROUTING_PATH_NAMES.NEW_BOOKED_CARS:
      return t('vehiclesList.tabs.newReturnedCars.title');
    case ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS:
      return t('vehiclesList.tabs.alreadyInProcessCars.title');
    case ROUTING_PATH_NAMES.READY_FOR_SALES_CARS:
      return t('vehiclesList.tabs.readyForSalesCars.title');
    case ROUTING_PATH_NAMES.ASSESSMENT_APPROVES:
      return t('common.titles.assessmentApprovesCars');
    case ROUTING_PATH_NAMES.ADD_NEW_CAR:
      return t('vehiclesList.createNewCar.title');
    case ROUTING_PATH_NAMES.CAR_DETAILS:
      return t('vehiclesList.carDetails.title');
    case ROUTING_PATH_NAMES.EDIT_ASSESSMENT:
      return 'Edit assessment';

    case ROUTING_PATH_NAMES.CONTROL_CATEGORIES:
      return t('controlCategories.title');

    case ROUTING_PATH_NAMES.DEALERS:
      return t('controlCategories.dealers.title');

    case ROUTING_PATH_NAMES.CUSTOMERS:
      return t('controlCategories.customers.title');
    case ROUTING_PATH_NAMES.B2C:
      return t('controlCategories.customers.b2c.title');
    case ROUTING_PATH_NAMES.B2B:
      return t('controlCategories.customers.b2b.title');
    case ROUTING_PATH_NAMES.RENTAL:
      return t('controlCategories.customers.rental.title');
    case ROUTING_PATH_NAMES.FLEX:
      return t('controlCategories.customers.flex.title');
    case ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER:
      return t('controlCategories.customers.createNewCustomer');
    case ROUTING_PATH_NAMES.CUSTOMER_INFO:
      return t('controlCategories.customers.b2b.details.customerInfo');
    case ROUTING_PATH_NAMES.USERS:
      return t('controlCategories.customers.b2b.details.users.title');
    case ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER_ADMIN_USER:
      return t(
        'controlCategories.customers.b2b.details.users.createNewAdmin.title',
      );

    case ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS:
      return t('controlCategories.customers.title');

    case ROUTING_PATH_NAMES.SUPPLIERS:
      return t('controlCategories.suppliers.title');
    case ROUTING_PATH_NAMES.SUPPLIER_INFO:
      return t('controlCategories.suppliers.supplierInfo');
    case ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER:
      return t('controlCategories.suppliers.createNewSupplier');
    case ROUTING_PATH_NAMES.CREATE_NEW_SUPPLIER_USER:
      return t('common.titles.createNew');
    case ROUTING_PATH_NAMES.WASH:
      return t('controlCategories.suppliers.wash.title');
    case ROUTING_PATH_NAMES.NAF:
      return t('controlCategories.suppliers.naf.title');
    case ROUTING_PATH_NAMES.WORKSHOP:
      return t('controlCategories.suppliers.workshop.title');

    case ROUTING_PATH_NAMES.CREATE_NEW:
      return t('common.titles.createNew');
    case ROUTING_PATH_NAMES.CREATE_NEW_ADMIN:
      return t('common.titles.createNewAdmin');
    case ROUTING_PATH_NAMES.CREATE_NEW_USER:
      return t('common.titles.createNewUser');
    case ROUTING_PATH_NAMES.INFO:
      return t('common.titles.info');

    default: {
      if (previousBreadcrumb === ROUTING_PATH_NAMES.DEALERS)
        return t('controlCategories.dealers.dealerDetails');

      if (previousBreadcrumb === ROUTING_PATH_NAMES.B2B)
        return t('controlCategories.customers.customerDetails');
      if (previousBreadcrumb === ROUTING_PATH_NAMES.USERS)
        return t(
          'controlCategories.customers.b2b.details.users.details.userDetails',
        );

      if (previousBreadcrumb === ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS)
        return t('common.titles.customerDetails');

      if (previousBreadcrumb === ROUTING_PATH_NAMES.WASH)
        return t('controlCategories.suppliers.supplierDetails');
      if (previousBreadcrumb === ROUTING_PATH_NAMES.NAF)
        return t('controlCategories.suppliers.supplierDetails');
      if (previousBreadcrumb === ROUTING_PATH_NAMES.WORKSHOP)
        return t('controlCategories.suppliers.supplierDetails');

      return null;
    }
  }
};
