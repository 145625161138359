import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BreadcrumbsGeneratorContainer from '../../../common/components/BreadcrubmsGenerator/BreadcrumbsGenerator.container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Route, Switch } from 'react-router-dom';
import B2BAdminCustomersListContainer from './List/List.container';
import WithAccessRightsRendererContainer from '../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import { ROUTING_PATH_NAMES } from '../../../common/constants/constants';
import { pathTester } from '../../../common/helpers/routing';
import CustomerDetailsContainer from './Details/Details.container';

function B2BAdminCustomers({
  t = () => {},
  redirectBackByBreadcrumb = () => {},
  pathname,
}) {
  const isB2BAdminCustomersCustomerDetails =
    pathTester.isB2BAdminCustomersCustomerDetails(pathname);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 1, md: 2 },
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">
            {t('controlCategories.customers.title')}
          </Typography>

          <BreadcrumbsGeneratorContainer />
        </Box>

        <Box sx={{ display: 'flex', ml: 'auto' }}>
          {isB2BAdminCustomersCustomerDetails && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{ mr: 1 }}
              onClick={() => {
                redirectBackByBreadcrumb();
              }}
            >
              {t('common.buttons.back')}
            </Button>
          )}
        </Box>
      </Box>

      <Switch>
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewCustomerUsers'],
                    content: (
                      <>
                        <B2BAdminCustomersListContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}/:customerId`}
          render={({
            match: {
              params: { customerId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewCustomerUsers'],
                    content: (
                      <>
                        <CustomerDetailsContainer customerId={customerId} />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

export default withTranslation()(B2BAdminCustomers);
