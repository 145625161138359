import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../../../store/appCommon/slice';
import { pickUpAssessmentRepair } from '../../../../../store/suppliers/slice';
import SupplierPickupAssessment from './SupplierPickupAssessment';

const mapStateToProps = (state) => {
  const { damageTypesLookup } = state.damageTypes;
  const { groupsLookup } = state.damageTypeGroups;
  return {
    damageTypes: damageTypesLookup,
    damageTypeGroups: groupsLookup,
  };
};

const mapDispatchToProps = {
  pickUpAssessmentRepair,
  setFsUploadIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupplierPickupAssessment);
