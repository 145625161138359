import { connect } from 'react-redux';
import { SUPPLIERS_TYPES } from '../../../../common/constants/suppliers';
import { redirectToAppSuppliersWorkshopSupplierDetailsInfo } from '../../../../store/redirect/slice';
import { getAllSuppliers } from '../../../../store/suppliers/slice';
import WorkshopSuppliers from './Workshop';

const mapStateToProps = (state) => ({
  suppliers: state.suppliers.suppliers.filter(
    (s) => s.supplierType === SUPPLIERS_TYPES.WORKSHOP,
  ),
});

const mapDispatchToProps = {
  redirectToAppSuppliersWorkshopSupplierDetailsInfo,
  getAllSuppliers,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopSuppliers);
