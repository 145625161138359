import { createSlice } from '@reduxjs/toolkit';
import { CAR_STATES } from '../../App/MainLayout/VehiclesList/AlreadyInProcessCars/constants';
import { api } from '../../core/api';
import {
  ASSESSMENT_SUPPLIER_STATUS,
  NAF_ERROR_TYPES,
  NAF_POINT_STATUSES,
  SUPPLIER_CONFIRM_STATUS,
} from '../../common/constants/assessment';
import { timeoutProcessor } from '../../utils/timeoutProcessor';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: {
    vehicles: [],
    nafAssessments: {},
    aldAssessments: {},
    flowStates: [],
    currentVehicle: undefined,
    currentVehicleFlowState: {},
    currentVehicleQRSrc: null,
    currentVehicleQRLoadingInProcess: false,
    contractsImages: {},
    prefetchedByQRVehicle: null,
    vehiclesStateLogs: {},
    returnInfos: {},
    repairStatuses: {},
    customerInfos: {},
    contracts: {},
    salesChannelEnums: [],
    accessories: {},
  },
  reducers: {
    _vehicleFetchSucceeded: (state, action) => {
      state.vehicle = state.vehicles.map((x, index) => {
        if (x.id === action.payload.id) {
          return {
            ...action.payload,
            status: index % 3 === 0 ? CAR_STATES.READY : CAR_STATES.IN_PROGRESS,
          };
        }
        return x;
      });
    },
    _setCurrentVehicle: (state, action) => {
      state.currentVehicle = action.payload;
    },
    _vehicleFlowStatesFetchSucceeded: (state, action) => {
      state.flowStates = action.payload;
    },
    _vehicleFlowStateFetchSucceeded: (state, action) => {
      state.currentVehicleFlowState = action.payload;
    },
    _vehicleFlowStatesLogsFetchSucceeded: (state, action) => {
      const { logs, vehicleId } = action.payload;

      state.vehiclesStateLogs = {
        ...state.vehiclesStateLogs,
        [vehicleId]: logs,
      };
    },
    _vehicleNafAssessmentFetchSucceeded: (state, action) => {
      const { vehicleId, assessment } = action.payload;
      state.nafAssessments[vehicleId] = assessment;
    },
    _vehicleAldAssessmentFetchSucceeded: (state, action) => {
      const { vehicleId, assessment } = action.payload;
      state.aldAssessments[vehicleId] = assessment;
    },

    _vehicleQRFetchStarted: (state) => {
      state.currentVehicleQRSrc = null;
      state.currentVehicleQRLoadingInProcess = true;
    },
    _vehicleQRFetchSucceeded: (state, action) => {
      state.currentVehicleQRSrc = URL.createObjectURL(action.payload);
      state.currentVehicleQRLoadingInProcess = false;
    },
    _vehicleFetchByQRSucceeded: (state, action) => {
      state.vehicles = [...state.vehicles, action.payload];
      state.prefetchedByQRVehicle = action.payload;
    },
    _vehicleQRCleared: (state) => {
      state.currentVehicleQRSrc = null;
    },
    _vehicleContractImagesFetched: (state, action) => {
      const { imagesIds, contractId } = action.payload;
      state.contractsImages[contractId] = imagesIds;
    },
    _vehicleCustomerInfoFetchSucceeded: (state, action) => {
      const { vehicleId, customer } = action.payload;
      state.customerInfos[vehicleId] = customer;
    },
    _vehicleContractInfoFetchSucceeded: (state, action) => {
      const { vehicleId, contract } = action.payload;
      state.contracts[vehicleId] = contract;
    },
    _assessmentSupplierStatusChanged: (state, action) => {
      const { vehicleId, repairId, supplierConfirmStatus, supplierId } =
        action.payload;
      const assessment = state.aldAssessments[vehicleId];
      const repair = assessment.repairs.find((x) => x.id === repairId);
      repair.supplierConfirmStatus = supplierConfirmStatus;
      repair.supplierId = supplierId;
    },
    _vehicleReturnInfoFetchSucceeded: (state, action) => {
      const { vehicleId, returnInfo } = action.payload;
      state.returnInfos[vehicleId] = returnInfo;
    },
    _vehicleRepairStatusesFetchSucceeded: (state, action) => {
      const { vehicleId, repairStatuses } = action.payload;
      state.repairStatuses[vehicleId] = repairStatuses;
    },
    _assessmentRepairCreated: (state, action) => {
      const { vehicleId, repair } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      assessment.repairs.push(repair);
    },
    _assessmentRepairUpdated: (state, action) => {
      const { vehicleId, repair } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      assessment.repairs = assessment.repairs.map((x) =>
        x.id === repair.id ? repair : x,
      );
    },
    _assessmentRepairDeleted: (state, action) => {
      const { vehicleId, repairId } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      assessment.repairs = assessment.repairs.filter((x) => x.id !== repairId);
    },
    _assessmentSheetCreated: (state, action) => {
      const { vehicleId, repairId, sheet } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      const repair = assessment.repairs.find((x) => x.id === repairId);
      repair.assessmentSheets.push(sheet);
    },
    _assessmentSheetUpdated: (state, action) => {
      const { vehicleId, repairId, sheet } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      const repair = assessment.repairs.find((x) => x.id === repairId);
      repair.assessmentSheets = repair.assessmentSheets.map((x) =>
        x.id === sheet.id ? sheet : x,
      );
    },
    _assessmentSheetDeleted: (state, action) => {
      const { vehicleId, repairId, sheetId } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      const repair = assessment.repairs.find((x) => x.id === repairId);
      repair.assessmentSheets = repair.assessmentSheets.filter(
        (x) => x.id !== sheetId,
      );
    },
    _assessmentDamageDetailCreated: (state, action) => {
      const { vehicleId, repairId, sheetId, detail } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      const repair = assessment.repairs.find((x) => x.id === repairId);
      const sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
      sheet.damageDetails.push(detail);
    },
    _assessmentDamageDetailUpdated: (state, action) => {
      const { vehicleId, repairId, sheetId, detail } = action.payload;
      console.error(action.payload);
      const assessment = state.aldAssessments[vehicleId];
      const repair = assessment.repairs.find((x) => x.id === repairId);
      const sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
      sheet.damageDetails = sheet.damageDetails.map((x) =>
        x.id === detail.id ? detail : x,
      );
    },
    _assessmentDamageDetailDeleted: (state, action) => {
      const { vehicleId, repairId, sheetId, detailId } = action.payload;
      const assessment = state.aldAssessments[vehicleId];
      const repair = assessment.repairs.find((x) => x.id === repairId);
      const sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
      sheet.damageDetails = sheet.damageDetails.filter(
        (x) => x.id !== detailId,
      );
    },
    _salesChannelEnumsFetchSucceeded: (state, action) => {
      state.salesChannelEnums = action.payload;
    },
    _vehicleAccessoriesFetchSucceeded: (state, action) => {
      const { vehicleId, accessories } = action.payload;
      state.accessories[vehicleId] = accessories;
    },
  },
});

export const {
  _vehiclesFetchSucceeded,
  _vehicleFetchSucceeded,
  _setCurrentVehicle,
  _vehicleFlowStatesFetchSucceeded,
  _vehicleFlowStateFetchSucceeded,
  _vehicleFlowStatesLogsFetchSucceeded,
  _vehicleQRFetchSucceeded,
  _vehicleQRFetchStarted,
  _vehicleFetchByQRSucceeded,
  _vehicleQRCleared,
  _vehicleNafAssessmentFetchSucceeded,
  _vehicleAldAssessmentFetchSucceeded,
  _vehicleContractImagesFetched,
  _vehicleCustomerInfoFetchSucceeded,
  _vehicleContractInfoFetchSucceeded,
  _assessmentSupplierStatusChanged,
  _assessmentRepairCreated,
  _assessmentRepairUpdated,
  _assessmentRepairDeleted,
  _assessmentSheetCreated,
  _assessmentSheetUpdated,
  _assessmentSheetDeleted,
  _assessmentDamageDetailCreated,
  _assessmentDamageDetailUpdated,
  _assessmentDamageDetailDeleted,
  _vehicleReturnInfoFetchSucceeded,
  _vehicleRepairStatusesFetchSucceeded,
  _salesChannelEnumsFetchSucceeded,
  _vehicleAccessoriesFetchSucceeded,
} = vehiclesSlice.actions;

export const vehiclesReducer = vehiclesSlice.reducer;

export const getVehicles =
  (offset, limit, stateEnumsArray, customerId, plateNo, sortCar, sort) =>
  async () => {
    const response = await api.vehicles.getAll(
      offset,
      limit,
      stateEnumsArray,
      customerId,
      plateNo,
      sortCar,
      sort,
    );
    return response;
  };

export const getVehicleFlowPossibleStates = () => async (dispatch) => {
  try {
    const response = await api.vehicles.getAllFlowStates();
    dispatch(_vehicleFlowStatesFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getVehicleFlowState =
  (vehicleId, contractId) => async (dispatch) => {
    try {
      const response = await api.vehicles.getVehicleFlowState(
        vehicleId,
        contractId,
      );
      dispatch(_vehicleFlowStateFetchSucceeded(response));
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const getVehicleFlowStatesLogs =
  (vehicleId, contractId) => async (dispatch) => {
    try {
      const response = await api.vehicles.getVehicleFlowStatesLogs(
        vehicleId,
        contractId,
      );
      dispatch(
        _vehicleFlowStatesLogsFetchSucceeded({
          vehicleId,
          logs: response,
        }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const getVehicleNafAssessment = (vehicleId) => async (dispatch) => {
  try {
    const response = await api.vehicles.getNafAssessmentByCarId(vehicleId);
    dispatch(
      _vehicleNafAssessmentFetchSucceeded({ vehicleId, assessment: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getVehicleAldAssessment = (vehicleId) => async (dispatch) => {
  try {
    const response = await api.vehicles.getAldAssessmentByCarId(vehicleId);
    dispatch(
      _vehicleAldAssessmentFetchSucceeded({ vehicleId, assessment: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const forceApproveAssessment =
  (vehicleId, assessmentId) => async (dispatch) => {
    const response = await api.assessments.forceApprove({ assessmentId });
    if (response.error) {
      console.error(response);
      return;
    }
    await dispatch(getVehicleAldAssessment(vehicleId));
  };

export const acceptAssessment =
  (vehicleId, assessmentId) => async (dispatch) => {
    try {
      await api.assessments.approve({ assessmentId });
      await dispatch(getVehicleAldAssessment(vehicleId));
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const declineAssessment =
  (vehicleId, assessmentId) => async (dispatch) => {
    try {
      await api.assessments.decline({ assessmentId });
      dispatch(getVehicleAldAssessment(vehicleId));
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const switchToNextVehicleFlowState =
  (vehicleId, currentStateId, nextStateEnum) => async () => {
    try {
      await api.vehicles.switchToNextFlowState(
        vehicleId,
        currentStateId,
        nextStateEnum,
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const getVehicleQR = (vehicleId, contractId) => async (dispatch) => {
  try {
    dispatch(_vehicleQRFetchStarted());
    const response = await api.vehicles.getVehicleQR(vehicleId, contractId);
    dispatch(_vehicleQRFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const updateVehicleAccessories =
  (vehicleId, accessories) => async (dispatch) => {
    try {
      await api.vehicles.updateVehicleAccessories(vehicleId, accessories);
      await dispatch(getVehicleById(vehicleId));
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const getVehicleAccessories = (vehicleId) => async (dispatch) => {
  try {
    const response = await api.vehicles.getVehicleAccessories(vehicleId);
    await dispatch(
      _vehicleAccessoriesFetchSucceeded({
        vehicleId,
        accessories: response.accessories,
      }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getVehicleById = (vehicleId) => async (dispatch) => {
  const response = await api.vehicles.getById(vehicleId);
  await dispatch(_vehicleFetchSucceeded(response));
  return response;
};

export const getVehicleByQRId = (qrId) => async (dispatch) => {
  try {
    const response = await api.vehicles.getVehicleByQRId(qrId);
    dispatch(_vehicleFetchByQRSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const clearVehicleQR = () => async (dispatch) => {
  dispatch(_vehicleQRCleared());
};

export const getVehicleCustomerInfo = (vehicleId) => async (dispatch) => {
  try {
    const customerResponse = await api.vehicles.getVehicleCustomerInfo(
      vehicleId,
    );

    dispatch(
      _vehicleCustomerInfoFetchSucceeded({
        vehicleId,
        customer: customerResponse,
      }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getVehicleContractInfo = (vehicleId) => async (dispatch) => {
  try {
    const contractResponse = await api.vehicles.getVehicleContractInfo(
      vehicleId,
    );

    dispatch(
      _vehicleContractInfoFetchSucceeded({
        vehicleId,
        contract: contractResponse,
      }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getContractImages = (contractId) => async (dispatch) => {
  try {
    const response = await api.contracts.getAllContractImages(contractId);
    dispatch(
      _vehicleContractImagesFetched({ contractId, imagesIds: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const addImageToContract =
  (imageFile, contractId, contractCarImageType, onUploadProgress = () => {}) =>
  async () => {
    try {
      const uploadResponse = await api.upload.file(imageFile, onUploadProgress);
      await api.contracts.addImageToContract(
        contractId,
        uploadResponse.id,
        contractCarImageType,
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const removeImageFromContract = (uploadId) => async () => {
  try {
    await api.contracts.removeImageFromContract(uploadId);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const updateVehicleMileage = (vehicleId, data) => async () => {
  try {
    await api.vehicles.updateVehicleMileage(vehicleId, data);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const resendAssessmentInfo = (vehicleId) => async () => {
  try {
    await api.assessment.resendUpdate(vehicleId);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const assignToSupplierGroup =
  (vehicleId, repairId) => async (dispatch) => {
    try {
      await api.assessment.confirmSupplier({
        id: repairId,
        supplierId: null,
        supplierConfirmStatus: SUPPLIER_CONFIRM_STATUS.WAITING_FOR_CONFIRMATION,
      });
      dispatch(
        _assessmentSupplierStatusChanged({
          vehicleId,
          repairId,
          supplierConfirmStatus:
            SUPPLIER_CONFIRM_STATUS.WAITING_FOR_CONFIRMATION,
          supplierId: null,
        }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const unassignSupplier = (vehicleId, repairId) => async (dispatch) => {
  try {
    await api.assessment.confirmSupplier({
      id: repairId,
      supplierId: null,
      supplierConfirmStatus: SUPPLIER_CONFIRM_STATUS.NOT_CONFIRMED,
    });
    dispatch(
      _assessmentSupplierStatusChanged({
        vehicleId,
        repairId,
        supplierConfirmStatus: SUPPLIER_CONFIRM_STATUS.NOT_CONFIRMED,
        supplierId: null,
      }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const assignSupplier =
  (vehicleId, repairId, supplierId) => async (dispatch) => {
    try {
      await api.assessment.confirmSupplier({
        id: repairId,
        supplierId,
        supplierConfirmStatus: SUPPLIER_CONFIRM_STATUS.CONFIRMED,
      });
      dispatch(
        _assessmentSupplierStatusChanged({
          vehicleId,
          repairId,
          supplierConfirmStatus: SUPPLIER_CONFIRM_STATUS.CONFIRMED,
          supplierId,
        }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const createNewRepair =
  (vehicleId, assessmentId, damageTypeGroupId) => async (dispatch) => {
    const response = await api.assessment.repairs.create(
      assessmentId,
      damageTypeGroupId,
    );
    if (response.error) {
      throw new Error(response.errors.message);
    }

    // should be deleted after back-end fix
    dispatch(getVehicleAldAssessment(vehicleId));
    // dispatch(_assessmentRepairCreated({ vehicleId, repair: response }));
  };

export const deleteRepair = (vehicleId, repairId) => async (dispatch) => {
  const response = await api.assessment.repairs.delete(repairId);
  if (response.error) {
    throw new Error(response.errors.message);
  }
  dispatch(_assessmentRepairDeleted({ vehicleId, repairId }));
};

export const createNewSheet =
  (vehicleId, repairId, damageTypeId, measurements) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const assessment = state.vehicles.aldAssessments[vehicleId];

      const response = await api.assessment.sheets.create({
        contractCarAssessmentId: assessment.id,
        damageTypeId,
        measurements,
        pointStatus: NAF_POINT_STATUSES.NEED_REPAIR,
        images: [],
      });

      dispatch(
        _assessmentSheetCreated({
          vehicleId,
          repairId,
          sheet: response,
        }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const deleteSheet =
  (vehicleId, repairId, sheetId) => async (dispatch) => {
    try {
      await api.assessment.sheets.delete(sheetId);
      dispatch(_assessmentSheetDeleted({ vehicleId, repairId, sheetId }));
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const updateSheet = (vehicleId, repairId, sheet) => async (dispatch) => {
  try {
    let response = await api.assessment.sheets.update(sheet.id, sheet);
    response = await api.assessment.sheets.get(sheet.id);
    dispatch(_assessmentSheetUpdated({ vehicleId, repairId, sheet: response }));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const addImageToSheet =
  (vehicleId, repairId, sheetId, imageFile) => async (dispatch) => {
    try {
      const upload = await api.upload.file(imageFile);
      await api.assessment.sheets.images.add(sheetId, upload.id);
      const sheet = await api.assessment.sheets.get(sheetId);
      dispatch(_assessmentSheetUpdated({ vehicleId, repairId, sheet }));
    } catch (e) {
      if (e instanceof HttpRequestClientError) {
        throw e;
      }
    }
  };

export const removeImageFromSheet =
  (vehicleId, repairId, sheetId, uploadId) => async (dispatch) => {
    try {
      await api.assessment.sheets.images.remove(sheetId, uploadId);
      const sheet = await api.assessment.sheets.get(sheetId);
      dispatch(_assessmentSheetUpdated({ vehicleId, repairId, sheet }));
    } catch (e) {
      if (e instanceof HttpRequestClientError) {
        throw e;
      }
    }
  };

export const addCommentToSheet =
  (vehicleId, repairId, sheetId, text) => async (dispatch) => {
    try {
      await api.assessment.sheets.comments.add(sheetId, text);
      const sheet = await api.assessment.sheets.get(sheetId);
      dispatch(_assessmentSheetUpdated({ vehicleId, repairId, sheet }));
    } catch (e) {
      if (e instanceof HttpRequestClientError) {
        throw e;
      }
    }
  };

export const removeCommentFromSheet =
  (vehicleId, repairId, sheetId, commentId) => async (dispatch) => {
    try {
      await api.assessment.sheets.comments.remove(commentId);
      const sheet = await api.assessment.sheets.get(sheetId);
      dispatch(_assessmentSheetUpdated({ vehicleId, repairId, sheet }));
    } catch (e) {
      if (e instanceof HttpRequestClientError) {
        throw e;
      }
    }
  };

export const delayedUpdateSheet =
  (vehicleId, repairId, sheet) => async (dispatch) => {
    timeoutProcessor.enqueueTask(sheet.id, 2000, () =>
      api.assessment.sheets.update(sheet.id, sheet),
    );
    dispatch(_assessmentSheetUpdated({ vehicleId, repairId, sheet }));
  };

export const changeSheetMeasurements =
  (vehicleId, repairId, sheetId, measurements) =>
  async (dispatch, getState) => {
    const assessment = getState().vehicles.aldAssessments[vehicleId];
    const repair = assessment.repairs.find((x) => x.id === repairId);
    let sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
    sheet = JSON.parse(JSON.stringify(sheet));
    sheet.measurements = measurements;
    await dispatch(delayedUpdateSheet(vehicleId, repairId, sheet));
  };

export const changeSheetDamageType =
  (vehicleId, repairId, sheetId, damageTypeId) =>
  async (dispatch, getState) => {
    const assessment = getState().vehicles.aldAssessments[vehicleId];
    const repair = assessment.repairs.find((x) => x.id === repairId);
    let sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
    sheet = JSON.parse(JSON.stringify(sheet));
    sheet.damageTypeId = damageTypeId;
    await dispatch(updateSheet(vehicleId, repairId, sheet));
  };

export const createNewDamageDetail =
  (vehicleId, repairId, sheetId, name, price) => async (dispatch) => {
    try {
      const response = await api.assessment.damageDetails.create({
        contractCarAssessmentSheetId: sheetId,
        name,
        errorType: NAF_ERROR_TYPES.NEED_REPAIR,
        repairStatus: ASSESSMENT_SUPPLIER_STATUS.NEED_REPAIR,
        price,
      });
      dispatch(
        _assessmentDamageDetailCreated({
          vehicleId,
          repairId,
          sheetId,
          detail: response,
        }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const deleteDamageDetail =
  (vehicleId, repairId, sheetId, detailId) => async (dispatch) => {
    try {
      await api.assessment.damageDetails.delete(detailId);
      dispatch(
        _assessmentDamageDetailDeleted({
          vehicleId,
          repairId,
          sheetId,
          detailId,
        }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const updateDamageDetail =
  (vehicleId, repairId, sheetId, detail) => async (dispatch) => {
    try {
      let response = await api.assessment.damageDetails.update(
        detail.id,
        detail,
      );
      response = await api.assessment.damageDetails.get(detail.id);
      dispatch(
        _assessmentDamageDetailUpdated({
          vehicleId,
          repairId,
          sheetId,
          detail: response,
        }),
      );
    } catch (error) {
      if (error instanceof HttpRequestClientError) {
        throw error;
      }
    }
  };

export const delayedUpdateDamageDetail =
  (vehicleId, repairId, sheetId, detail) => async (dispatch) => {
    timeoutProcessor.enqueueTask(detail.id, 2000, () =>
      api.assessment.damageDetails.update(detail.id, detail),
    );
    dispatch(
      _assessmentDamageDetailUpdated({
        vehicleId,
        repairId,
        sheetId,
        detail,
      }),
    );
  };

export const changeDamageDetailPrice =
  (vehicleId, repairId, sheetId, detailId, price) =>
  async (dispatch, getState) => {
    if (Number.isNaN(price)) return;
    const assessment = getState().vehicles.aldAssessments[vehicleId];
    const repair = assessment.repairs.find((x) => x.id === repairId);
    const sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
    let detail = sheet.damageDetails.find((x) => x.id === detailId);
    detail = JSON.parse(JSON.stringify(detail));
    detail.price = price;
    await dispatch(
      delayedUpdateDamageDetail(vehicleId, repairId, sheetId, detail),
    );
  };

export const changeDamageDetailName =
  (vehicleId, repairId, sheetId, detailId, name) =>
  async (dispatch, getState) => {
    const assessment = getState().vehicles.aldAssessments[vehicleId];
    const repair = assessment.repairs.find((x) => x.id === repairId);
    const sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
    let detail = sheet.damageDetails.find((x) => x.id === detailId);
    detail = JSON.parse(JSON.stringify(detail));
    detail.name = name;
    await dispatch(
      delayedUpdateDamageDetail(vehicleId, repairId, sheetId, detail),
    );
  };

export const changeDamageDetailStatus =
  (vehicleId, repairId, sheetId, detailId, repairStatus) =>
  async (dispatch, getState) => {
    const assessment = getState().vehicles.aldAssessments[vehicleId];
    const repair = assessment.repairs.find((x) => x.id === repairId);
    const sheet = repair.assessmentSheets.find((x) => x.id === sheetId);
    let detail = sheet.damageDetails.find((x) => x.id === detailId);
    detail = JSON.parse(JSON.stringify(detail));
    detail.repairStatus = parseInt(repairStatus, 10);
    await dispatch(updateDamageDetail(vehicleId, repairId, sheetId, detail));
  };

export const getVehicleReturnInfo = (vehicleId) => async (dispatch) => {
  try {
    const response = await api.vehicles.getVehicleReturnInfo(vehicleId);
    dispatch(
      _vehicleReturnInfoFetchSucceeded({ vehicleId, returnInfo: response }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getVehicleRepairStatuses = (vehicleId) => async (dispatch) => {
  try {
    const response = await api.vehicles.getVehicleRepairStatuses(vehicleId);
    dispatch(
      _vehicleRepairStatusesFetchSucceeded({
        vehicleId,
        repairStatuses: response,
      }),
    );
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const changeVehicleBooking = (vehicleId) => async () => {
  try {
    await api.vehicles.changeBooking(vehicleId);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getSalesChannelEnums = () => async (dispatch) => {
  try {
    const result = await api.contracts.getSalesChannelEnums();
    await dispatch(_salesChannelEnumsFetchSucceeded(result));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const updateContract = (contractId, data) => async () => {
  try {
    await api.contracts.updateContract(contractId, data);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getContractById = (contractId) => async () => {
  const result = await api.contracts.getContractById(contractId);
  return result;
};
