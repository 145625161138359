export const SortCarsEnum = {
  CarBrand: 0,
  CarModel: 1,
  PlateNo: 2,
  EndDate: 3,
  AldAssessmentApproveState: 4,
  LastAssessmentSent: 5,
  BookedDate: 6,
  ArrivedToALD: 7,
  LastUpdate: 8,
  TimeUsed: 9,
  Owner: 10,
  ReturnToDealerOtherDate: 11,
  CompletedDate: 12,
  SalesChannel: 13,
};

export const SortEnum = {
  OrderByAscending: 0,
  OrderByDescending: 1,
};
