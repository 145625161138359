import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';

const NewCommentBlock = ({ onAddComment, onCancel }) => {
  const [newComment, setNewComment] = useState('');

  return (
    <Box sx={{ p: 2, background: '#F7F7FD', borderRadius: '15px' }}>
      <TextField
        sx={{ background: 'white', borderRadius: '15px' }}
        multiline
        rows={3}
        fullWidth
        value={newComment}
        onChange={(ev) => setNewComment(ev.target.value)}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 'fit-content',
          ml: 'auto',
          mr: '10px',
        }}
      >
        <Button
          variant="outlined"
          sx={{ m: '10px', background: 'white' }}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ m: '10px', mr: 0 }}
          onClick={() => {
            onAddComment(newComment);
            setNewComment('');
          }}
        >
          Add comment
        </Button>
      </Box>
    </Box>
  );
};

export default NewCommentBlock;
