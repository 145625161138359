import { createSlice } from '@reduxjs/toolkit';

export const backPathSlice = createSlice({
  name: 'backPath',
  initialState: {
    previousLocation: null,
  },
  reducers: {
    setPreviousLocation: (state, action) => {
      state.previousLocation = action.payload;
    },
  },
});

export const { setPreviousLocation } = backPathSlice.actions;

export const backPathReducer = backPathSlice.reducer;
