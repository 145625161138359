import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../../../store/appCommon/slice';
import {
  addImageToContract,
  getContractImages,
  removeImageFromContract,
  switchToNextVehicleFlowState,
} from '../../../../../store/vehicles/slice';
import PhotoUploadBeforeReturn from './PhotoUploadBeforeReturn';

const mapStateToProps = (state, ownProps) => {
  const {
    bookings: { bookings },
  } = state;
  const {
    vehicle: { id: carId },
  } = ownProps;
  const currentVehicleBooking = bookings[carId];

  return {
    currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
    currentVehicleBooking,
  };
};

const mapDispatchToProps = {
  addImageToContract,
  removeImageFromContract,
  getContractImages,
  switchToNextVehicleFlowState,
  setFsUploadIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhotoUploadBeforeReturn);
