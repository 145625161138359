import thunkMiddleware from 'redux-thunk';
import createRootReducer from './rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import { appCommonMiddleware } from './appCommon/middleware';
import revokeTokenMiddleware from './auth/middlewares/revokeToken';
import { authMiddleware } from './auth/middlewares/auth';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { redirectMiddleware } from './redirect/middleware';
import { vehiclesMiddleware } from './vehicles/middleware';
import { customersMiddleware } from './customers/middleware';
import { dealersMiddleware } from './dealers/middleware';
import { damageTypesMiddleware } from './damageTypes/middleware';
import { suppliersMiddleware } from './suppliers/middleware';
import { bookingMiddleware } from './booking/middleware';
import { damageTypeGroupsMiddleware } from './damageTypeGroups/middleware';
import { messagesMiddleware } from './messages/middleware';
import { realtimeMiddleware } from './realtime/middleware';
import { backPathMiddleware } from './backPath/middleware';

export const history = createBrowserHistory();

export function configureStore(preloadedState, _history = history) {
  const middlewares = [
    backPathMiddleware,
    routerMiddleware(_history),
    revokeTokenMiddleware,
    thunkMiddleware,
    appCommonMiddleware,
    authMiddleware,
    realtimeMiddleware,
    vehiclesMiddleware,
    customersMiddleware,
    dealersMiddleware,
    suppliersMiddleware,
    damageTypesMiddleware,
    bookingMiddleware,
    damageTypeGroupsMiddleware,
    messagesMiddleware,
    redirectMiddleware,
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    createRootReducer(_history),
    preloadedState,
    composedEnhancers,
  );

  return store;
}

const store = configureStore();
export default store;
