import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  CONTRACT_CAR_IMAGE_TYPES,
  SUB_FLOW_STATE_ENUM,
} from '../../../../common/constants/vehicles';
import PhotoUploadBeforeReturnContainer from './PhotoUploadBeforeReturn/PhotoUploadBeforeReturn.container';
import ReturningToDealerContainer from './ReturningToDealer/ReturningToDealer.container';
import AccessoriesCheckInProcess from './AccessoriesCheckInProcess/AccessoriesCheckInProcess';
import ReadyForReturnToALDFromOtherContainer from './ReadyForReturnToALDFromOther/ReadyForReturnToALDFromOther.container';
import ReturningToALDContainer from './ReturningToALD/ReturningToALD.container';
import PhotoUploadAfterReturnToALDContainer from './PhotoUploadAfterReturnToALD/PhotoUploadAfterReturnToALD.container';
import ReadyForQRGeneratingContainer from './ReadyForQRGenerating/ReadyForQRGenerating.container';
import QRGeneratingContainer from './QRGenerating/QRGenerating.container';
import GalleryCarousel from '../../../../common/components/GalleryCarousel/GalleryCarousel';
import ReadyForAccessoriesCheckContainer from './ReadyForAccessoriesCheck/ReadyForAccessoriesCheck.container';
import DeliveredToAccessoriesCheckContainer from './DeliveredToAccessoriesCheck/DeliveredToAccessoriesCheck.container';
import ReadyForWashContainer from './ReadyForWash/ReadyForWash.container';
import DeliveredToWashContainer from './DeliveredToWash/DeliveredToWash.container';
import WashInProcessContainer from './WashInProcess/WashInProcess.container';
import ReadyForNAFAssessmentContainer from './ReadyForNAFAssessment/ReadyForNAFAssessment.container';
import DeliveredToNAFAssessmentContainer from './DeliveredToNAFAssessment/DeliveredToNAFAssessment.container';
import NAFAssessmentInProcessContainer from './NAFAssessmentInProcess/NAFAssessmentInProcess.container';
import ReadyForALDAssessmentContainer from './ReadyForALDAssessment/ReadyForALDAssessment.container';
import DeliveredToALDAssessmentContainer from './DeliveredToALDAssessment/DeliveredToALDAssessment.container';
import ALDAssessmentInProcessContainer from './ALDAssessmentInProcess/ALDAssessmentInProcess.container';
import AssessmentCompletedContainer from './AssessmentCompleted/AssessmentCompleted.container';
import DeliveredToWorkshopContainer from './DeliveredToWorkshop/DeliveredToWorkshop.container';
import WorkshopInProcessContainer from './WorkshopInProcess/WorkshopInProcess.container';
import WorkshopCompletedContainer from './WorkshopCompleted/WorkshopCompleted.container';
import ReadyForSales from './ReadyForSales/ReadyForSales';
import WaitingBooksDateAndTimeContainer from './WaitingBooksDateAndTime/WaitingBooksDateAndTime.container';
import ReturningToOtherContainer from './ReturningToOther/ReturningToOther.container';
import ReadyForReturnToALDFromDealerContainer from './ReadyForReturnToALDFromDealer/ReadyForReturnToALDFromDealer.container';
import UpdateMileageBeforeReturnContainer from './UpdateMileageBeforeReturn/UpdateMileageBeforeReturn.container';
import UpdateMileageAfterReturnContainer from './UpdateMileageAfterReturn/UpdateMileageAfterReturn.container';
import WithAccessRightsRendererContainer from '../../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import {
  Build as BuildIcon,
  LocalOfferOutlined,
  Speed,
} from '@mui/icons-material';
import WaitingStartReturnContainer from './WaitingStartReturn/WaitingStartReturn.container';
import { MicroDashboardItem } from '../CarDetails/components/MicroDashboardItem';
import DriverLicenseIcon from '../../../../icons/DriverLicense';
import FileTextIcon from '../../../../icons/FileText';
import {
  getChannelColorByEnum,
  getChannelNameByEnum,
} from '../CarDetails/helpers';
import { CAR_OWNER_ENUM } from '../SupplierCars/helpers';

function CarStatusInfo({
  t = () => {},
  vehicleId,
  vehicle = {},
  getVehicleFlowState = () => {},
  getVehicleFlowPossibleStates = () => {},
  getVehicleFlowStatesLogs = () => {},
  getContractImages = () => {},
  getBookingByContractId = () => {},
  redirectToAppVehiclesList = () => {},
  getVehicleById = () => {},
  _setCurrentVehicle = () => {},
  getContractById = () => {},
  updateContract = () => {},
  accessRights = {},
  salesChannelEnums = [],
  getSalesChannelEnums = () => {},
  contractInfo = {},
  getVehicleContractInfo = () => {},
}) {
  const {
    id,
    contractId,
    carBrand,
    carModel,
    mileage,
    carOwner,
    plateNo,
    salesChannel,
    currentVehicleFlowState: { currentStateEnum } = {},
    currentVehicleContractImages = [],
  } = vehicle;

  const { contractType } = contractInfo;

  const [enoughAccessRights, setEnoughAccessRights] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);

  useEffect(() => {
    getVehicleById(vehicleId).then((data) => _setCurrentVehicle(data));
    return () => {
      _setCurrentVehicle(undefined);
    };
  }, []);

  useEffect(() => {
    if (vehicle) {
      getSalesChannelEnums().catch((error) => {
        console.error(error);
      });

      getVehicleContractInfo(vehicleId).catch((error) => {
        console.error(error);
      });
    }
  }, [vehicle.id]);

  const wrappedGetVehicleFlowState = async (
    vId,
    vehicleContractId,
    noRightsCallback = () => {},
  ) => {
    try {
      await getVehicleFlowState(vId, vehicleContractId);
      setEnoughAccessRights(true);
    } catch (error) {
      if (error.httpStatusCode === 403) {
        setEnoughAccessRights(false);
        await noRightsCallback();
      }
    }
  };

  useEffect(async () => {
    setIsDataLoading(true);
    if (id) {
      try {
        await wrappedGetVehicleFlowState(id, contractId);
        await getVehicleFlowPossibleStates(id, contractId);
        await getVehicleFlowStatesLogs(id, contractId);
        await getContractImages(contractId);
        await getBookingByContractId(contractId);
      } catch (error) {
        console.error(error);
      }
    }

    setIsDataLoading(false);
  }, [id]);

  const [isSalesChannelChangeLoading, setIsSalesChannelChangeLoading] =
    useState(false);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.default',
        overflow: 'auto',
        alignItems: 'center',
        justifyContent:
          isDataLoading || !enoughAccessRights || !id ? 'center' : 'unset',
      }}
    >
      {isDataLoading && <CircularProgress />}

      {!isDataLoading && (
        <>
          {!!id && (
            <>
              {!enoughAccessRights && (
                <Box
                  sx={{
                    width: '80%',
                    height: { xs: '40%', md: '60%' },
                    border: '1px solid #5664D2',
                    borderRadius: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                  }}
                >
                  <BuildIcon color="primary" />
                  <Typography
                    sx={{ color: '#5664D2', mt: 2, textAlign: 'center' }}
                  >
                    You have not enough access rights to see current car state
                    details.
                  </Typography>
                </Box>
              )}

              {enoughAccessRights && (
                <>
                  {currentStateEnum >=
                    SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK && (
                    <GalleryCarousel
                      sx={{ flex: '0 0 30%', minHeight: 0 }}
                      uploadIds={currentVehicleContractImages.map(
                        (imgData) => imgData.uploadId,
                      )}
                    />
                  )}

                  <Box
                    sx={{
                      p: 2,
                      pb: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        mb: 1,
                      }}
                    >
                      <Typography noWrap sx={{ mr: 1, fontWeight: 500 }}>
                        {carBrand}
                      </Typography>

                      <Typography noWrap sx={{ mr: 1 }}>
                        {carModel}
                      </Typography>

                      <Typography noWrap>{plateNo}</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: { xs: 'wrap', md: 'unset' },
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flex: 1,
                        }}
                      >
                        <MicroDashboardItem
                          Icon={DriverLicenseIcon}
                          title="Car owner"
                          value={
                            carOwner === CAR_OWNER_ENUM.NF_FLEET
                              ? 'NFFLEET'
                              : 'FLEET'
                          }
                        />

                        <MicroDashboardItem
                          Icon={FileTextIcon}
                          title="Contract type"
                          value={contractType}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flex: 1,
                        }}
                      >
                        <MicroDashboardItem
                          Icon={Speed}
                          title="Mileage"
                          value={mileage}
                        />

                        <WithAccessRightsRendererContainer
                          contentByAccessRights={[
                            {
                              accessRights: ['canViewSalesChannel'],
                              content: (
                                <MicroDashboardItem
                                  sx={{
                                    mr: { xs: 0, md: 0 },
                                  }}
                                  Icon={LocalOfferOutlined}
                                  title="Sales channel"
                                  value={getChannelNameByEnum(salesChannel)}
                                  ValueSx={{
                                    color: getChannelColorByEnum(salesChannel),
                                  }}
                                  loading={isSalesChannelChangeLoading}
                                  expandable={accessRights.canEditSalesChannel}
                                  popperContentRenderer={({ handleClose }) => {
                                    return (
                                      <List
                                        sx={{
                                          p: 0,
                                          width: '100%',
                                          maxHeight: '200px',
                                          overflow: 'auto',
                                        }}
                                      >
                                        {salesChannelEnums.map((obj, index) => {
                                          return (
                                            <ListItem
                                              key={index}
                                              sx={{ p: 0 }}
                                              onClick={async () => {
                                                setIsSalesChannelChangeLoading(
                                                  true,
                                                );
                                                const contractData =
                                                  await getContractById(
                                                    contractId,
                                                  );
                                                await updateContract(
                                                  contractId,
                                                  {
                                                    ...contractData,
                                                    salesChannel:
                                                      obj.salesChannel,
                                                  },
                                                );
                                                const newVehicleData =
                                                  await getVehicleById(
                                                    vehicleId,
                                                  );
                                                _setCurrentVehicle(
                                                  newVehicleData,
                                                );
                                                handleClose();
                                                setIsSalesChannelChangeLoading(
                                                  false,
                                                );
                                              }}
                                            >
                                              <ListItemButton>
                                                <ListItemText
                                                  primary={getChannelNameByEnum(
                                                    obj.salesChannel,
                                                  )}
                                                  sx={{
                                                    color:
                                                      getChannelColorByEnum(
                                                        obj.salesChannel,
                                                      ),
                                                  }}
                                                />
                                              </ListItemButton>
                                            </ListItem>
                                          );
                                        })}
                                      </List>
                                    );
                                  }}
                                />
                              ),
                            },
                          ]}
                          fallBackContent={null}
                        />
                      </Box>
                    </Box>
                  </Box>

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_BOOKS_DATE_AND_TIME && (
                    <WaitingBooksDateAndTimeContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.WAITING_START_RETURN && (
                    <WaitingStartReturnContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.WAITING_UNTIL_CUSTOMER_UPDATE_MILEAGE_BEFORE_RETURN && (
                    <UpdateMileageBeforeReturnContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_BEFORE_RETURN && (
                    <PhotoUploadBeforeReturnContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractImages={currentVehicleContractImages.filter(
                        (img) =>
                          img.contractCarImageType ===
                          CONTRACT_CAR_IMAGE_TYPES.IMAGE_BEFORE_RETURN,
                      )}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_DEALER_LOCATION && (
                    <ReturningToDealerContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractImages={currentVehicleContractImages}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_DEALER && (
                    <ReadyForReturnToALDFromDealerContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractImages={currentVehicleContractImages}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_OTHER_LOCATION && (
                    <ReturningToOtherContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractImages={currentVehicleContractImages}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION && (
                    <ReadyForReturnToALDFromOtherContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractImages={currentVehicleContractImages}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION && (
                    <ReturningToALDContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractImages={currentVehicleContractImages}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN && (
                    <UpdateMileageAfterReturnContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_AFTER_RETURN && (
                    <PhotoUploadAfterReturnToALDContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractImages={currentVehicleContractImages.filter(
                        (img) =>
                          img.contractCarImageType ===
                          CONTRACT_CAR_IMAGE_TYPES.IMAGE_AFTER_RETURN,
                      )}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_QR_GENERATING && (
                    <ReadyForQRGeneratingContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                      contractBeforeImages={currentVehicleContractImages.filter(
                        (img) =>
                          img.contractCarImageType ===
                          CONTRACT_CAR_IMAGE_TYPES.IMAGE_BEFORE_RETURN,
                      )}
                      contractAfterImages={currentVehicleContractImages.filter(
                        (img) =>
                          img.contractCarImageType ===
                          CONTRACT_CAR_IMAGE_TYPES.IMAGE_AFTER_RETURN,
                      )}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR && (
                    <QRGeneratingContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK && (
                    <ReadyForAccessoriesCheckContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.DELIVERED_TO_CHECK_MISSING_ACCESSORIES && (
                    <DeliveredToAccessoriesCheckContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.IN_PROCESS_CHECK_MISSING_ACCESSORIES && (
                    <AccessoriesCheckInProcess
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum === SUB_FLOW_STATE_ENUM.READY_FOR_WASH && (
                    <ReadyForWashContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH && (
                    <DeliveredToWashContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum === SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH && (
                    <WashInProcessContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT && (
                    <ReadyForNAFAssessmentContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT && (
                    <DeliveredToNAFAssessmentContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT && (
                    <NAFAssessmentInProcessContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT && (
                    <ReadyForALDAssessmentContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT && (
                    <DeliveredToALDAssessmentContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT && (
                    <ALDAssessmentInProcessContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}

                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP && (
                    <AssessmentCompletedContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}
                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP && (
                    <DeliveredToWorkshopContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}
                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP && (
                    <WorkshopInProcessContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}
                  {currentStateEnum ===
                    SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE && (
                    <WorkshopCompletedContainer
                      vehicle={vehicle}
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}
                  {currentStateEnum === SUB_FLOW_STATE_ENUM.READY_FOR_SALES && (
                    <ReadyForSales
                      getVehicleFlowState={wrappedGetVehicleFlowState}
                    />
                  )}
                </>
              )}
            </>
          )}

          {!id && (
            <>
              <Box
                sx={{
                  width: '80%',
                  height: { xs: '40%', md: '60%' },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 2,
                }}
              >
                <Typography sx={{ mt: 2, textAlign: 'center' }}>
                  {t('vehiclesList.carDetails.noSpecifiedCarFound')}
                </Typography>

                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    redirectToAppVehiclesList();
                  }}
                >
                  Back to list
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default withTranslation()(CarStatusInfo);
