import { connect } from 'react-redux';
import { getSupplierUsers } from '../../../../../../store/suppliers/slice';
import {
  redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWashSupplierDetailsUsersUserDetails,
} from '../../../../../../store/redirect/slice';
import WashSupplierUsers from './Users';

const mapStateToProps = (state, ownProps) => {
  const { supplierId } = ownProps;
  const { suppliersUsers } = state.suppliers;

  return {
    supplierUsers: suppliersUsers[supplierId] || [],
  };
};

const mapDispatchToProps = {
  getSupplierUsers,
  redirectToAppSuppliersWashSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWashSupplierDetailsUsersUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(WashSupplierUsers);
