import { connect } from 'react-redux';
import { getAllAldUsers } from '../../../../store/aldUsers/slice';
import { redirectToAppAldUsersUserDetailsUserInfo } from '../../../../store/redirect/slice';
import AldUsersList from './Users';

const mapStateToProps = (state) => ({
  aldUsers: state.aldUsers.aldUsers,
});

const mapDispatchToProps = {
  getAllAldUsers,
  redirectToAppAldUsersUserDetailsUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AldUsersList);
