import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { RETURN_BOOKING_TYPE } from '../../../../../common/constants/booking';
import { CAR_OWNER_ENUM } from '../../SupplierCars/helpers';
import { isDealerOnlyReturn } from './helpers';
import BookingReturnToAldContainer from './ReturnToAld/BookingReturnToAld.container';
import BookingReturnToDealerContainer from './ReturnToDealer/BookingReturnToDealer.container';
import BookingReturnToOtherContainer from './ReturnToOther/BookingReturnToOther.container';

const WaitingBooksDateAndTime = ({
  t = () => {},
  locations = [],
  vehicle = {},
  loadBookingLocations = () => {},
  getVehicleFlowState = () => {},
}) => {
  useEffect(() => {
    loadBookingLocations(vehicle.contractId);
  }, []);

  const [locationId, setLocationId] = useState(
    '00000000-0000-0000-0000-000000000000',
  );
  // confirmed means that button next was clicked, default null
  const [confirmedReturnType, setConfirmedReturnType] = useState(null);

  if (locations.length > 0) {
    const isDealerOnly = isDealerOnlyReturn(locations);

    if (isDealerOnly) {
      const handleConfirmReturnType = () => {
        setConfirmedReturnType(RETURN_BOOKING_TYPE.RETURN_TO_DEALER);
      };
      const location = locations.at(0);

      return (
        <>
          {confirmedReturnType === null && (
            <Box
              sx={{
                height: '100%',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ p: 2, textAlign: 'center' }} variant="h5">
                To deliver your car please confirm this address
              </Typography>

              <Typography
                variant="h6"
                sx={{ m: 2, fontSize: '16px', textAlign: 'center' }}
              >
                {location.name}
              </Typography>

              <Button onClick={handleConfirmReturnType} variant="contained">
                Next
              </Button>
            </Box>
          )}

          {confirmedReturnType === RETURN_BOOKING_TYPE.RETURN_TO_DEALER && (
            <BookingReturnToDealerContainer
              getVehicleFlowState={getVehicleFlowState}
              locationId={location.id}
              vehicle={vehicle}
              onBackButtonClicked={() => {
                setConfirmedReturnType(null);
              }}
            />
          )}
        </>
      );
    }

    const handleConfirmReturnType = () => {
      const location = locations.find((l) => l.id === locationId) || {};
      setConfirmedReturnType(location.returnBookingType);
    };

    const handleReturnTypeChange = (e) => {
      setLocationId(e.target.value);
    };

    return (
      <>
        {confirmedReturnType === null && (
          <Box
            sx={{
              height: '100%',
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ p: 2, textAlign: 'center' }} variant="h5">
              How would you like to deliver your car?
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '100%', md: '60%' },
                m: 'auto',
              }}
            >
              <RadioGroup
                value={locationId}
                onChange={handleReturnTypeChange}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    mr: 0,
                    marginLeft: 0,
                  },
                  width: '100%',
                }}
              >
                {locations.map((x, index) => {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <FormControlLabel
                        key={index}
                        value={x.id}
                        control={<Radio />}
                        label={x.name}
                      />
                      {x.name === 'Other location' && (
                        <>
                          <Typography
                            color="error"
                            sx={{ fontSize: '12px', ml: '42px' }}
                          >
                            {t(
                              'common.messages.pleaseMakeSureValidityOfLocation',
                            )}
                          </Typography>

                          <Typography
                            color="primary"
                            sx={{ fontSize: '12px', ml: '42px' }}
                          >
                            {vehicle.owner === CAR_OWNER_ENUM.ALD
                              ? 'innlevering.no@fleetautomotive.com'
                              : 'nfinnlevering.02@nffleet.com'}
                          </Typography>
                        </>
                      )}
                    </Box>
                  );
                })}
              </RadioGroup>
            </Box>

            <Button onClick={handleConfirmReturnType} variant="contained">
              Next
            </Button>
          </Box>
        )}

        {confirmedReturnType === RETURN_BOOKING_TYPE.RETURN_TO_OTHER && (
          <BookingReturnToOtherContainer
            getVehicleFlowState={getVehicleFlowState}
            vehicle={vehicle}
            onBackButtonClicked={() => {
              setConfirmedReturnType(null);
            }}
          />
        )}

        {confirmedReturnType === RETURN_BOOKING_TYPE.RETURN_TO_ALD && (
          <BookingReturnToAldContainer
            getVehicleFlowState={getVehicleFlowState}
            locationId={locationId}
            vehicle={vehicle}
            onBackButtonClicked={() => {
              setConfirmedReturnType(null);
            }}
          />
        )}
      </>
    );
  }

  return <></>;
};

export default withTranslation()(WaitingBooksDateAndTime);
