export const MESSAGE_TYPE = {
  TEXT: 0,
  FILE: 1,
  PHOTO: 2,
};

export const CHAT_TYPE = {
  ALD_CUSTOMER: 0,
  ALD_SUPPLIER: 1,
};
