import { connect } from 'react-redux';
import { getSupplierUsers } from '../../../../../../store/suppliers/slice';
import {
  redirectToAppSuppliersWorkshopSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWorkshopSupplierDetailsUsersUserDetails,
} from '../../../../../../store/redirect/slice';
import WorkshopSupplierUsers from './Users';

const mapStateToProps = (state, ownProps) => {
  const { supplierId } = ownProps;
  const { suppliersUsers } = state.suppliers;

  return {
    supplierUsers: suppliersUsers[supplierId] || [],
  };
};

const mapDispatchToProps = {
  getSupplierUsers,
  redirectToAppSuppliersWorkshopSupplierDetailsUsersCreateNewUser,
  redirectToAppSuppliersWorkshopSupplierDetailsUsersUserDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkshopSupplierUsers);
