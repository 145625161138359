import { withTranslation } from 'react-i18next';
import { SUPPLIER_CONFIRM_STATUS } from '../../../../../common/constants/assessment';
import {
  Button,
  Divider,
  ImageList,
  ImageListItem,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ChatContainer from '../../../Chat/Chat.container';
import ImageLoader from '../../../../../common/components/ImageLoader/ImageLoader';

function SupplierPickupAssessment({
  assessment = null,
  damageTypes = {},
  damageTypeGroups = {},
  vehicleId,
  pickUpAssessmentRepair,
  setFsUploadIds,
}) {
  if (!assessment) return <></>;

  return (
    <>
      {assessment.repairs.map((repair) => {
        if (repair.assessmentSheets.length === 0) {
          return <React.Fragment key={`${new Date()}`} />;
        }
        return (
          <Box sx={{ m: '15px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography>
                {damageTypeGroups[repair.damageTypeGroupId]?.name}
              </Typography>
              <Button
                sx={{
                  height: '40px',
                  mt: 'auto',
                  ml: 'auto',
                  mb: '7px',
                  mr: '10px',
                  width: '140px',
                }}
                variant={
                  repair.supplierConfirmStatus ===
                  SUPPLIER_CONFIRM_STATUS.CONFIRMED
                    ? 'outlined'
                    : 'contained'
                }
                onClick={() =>
                  repair.supplierConfirmStatus !==
                  SUPPLIER_CONFIRM_STATUS.CONFIRMED
                    ? pickUpAssessmentRepair(vehicleId, repair.id)
                    : null
                }
              >
                {repair.supplierConfirmStatus ===
                SUPPLIER_CONFIRM_STATUS.CONFIRMED
                  ? 'Picked up'
                  : 'Pick up'}
              </Button>
            </Box>
            <Divider />
            {repair.assessmentSheets.map((damage, damageIndex) => {
              return (
                <React.Fragment key={damageIndex}>
                  <Box
                    sx={{
                      display: 'flex',
                      p: 2,
                      pr: 0,
                      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                  >
                    <Box
                      sx={{
                        flex: 2,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography sx={{ mb: 2 }}>
                        {damageTypes[damage.damageTypeId]?.name}
                      </Typography>
                      {damage.damageDetails.map((d) => (
                        <Typography key={d.id} sx={{ fontSize: '12px', mb: 1 }}>
                          {d.name}
                        </Typography>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        flex: 2,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mr: '20px',
                      }}
                    >
                      {damage.images && damage.images.length > 0 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <ImageList rowHeight={105} gap={10}>
                            {damage.images.map((image, imageIndex) => {
                              if (imageIndex > 1)
                                return <React.Fragment key={image} />;

                              return (
                                <ImageListItem
                                  key={image}
                                  cols={1}
                                  rows={1}
                                  sx={{}}
                                  onClick={() => setFsUploadIds(damage.images)}
                                >
                                  <ImageLoader
                                    sx={{
                                      borderRadius: '6px',
                                      maxWidth: '100px',
                                      maxHeight: '100px',
                                    }}
                                    loading="lazy"
                                    uploadId={image}
                                  />
                                </ImageListItem>
                              );
                            })}
                            {damage.images && damage.images.length > 2 && (
                              <Typography sx={{ fontSize: '12px' }}>
                                + {damage.images.length - 2} pictures
                              </Typography>
                            )}
                          </ImageList>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                </React.Fragment>
              );
            })}
          </Box>
        );
      })}
      {assessment.repairs.length > 0 && (
        <Box
          sx={{
            mt: 1,
            ml: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChatContainer vehicleId={vehicleId} />
        </Box>
      )}
    </>
  );
}

export default withTranslation()(SupplierPickupAssessment);
