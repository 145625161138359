import { connect } from 'react-redux';
import { redirectToAppVehiclesListReadyForSalesCarsCarDetails } from '../../../../store/redirect/slice';
import { getVehicles } from '../../../../store/vehicles/slice';
import ReadyForSalesCars from './ReadyForSalesCars';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListReadyForSalesCarsCarDetails,
  getVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadyForSalesCars);
