import { connect } from 'react-redux';
import { redirectBackByBreadcrumb } from '../../../store/redirect/slice';
import B2BAdminCustomers from './CustomerUsers';

const mapStateToProps = (state) => {
  const {
    customers: { appropriateCustomer },
  } = state;
  const customerId = appropriateCustomer.id;

  return { customerId, pathname: state.router.location.pathname };
};

const mapDispatchToProps = {
  redirectBackByBreadcrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(B2BAdminCustomers);
