import { withTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import RoundedIconWithColorBackground from '../RoundedIconWithColorBackground/RoundedIconWithColorBackground';

function FormTitle({ theme = {}, icon = null, text = '', sx = {} }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flex: '0 0 80px',
        px: 2.5,
        backgroundColor: theme.palette.background.default,
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        ...sx,
      }}
    >
      {icon && (
        <RoundedIconWithColorBackground sx={{ mr: 2 }}>
          {icon}
        </RoundedIconWithColorBackground>
      )}

      <Typography variant="h5">{text}</Typography>
    </Box>
  );
}

const CommonFormTitle = withTheme(FormTitle);
export default CommonFormTitle;
