import { withTheme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import GridGalleryContainer from '../../../../../common/components/GridGallery/GridGallery.container';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

function ReadyForReturnToALDFromDealer({
  contractImages = [],
  switchToNextVehicleFlowState = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  getVehicleFlowState = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          Ready for return to FLEET from “Dealer”
        </Typography>

        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          Press the button to start return to FLEET
        </Typography>
      </Box>

      <GridGalleryContainer
        maxRenderingImagesCount={6}
        uploadIds={contractImages.map((imgData) => {
          return imgData.uploadId;
        })}
      />

      <Button
        sx={{ width: '100%', mt: 'auto' }}
        color="primary"
        variant="contained"
        onClick={async () => {
          await switchToNextVehicleFlowState(
            id,
            currentStateId,
            SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION,
          );
          await getVehicleFlowState(id, contractId);
        }}
      >
        Start return to FLEET
      </Button>
    </Box>
  );
}

export default withTheme(ReadyForReturnToALDFromDealer);
