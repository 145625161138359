import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { CustomPrintFrame } from '../../../../../common/components/Frame/Frame';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';
import PrintIcon from '@mui/icons-material/Print';

function QRGenerating({
  switchToNextVehicleFlowState = () => {},
  getVehicleQR = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  currentVehicleQRLoadingInProcess = false,
  qrSrc = null,
  getVehicleFlowState = () => {},
}) {
  const { id, contractId, plateNo } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  useEffect(() => {
    getVehicleQR(id, contractId);
  }, []);

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: 'primary.main', width: '100%', textAlign: 'center' }}
        >
          QR generating
        </Typography>

        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          Print the QR and place on the car, then tap complete button to finish
          the step
        </Typography>
      </Box>

      <Box
        sx={{
          flex: 1,
          minHeight: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {!currentVehicleQRLoadingInProcess && (
          <CustomPrintFrame
            style={{ height: '100%', border: 'none' }}
            id="qr-print-frame"
            onLoad={() => {
              const doc =
                window.frames['qr-print-frame'].contentWindow.document;

              const style = doc.createElement('style');
              style.textContent = `html,body {width:100%;height:100%;margin:0;display: flex;flex-direction: column;font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"}`;

              window.frames[
                'qr-print-frame'
              ].contentWindow.document.head.appendChild(style);
            }}
          >
            <img
              alt="Vehicle QR"
              style={{
                minHeight: 0,
                minWidth: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={qrSrc}
              title="Vehicle QR"
            />
            <h4 style={{ textAlign: 'center', marginTop: 0 }}>{plateNo}</h4>
          </CustomPrintFrame>
        )}

        {currentVehicleQRLoadingInProcess && <CircularProgress />}
      </Box>

      <Button
        startIcon={<PrintIcon />}
        variant="outlined"
        sx={{ mb: 1, mt: 1 }}
        onClick={() => {
          window.frames['qr-print-frame'].focus();

          // increase text size on print
          const doc = window.frames['qr-print-frame'].contentWindow.document;
          const style = doc.createElement('style');
          style.textContent = `
              h4 {
                font-size: xxx-large;
              }
          `;
          window.frames[
            'qr-print-frame'
          ].contentWindow.document.head.appendChild(style);

          // calling print
          window.frames['qr-print-frame'].contentWindow.print();

          // after print closed
          window.frames[
            'qr-print-frame'
          ].contentWindow.document.head.removeChild(style);
        }}
      >
        Print QR
      </Button>

      <Button
        sx={{ width: '100%', mt: 'auto' }}
        color="primary"
        variant="contained"
        onClick={async () => {
          await switchToNextVehicleFlowState(
            id,
            currentStateId,
            SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK,
          );
          await getVehicleFlowState(id, contractId);
        }}
      >
        Complete
      </Button>
    </Box>
  );
}

export default QRGenerating;
