import { connect } from 'react-redux';
import { getAllB2BCustomerUsers } from '../../../../../store/customers/slice';
import Details from './Details';

const mapStateToProps = (state, ownProps) => {
  const { b2bCustomers } = state.customers;
  const { customerId } = ownProps;
  const customer = b2bCustomers.find((c) => c.id === customerId);

  return {
    customer,
    pathname: state.router.location.pathname,
  };
};

const mapDispatchToProps = { getAllB2BCustomerUsers };

export default connect(mapStateToProps, mapDispatchToProps)(Details);
