import { Box, Typography, LinearProgress, Button } from '@mui/material';
import { withTheme } from '@emotion/react';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

const MissingEquipmentSaved = ({
  currentVehicleFlowState,
  vehicle,
  switchToNextVehicleFlowState,
  getVehicleFlowState = () => {},
}) => {
  const { id: vehicleId, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  const handleNextState = async () => {
    await switchToNextVehicleFlowState(
      vehicleId,
      currentStateId,
      SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
    );
    await getVehicleFlowState(vehicleId, contractId);
  };

  return (
    <Box
      sx={{
        m: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '22px',
          color: 'rgba(86, 100, 210, 0.5)',
          textAlign: 'center',
          pb: '10px',
        }}
        variant="h5"
      >
        Checking accessories in process
      </Typography>
      <LinearProgress sx={{ width: '100%', m: '10px' }} />
      <Typography
        sx={{
          textAlign: 'center',
          color: 'rgba(66, 82, 110, 0.82)',
          p: '10px',
        }}
        variant="h7"
      >
        To complete the checking press “Completed”
      </Typography>
      <Button variant="contained" onClick={handleNextState}>
        COMPLETED
      </Button>
    </Box>
  );
};

export default withTheme(MissingEquipmentSaved);
