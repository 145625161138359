import { withTheme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import GridGalleryContainer from '../../../../../common/components/GridGallery/GridGallery.container';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

function ReturningToOther({
  theme = {},
  contractImages = [],
  switchToNextVehicleFlowState = () => {},
  getBookingByContractId = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  currentVehicleBooking = {},
  getVehicleFlowState = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  const {
    bookingDate,
    contactPersonName,
    contactPersonTelephone,
    returnAddress: { name: returnAddressString } = {},
  } = currentVehicleBooking;

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          In transport to “Other”
        </Typography>

        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          When the vehicle return to “Other” location press the button
        </Typography>
      </Box>

      <Box
        sx={{
          borderRadius: '26px',
          border: `1px solid ${theme.palette.primary.main}`,
          py: 0.5,
          px: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          {moment(bookingDate).format('Do MMMM, HH:mm')}
        </Typography>

        {contactPersonName && contactPersonTelephone && (
          <Typography>{`${contactPersonName} ${contactPersonTelephone}`}</Typography>
        )}

        <Typography sx={{ fontStyle: 'italic', fontWeight: 300 }}>
          {returnAddressString}
        </Typography>
      </Box>

      <GridGalleryContainer
        uploadIds={contractImages.map((imgData) => {
          return imgData.uploadId;
        })}
      />

      <Button
        sx={{ width: '100%', mt: 'auto' }}
        color="primary"
        variant="contained"
        onClick={async () => {
          await getBookingByContractId(contractId);
          await switchToNextVehicleFlowState(
            id,
            currentStateId,
            SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
          );
          await getVehicleFlowState(id, contractId);
        }}
      >
        Returned to “Other”
      </Button>
    </Box>
  );
}

export default withTheme(ReturningToOther);
