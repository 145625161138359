import { connect } from 'react-redux';
import {
  redirectToAppSuppliersWashSupplierDetailsInfo,
  redirectToAppSuppliersWashSupplierDetailsUsers,
} from '../../../../../../store/redirect/slice';
import Tabs from './Tabs';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppSuppliersWashSupplierDetailsInfo,
  redirectToAppSuppliersWashSupplierDetailsUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);
