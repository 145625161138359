import { connect } from 'react-redux';
import { setRequestedLocation } from '../../../store/redirect/slice';
import AuthGuard from './AuthGuard';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    location: state.router.location,
    requestedLocation: state.redirect.requestedLocation,
  };
};

const mapDispatchToProps = { setRequestedLocation };

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
