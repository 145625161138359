import { List } from '@mui/material';
import { styled } from '@mui/system';

export const StyledExpandersList = styled(List)(({ theme }) => ({
  '&.MuiList-root': {
    flex: 1,
    overflow: 'auto',
  },
  '& .MuiListItem-root': {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    borderRadius: '16px',

    ':first-of-type': {
      marginTop: 0,
    },
  },
  '& .MuiListItemButton-root': {
    width: '100%',
    flex: '0 0 80px',
    borderRadius: '16px',
    boxShadow:
      '0px 0px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.12)',
  },
  '& .MuiListItemIcon-root': {
    padding: theme.spacing(1),
    borderRadius: '50%',

    '&.ExpandIcon': {
      backgroundColor: '#ffffff',
      color: 'primary.main',
      border: `1px solid ${theme.palette.separator}`,
    },
  },
  '& .MuiCollapse-root': {
    width: '100%',

    '& .CollapseContent': {
      display: 'flex',
      flexDirection: 'column',

      '& .CollapseContentRow': {
        display: 'flex',
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),

        '& .CollapseContentRowTitle': {
          flex: 2,
        },
        '& .CollapseContentRowContent': {
          flex: 7,
          fontWeight: 400,
        },
      },
    },
  },
}));
