import { SUB_FLOW_STATE_ENUM } from '../../../../common/constants/vehicles';

export const getSupplierCarStatusColorByCarStateEnum = (stateEnum) => {
  switch (stateEnum) {
    case SUB_FLOW_STATE_ENUM.READY_FOR_WASH:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH:
    case SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP:
    case SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE:
      return 'warning';

    case SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH:
    case SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP:
      return 'error';

    default:
      return 'error';
  }
};

export const getSupplierCarStatusTextByCarStateEnum = (
  stateEnum,
  t = () => {},
) => {
  switch (stateEnum) {
    case SUB_FLOW_STATE_ENUM.READY_FOR_WASH:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH:
    case SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP:
    case SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP:
    case SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE:
      return t('common.titles.ready').toUpperCase();

    case SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH:
    case SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT:
    case SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP:
      return t('common.titles.inProcess').toUpperCase();

    default:
      return 'error';
  }
};

export const CAR_OWNER_ENUM = {
  ALD: 1,
  NF_FLEET: 2,
};
