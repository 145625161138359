import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import AuthGuardContainer from '../../common/components/AuthGuard/AuthGuard.container';
import GuestGuard from '../../common/components/GuestGuard';
import LoadingScreen from '../../common/components/LoadingScreen';
import NotFound from '../../common/components/pages/NotFound';
import WithAccessRightsRendererContainer from '../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import {
  ROUTING_PATH_NAMES,
  ROUTING_URLS,
} from '../../common/constants/constants';
import AuthLayout from '../Auth/Layout';
import AldUsersContainer from '../MainLayout/AldUsers/AldUsers.container';
import VehiclesListContainer from '../MainLayout/VehiclesList/VehiclesList.container';
import CustomersContainer from '../MainLayout/Customers/Customers.container';
import DealersContainer from '../MainLayout/Dealers/Dealers.container';
import Inbox from '../MainLayout/Inbox/Inbox';
import MainLayoutContainer from '../MainLayout/MainLayout.container';
import Reports from '../MainLayout/Reports/Reports';
import SuppliersContainer from '../MainLayout/Suppliers/Suppliers.container';
import VehicleByQRRedirectContainer from '../MainLayout/VehicleByQRRedirect/VehicleByQRRedirect.container';
import PasswordlessAuthContainer from '../Auth/PasswordlessAuth/PasswordlessAuth.container';
import CustomerUsersContainer from '../MainLayout/CustomerUsers/CustomerUsers.container';
import DashboardContainer from '../MainLayout/Dashboard/Dashboard.container';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
const Login = Loadable(lazy(() => import('../Auth/Login/Login.container')));

function Content() {
  return (
    <Switch>
      <Route
        path="/auth"
        render={() => (
          <AuthLayout>
            <GuestGuard>
              <Route
                path={ROUTING_URLS.AUTH.SIGN_IN}
                render={() => <Login />}
              />

              <Route
                path={`/${ROUTING_PATH_NAMES.AUTH}/${ROUTING_PATH_NAMES.PASSWORDLESS_AUTH}`}
                render={({ location: { search } }) => {
                  const searchParams = new URLSearchParams(search);
                  const email = searchParams.get('email');
                  const entranceToken = searchParams.get('token');
                  const carId = searchParams.get('carId');

                  return (
                    <PasswordlessAuthContainer
                      email={email}
                      entranceToken={entranceToken}
                      carId={carId}
                    />
                  );
                }}
              />
            </GuestGuard>
          </AuthLayout>
        )}
      />

      <Route
        exact
        path="/app"
        render={() => <Redirect to={ROUTING_URLS.APP.DASHBOARD} />}
      />

      <Route
        path="/app"
        render={() => (
          <AuthGuardContainer>
            <MainLayoutContainer>
              <Route
                path={ROUTING_URLS.APP.DASHBOARD}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: ['canViewDashboard'],
                        content: <DashboardContainer />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path={ROUTING_URLS.APP.ALD_USERS}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: ['canViewAldUsers'],
                        content: <AldUsersContainer />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path={ROUTING_URLS.APP.VEHICLES_LIST}
                render={() => <VehiclesListContainer />}
              />

              <Route
                path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.DEALERS}`}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: ['canViewDealers'],
                        content: <DealersContainer />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: [
                          'canViewDealers',
                          'canViewCustomersB2B',
                          'canViewCustomersB2C',
                        ],
                        content: <CustomersContainer />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}`}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: ['canViewCustomerUsers'],
                        content: <CustomerUsersContainer />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path={ROUTING_URLS.APP.CONTROL_CATEGORIES__SUPPLIERS}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: ['canViewSuppliers'],
                        content: <SuppliersContainer />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path={ROUTING_URLS.APP.REPORTS}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: ['canViewReports'],
                        content: <Reports />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path={ROUTING_URLS.APP.INBOX}
                render={() => (
                  <WithAccessRightsRendererContainer
                    contentByAccessRights={[
                      {
                        accessRights: ['canViewInbox'],
                        content: <Inbox />,
                      },
                    ]}
                    fallBackContent={<Redirect to="/" />}
                  />
                )}
              />

              <Route
                path="/cars/byQrCode/:qrId"
                render={({
                  match: {
                    params: { qrId },
                  },
                }) => <VehicleByQRRedirectContainer qrId={qrId} />}
              />
            </MainLayoutContainer>
          </AuthGuardContainer>
        )}
      />

      <Route
        path="/cars/byQrCode/:qrId"
        render={({
          match: {
            params: { qrId },
          },
        }) => (
          <AuthGuardContainer>
            <MainLayoutContainer>
              <VehicleByQRRedirectContainer qrId={qrId} />
            </MainLayoutContainer>
          </AuthGuardContainer>
        )}
      />

      <WithAccessRightsRendererContainer
        contentByAccessRights={[
          {
            accessRights: ['canViewDashboard'],
            content: (
              <Route
                exact
                path="/"
                render={() => <Redirect to={ROUTING_URLS.APP.DASHBOARD} />}
              />
            ),
          },
        ]}
        fallBackContent={
          <Route
            exact
            path="/"
            render={() => <Redirect to={ROUTING_URLS.APP.VEHICLES_LIST} />}
          />
        }
      />

      <Route path="*" render={() => <NotFound />} />
    </Switch>
  );
}

export default Content;
