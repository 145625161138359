import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../../../store/appCommon/slice';
import {
  addImageToContract,
  getContractImages,
  removeImageFromContract,
  switchToNextVehicleFlowState,
} from '../../../../../store/vehicles/slice';
import PhotoUploadAfterReturnToALD from './PhotoUploadAfterReturnToALD';

const mapStateToProps = (state) => {
  return {
    currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
  };
};

const mapDispatchToProps = {
  addImageToContract,
  removeImageFromContract,
  getContractImages,
  switchToNextVehicleFlowState,
  setFsUploadIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhotoUploadAfterReturnToALD);
