import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { withTranslation } from 'react-i18next';

export const B2BADMIN_CUSTOMER_DETAILS_TABS = {
  CUSTOMER_INFO: 0,
  USERS: 1,
};

function B2BAdminCustomersCustomerDetailsTabs({
  t = () => {},
  customerId = '',
  selectedTabId = B2BADMIN_CUSTOMER_DETAILS_TABS.CUSTOMER_INFO,
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo = () => {},
  redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers = () => {},
}) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{
          borderBottom: '1px solid #E0E0E0',
          mb: 2,
          '& .MuiTab-root': {
            flex: 1,
          },
        }}
      >
        <Tab
          onClick={() => {
            redirectToAppB2BAdminCustomersCustomerDetailsCustomerInfo(
              customerId,
            );
          }}
          id={B2BADMIN_CUSTOMER_DETAILS_TABS.CUSTOMER_INFO}
          label={t('controlCategories.customers.b2b.details.customerInfo')}
        />

        <Tab
          onClick={() => {
            redirectToAppB2BAdminCustomersCustomerDetailsCustomerUsers(
              customerId,
            );
          }}
          id={B2BADMIN_CUSTOMER_DETAILS_TABS.USERS}
          label={t('controlCategories.customers.b2b.details.users.title')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(B2BAdminCustomersCustomerDetailsTabs);
