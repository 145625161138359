import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BreadcrumbsGeneratorContainer from '../../../common/components/BreadcrubmsGenerator/BreadcrumbsGenerator.container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Route, Switch } from 'react-router-dom';
import AldUsersContainer from './Users/Users.container';
import WithAccessRightsRendererContainer from '../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import { ROUTING_PATH_NAMES } from '../../../common/constants/constants';
import CreateNewAldUserContainer from './CreateNewUser/CreateNewUser.container';
import { pathTester } from '../../../common/helpers/routing';
import AldUserDetailsContainer from './UserDetails/UserDetails.container';
import AddIcon from '@mui/icons-material/Add';

function AldUsers({
  t = () => {},
  redirectBackByBreadcrumb = () => {},
  redirectToAppAldUsersCreateNewUser = () => {},
  pathname,
}) {
  const isCreateALDUserTabSelected =
    pathTester.isAldUsersCreateNewUser(pathname);
  const isALDUserDetailsTabSelected =
    pathTester.isAldUsersUserDetailsUserInfo(pathname);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 1, md: 2 },
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">{t('aldUsers.title')}</Typography>

          <BreadcrumbsGeneratorContainer />
        </Box>

        <Box sx={{ display: 'flex', ml: 'auto' }}>
          {!isCreateALDUserTabSelected && !isALDUserDetailsTabSelected && (
            <Button
              variant="contained"
              onClick={() => {
                redirectToAppAldUsersCreateNewUser();
              }}
              startIcon={<AddIcon />}
            >
              {t('aldUsers.create.title')}
            </Button>
          )}

          {(isCreateALDUserTabSelected || isALDUserDetailsTabSelected) && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{ mr: 1 }}
              onClick={() => {
                redirectBackByBreadcrumb();
              }}
            >
              {t('common.buttons.back')}
            </Button>
          )}
        </Box>
      </Box>

      <Switch>
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewAldUsers'],
                    content: (
                      <>
                        <AldUsersContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_ALD_USER}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canAddAldUsers'],
                    content: (
                      <>
                        <CreateNewAldUserContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />

        <Route
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.ALD_USERS}/:aldUserId`}
          render={({
            match: {
              params: { aldUserId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewAldUsers'],
                    content: (
                      <>
                        <AldUserDetailsContainer aldUserId={aldUserId} />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

export default withTranslation()(AldUsers);
