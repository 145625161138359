import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../../../store/appCommon/slice';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import ReadyForQRGenerating from './ReadyForQRGenerating';

const mapStateToProps = (state) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
});

const mapDispatchToProps = { switchToNextVehicleFlowState, setFsUploadIds };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReadyForQRGenerating);
