import { connect } from 'react-redux';
import {
  switchToNextVehicleFlowState,
  updateVehicleMileage,
} from '../../../../../store/vehicles/slice';
import UpdateMileageAfterReturn from './UpdateMileageAfterReturn';

const mapStateToProps = (state) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
});

const mapDispatchToProps = {
  updateVehicleMileage,
  switchToNextVehicleFlowState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateMileageAfterReturn);
