import { connect } from 'react-redux';
import {
  redirectTo,
  redirectToAppVehiclesListCarStatus,
} from '../../../../store/redirect/slice';
import {
  getVehicleAldAssessment,
  getVehicleNafAssessment,
  clearVehicleQR,
  getVehicleQR,
  getVehicleCustomerInfo,
  getVehicleContractInfo,
  acceptAssessment,
  declineAssessment,
  getVehicleFlowStatesLogs,
  getVehicleReturnInfo,
  getVehicleRepairStatuses,
  forceApproveAssessment,
  getContractImages,
  _setCurrentVehicle,
  getVehicleById,
  getSalesChannelEnums,
  updateContract,
  getContractById,
  getVehicleAccessories,
} from '../../../../store/vehicles/slice';
import CarDetails from './CarDetails';

const mapStateToProps = (state, ownProps) => {
  const { vehicleId } = ownProps;
  const {
    vehicles: {
      currentVehicle,
      nafAssessments,
      aldAssessments,
      currentVehicleQRSrc,
      currentVehicleQRLoadingInProcess,
      customerInfos,
      contracts,
      vehiclesStateLogs,
      returnInfos,
      contractsImages,
      repairStatuses,
      salesChannelEnums,
      accessories,
    },
    damageTypes: { damageTypesLookup, groupsLookup },
    auth: {
      user: { accessRights },
    },
  } = state;
  const customerInfo = customerInfos[vehicleId];
  const contractInfo = contracts[vehicleId];
  const vehicle = currentVehicle;
  const currentVehicleContractImages =
    contractsImages[(vehicle || {}).contractId] || [];
  const nafAssessment = nafAssessments[vehicleId];
  const aldAssessment = aldAssessments[vehicleId];
  const returnInfo = returnInfos[vehicleId] || {};
  const suppliersLogs = repairStatuses[vehicleId];
  const vehicleAccessories = accessories[vehicleId];

  return {
    vehicle,
    customerInfo,
    contractInfo,
    qrSrc: currentVehicleQRSrc,
    currentVehicleQRLoadingInProcess,
    nafAssessment,
    aldAssessment,
    vehiclesStateLogs,
    returnInfo,
    currentVehicleContractImages,
    suppliersLogs,
    damageTypes: damageTypesLookup,
    damageTypeGroups: groupsLookup,
    salesChannelEnums,
    accessRights,
    pathname: state.router.location.pathname,
    accessories: vehicleAccessories,
  };
};

const mapDispatchToProps = {
  getVehicleQR,
  clearVehicleQR,
  getVehicleAldAssessment,
  getVehicleNafAssessment,
  getVehicleCustomerInfo,
  getVehicleContractInfo,
  redirectToAppVehiclesListCarStatus,
  acceptAssessment,
  forceApproveAssessment,
  declineAssessment,
  getVehicleFlowStatesLogs,
  getVehicleReturnInfo,
  getVehicleRepairStatuses,
  getContractImages,
  _setCurrentVehicle,
  getVehicleById,
  getSalesChannelEnums,
  updateContract,
  getContractById,
  redirectTo,
  getVehicleAccessories,
};

export default connect(mapStateToProps, mapDispatchToProps)(CarDetails);
