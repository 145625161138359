import { withTheme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import OneByOnePhotosUpload from '../../../../../common/components/OneByOnePhotosUpload/OneByOnePhotosUpload';
import {
  CONTRACT_CAR_IMAGE_TYPES,
  SUB_FLOW_STATE_ENUM,
} from '../../../../../common/constants/vehicles';

function PhotoUploadAfterReturnToALD({
  vehicle = {},
  addImageToContract = () => {},
  removeImageFromContract = () => {},
  getContractImages = () => {},
  switchToNextVehicleFlowState = () => {},
  contractImages = [],
  currentVehicleFlowState = {},
  getVehicleFlowState = () => {},
  setFsUploadIds = () => {},
}) {
  const { contractId, id } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  return (
    <>
      <Box
        sx={{
          p: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box
          sx={{
            pb: { xxs: 0.5, xs: 2 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ color: 'primary.main' }}>
            Ready for upload pictures
          </Typography>

          <Typography
            sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
          >
            Add car pictures for complete vehicle information
          </Typography>
        </Box>

        <OneByOnePhotosUpload
          onImageSelected={async (file) => {
            await addImageToContract(
              file,
              contractId,
              CONTRACT_CAR_IMAGE_TYPES.IMAGE_AFTER_RETURN,
            );
            await getContractImages(contractId);
          }}
          uploadIds={contractImages.map((imgData) => imgData.uploadId)}
          onClickImage={() =>
            setFsUploadIds(
              contractImages.map((imgData) => imgData.uploadId),
              async (uploadId) => {
                await removeImageFromContract(uploadId);
                await getContractImages(contractId);
              },
            )
          }
        />
        <Button
          sx={{ width: '100%', mt: 1 }}
          disabled={contractImages.length < 6}
          color="primary"
          variant="contained"
          onClick={async () => {
            await switchToNextVehicleFlowState(
              id,
              currentStateId,
              SUB_FLOW_STATE_ENUM.READY_FOR_QR_GENERATING,
            );
            await getVehicleFlowState(id, contractId);
          }}
        >
          Complete
        </Button>
      </Box>
    </>
  );
}

export default withTheme(PhotoUploadAfterReturnToALD);
