import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { StyledExpandersList } from '../../../../../../../common/components/StyledExpandersList/StyledExpandersList';
import PersonIcon from '../../../../../../../icons/Person';
import { B2B_CUSTOMER_USERS_ROLES } from '../constants';
import { getCustomerUserRoleNameByRole } from '../helpers';

function CustomerUserDetails({ t = () => {}, customerUser = {} }) {
  if (customerUser) {
    const {
      displayName = '-',
      email = '-',
      address = {
        name: '-',
      },
      telephone = '-',
      role = B2B_CUSTOMER_USERS_ROLES.USER,
    } = customerUser;
    const [isCustomerUserInfoOpened, setCustomerUserInfoOpened] =
      useState(true);

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <StyledExpandersList>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor: isCustomerUserInfoOpened
                  ? 'primary.main'
                  : 'background.default',
                color: isCustomerUserInfoOpened
                  ? 'background.default'
                  : 'text.primary',
                ':hover': {
                  backgroundColor: isCustomerUserInfoOpened
                    ? 'primary.main'
                    : 'background.default',
                },
              }}
              onClick={() => {
                setCustomerUserInfoOpened(!isCustomerUserInfoOpened);
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: isCustomerUserInfoOpened
                    ? '#ffffff'
                    : 'primary.main',
                  color: isCustomerUserInfoOpened ? 'primary.main' : '#ffffff',
                }}
              >
                <PersonIcon />
              </ListItemIcon>

              <ListItemText
                primary={
                  role === B2B_CUSTOMER_USERS_ROLES.ADMIN
                    ? t(
                        'controlCategories.customers.b2b.details.users.details.adminInfo',
                      )
                    : t(
                        'controlCategories.customers.b2b.details.users.details.userInfo',
                      )
                }
                primaryTypographyProps={{
                  fontWeight: 400,
                }}
              />

              <ListItemIcon className="ExpandIcon">
                {isCustomerUserInfoOpened ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>

            <Collapse in={isCustomerUserInfoOpened}>
              <Box className="CollapseContent">
                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.customers.b2b.details.users.details.role',
                    )}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {getCustomerUserRoleNameByRole(t, role)}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.customers.b2b.details.users.details.name',
                    )}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {displayName}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.customers.b2b.details.users.details.address',
                    )}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {address.name}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.customers.b2b.details.users.details.phone',
                    )}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {telephone}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.customers.b2b.details.users.details.email',
                    )}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {email}
                    {/* <Button
                      sx={{ ml: 'auto' }}
                      variant="contained"
                      startIcon={<EmailIcon />}
                    >
                      {t(
                        'controlCategories.customers.b2b.details.users.details.sendEmail',
                      )}
                    </Button> */}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </ListItem>
        </StyledExpandersList>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t('vehiclesList.carDetails.noSpecifiedCarFound')}
      </Typography>
    </Box>
  );
}

export default withTranslation()(CustomerUserDetails);
