import { ClearOutlined } from '@mui/icons-material';
import { Button, Divider, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

const RestrictErrorModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: 'white',
          m: { xs: 2, md: 20 },
          mt: { xs: '25%', md: '10%' },
          borderRadius: '16px',
          minHeight: '460px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Typography sx={{ fontSize: '24px', fornWeight: 500 }}>
            Attention
          </Typography>
          <ClearOutlined onClick={onClose} />
        </Box>
        <Divider />
        <Box
          sx={{
            minHeight: '390px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              p: 2,
              mt: '80px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ReportGmailerrorredIcon
              sx={{ fill: '#E5313C', width: '50px', height: '50px' }}
            />
            <Typography sx={{ textAlign: 'center' }}>
              You are trying to upload photos which were created more than 3
              hours ago
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{
                width: '100%',
                border: '1px solid #253858',
                color: '#253858',
              }}
              onClick={onClose}
            >
              Try again
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default RestrictErrorModal;
