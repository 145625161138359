import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import CompanyIcon from '../../../../../icons/Company';
import B2CCustomerInfoContainer from './Info/Info.container';
import B2CCustomerUsersContainer from './Users/Users.container';
import B2CCustomerVehiclesListContainer from './VehiclesList/VehiclesList.container';
import { B2C_CUSTOMER_DETAILS_TABS } from './Tabs/Tabs';
import B2CCustomerDetailsTabsContainer from './Tabs/Tabs.container';
import { Route, Switch } from 'react-router';
import { ROUTING_PATH_NAMES } from '../../../../../common/constants/constants';
import { pathTester } from '../../../../../common/helpers/routing';
import CustomerUserDetailsContainer from './Users/UserDetails/UserDetails.container';

function B2СCustomerDetails({
  customer = null,
  t = () => {},
  pathname = {},
  getAllB2CCustomerUsers = () => {},
}) {
  if (customer) {
    useEffect(async () => {
      try {
        await getAllB2CCustomerUsers(customer.id);
      } catch (error) {
        console.error(error);
      }
    }, []);

    const { name = '-' } = customer;
    const isCustomerInfoTabSelected =
      pathTester.isB2CCustomerDetailsCustomerInfo(pathname);
    const isCustomerVehiclesListTabSelected =
      pathTester.isB2CCustomerDetailsCustomerVehiclesList(pathname);
    const isCustomerUsersTabSelected =
      pathTester.isB2CCustomerDetailsCustomerUsers(pathname);
    const isCustomerUsersUserDetailsTabSelected =
      pathTester.isB2CCustomerDetailsUsersUserDetails(pathname);

    let selectedTab = -1;
    if (isCustomerInfoTabSelected) {
      selectedTab = B2C_CUSTOMER_DETAILS_TABS.CUSTOMER_INFO;
    }
    if (isCustomerVehiclesListTabSelected) {
      selectedTab = B2C_CUSTOMER_DETAILS_TABS.CARS;
    }
    if (isCustomerUsersTabSelected) {
      selectedTab = B2C_CUSTOMER_DETAILS_TABS.USERS;
    }

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
          p: { xs: 2 },
        }}
      >
        {!isCustomerUsersUserDetailsTabSelected && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                sx={{
                  mr: 3,
                  width: 102,
                  height: 102,
                  backgroundColor: 'primary.main',
                }}
              >
                <CompanyIcon />
              </Avatar>

              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4">{name}</Typography>

                <Typography>
                  {t('controlCategories.customers.b2c.b2cCustomer')}
                </Typography>
              </Box>
            </Box>

            <B2CCustomerDetailsTabsContainer
              selectedTabId={selectedTab}
              customerId={customer.id}
            />
          </>
        )}

        <Switch>
          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/:customerId/${ROUTING_PATH_NAMES.CUSTOMER_INFO}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <B2CCustomerInfoContainer customerId={customerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/:customerId/${ROUTING_PATH_NAMES.VEHICLES_LIST}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <B2CCustomerVehiclesListContainer customerId={customerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/:customerId/${ROUTING_PATH_NAMES.USERS}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <B2CCustomerUsersContainer customerId={customerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2C}/:customerId/${ROUTING_PATH_NAMES.USERS}/:customerUserId`}
            render={({
              match: {
                params: { customerId, customerUserId },
              },
            }) => {
              return (
                <>
                  <CustomerUserDetailsContainer
                    customerId={customerId}
                    customerUserId={customerUserId}
                  />
                </>
              );
            }}
          />
        </Switch>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t('controlCategories.customers.b2c.noSpecifiedB2CCustomerFound')}
      </Typography>
    </Box>
  );
}

export default withTranslation()(B2СCustomerDetails);
