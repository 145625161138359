import { Avatar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import CompanyIcon from '../../../../../icons/Company';
import B2BCustomerInfoContainer from './Info/Info.container';
import B2BCustomerUsersContainer from './Users/Users.container';
import B2BCustomerVehiclesListContainer from './VehiclesList/VehiclesList.container';
import { B2B_CUSTOMER_DETAILS_TABS } from './Tabs/Tabs';
import B2BCustomerDetailsTabsContainer from './Tabs/Tabs.container';
import { Route, Switch } from 'react-router';
import { ROUTING_PATH_NAMES } from '../../../../../common/constants/constants';
import { pathTester } from '../../../../../common/helpers/routing';
import CreateNewCustomerAdminUserContainer from './Users/CreateNewAdmin/CreateNewAdmin.container';
import CreateNewCustomerUserContainer from './Users/CreateNewUser/CreateNewUser.container';
import CustomerUserDetailsContainer from './Users/UserDetails/UserDetails.container';

function B2BCustomerDetails({
  customer = null,
  t = () => {},
  pathname = {},
  getAllB2BCustomerUsers = () => {},
}) {
  if (customer) {
    useEffect(async () => {
      try {
        await getAllB2BCustomerUsers(customer.id);
      } catch (error) {
        console.error(error);
      }
    }, []);

    const { name = '-' } = customer;
    const isCustomerInfoTabSelected =
      pathTester.isB2BCustomerDetailsCustomerInfo(pathname);
    const isCustomerVehiclesListTabSelected =
      pathTester.isB2BCustomerDetailsCustomerVehiclesList(pathname);
    const isCustomerUsersTabSelected =
      pathTester.isB2BCustomerDetailsCustomerUsers(pathname);
    const isCustomerUsersUserDetailsTabSelected =
      pathTester.isB2BCustomerDetailsUsersUserDetails(pathname);
    const isCustomerUsersCreateNewAdminUserTabSelected =
      pathTester.isB2BCustomerDetailsUsersCreateNewAdminUser(pathname);
    const isCustomerUsersCreateNewUserTabSelected =
      pathTester.isB2BCustomerDetailsUsersCreateNewUser(pathname);

    let selectedTab = -1;
    if (isCustomerInfoTabSelected) {
      selectedTab = B2B_CUSTOMER_DETAILS_TABS.CUSTOMER_INFO;
    }
    if (isCustomerVehiclesListTabSelected) {
      selectedTab = B2B_CUSTOMER_DETAILS_TABS.CARS;
    }
    if (isCustomerUsersTabSelected) {
      selectedTab = B2B_CUSTOMER_DETAILS_TABS.USERS;
    }

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
          p: { xs: 2 },
        }}
      >
        {!isCustomerUsersCreateNewAdminUserTabSelected &&
          !isCustomerUsersCreateNewUserTabSelected &&
          !isCustomerUsersUserDetailsTabSelected && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar
                  sx={{
                    mr: 3,
                    width: 102,
                    height: 102,
                    backgroundColor: 'primary.main',
                  }}
                >
                  <CompanyIcon />
                </Avatar>

                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h4">{name}</Typography>

                  <Typography>
                    {t('controlCategories.customers.b2b.details.b2bCustomer')}
                  </Typography>
                </Box>
              </Box>

              <B2BCustomerDetailsTabsContainer
                selectedTabId={selectedTab}
                customerId={customer.id}
              />
            </>
          )}

        <Switch>
          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/:customerId/${ROUTING_PATH_NAMES.CUSTOMER_INFO}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <B2BCustomerInfoContainer customerId={customerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/:customerId/${ROUTING_PATH_NAMES.VEHICLES_LIST}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <B2BCustomerVehiclesListContainer customerId={customerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/:customerId/${ROUTING_PATH_NAMES.USERS}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <B2BCustomerUsersContainer customerId={customerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/:customerId/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_CUSTOMER_ADMIN_USER}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <CreateNewCustomerAdminUserContainer
                    customerId={customerId}
                  />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/:customerId/${ROUTING_PATH_NAMES.USERS}/${ROUTING_PATH_NAMES.CREATE_NEW_USER}`}
            render={({
              match: {
                params: { customerId },
              },
            }) => {
              return (
                <>
                  <CreateNewCustomerUserContainer customerId={customerId} />
                </>
              );
            }}
          />

          <Route
            exact
            path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.CONTROL_CATEGORIES}/${ROUTING_PATH_NAMES.CUSTOMERS}/${ROUTING_PATH_NAMES.B2B}/:customerId/${ROUTING_PATH_NAMES.USERS}/:customerUserId`}
            render={({
              match: {
                params: { customerId, customerUserId },
              },
            }) => {
              return (
                <>
                  <CustomerUserDetailsContainer
                    customerId={customerId}
                    customerUserId={customerUserId}
                  />
                </>
              );
            }}
          />
        </Switch>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t(
          'controlCategories.customers.b2b.details.noSpecifiedB2BCustomerFound',
        )}
      </Typography>
    </Box>
  );
}

export default withTranslation()(B2BCustomerDetails);
