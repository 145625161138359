import { createSlice } from '@reduxjs/toolkit';
// import { uniqueId } from 'lodash';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const vehiclesSlice = createSlice({
  name: 'dealers',
  initialState: {
    dealers: [],
    dealerUsers: {},
  },
  reducers: {
    _dealersFetchSucceeded: (state, action) => {
      state.dealers = action.payload;
    },
    _dealerCreateSucceeded: (state, action) => {
      state.dealers = [...state.dealers, action.payload];
    },
    _dealerUsersFetchSucceeded: (state, action) => {
      const { dealerId, users } = action.payload;
      state.dealerUsers[dealerId] = users;
    },
  },
});

export const {
  _dealersFetchSucceeded,
  _dealerCreateSucceeded,
  _dealerUsersFetchSucceeded,
} = vehiclesSlice.actions;

export const dealersReducer = vehiclesSlice.reducer;

export const getAllDealers = () => async (dispatch) => {
  try {
    const response = await api.dealers.getAll();
    await dispatch(_dealersFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewDealer = (dealerData) => async (dispatch) => {
  try {
    const response = await api.dealers.createNew(dealerData);
    await dispatch(_dealerCreateSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const getAllDealerUsers = (dealerId) => async (dispatch) => {
  try {
    const response = await api.dealers.getAllDealerUsers(dealerId);
    await dispatch(_dealerUsersFetchSucceeded({ users: response, dealerId }));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewDealerUser = (dealerUserData) => async () => {
  try {
    await api.dealers.createNewDealerUser(dealerUserData);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
