import { byAccessRightsExecutor } from '../../common/helpers/common';
import { _authenticateSucceeded } from '../auth/slice';
import { getAllDamageTypeGroups } from './slice';

export const damageTypeGroupsMiddleware = ({ dispatch }) => {
  return (next) => async (action) => {
    switch (action.type) {
      case _authenticateSucceeded.type: {
        try {
          await byAccessRightsExecutor({
            actionsByAccessRights: [
              {
                accessRights: ['canViewDamageTypeGroups'],
                action: async () => {
                  await dispatch(getAllDamageTypeGroups());
                },
              },
            ],
            userAccessRights: action.payload.accessRights,
          });
        } catch (e) {
          console.error(e);
        }

        break;
      }

      default:
        break;
    }

    return await next(action);
  };
};
