import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const CompanyIcon = createSvgIcon(
  <svg viewBox="0 0 30 30">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67157 1.67157C5.42172 0.921427 6.43913 0.5 7.5 0.5H22.5C23.5609 0.5 24.5783 0.921427 25.3284 1.67157C26.0786 2.42172 26.5 3.43913 26.5 4.5V27.5H28.5C29.0523 27.5 29.5 27.9477 29.5 28.5C29.5 29.0523 29.0523 29.5 28.5 29.5H1.5C0.947715 29.5 0.5 29.0523 0.5 28.5C0.5 27.9477 0.947715 27.5 1.5 27.5H3.5V4.5C3.5 3.43913 3.92143 2.42172 4.67157 1.67157ZM5.5 27.5H11V21C11 20.337 11.2634 19.7011 11.7322 19.2322C12.2011 18.7634 12.837 18.5 13.5 18.5H16.5C17.163 18.5 17.7989 18.7634 18.2678 19.2322C18.7366 19.7011 19 20.337 19 21V27.5H24.5V4.5C24.5 3.96957 24.2893 3.46086 23.9142 3.08579C23.5391 2.71071 23.0304 2.5 22.5 2.5H7.5C6.96957 2.5 6.46086 2.71071 6.08579 3.08579C5.71071 3.46086 5.5 3.96957 5.5 4.5V27.5ZM17 27.5V21C17 20.8674 16.9473 20.7402 16.8536 20.6465C16.7598 20.5527 16.6326 20.5 16.5 20.5H13.5C13.3674 20.5 13.2402 20.5527 13.1464 20.6464C13.0527 20.7402 13 20.8674 13 21V27.5H17ZM9.5 7.5C9.5 6.94772 9.94771 6.5 10.5 6.5H12C12.5523 6.5 13 6.94772 13 7.5C13 8.05228 12.5523 8.5 12 8.5H10.5C9.94771 8.5 9.5 8.05228 9.5 7.5ZM17 7.5C17 6.94772 17.4477 6.5 18 6.5H19.5C20.0523 6.5 20.5 6.94772 20.5 7.5C20.5 8.05228 20.0523 8.5 19.5 8.5H18C17.4477 8.5 17 8.05228 17 7.5ZM9.5 13.5C9.5 12.9477 9.94771 12.5 10.5 12.5H12C12.5523 12.5 13 12.9477 13 13.5C13 14.0523 12.5523 14.5 12 14.5H10.5C9.94771 14.5 9.5 14.0523 9.5 13.5ZM17 13.5C17 12.9477 17.4477 12.5 18 12.5H19.5C20.0523 12.5 20.5 12.9477 20.5 13.5C20.5 14.0523 20.0523 14.5 19.5 14.5H18C17.4477 14.5 17 14.0523 17 13.5Z"
      fillOpacity="0.86"
    />
  </svg>,
  'Company',
);

export default CompanyIcon;
