import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    user: {},
    logoUrl: '',
  },
  reducers: {
    _authenticateStarted: () => {},
    _authenticateSucceeded: (state, action) => {
      state.isAuthenticated = true;
      state.user.id = action.payload.id;
      state.user.username = action.payload.username;
      state.user.displayName = action.payload.displayName;
      state.user.role = action.payload.role;
      state.user.roleDescription = action.payload.roleDescription;
      state.user.accessRights = action.payload.accessRights;
    },
    _authenticateFailed: (state) => {
      state.isAuthenticated = false;
    },

    _userDataFetchSucceeded: (state, action) => {
      state.user.id = action.payload.id;
      state.user.username = action.payload.username;
      state.user.displayName = action.payload.displayName;
      state.user.role = action.payload.role;
      state.user.roleDescription = action.payload.roleDescription;
    },

    _tokenRefreshSucceeded: (state, action) => {
      state.user.id = action.payload.id;
      state.user.username = action.payload.username;
      state.user.displayName = action.payload.displayName;
      state.user.role = action.payload.role;
      state.user.roleDescription = action.payload.roleDescription;
      state.user.accessRights = action.payload.accessRights;
    },
    _tokenRefreshFailed: () => {},
    _tokenRevokeSucceeded: () => {},
    _initialJwtRefresh: () => {},

    _logoutSucceeded: (state) => {
      state.isAuthenticated = false;
      state.user = {};
    },
    _appLogoFetchSucceeded: (state, action) => {
      state.logoUrl = action.payload.brandingLogoUrl;
    },
  },
});

export const {
  _authenticateSucceeded,
  _authenticateFailed,
  _tokenRefreshSucceeded,
  _tokenRefreshFailed,
  _tokenRevokeSucceeded,
  _initialJwtRefresh,
  _userDataFetchSucceeded,
  _logoutSucceeded,
  _appLogoFetchSucceeded,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

// actions

export const fetchUserData = () => async (dispatch) => {
  const response = await api.auth.me();
  if (!response.error) {
    await dispatch(_userDataFetchSucceeded(response));
    await dispatch(fetchBrandingLogo());
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    const response = await api.auth.login(email, password);
    await dispatch(_authenticateSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const register = (email, password, name) => async (dispatch) => {
  try {
    const response = await api.auth.register(email, password, name);
    await dispatch(_authenticateSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const logout = () => async (dispatch) => {
  try {
    const response = await api.auth.revokeToken();
    await dispatch(_logoutSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const passwordlessAuth = (email, token) => async (dispatch) => {
  try {
    const response = await api.auth.passwordlessAuth({ email, token });
    await dispatch(_authenticateSucceeded(response));
    await dispatch(fetchBrandingLogo());
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const fetchBrandingLogo = () => async (dispatch, getState) => {
  const { accessRights } = getState().auth?.user;

  if (!accessRights || !accessRights.canViewSpecificAppLogo) return;

  try {
    const response = await api.auth.fetchAppLogo();
    dispatch(_appLogoFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
