import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../../../common/components/DataGrid/DataGrid';
import ExpandableButton from '../../../../../../common/components/ExpandableButton/ExpandableButton';
import AddIcon from '@mui/icons-material/Add';
import { B2B_CUSTOMER_USERS_ROLES } from './constants';

function CustomerUsers({
  t = () => {},
  customerId = '',
  customerUsers = [],
  getAllB2BCustomerUsers = () => {},
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser = () => {},
  redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser = () => {},
  redirectToAppCustomersB2BCustomerDetailsUsersUserDetails = () => {},
}) {
  useEffect(async () => {
    try {
      await getAllB2BCustomerUsers(customerId);
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (customerUsers.length > 0) {
    const rows = customerUsers.map((u) => {
      return {
        ...u,
        address: (u.address && u.address.name) || '-',
        telephone: u.telephone || '-',
      };
    });
    const columns = [
      {
        sx: { flex: { xs: '0 0 150px', md: 1 } },
        field: 'displayName',
        headerName: t('controlCategories.customers.b2b.details.users.name'),
      },
      {
        sx: { flex: { xs: '0 0 150px', md: 1 } },
        field: 'email',
        headerName: t('controlCategories.customers.b2b.details.users.email'),
      },
      {
        sx: { flex: { xs: '0 0 150px', md: 1 } },
        field: 'telephone',
        headerName: t(
          'controlCategories.customers.b2b.details.users.phoneNumber',
        ),
      },
      {
        sx: { flex: { xs: '0 0 150px', md: 1 } },
        field: 'address',
        headerName: t('controlCategories.customers.b2b.details.users.address'),
      },
      {
        sx: { flex: { xs: '0 0 150px', md: 1 } },
        headerName: t('controlCategories.customers.b2b.details.users.role'),
        renderRowCell: ({ rowData: { role = '' } }) => {
          return (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                minWidth: '100px',
                px: 1,
              }}
            >
              <Typography>
                {role === B2B_CUSTOMER_USERS_ROLES.ADMIN
                  ? t('controlCategories.customers.b2b.details.users.admin')
                  : t('controlCategories.customers.b2b.details.users.user')}
              </Typography>
            </Box>
          );
        },
        filterPredicate: (rowData, filterValue) => {
          const roleTitle =
            rowData.role === B2B_CUSTOMER_USERS_ROLES.ADMIN
              ? t('controlCategories.customers.b2b.details.users.admin')
              : t('controlCategories.customers.b2b.details.users.user');
          return roleTitle.toLowerCase().includes(filterValue.toLowerCase());
        },
      },
    ];

    return (
      <Box
        sx={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ExpandableButton
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mb: 2, ml: 'auto' }}
          items={[
            {
              text: t('controlCategories.customers.b2b.details.users.admin'),
              onClick: () => {
                redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser(
                  customerId,
                );
              },
            },
            {
              text: t('controlCategories.customers.b2b.details.users.user'),
              onClick: () => {
                redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerUser(
                  customerId,
                );
              },
            },
          ]}
        >
          {t('controlCategories.customers.b2b.details.users.createNew')}
        </ExpandableButton>

        <Box
          sx={{
            backgroundColor: 'background.default',
            flex: 1,
            width: '100%',
            borderRadius: '16px',
            display: 'flex',
            overflow: 'hidden',
          }}
        >
          <CommonDataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            noDataToShowMessage={t('common.messages.noDataToShow')}
            selectRowHandler={(rowData) => {
              redirectToAppCustomersB2BCustomerDetailsUsersUserDetails({
                customerId,
                customerUserId: rowData.id,
              });
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography sx={{ mb: 2 }}>
        No admins have been added here yet.
      </Typography>

      <Button
        onClick={() => {
          redirectToAppCustomersB2BCustomerDetailsCreateNewCustomerAdminUser(
            customerId,
          );
        }}
        variant="contained"
      >
        Create new admin user
      </Button>
    </Box>
  );
}

export default withTranslation()(CustomerUsers);
