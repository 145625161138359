import Box from '@mui/system/Box';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import moment from 'moment';
import {
  getAssessmentApprovalStateColorByState,
  getAssessmentApprovalStateTitleByState,
} from './helpers';
import { SUB_FLOW_STATE_ENUM } from '../../../../common/constants/vehicles';
import CustomDataGridV3 from '../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import { CustomDataGridToolbar } from '../AllCars/AllCars';
import {
  getCarsSortEnumByField,
  getSortEnumBySortTypeString,
} from '../../../../common/helpers/vehicles';
import { getChannelNameByEnum } from '../CarDetails/helpers';

function AssessmentApprovesCars({
  t = () => {},
  redirectToAppVehiclesListAssessmentApprovesCarsCarDetails = () => {},
  getVehicles = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('common.titles.car'),
    },
    {
      field: 'carModel',
      headerName: t('common.titles.model'),
    },
    {
      field: 'plateNo',
      headerName: t('common.titles.numbers'),
    },
    {
      field: 'lastAssessmentSent',
      headerName: t('common.titles.lastAssessmentSent'),
    },
    {
      field: '_approvalState',
      headerName: t('common.titles.approvalState'),
      renderCell: (params = {}) => (
        <Button
          color={getAssessmentApprovalStateColorByState(
            params.row.aldAssessmentApproveState,
          )}
          variant="contained"
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            cursor: 'default',
          }}
        >
          <Typography
            noWrap
            sx={{
              width: '100%',
              fontSize: '12px',
              textAlign: 'center',
            }}
          >
            {getAssessmentApprovalStateTitleByState(
              params.row.aldAssessmentApproveState,
              t,
            )}
          </Typography>
        </Button>
      ),
    },
    {
      field: 'salesChannel',
      headerName: 'Sales channel',
      valueGetter: (params = {}) => {
        return getChannelNameByEnum(params.row.salesChannel);
      },
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      lastAssessmentSent: moment(car.lastAssessmentSent).format('DD/MM/YYYY'),
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sortModel, setSortModel] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListAssessmentApprovesCarsCarDetails(
            params.row.id,
          );
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        paginationCallback={async (offset, limit, plateNo, _sortModel) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [
                SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP,
                SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP,
                SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
                SUB_FLOW_STATE_ENUM.READY_FOR_SALES,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_SALES,
              ],
              undefined,
              plateNo,
              getCarsSortEnumByField(_sortModel.field),
              getSortEnumBySortTypeString(_sortModel.sort),
            );
            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[
          plateNumberSearchValue,
          sortModel,
        ]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
        onSortModelChange={(sortChangeData) => {
          if (sortChangeData && sortChangeData.length > 0) {
            setSortModel(sortChangeData[0]);
          }
        }}
      />
    </Box>
  );
}

export default withTranslation()(AssessmentApprovesCars);
