import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

function NAFAssessmentInProcess() {
  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          3rd Party assessment in process
        </Typography>
        <LinearProgress sx={{ width: '100%', m: '10px' }} />
      </Box>
    </Box>
  );
}

export default NAFAssessmentInProcess;
