import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const FileAddIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 13H13.0002V12C13.0002 11.448 12.5532 11 12.0002 11C11.4472 11 11.0002 11.448 11.0002 12V13H10.0002C9.44719 13 9.00019 13.448 9.00019 14C9.00019 14.552 9.44719 15 10.0002 15H11.0002V16C11.0002 16.552 11.4472 17 12.0002 17C12.5532 17 13.0002 16.552 13.0002 16V15H14.0002C14.5532 15 15.0002 14.552 15.0002 14C15.0002 13.448 14.5532 13 14.0002 13ZM17.4445 20H6.55549C6.24949 20 6.00049 19.776 6.00049 19.5V4.5C6.00049 4.224 6.24949 4 6.55549 4H12.0005V7.15C12.0005 8.722 13.2175 10 14.7145 10H18.0005V19.5C18.0005 19.776 17.7515 20 17.4445 20ZM14.0005 4.978L16.7425 8H14.7145C14.3205 8 14.0005 7.619 14.0005 7.15V4.978ZM19.7405 8.328L14.2965 2.328C14.1065 2.119 13.8385 2 13.5555 2H6.55549C5.14649 2 4.00049 3.122 4.00049 4.5V19.5C4.00049 20.878 5.14649 22 6.55549 22H17.4445C18.8535 22 20.0005 20.878 20.0005 19.5V9C20.0005 8.751 19.9075 8.512 19.7405 8.328Z"
    />
    <mask
      id="mask0_3031_5441"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="2"
      width="17"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0002 13H13.0002V12C13.0002 11.448 12.5532 11 12.0002 11C11.4472 11 11.0002 11.448 11.0002 12V13H10.0002C9.44719 13 9.00019 13.448 9.00019 14C9.00019 14.552 9.44719 15 10.0002 15H11.0002V16C11.0002 16.552 11.4472 17 12.0002 17C12.5532 17 13.0002 16.552 13.0002 16V15H14.0002C14.5532 15 15.0002 14.552 15.0002 14C15.0002 13.448 14.5532 13 14.0002 13ZM17.4445 20H6.55549C6.24949 20 6.00049 19.776 6.00049 19.5V4.5C6.00049 4.224 6.24949 4 6.55549 4H12.0005V7.15C12.0005 8.722 13.2175 10 14.7145 10H18.0005V19.5C18.0005 19.776 17.7515 20 17.4445 20ZM14.0005 4.978L16.7425 8H14.7145C14.3205 8 14.0005 7.619 14.0005 7.15V4.978ZM19.7405 8.328L14.2965 2.328C14.1065 2.119 13.8385 2 13.5555 2H6.55549C5.14649 2 4.00049 3.122 4.00049 4.5V19.5C4.00049 20.878 5.14649 22 6.55549 22H17.4445C18.8535 22 20.0005 20.878 20.0005 19.5V9C20.0005 8.751 19.9075 8.512 19.7405 8.328Z"
      />
    </mask>
    <g mask="url(#mask0_3031_5441)">
      <rect width="24" height="24" />
    </g>
  </>,
  'FileAdd',
);

export default FileAddIcon;
