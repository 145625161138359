import { Box } from '@mui/system';
import React from 'react';
import CommonHorizontalStepper from '../HorizontalStepper/HorizontalStepper';
import VerticalStepper from '../VerticalStepper/VerticalStepper';

function CommonTimeline({
  steps = [],
  mainTimelineStepSeparatorRenderer,
  subTimelineStepSeparatorRenderer,
  subTimelineStepContentRenderer,
  subTimelineStepOppositeContentRenderer,
  sx = {},
}) {
  return (
    <Box
      sx={{ height: '100%', display: 'flex', flexDirection: 'column', ...sx }}
    >
      <CommonHorizontalStepper
        steps={steps}
        renderSubStepsChainLine
        stepSeparatorRenderer={mainTimelineStepSeparatorRenderer}
      />

      <Box sx={{ display: 'flex', flex: 1 }}>
        {steps.map((step, index) => {
          return (
            <React.Fragment key={index}>
              <VerticalStepper
                sx={{ flex: 1, height: '100%', overflow: 'hidden' }}
                steps={step.subSteps}
                isSubStepsStepper
                stepSeparatorRenderer={subTimelineStepSeparatorRenderer}
                stepContentRenderer={subTimelineStepContentRenderer}
                stepOppositeContentRenderer={
                  subTimelineStepOppositeContentRenderer
                }
              />
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
}

export default CommonTimeline;
