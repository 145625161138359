import { connect } from 'react-redux';
import { redirectToAppVehiclesListAssessmentApprovesCarsCarDetails } from '../../../../store/redirect/slice';
import { getVehicles } from '../../../../store/vehicles/slice';
import AssessmentApprovesCars from './AssessmentApprovesCars';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListAssessmentApprovesCarsCarDetails,
  getVehicles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssessmentApprovesCars);
