import { connect } from 'react-redux';
import {
  createNewB2CCustomer,
  getB2CCustomersBrandings,
} from '../../../../../store/customers/slice';
import { redirectBackByBreadcrumb } from '../../../../../store/redirect/slice';
import CreateNewB2CCustomerForm from './B2C';

const mapStateToProps = (state) => ({
  b2cCustomersBrandings: state.customers.b2cCustomersBrandings,
});

const mapDispatchToProps = {
  createNewB2CCustomer,
  redirectBackByBreadcrumb,
  getB2CCustomersBrandings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewB2CCustomerForm);
