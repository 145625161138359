import { connect } from 'react-redux';
import { getAllB2CCustomers } from '../../../../store/customers/slice';
import { redirectToAppCustomersB2CCustomerDetailsCustomerInfo } from '../../../../store/redirect/slice';
import B2CCustomers from './B2C';

const mapStateToProps = (state) => {
  return {
    customers: state.customers.b2cCustomers,
  };
};

const mapDispatchToProps = {
  redirectToAppCustomersB2CCustomerDetailsCustomerInfo,
  getAllB2CCustomers,
};

export default connect(mapStateToProps, mapDispatchToProps)(B2CCustomers);
