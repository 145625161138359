import { withTheme } from '@emotion/react';
import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ACCESSORY_STATUS } from '../../../../../common/constants/accessories';
import AccessoryItem from './AccessoryItem';
import EquipmentModal from './EquipmentModal';

const MissingEquipmentCheck = ({
  vehicle,
  onSave,
  updateVehicleAccessories,
  accessories,
}) => {
  const { id } = vehicle;

  const [editEquipmentModalOpenedIndex, setEditEquipmentModalOpenedIndex] =
    useState(null);
  const [isAddEquipmentModalOpened, setAddEquipmentModalOpened] =
    useState(false);
  const [accessoriesState, setAccessories] = useState(accessories || []);

  const handleCreateAccessory = (accessory) => {
    setAccessories([...accessoriesState, accessory]);
  };

  const handleDeleteAccessory = (index) => {
    setAccessories(accessoriesState.filter((_, idx) => index !== idx));
  };

  const handleUpdateAccessory = (accessory, index) => {
    setAccessories(
      accessoriesState.map((x, idx) => {
        if (idx === index) {
          return {
            ...x,
            ...accessory,
          };
        }
        return x;
      }),
    );
  };

  const handleCheckAccessory = (index, checked) => {
    setAccessories(
      accessoriesState.map((a, idx) => {
        if (index === idx) {
          return {
            ...a,
            accessoryRepairStatus: checked
              ? ACCESSORY_STATUS.NEED_REPAIR
              : ACCESSORY_STATUS.OK,
          };
        }
        return a;
      }),
    );
  };

  const handleSave = async () => {
    await updateVehicleAccessories(id, accessoriesState);
    onSave();
  };
  return (
    <Box
      sx={{
        p: 2,
        overflow: { xs: 'scroll', md: 'unset' },
      }}
    >
      <Typography sx={{ fontWeight: 500 }} color="primary">
        Equipment
      </Typography>

      <Typography sx={{ fontWeight: 'normal' }}>
        Select please those parts that need repair
      </Typography>

      <Box sx={{ mt: '20px', mb: '10px' }}>
        {accessoriesState.map((x, index) => {
          return (
            <React.Fragment key={index}>
              <AccessoryItem
                key={x.id}
                accessory={x}
                onCheck={(checked) => handleCheckAccessory(index, checked)}
                onDelete={() => handleDeleteAccessory(index)}
                onEdit={() => setEditEquipmentModalOpenedIndex(index)}
              />
              <EquipmentModal
                isOpened={editEquipmentModalOpenedIndex === index}
                headerTitle="Edit equipment"
                buttonTitle="Change"
                name={x.name}
                description={x.description}
                accessoryRepairStatus={x.repairStatus}
                onClose={() => setEditEquipmentModalOpenedIndex(null)}
                onClick={(accessory) => handleUpdateAccessory(accessory, index)}
              />
            </React.Fragment>
          );
        })}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => setAddEquipmentModalOpened(true)}>
          + Add New Equipment
        </Button>
      </Box>

      <Button
        fullWidth
        sx={{ mt: { xs: '70px', md: '10px' }, height: '40px' }}
        variant="contained"
        onClick={handleSave}
      >
        SAVE
      </Button>

      <EquipmentModal
        isOpened={isAddEquipmentModalOpened}
        headerTitle="Add new equipment"
        buttonTitle="Add"
        onClose={() => setAddEquipmentModalOpened(false)}
        onClick={handleCreateAccessory}
        clearOnClose
      />
    </Box>
  );
};

export default withTheme(MissingEquipmentCheck);
