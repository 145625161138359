import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CustomDataGridV3 from '../../../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import { getLocationByStatusFlow } from '../../../../../../common/constants/vehicles';
import { appendDaysWord } from '../../../../../../common/helpers/common';
import { CustomDataGridToolbar } from '../../../../VehiclesList/AllCars/AllCars';
import { getChannelNameByEnum } from '../../../../VehiclesList/CarDetails/helpers';

function VehiclesList({
  t = () => {},
  redirectToAppVehiclesListInProcessCarsCarDetails = () => {},
  getVehicles = () => {},
  customerId,
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.car'),
    },
    {
      field: 'carModel',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.model'),
    },
    {
      field: 'plateNo',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.numbers'),
    },
    {
      field: 'arrivedToALD',
      headerName: t('common.titles.arrivedToALD'),
    },
    {
      field: 'lastUpdate',
      headerName: t('common.titles.lastUpdate'),
    },
    {
      headerName: t('common.titles.stage'),
      renderCell: (params = {}) => (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minWidth: 0,
            px: 1,
          }}
        >
          <Tooltip title={getLocationByStatusFlow(params.row.currentStateEnum)}>
            <Typography noWrap>
              {getLocationByStatusFlow(params.row.currentStateEnum)}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'salesChannel',
      headerName: 'Sales channel',
      valueGetter: (params = {}) => {
        return getChannelNameByEnum(params.row.salesChannel);
      },
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      lastUpdate: appendDaysWord(car.lastUpdate),
      arrivedToALD: car.arrivedToALD
        ? moment(car.arrivedToALD).format('DD/MM/YYYY')
        : '-',
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { xs: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListInProcessCarsCarDetails(params.row.id);
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        paginationCallback={async (offset, limit, plateNo) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [],
              customerId,
              plateNo,
            );
            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[plateNumberSearchValue]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
      />
    </Box>
  );
}

export default withTranslation()(VehiclesList);
