import { Box } from '@mui/system';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { withTheme } from '@emotion/react';
import moment from 'moment';

function WaitingStartReturn({
  t = () => {},
  vehicle = {},
  getVehicleFlowState = () => {},
  changeVehicleBooking = () => {},
  currentVehicleBooking = { returnAddress: {} },
  currentVehicleFlowState = {},
  theme = {},
}) {
  const { id, contractId } = vehicle;

  const {
    bookingDate,
    contactPersonName,
    contactPersonTelephone,
    returnAddress: { name: returnAddressString },
  } = currentVehicleBooking;
  const { id: currentStateId } = currentVehicleFlowState;

  return (
    <Box
      sx={{
        p: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          overflow: 'auto',
          minWidth: 0,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              borderRadius: '26px',
              border: `1px solid ${theme.palette.primary.main}`,
              py: 0.5,
              px: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ color: 'primary.main' }}>
              {moment(bookingDate).format('Do MMMM, HH:mm')}
            </Typography>

            {contactPersonName && contactPersonTelephone && (
              <Typography>{`${contactPersonName} ${contactPersonTelephone}`}</Typography>
            )}

            <Typography sx={{ fontStyle: 'italic', fontWeight: 300 }}>
              {returnAddressString}
            </Typography>
          </Box>

          <Button
            sx={{ width: '100%', mt: 'auto' }}
            color="primary"
            variant="outlined"
            type="submit"
            onClick={async () => {
              try {
                await changeVehicleBooking(currentStateId);
                await getVehicleFlowState(id, contractId);
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {t('common.titles.changeBooking')}
          </Button>
        </Box>
      </div>
    </Box>
  );
}

export default withTranslation()(withTheme(WaitingStartReturn));
