import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';
import { byAccessRightsExecutor } from '../../../../../common/helpers/common';

function WashInProcess({
  t = () => {},
  switchToNextVehicleFlowState = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  userAccessRights = {},
  redirectToAppVehiclesList = () => {},
  getVehicleFlowState = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          Washing in process
        </Typography>
        <LinearProgress sx={{ width: '100%', m: '10px' }} />
        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          To complete the washing press “Completed”
        </Typography>
      </Box>

      <Button
        sx={{ width: '100%', mt: 'auto' }}
        color="primary"
        variant="outlined"
        onClick={async () => {
          try {
            await switchToNextVehicleFlowState(
              id,
              currentStateId,
              SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
            );
            await byAccessRightsExecutor({
              actionsByAccessRights: [
                {
                  accessRights: [
                    'canAutomaticallyReturnToCarsListAfterWorkFinished',
                  ],
                  action: async () => {
                    await redirectToAppVehiclesList();
                    enqueueSnackbar(
                      t('common.messages.jobFinishedCanNoMoreAvailableForYou'),
                      { variant: 'success' },
                    );
                  },
                },
              ],
              userAccessRights,
              fallBackAction: async () => {
                await getVehicleFlowState(id, contractId);
              },
            });
          } catch (error) {
            console.error(error);
          }
        }}
      >
        Completed
      </Button>
    </Box>
  );
}

export default withTranslation()(WashInProcess);
