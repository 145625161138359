import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../../../store/appCommon/slice';
import {
  addCommentToSheet,
  removeCommentFromSheet,
  resendAssessmentInfo,
} from '../../../../../store/vehicles/slice';
import ALDAssessment from './ALDAssessment';

const mapStateToProps = (state) => {
  return {
    damageTypes: state.damageTypes.damageTypesLookup,
    damageTypeGroups: state.damageTypeGroups.groupsLookup,
    userId: state.auth.user.id,
  };
};

const mapDispatchToProps = {
  setFsUploadIds,
  addCommentToSheet,
  removeCommentFromSheet,
  resendAssessmentInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ALDAssessment);
