import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

function CustomLoadingButton({
  onClick = async () => {},
  children,
  ...otherProps
}) {
  const [isLoading, setLoading] = useState(false);

  return (
    <LoadingButton
      loading={isLoading}
      onClick={async (e) => {
        setLoading(true);
        await onClick(e);
        setLoading(false);
      }}
      {...otherProps}
    >
      {children}
    </LoadingButton>
  );
}

export default CustomLoadingButton;
