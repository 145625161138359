export const NAF_POINT_STATUSES = {
  NOT_DONE: 2,
  OK: 4,
  NEED_REPAIR: 8,
  DEVIATION: 16,
  DOES_NOT_EXIST: 32,
  INFORMATION: 64,
  WORN: 128,
  NEED_ADJUSTMENT: 256,
};

export const NAF_POINT_STATUSES_NAMES = {
  [NAF_POINT_STATUSES.NOT_DONE]: 'Not done',
  [NAF_POINT_STATUSES.OK]: 'Ok',
  [NAF_POINT_STATUSES.NEED_REPAIR]: 'Need repair',
  [NAF_POINT_STATUSES.DEVIATION]: 'Deviation',
  [NAF_POINT_STATUSES.DOES_NOT_EXIST]: 'Does not exist',
  [NAF_POINT_STATUSES.INFORMATION]: 'Information',
  [NAF_POINT_STATUSES.WORN]: 'Worn',
  [NAF_POINT_STATUSES.NEED_ADJUSTMENT]: 'Need adjustment',
};

export const NAF_POINT_STATUS_INPUTS = Object.keys(
  NAF_POINT_STATUSES_NAMES,
).map((key) => ({
  id: key,
  title: NAF_POINT_STATUSES_NAMES[key],
}));

export const NAF_ERROR_TYPES = {
  NEED_REPAIR: 8,
  DEVIATION: 16,
  INFORMATION: 64,
  WORN: 128,
  NEED_ADJUSTMENT: 256,
};
export const NAF_ERROR_TYPES_NAMES = {
  [NAF_ERROR_TYPES.NEED_REPAIR]: 'Need repair',
  [NAF_ERROR_TYPES.DEVIATION]: 'Deviation',
  [NAF_ERROR_TYPES.INFORMATION]: 'Information',
  [NAF_ERROR_TYPES.WORN]: 'Worn',
  [NAF_ERROR_TYPES.NEED_ADJUSTMENT]: 'Need adjustment',
};

export const NAF_ERROR_TYPE_INPUTS = Object.keys(NAF_ERROR_TYPES_NAMES).map(
  (key) => ({
    id: key,
    title: NAF_ERROR_TYPES_NAMES[key],
  }),
);

export const NAF_ASSESSMENT_DAMAGE_GROUPS = {
  DIVERSE: 0,
  KOSMETISK: 3,
  MEKANISK: 4,
  HJULOGDEKK: 5,
};

export const NAF_ASSESSMENT_DAMAGE_GROUP_NAMES = {
  [NAF_ASSESSMENT_DAMAGE_GROUPS.DIVERSE]: 'Diverse',
  [NAF_ASSESSMENT_DAMAGE_GROUPS.KOSMETISK]: 'Kosmetisk',
  [NAF_ASSESSMENT_DAMAGE_GROUPS.MEKANISK]: 'Mekanisk',
  [NAF_ASSESSMENT_DAMAGE_GROUPS.HJULOGDEKK]: 'HjulOgDekk',
};

export const NAF_DAMAGE_GROUP_INPUTS = Object.keys(
  NAF_ASSESSMENT_DAMAGE_GROUP_NAMES,
).map((key) => ({ id: key, title: NAF_ASSESSMENT_DAMAGE_GROUP_NAMES[key] }));

export const SUPPLIER_CONFIRM_STATUS = {
  NOT_CONFIRMED: 0,
  WAITING_FOR_CONFIRMATION: 1,
  CONFIRMED: 2,
};

export const SUPPLIER_CONFIRM_STATUS_NAMES = {
  [SUPPLIER_CONFIRM_STATUS.NOT_CONFIRMED]: 'Not confirmed',
  [SUPPLIER_CONFIRM_STATUS.WAITING_FOR_CONFIRMATION]:
    'Waiting for confirmation',
  [SUPPLIER_CONFIRM_STATUS.CONFIRMED]: 'Confirmed',
};

export const SUPPLIER_CONFIRM_STATUS_INPUTS = Object.keys(
  SUPPLIER_CONFIRM_STATUS_NAMES,
).map((key) => ({ id: key, title: SUPPLIER_CONFIRM_STATUS_NAMES[key] }));

export const ASSESSMENT_SUPPLIER_STATUS = {
  NOT_NEED_REPAIR: 1,
  NEED_REPAIR: 0,
};
export const ASSESSMENT_SUPPLIER_STATUS_NAMES = {
  [ASSESSMENT_SUPPLIER_STATUS.NOT_NEED_REPAIR]: 'No need to repair',
  [ASSESSMENT_SUPPLIER_STATUS.NEED_REPAIR]: 'Need repair',
};

export const ASSESSMENT_SUPPLIER_STATUS_INPUTS = Object.keys(
  ASSESSMENT_SUPPLIER_STATUS_NAMES,
).map((key) => ({ id: key, title: ASSESSMENT_SUPPLIER_STATUS_NAMES[key] }));

export const ASSESSMENT_APPROVAL_STATE = {
  NONE: 0,
  APPROVED: 1,
  DECLINED: 2,
  FORCE_APPROVED: 3,
};

export const getUnusedDamageTypes = (damageTypes, assessment) => {
  const usedDamageTypeIds = [];
  for (const repair of assessment.repairs)
    for (const sheet of repair.assessmentSheets)
      usedDamageTypeIds.push(sheet.damageTypeId);

  return damageTypes.filter((x) => !usedDamageTypeIds.includes(x.id));
};

export const getUnusedDamageTypeGroups = (damageTypeGroups, assessment) => {
  const usedDamageTypeGroupIds = [];
  for (const repair of assessment.repairs)
    usedDamageTypeGroupIds.push(repair.damageTypeGroupId);

  return damageTypeGroups.filter((x) => !usedDamageTypeGroupIds.includes(x.id));
};
