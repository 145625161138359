function WithAccessRightsRenderer({
  contentByAccessRights = [],
  fallBackContent = null,
  userAccessRights = {},
}) {
  for (let i = 0; i < contentByAccessRights.length; ++i) {
    const rightsCase = contentByAccessRights[i];
    if (
      rightsCase.accessRights.every((accessRight) => {
        return userAccessRights[accessRight];
      })
    ) {
      return rightsCase.content;
    }
  }

  return fallBackContent;
}

export default WithAccessRightsRenderer;
