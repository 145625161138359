import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import GridGalleryContainer from '../../../../../common/components/GridGallery/GridGallery.container';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

function ReadyForQRGenerating({
  contractBeforeImages = [],
  contractAfterImages = [],
  switchToNextVehicleFlowState = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  getVehicleFlowState = () => {},
  setFsUploadIds = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  return (
    <>
      <Box
        sx={{
          p: 2,
          pt: { xs: 2, xxs: 0.5 },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box
          sx={{
            pb: { xxs: 0.5, xs: 2 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ color: 'primary.main' }}>
            Ready for QR generating
          </Typography>

          <Typography
            sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
          >
            Press the button to start generating QR
          </Typography>
        </Box>

        <Typography sx={{ fontWeight: 'bold' }}>
          Uploads before return
        </Typography>
        <GridGalleryContainer
          sx={{ flex: 'unset', mt: 1, mb: 1 }}
          maxRenderingImagesCount={3}
          uploadIds={contractBeforeImages.map((imgData) => imgData.uploadId)}
          onClickMore={() =>
            setFsUploadIds(contractBeforeImages.map((x) => x.uploadId))
          }
        />

        <Typography sx={{ fontWeight: 'bold' }}>
          Uploads after return
        </Typography>
        <GridGalleryContainer
          sx={{ flex: 'unset', mt: 1 }}
          maxRenderingImagesCount={3}
          uploadIds={contractAfterImages.map((imgData) => imgData.uploadId)}
          onClickMore={() =>
            setFsUploadIds(contractAfterImages.map((x) => x.uploadId))
          }
        />

        <Button
          sx={{ width: '100%', mt: '15px' }}
          color="primary"
          variant="contained"
          onClick={async () => {
            await switchToNextVehicleFlowState(
              id,
              currentStateId,
              SUB_FLOW_STATE_ENUM.QR_GENERATING,
            );
            await getVehicleFlowState(id, contractId);
          }}
        >
          Generate QR
        </Button>
      </Box>
    </>
  );
}

export default ReadyForQRGenerating;
