export const dealersMiddleware = () => {
  return (next) => async (action) => {
    switch (action.type) {
      default:
        break;
    }

    return await next(action);
  };
};
