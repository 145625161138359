import Box from '@mui/system/Box';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import {
  getLocationByStatusFlow,
  getProcessStatusByStatusFlow,
  SUB_FLOW_STATE_ENUM,
} from '../../../../common/constants/vehicles';
import { appendDaysWord } from '../../../../common/helpers/common';
import { CustomDataGridToolbar } from '../AllCars/AllCars';
import CustomDataGridV3 from '../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import {
  getCarsSortEnumByField,
  getSortEnumBySortTypeString,
} from '../../../../common/helpers/vehicles';
import { getEstimationColorByEnumValue } from '../CarDetails/SupplierLogs/helpers';
import { getChannelNameByEnum } from '../CarDetails/helpers';

function AlreadyInProcessCars({
  t = () => {},
  redirectToAppVehiclesListInProcessCarsCarDetails = () => {},
  getVehicles = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.car'),
    },
    {
      field: 'carModel',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.model'),
    },
    {
      field: 'plateNo',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.numbers'),
    },
    {
      field: 'arrivedToALD',
      headerName: t('common.titles.arrivedToALD'),
    },
    {
      field: 'lastUpdate',
      headerName: t('common.titles.lastUpdate'),
    },
    {
      field: '_location',
      headerName: t('common.titles.stage'),
      renderCell: (params = {}) => (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            minWidth: 0,
            px: 1,
          }}
        >
          <Tooltip title={getLocationByStatusFlow(params.row.currentStateEnum)}>
            <Typography noWrap>
              {getLocationByStatusFlow(params.row.currentStateEnum)}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: '_status',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.status'),
      renderCell: (params = {}) => {
        const { state } = getProcessStatusByStatusFlow(
          params.row.currentStateEnum,
        );
        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              minWidth: 0,
              px: 1,
            }}
          >
            <Button
              color={getEstimationColorByEnumValue(params.row.workshopColor)}
              variant="contained"
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                noWrap
                sx={{
                  width: '100%',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                {state || ''}
              </Typography>
            </Button>
          </Box>
        );
      },
    },
    {
      field: 'salesChannel',
      headerName: 'Sales channel',
      valueGetter: (params = {}) => {
        return getChannelNameByEnum(params.row.salesChannel);
      },
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      lastUpdate: appendDaysWord(car.lastUpdate),
      arrivedToALD: car.arrivedToALD
        ? moment(car.arrivedToALD).format('DD/MM/YYYY')
        : '-',
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sortModel, setSortModel] = useState({
    field: 'lastUpdate',
    sort: 'desc',
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { xs: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListInProcessCarsCarDetails(params.row.id);
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        paginationCallback={async (offset, limit, plateNo, _sortModel) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [
                SUB_FLOW_STATE_ENUM.WAITING_UNTIL_ALD_UPDATE_MILEAGE_AFTER_RETURN,
                SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_AFTER_RETURN,
                SUB_FLOW_STATE_ENUM.READY_FOR_QR_GENERATING,
                SUB_FLOW_STATE_ENUM.QR_GENERATING,
                SUB_FLOW_STATE_ENUM.WAITING_UNTIL_QR_PLACES_ON_THE_CAR,
                SUB_FLOW_STATE_ENUM.READY_FOR_ACCESSORIES_CHECK,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_CHECK_MISSING_ACCESSORIES,
                SUB_FLOW_STATE_ENUM.IN_PROCESS_CHECK_MISSING_ACCESSORIES,
                SUB_FLOW_STATE_ENUM.READY_FOR_WASH,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_WASH,
                SUB_FLOW_STATE_ENUM.IN_PROCESS_WASH,
                SUB_FLOW_STATE_ENUM.READY_FOR_NAF_ASSESSMENT,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_NAF_ASSESSMENT,
                SUB_FLOW_STATE_ENUM.IN_PROCESS_NAF_ASSESSMENT,
                SUB_FLOW_STATE_ENUM.READY_FOR_ALD_ASSESSMENT,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_ALD_ASSESSMENT,
                SUB_FLOW_STATE_ENUM.IN_PROCESS_ALD_ASSESSMENT,
                SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP,
                SUB_FLOW_STATE_ENUM.IN_PROCESS_WORKSHOP,
                SUB_FLOW_STATE_ENUM.READY_FOR_THE_NEXT_WORKSHOP_GROUP_OR_COMPLETE,
              ],
              undefined,
              plateNo,
              getCarsSortEnumByField(_sortModel.field),
              getSortEnumBySortTypeString(_sortModel.sort),
            );
            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[
          plateNumberSearchValue,
          sortModel,
        ]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
        onSortModelChange={(sortChangeData) => {
          if (sortChangeData && sortChangeData.length > 0) {
            setSortModel(sortChangeData[0]);
          }
        }}
      />
    </Box>
  );
}

export default withTranslation()(AlreadyInProcessCars);
