import { connect } from 'react-redux';
import { redirectBackByBreadcrumb } from '../../../../../../../store/redirect/slice';
import { createNewSupplierUser } from '../../../../../../../store/suppliers/slice';
import CreateNewSupplierUser from './CreateNewUser';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createNewSupplierUser,
  redirectBackByBreadcrumb,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewSupplierUser);
