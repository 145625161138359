import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { ROUTING_PATH_NAMES } from '../../../../common/constants/constants';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export const DASHBOARD_TABS = {
  TODAY: 0,
  LAST_WEEK: 1,
  LAST_MONTH: 2,
};

function DashboardTabs({ t = () => {}, selectedTabId = DASHBOARD_TABS.TODAY }) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{ borderBottom: '1px solid #E0E0E0', mb: 1 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          LinkComponent={RouterLink}
          to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.TODAY}`}
          id={DASHBOARD_TABS.TODAY}
          label={t('dashboard.today')}
        />

        <Tab
          LinkComponent={RouterLink}
          to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_WEEK}`}
          id={DASHBOARD_TABS.LAST_WEEK}
          label={t('dashboard.last7Days')}
        />

        <Tab
          LinkComponent={RouterLink}
          to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.DASHBOARD}/${ROUTING_PATH_NAMES.LAST_MONTH}`}
          id={DASHBOARD_TABS.LAST_MONTH}
          label={t('dashboard.last30Days')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(DashboardTabs);
