import {
  Button,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CommonFormTitle from '../../../../../common/components/FormTitle/FormTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMounted from '../../../../../common/hooks/useMounted';
import { SUPPLIERS_TYPES } from '../../../../../common/constants/suppliers';

function CreateNewWorkshopSupplier({
  t = () => {},
  createNewSupplier = () => {},
  redirectToAppSuppliersWorkshop = () => {},
  damageTypeGroups = [],
}) {
  const mounted = useMounted();
  const initialDmgTypeGroupsState = {};
  damageTypeGroups.forEach((group) => {
    initialDmgTypeGroupsState[group.id] = false;
  });
  const [dmgTypeGroupsState, setDmgTypeGroupsState] = useState({
    ...initialDmgTypeGroupsState,
  });

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        postalCode: '',
        city: '',
        address: '',
        email: '',
        phoneNumber: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name field is required'),
        description: Yup.string().required('Description field is required'),
        postalCode: Yup.string().required('Postal code field is required'),
        city: Yup.string().required('City field is required'),
        address: Yup.string().required('Address field is required'),
        email: Yup.string()
          .email('Email must be valid')
          .required('Email field is required'),
        phoneNumber: Yup.string().required('Phone field is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await createNewSupplier({
            name: values.name,
            description: values.description,
            postCode: values.postalCode,
            city: values.city,
            address: values.address,
            email: values.email,
            phone: values.phoneNumber,
            supplierType: SUPPLIERS_TYPES.WORKSHOP,
            damageTypeGroupIds: Object.keys(dmgTypeGroupsState).filter(
              (groupId) => dmgTypeGroupsState[groupId],
            ),
          });
          await redirectToAppSuppliersWorkshop();

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <CommonFormTitle
            text={t('controlCategories.suppliers.workshop.create.title')}
            icon={<InfoOutlinedIcon />}
            sx={{ mt: 3 }}
          />

          <Divider />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 2.5,
              py: 1,
              paddingBottom: 3,
              backgroundColor: 'background.default',
              borderBottomLeftRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                overflow: 'auto',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    label={t(
                      'controlCategories.suppliers.workshop.create.name',
                    )}
                    margin="normal"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label={t(
                      'controlCategories.suppliers.workshop.create.description',
                    )}
                    margin="normal"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.description}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t('controlCategories.suppliers.wash.create.email')}
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    fullWidth
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    label={t(
                      'controlCategories.suppliers.wash.create.phoneNumber',
                    )}
                    margin="normal"
                    name="phoneNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.phoneNumber}
                    variant="outlined"
                  />
                </Box>

                <Box
                  sx={{
                    ml: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <TextField
                    error={Boolean(touched.postalCode && errors.postalCode)}
                    fullWidth
                    helperText={touched.postalCode && errors.postalCode}
                    label={t(
                      'controlCategories.suppliers.workshop.create.postalCode',
                    )}
                    margin="normal"
                    name="postalCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.postalCode}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    label={t(
                      'controlCategories.suppliers.workshop.create.city',
                    )}
                    margin="normal"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.city}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    label={t(
                      'controlCategories.suppliers.workshop.create.address',
                    )}
                    margin="normal"
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.address}
                    variant="outlined"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {damageTypeGroups.map((dmgGroup, dmgGroupIndex) => {
                  return (
                    <Box sx={{ pr: 1, mb: 1 }} key={dmgGroupIndex}>
                      <FormControlLabel
                        sx={{
                          ml: 0,
                          mr: 1.5,
                          border: '1px solid #c4c4c4',
                          height: '56px',
                          borderRadius: '16px',
                          pl: 2,
                          '& .MuiTypography-root': {},
                        }}
                        control={
                          <Switch
                            checked={dmgTypeGroupsState[dmgGroup.id]}
                            color="primary"
                            onChange={(e) => {
                              setDmgTypeGroupsState({
                                ...dmgTypeGroupsState,
                                [dmgGroup.id]: e.target.checked,
                              });
                            }}
                          />
                        }
                        label={dmgGroup.name}
                        labelPlacement="start"
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>

            {errors.submit && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>

          <Button
            variant="contained"
            color="primary"
            disabled={
              isSubmitting ||
              !values.name ||
              !values.description ||
              !values.postalCode ||
              !values.city ||
              !values.address
            }
            sx={{ width: '25%', ml: 'auto', mt: 3, mb: 2 }}
            type="submit"
          >
            {t('common.buttons.save')}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default withTranslation()(CreateNewWorkshopSupplier);
