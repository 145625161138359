import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../../common/components/DataGrid/DataGrid';
import AddIcon from '@mui/icons-material/Add';

function DealerUsers({
  t = () => {},
  dealerId = '',
  dealerUsers = [],
  getAllDealerUsers = () => {},
  redirectToAppDealersDealerDetailsDealerUsersCreateNewUser = () => {},
  redirectToAppDealersDealerDetailsDealerUsersUserInfo = () => {},
}) {
  useEffect(async () => {
    try {
      await getAllDealerUsers(dealerId);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const rows = dealerUsers.map((u) => {
    return { ...u, address: (u.address && u.address.name) || '-' };
  });
  const columns = [
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'displayName',
      headerName: t('common.titles.name'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'email',
      headerName: t('common.titles.email'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'telephone',
      headerName: t('common.titles.telephone'),
    },
    {
      sx: { flex: { xs: '0 0 150px', md: 1 } },
      field: 'address',
      headerName: t('common.titles.address'),
    },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ mb: 2, ml: 'auto' }}
        onClick={() => {
          redirectToAppDealersDealerDetailsDealerUsersCreateNewUser(dealerId);
        }}
      >
        {t('common.titles.createNewUser')}
      </Button>

      <Box
        sx={{
          backgroundColor: 'background.default',
          flex: 1,
          width: '100%',
          borderRadius: '16px',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <CommonDataGrid
          rows={rows}
          columns={columns}
          checkboxSelection
          noDataToShowMessage={t('common.messages.noDataToShow')}
          selectRowHandler={(rowData) => {
            redirectToAppDealersDealerDetailsDealerUsersUserInfo({
              dealerId,
              dealerUserId: rowData.id,
            });
          }}
        />
      </Box>
    </Box>
  );
}

export default withTranslation()(DealerUsers);
