import { connect } from 'react-redux';
import { getAllSuppliers } from '../../../../../store/suppliers/slice';
import {
  createNewRepair,
  deleteRepair,
  getVehicleAldAssessment,
  getVehicleById,
  getVehicleNafAssessment,
  _setCurrentVehicle,
} from '../../../../../store/vehicles/slice';

import EditAssessment from './EditAssessment';

const mapStateToProps = (state, ownProps) => {
  const {
    vehicles,
    damageTypes: { damageTypes },
    damageTypeGroups: { groupsLookup, groups },
    suppliers: { suppliers },
  } = state;
  const { vehicleId } = ownProps;

  const assessment = vehicles.aldAssessments[vehicleId];

  return {
    assessment,
    vehicle: vehicles.currentVehicle,
    damageTypes,
    damageTypeGroups: groupsLookup,
    damageTypeGroupsArray: groups,
    suppliers,
  };
};

const mapDispatchToProps = {
  createNewRepair,
  deleteRepair,
  _setCurrentVehicle,
  getVehicleById,
  getVehicleAldAssessment,
  getVehicleNafAssessment,
  getAllSuppliers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAssessment);
