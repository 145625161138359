import * as signalR from '@microsoft/signalr';

export class SignalHub {
  constructor(url, accessToken) {
    this.url = url;
    this.accessToken = accessToken;

    const protocol = new signalR.JsonHubProtocol();

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.url, { accessTokenFactory: () => this.accessToken })
      .withHubProtocol(protocol)
      .withAutomaticReconnect()
      .build();
  }

  on(event, fn) {
    this.connection.on(event, fn);
  }

  invoke(methodName, ...args) {
    this.connection.invoke(methodName, ...args);
  }

  async connect() {
    try {
      this.connection.onclose((err) =>
        console.warn(`SignalR connection closed. Error: ${err.toString()}`),
      );
      await this.connection.start();
      console.log('SignalR connected successfully');
    } catch (e) {
      console.error('SignalR connection failed!');
    }
  }

  async disconnect() {
    await this.connection.stop();
  }
}
