import axios from 'axios';
import { SUPPLIER_CONFIRM_STATUS } from '../common/constants/assessment';
import { SUPPLIER_REPAIR_STATUS } from '../common/constants/suppliers';
import { parseServerErrors } from './helpers';
import { HttpRequestClientError } from './HttpRequestClientError';
import { HttpRequestNetworkError } from './HttpRequestNetworkError';
import { SortCarsEnum, SortEnum } from './types';

const API_VERSIONS = {
  V1: 'v1',
};

axios.defaults.withCredentials = true;

class AxiosJWTApiClient {
  constructor() {
    /**
     * @private
     */
    this.hostUrl = '';
    /**
     * @private
     */
    this.token = '';
    /**
     * @private
     */
    this.refreshToken = '';
  }

  setHostUrl(hostUrl = '') {
    this.hostUrl = hostUrl;
  }

  setJwtToken(token) {
    this.token = token;
  }

  getJwtToken() {
    return this.token;
  }

  clearJwtToken() {
    return (this.token = '');
  }

  /**
   * @private
   */
  createRequestConfig(
    apiVersion,
    url,
    method,
    params,
    headers = {},
    customConfig = null,
  ) {
    let config = customConfig || {};
    if (this.token) {
      config = {
        ...config,
        headers: { Authorization: 'bearer '.concat(this.token), ...headers },
      };
    }
    return {
      data: params,
      method,
      url,
      withCredentials: true,
      baseURL: this.hostUrl.concat('/api').concat(`/${apiVersion}`),
      validateStatus: this.validateStatus,
      ...config,
    };
  }

  /**
   * @private
   */
  validateStatus(status) {
    return (
      (status >= 200 && status < 300) ||
      status === 400 ||
      status === 401 ||
      status === 403 ||
      status === 404 ||
      status === 405 ||
      status === 413 ||
      status === 500 ||
      status === 503
    );
  }

  /**
   * @private
   */
  request(config) {
    return axios
      .request(config)
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  /**
   * @private
   */
  handleSuccess(response) {
    // resourceResponseListener.emit(response);

    if (
      response.status === 400 ||
      response.status === 401 ||
      response.status === 403 ||
      response.status === 404 ||
      response.status === 405 ||
      response.status === 413 ||
      response.status === 423 ||
      response.status === 429 ||
      response.status === 500 ||
      response.status === 503
    ) {
      const errors = parseServerErrors(response.data);
      throw new HttpRequestClientError(
        errors.join(' '),
        errors,
        response.status,
      );
    }

    return response.data;
  }

  /**
   * @private
   */
  handleError(error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }

    if (error.message === 'Network Error') throw new HttpRequestNetworkError();

    throw error;
  }

  get({ apiVersion = API_VERSIONS.V1, url, params }) {
    const config = this.createRequestConfig(apiVersion, url, 'GET', params);
    return this.request(config);
  }

  getBlob({ apiVersion = API_VERSIONS.V1, url, params }) {
    const config = this.createRequestConfig(
      apiVersion,
      url,
      'GET',
      params,
      {},
      { responseType: 'blob' },
    );
    return this.request(config);
  }

  post({ apiVersion = API_VERSIONS.V1, url, params, onUploadProgress }) {
    const config = {
      ...this.createRequestConfig(apiVersion, url, 'POST', params),
      onUploadProgress,
    };
    return this.request(config);
  }

  put({ apiVersion = API_VERSIONS.V1, url, params }) {
    const config = this.createRequestConfig(apiVersion, url, 'PUT', params);
    return this.request(config);
  }

  delete({ apiVersion = API_VERSIONS.V1, url, params }) {
    const config = this.createRequestConfig(apiVersion, url, 'DELETE', params);
    return this.request(config);
  }
}

export const jwtApiClient = new AxiosJWTApiClient();

export const api = {
  auth: {
    login: (email, password) =>
      jwtApiClient.post({
        url: '/user/authenticate',
        params: { email, password },
      }),
    register: (email, password, displayName) =>
      jwtApiClient.post({
        url: '/user/register',
        params: { email, password, displayName },
      }),
    revokeToken: () =>
      jwtApiClient.get({
        url: '/user/revoke-token',
      }),
    refreshToken: () =>
      jwtApiClient.get({
        url: '/user/refresh-token',
      }),
    me: () =>
      jwtApiClient.get({
        url: '/user/me',
      }),
    passwordlessAuth: (data) =>
      jwtApiClient.post({
        url: '/user/password-less-auth',
        params: data,
      }),
    fetchAppLogo: () =>
      jwtApiClient.get({
        url: '/user/logo',
      }),
  },
  upload: {
    file: (file, onUploadProgress = () => {}) => {
      const formData = new FormData();
      formData.append('file', file);

      return jwtApiClient.post({
        url: '/uploads',
        params: formData,
        onUploadProgress,
      });
    },
  },
  download: {
    image: (uploadId) => {
      return jwtApiClient.getBlob({
        url: `/uploads/${uploadId}`,
      });
    },
  },
  import: {
    xls: (uploadId) =>
      jwtApiClient.post({
        url: '/import/xls',
        params: {
          uploadId,
        },
      }),
  },
  contracts: {
    getAllContractImages: (contractId) =>
      jwtApiClient.get({
        url: `/contracts/${contractId}/gallery-all`,
      }),
    addImageToContract: (contractId, uploadId, contractCarImageType) =>
      jwtApiClient.post({
        url: `/contracts/gallery`,
        params: {
          contractId,
          uploadId,
          contractCarImageType,
        },
      }),
    removeImageFromContract: (uploadId) =>
      jwtApiClient.delete({
        url: `/contracts/gallery/${uploadId}`,
      }),
    getSalesChannelEnums: () =>
      jwtApiClient.get({
        url: `/contracts/salesChannelEnums`,
      }),
    updateContract: (contractId, data) =>
      jwtApiClient.put({
        url: `/contracts/${contractId}`,
        params: {
          ...data,
        },
      }),
    getContractById: (contractId) =>
      jwtApiClient.get({
        url: `/contracts/${contractId}`,
      }),
  },
  vehicles: {
    getAll: (
      offset,
      limit,
      stateEnumsArray = [],
      customerId = '',
      plateNo = '',
      sortCar = SortCarsEnum.LastUpdate,
      sort = SortEnum.OrderByDescending,
    ) => {
      const statesData =
        stateEnumsArray.length > 0
          ? stateEnumsArray.map((s) => `&states=${s}`).join('')
          : '';
      return jwtApiClient.get({
        url: `/cars?offset=${offset}&limit=${limit}&customerId=${customerId}${statesData}&plate=${plateNo}&sortCar=${sortCar}&sort=${sort}`,
      });
    },
    getById: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}`,
      }),
    getAllFlowStates: () =>
      jwtApiClient.get({
        url: `/cars/stateEnums`,
      }),
    getVehicleFlowState: (vehicleId, contractId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/flow/${contractId}/state`,
      }),
    getVehicleFlowStatesLogs: (vehicleId, contractId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/flow/${contractId}/logs`,
      }),
    switchToNextFlowState: (vehicleId, currentStateId, selectedStepEnum) =>
      jwtApiClient.post({
        url: `/cars/${vehicleId}/flow/nextFlow`,
        params: { id: currentStateId, selectedStep: selectedStepEnum },
      }),
    selectStateStateFlow: (vehicleId, stateEnum) =>
      jwtApiClient.post({
        url: `/cars/${vehicleId}/flow/selectFlow`,
        params: { stateEnum },
      }),
    getVehicleQR: (vehicleId, contractId) =>
      jwtApiClient.getBlob({
        url: `/cars/${vehicleId}/flow/qrcode`,
        params: {
          contractId,
        },
      }),
    getVehicleByQRId: (qrId) =>
      jwtApiClient.get({
        url: `/cars/byQrCode/${qrId}/`,
      }),
    getNafAssessmentByCarId: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/assessment/naf`,
      }),
    getAldAssessmentByCarId: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/assessment/ald`,
      }),
    updateVehicleAccessories: (vehicleId, accessories) =>
      jwtApiClient.put({
        url: `/cars/${vehicleId}/accessories/update`,
        params: {
          id: vehicleId,
          accessories,
        },
      }),
    getVehicleAccessories: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/accessories`,
      }),
    getVehicleCustomerInfo: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/customer`,
      }),
    getVehicleContractInfo: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/contract`,
      }),
    updateVehicleMileage: (vehicleId, data) =>
      jwtApiClient.put({
        url: `/cars/${vehicleId}/mileage`,
        params: data,
      }),
    getVehicleReturnInfo: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/return-info`,
      }),
    getVehicleRepairStatuses: (vehicleId) =>
      jwtApiClient.get({
        url: `/cars/${vehicleId}/repair-statuses`,
      }),
    changeBooking: (vehicleId) =>
      jwtApiClient.post({
        url: `/cars/${vehicleId}/flow/change-booking`,
        params: {
          id: vehicleId,
        },
      }),
  },
  booking: {
    confirmCar: (vehicleId) =>
      jwtApiClient.put({
        url: `/booking/confirm-car/${vehicleId}`,
      }),
    create: (booking) =>
      jwtApiClient.post({
        url: '/booking',
        params: booking,
      }),
    createWithOther: (booking) =>
      jwtApiClient.post({
        url: '/booking/with-other',
        params: booking,
      }),
    getByContractId: (contractId) =>
      jwtApiClient.get({
        url: `/booking/contract/${contractId}`,
      }),
    getLocations: (contractId) =>
      jwtApiClient.get({
        url: `/booking/locations/${contractId}`,
      }),
    addContactPerson: (personData) =>
      jwtApiClient.put({
        url: '/booking/contact-person',
        params: personData,
      }),
  },
  customers: {
    b2c: {
      getAll: () =>
        jwtApiClient.get({
          url: '/b2c-customers',
        }),
      createNew: (customerData) =>
        jwtApiClient.post({
          url: '/b2c-customers',
          params: customerData,
        }),
      createNewUser: (userData) =>
        jwtApiClient.post({
          url: '/b2c-customers/users',
          params: userData,
        }),
      getAllUsers: (customerId) =>
        jwtApiClient.get({
          url: `/b2c-customers/${customerId}/users`,
        }),
      getBrandings: () =>
        jwtApiClient.get({
          url: `/b2c-customers/type-branding`,
        }),
    },
    b2b: {
      getAll: () =>
        jwtApiClient.get({
          url: '/b2b-customers',
        }),
      createNew: (customerData) =>
        jwtApiClient.post({
          url: '/b2b-customers',
          params: customerData,
        }),
      createNewAdminUser: (adminUserData) =>
        jwtApiClient.post({
          url: '/b2b-customers/users',
          params: adminUserData,
        }),
      createNewUser: (userData) =>
        jwtApiClient.post({
          url: '/b2b-customers/users',
          params: userData,
        }),
      getAllUsers: (customerId) =>
        jwtApiClient.get({
          url: `/b2b-customers/${customerId}/users`,
        }),
      getBrandings: () =>
        jwtApiClient.get({
          url: `/b2b-customers/type-branding`,
        }),
      getCustomerAsB2BCustomerUser: () =>
        jwtApiClient.get({
          url: `/b2b-customers/user`,
        }),
    },
  },
  dealers: {
    getAll: () =>
      jwtApiClient.get({
        url: '/dealers',
      }),
    createNew: (dealerData) =>
      jwtApiClient.post({
        url: '/dealers',
        params: dealerData,
      }),
    getAllDealerUsers: (dealerId) =>
      jwtApiClient.get({
        url: `/dealers/${dealerId}/users`,
      }),
    createNewDealerUser: (dealerUserData) =>
      jwtApiClient.post({
        url: '/dealers/users',
        params: dealerUserData,
      }),
  },
  suppliers: {
    getAll: () =>
      jwtApiClient.get({
        url: '/suppliers',
      }),
    createNew: (data) =>
      jwtApiClient.post({
        url: '/suppliers',
        params: data,
      }),
    getSupplierUsers: (supplierId) =>
      jwtApiClient.get({
        url: `/suppliers/${supplierId}/users`,
      }),
    createNewSupplierUser: (data) =>
      jwtApiClient.post({
        url: '/suppliers/users',
        params: data,
      }),
    pickUpAssessmentRepair: (repairId) =>
      jwtApiClient.put({
        url: `/suppliers/confirm-repair`,
        params: {
          id: repairId,
        },
      }),
    getSupplierAsSupplierUser: () =>
      jwtApiClient.get({
        url: `/suppliers/user`,
      }),
  },
  damageTypes: {
    getAll: () =>
      jwtApiClient.get({
        url: '/damage-types',
      }),
  },
  damageTypeGroups: {
    getAll: () =>
      jwtApiClient.get({
        url: '/damage-type-groups',
      }),
  },
  assessments: {
    updateAssessment: (assessment) =>
      jwtApiClient.put({
        url: `/assessments/ald-assessment/${assessment.id}`,
        params: assessment,
      }),
    approve: (data) =>
      jwtApiClient.put({
        url: `/assessments/approve`,
        params: data,
      }),
    forceApprove: (data) =>
      jwtApiClient.put({
        url: `/assessments/approve/force`,
        params: data,
      }),
    decline: (data) =>
      jwtApiClient.put({
        url: `/assessments/decline`,
        params: data,
      }),
  },
  messages: {
    send: (message) =>
      jwtApiClient.post({
        url: '/messages',
        params: message,
      }),
    getById: (contractId, messageId) =>
      jwtApiClient.get({
        url: `/messages/${contractId}/${messageId}`,
      }),
    getAllAldSupplier: (contractId) =>
      jwtApiClient.get({
        url: `/messages/ald-supplier/${contractId}`,
      }),
    getAllAldCustomer: (contractId) =>
      jwtApiClient.get({
        url: `/messages/ald-customer/${contractId}`,
      }),
  },
  aldUsers: {
    getAll: () =>
      jwtApiClient.get({
        url: '/ald-users',
      }),
    createNew: (aldUserData) =>
      jwtApiClient.post({
        url: '/ald-users',
        params: aldUserData,
      }),
  },
  assessment: {
    confirmSupplier: (model) =>
      jwtApiClient.put({
        url: '/assessments/confirm-supplier',
        params: model,
      }),
    resendUpdate: (vehicleId) =>
      jwtApiClient.post({
        url: `/assessments/resend-assessment/${vehicleId}`,
      }),
    repairs: {
      create: (assessmentId, damageTypeGroupId) =>
        jwtApiClient.post({
          url: '/repairs',
          params: {
            assessmentId,
            damageTypeGroupId,
            supplierId: null,
            supplierConfirmStatus: SUPPLIER_CONFIRM_STATUS.NOT_CONFIRMED,
            repairStatus: SUPPLIER_REPAIR_STATUS.NEED_REPAIR,
          },
        }),
      delete: (repairId) =>
        jwtApiClient.delete({
          url: `/repairs/${repairId}`,
        }),
    },
    damageDetails: {
      create: (model) =>
        jwtApiClient.post({
          url: '/assessment-damage-details',
          params: model,
        }),
      update: (id, model) =>
        jwtApiClient.put({
          url: `/assessment-damage-details/${id}`,
          params: model,
        }),
      delete: (id) =>
        jwtApiClient.delete({
          url: `/assessment-damage-details/${id}`,
        }),
      get: (id) =>
        jwtApiClient.get({
          url: `/assessment-damage-details/${id}`,
        }),
      getAll: (sheetId) =>
        jwtApiClient.get({
          url: `/assessment-damage-details/assessment-sheet/${sheetId}`,
        }),
    },
    sheets: {
      create: (model) =>
        jwtApiClient.post({
          url: `/assessment-sheets`,
          params: model,
        }),
      update: (id, model) =>
        jwtApiClient.put({
          url: `/assessment-sheets/${id}`,
          params: model,
        }),
      delete: (id) =>
        jwtApiClient.delete({
          url: `/assessment-sheets/${id}`,
        }),
      get: (id) =>
        jwtApiClient.get({
          url: `/assessment-sheets/${id}`,
        }),
      getAll: (assessmentId) =>
        jwtApiClient.get({
          url: `/assessment-sheets/assessment/${assessmentId}`,
        }),
      images: {
        add: (sheetId, uploadId) =>
          jwtApiClient.put({
            url: `/assessment-sheets/${sheetId}/images/${uploadId}`,
          }),
        remove: (sheetId, uploadId) =>
          jwtApiClient.delete({
            url: `/assessment-sheets/${sheetId}/images/${uploadId}`,
          }),
      },
      comments: {
        add: (sheetId, text) =>
          jwtApiClient.post({
            url: '/assessment-sheets/comments',
            params: {
              assessmentSheetId: sheetId,
              text,
            },
          }),
        remove: (commentId) =>
          jwtApiClient.delete({
            url: `/assessment-sheets/comments/${commentId}`,
          }),
      },
    },
  },
  repairs: {
    getAllByContract: (contractId) =>
      jwtApiClient.get({
        url: `/repairs/contract/${contractId}`,
      }),
    select: (repairId) =>
      jwtApiClient.put({
        url: `/repairs/select-repair`,
        params: {
          id: repairId,
        },
      }),
    complete: (contractId) =>
      jwtApiClient.put({
        url: `/repairs/completed`,
        params: {
          id: contractId,
        },
      }),
  },
  dashboard: {
    getTodayStatistic: () =>
      jwtApiClient.get({
        url: `/dashboard/today`,
      }),
    getLastWeekStatistic: () =>
      jwtApiClient.get({
        url: `/dashboard/last-seven-days`,
      }),
    getLastMonthStatistic: () =>
      jwtApiClient.get({
        url: `/dashboard/last-thirty-days`,
      }),
  },
};
