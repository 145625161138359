import Box from '@mui/system/Box';
import moment from 'moment';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import CustomDataGridV3 from '../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import { SUB_FLOW_STATE_ENUM } from '../../../../common/constants/vehicles';
import { appendDaysWord } from '../../../../common/helpers/common';
import {
  getCarsSortEnumByField,
  getSortEnumBySortTypeString,
} from '../../../../common/helpers/vehicles';
import { CustomDataGridToolbar } from '../AllCars/AllCars';
import { getChannelNameByEnum } from '../CarDetails/helpers';

function ReadyForSalesCars({
  t = () => {},
  redirectToAppVehiclesListReadyForSalesCarsCarDetails = () => {},
  getVehicles = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('vehiclesList.tabs.readyForSalesCars.car'),
    },
    {
      field: 'carModel',
      headerName: t('vehiclesList.tabs.readyForSalesCars.model'),
    },
    {
      field: 'plateNo',
      headerName: t('vehiclesList.tabs.readyForSalesCars.numbers'),
    },
    {
      field: 'completedDate',
      headerName: 'Completed date',
    },
    {
      field: 'timeUsed',
      headerName: t('common.titles.timeUsed'),
    },
    {
      field: 'salesChannel',
      headerName: 'Sales channel',
      valueGetter: (params = {}) => {
        return getChannelNameByEnum(params.row.salesChannel);
      },
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      timeUsed: appendDaysWord(car.timeUsed),
      completedDate: moment(car.completedDate).format('DD/MM/YYYY'),
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sortModel, setSortModel] = useState({
    field: 'completedDate',
    sort: 'asc',
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListReadyForSalesCarsCarDetails(params.row.id);
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        paginationCallback={async (offset, limit, plateNo, _sortModel) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [
                SUB_FLOW_STATE_ENUM.READY_FOR_SALES,
                SUB_FLOW_STATE_ENUM.DELIVERED_TO_SALES,
              ],
              undefined,
              plateNo,
              getCarsSortEnumByField(_sortModel.field),
              getSortEnumBySortTypeString(_sortModel.sort),
            );

            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[
          plateNumberSearchValue,
          sortModel,
        ]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
        onSortModelChange={(sortChangeData) => {
          if (sortChangeData && sortChangeData.length > 0) {
            setSortModel(sortChangeData[0]);
          }
        }}
      />
    </Box>
  );
}

export default withTranslation()(ReadyForSalesCars);
