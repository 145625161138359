import { Box } from '@mui/system';
import React from 'react';
import Header from './Header/Header.container';
import Nav from './Nav/Nav.container';
import { withTheme } from '@emotion/react';
import FullScreenGalleryCarouselContainer from '../../common/components/GalleryCarousel/FullScreen/FullScreenGalleryCarousel.container';

function MainLayout({ children, theme = {}, isNavOpened }) {
  return (
    <>
      <FullScreenGalleryCarouselContainer />

      <Header />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          overflow: 'auto',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
          },
          position: 'relative',
        }}
      >
        {isNavOpened && <Nav />}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            p: { xs: 0, md: 3 },
            minWidth: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

export default withTheme(MainLayout);
