import { connect } from 'react-redux';
import NAFSupplierInfo from './Info';

const mapStateToProps = (state, ownProps) => {
  const { suppliers } = state.suppliers;
  const { supplierId } = ownProps;
  const supplier = suppliers.find((c) => c.id === supplierId);

  return {
    supplier,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NAFSupplierInfo);
