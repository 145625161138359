import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

function CommonDataGrid({
  columns = [],
  rows = [],
  rowHeight = 52,
  rowCollapseDataRenderer = () => {},
  noDataToShowMessage = '',
  selectRowHandler = () => {},
}) {
  const [canRenderRows, setCanRenderRows] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [renderingRowsCount, setRenderingRowsCount] = useState(0);
  const [rowsState, setRowsState] = useState(null);

  const rowsContainerRef = useRef(null);

  const [isFilterSettingsOpened, setFilterSettingsOpened] = useState(false);
  const [filteringColumnHeader, setFilteringColumnHeader] = useState(null);
  const [filterValue, setFilterValue] = useState('');

  const renderingCols = [
    ...columns,
    {
      renderColCell: () => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 1,
            }}
          >
            <IconButton
              color={filterValue === '' ? 'secondary' : 'primary'}
              variant="contained"
              onClick={() => {
                setFilterSettingsOpened(true);
              }}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        );
      },
      renderRowCell: ({ rowData }) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 1,
            }}
          >
            <IconButton
              disabled={!selectRowHandler}
              onClick={() => {
                if (selectRowHandler) {
                  selectRowHandler(rowData);
                }
              }}
            >
              {selectRowHandler ? (
                <KeyboardArrowRightIcon />
              ) : (
                <Box sx={{ width: '24px', height: '24px' }} />
              )}
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (columns.length > 0 && !filteringColumnHeader) {
      setFilteringColumnHeader(columns[0].headerName);
    }
  }, [columns]);

  let filteredRows = rows;
  if (filteringColumnHeader) {
    const filteringColumn = columns.find(
      (c) => c.headerName === filteringColumnHeader,
    );

    filteredRows = rows.filter(
      filteringColumn.filterPredicate
        ? (r) => {
            return filteringColumn.filterPredicate(r, filterValue);
          }
        : (r) => {
            return r[filteringColumn.field]
              .toLowerCase()
              .includes(filterValue.toLowerCase());
          },
    );
  }

  const startRowIndex = selectedPage * renderingRowsCount;
  let endRowIndex = selectedPage * renderingRowsCount + renderingRowsCount;
  if (endRowIndex > filteredRows.length) endRowIndex = filteredRows.length;

  const pagesCount = Math.ceil(filteredRows.length / renderingRowsCount);

  useEffect(() => {
    if (rowsContainerRef.current) {
      setCanRenderRows(true);
      setRenderingRowsCount(
        Math.floor(rowsContainerRef.current.clientHeight / (rowHeight + 1)),
      );
    }

    if (!rowsState && rows.length > 0) {
      const newRowsState = {};
      rows.forEach((row) => {
        newRowsState[row.id] = { isRowExpanded: false };
      });
      setRowsState(newRowsState);
    }
  });

  const renderRows = () => {
    let renderingRows = [];
    if (canRenderRows) {
      renderingRows = filteredRows.slice(startRowIndex, endRowIndex);
    }

    return (
      <Box
        ref={rowsContainerRef}
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: '100%',
          overflowY: 'auto',
          width: { xs: `${renderingCols.length * 150}px`, md: '100%' },
        }}
      >
        {filteredRows.length > 0 ? (
          <>
            {canRenderRows &&
              rowsState &&
              renderingRows.map((row, rowIndex) => {
                return (
                  <React.Fragment key={rowIndex}>
                    {/* {rowIndex === 0 && <Divider />} */}
                    <Box
                      sx={{
                        display: 'flex',
                        flex: `0 0 ${rowHeight}px`,
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                        borderTop:
                          rowIndex === 0 ? '1px solid #e0e0e0' : 'unset',
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      {renderingCols.map((col, index) => {
                        if (col.renderRowCell) {
                          return (
                            <React.Fragment key={index}>
                              {col.renderRowCell({
                                value: row[col.field],
                                toggleCollapse: () => {
                                  setRowsState({
                                    ...rowsState,
                                    [row.id]: {
                                      ...rowsState[row.id],
                                      isRowExpanded:
                                        rowsState[row.id] &&
                                        !rowsState[row.id].isRowExpanded,
                                    },
                                  });
                                },
                                isRowExpanded:
                                  rowsState[row.id] &&
                                  rowsState[row.id].isRowExpanded,
                                rowData: { ...row },
                              })}
                            </React.Fragment>
                          );
                        }

                        return (
                          <Box
                            key={index}
                            sx={{
                              px: 1,
                              flex: 1,
                              display: 'flex',
                              minWidth: '0',
                              ':first-of-type': {
                                pl: 2,
                              },
                              overflow: 'hidden',
                              ...col.sx,
                            }}
                          >
                            <Tooltip title={row[col.field] || ''}>
                              <Typography noWrap>{row[col.field]}</Typography>
                            </Tooltip>
                          </Box>
                        );
                      })}
                    </Box>

                    <Collapse
                      in={rowsState[row.id] && rowsState[row.id].isRowExpanded}
                      timeout="auto"
                      sx={{
                        display: 'flex',
                        minHeight: 'unset !important',
                      }}
                      unmountOnExit
                    >
                      {rowCollapseDataRenderer(row)}
                    </Collapse>

                    {/* <Divider /> */}
                  </React.Fragment>
                );
              })}

            {!canRenderRows && (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                loading...
              </Box>
            )}
          </>
        ) : (
          <Typography sx={{ m: 'auto' }}>{noDataToShowMessage}</Typography>
        )}
      </Box>
    );
  };

  const renderCols = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flex: `0 0 ${rowHeight}px`,
            minWidth: '100%',
            width: { xs: `${renderingCols.length * 150}px`, md: '100%' },
            position: { xs: 'unset', md: 'relative' },
          }}
        >
          {renderingCols.map((col, index) => {
            return (
              <React.Fragment key={index}>
                {!col.renderColCell && (
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      alignItems: 'center',
                      px: 1,
                      minWidth: 0,
                      ':first-of-type': {
                        pl: 2,
                      },
                      ...col.sx,
                    }}
                  >
                    <Tooltip title={col.headerName}>
                      <Typography noWrap sx={{ fontWeight: 500 }}>
                        {col.headerName}
                      </Typography>
                    </Tooltip>
                  </Box>
                )}

                {col.renderColCell && col.renderColCell({ colData: col })}
              </React.Fragment>
            );
          })}

          {isFilterSettingsOpened && (
            <Box
              sx={{
                top: { xs: 0, md: '100%' },
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                p: 2,
                backgroundColor: 'background.default',
                borderRadius: '4px',
                boxShadow:
                  '0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%)',
                width: { xs: '100%', md: '70%' },
                zIndex: 1,
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="data-grid-filter-col-label">Column</InputLabel>
                <Select
                  labelId="data-grid-filter-col-label"
                  value={filteringColumnHeader}
                  label="Column"
                  onChange={(e) => {
                    setFilteringColumnHeader(e.target.value);
                    setFilterValue('');
                  }}
                >
                  {columns.map((c, index) => (
                    <MenuItem key={index} value={c.headerName}>
                      {c.headerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                sx={{ ml: 1 }}
                autoFocus
                fullWidth
                label="Value"
                // margin="normal"
                name="data-grid-filter-value-input"
                onChange={(e) => {
                  setFilterValue(e.target.value);
                }}
                type="text"
                value={filterValue}
                variant="outlined"
              />

              <Box sx={{ ml: 1, display: 'flex' }}>
                <IconButton
                  sx={{ m: 'auto' }}
                  onClick={() => {
                    setFilterSettingsOpened(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          overflowX: 'auto',
        }}
      >
        {renderCols()}
        {renderRows()}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pb: 1,
          pr: 1,
        }}
      >
        <Typography
          sx={{ textAlign: 'center', verticalAlign: 'middle', mr: 1 }}
        >
          {`${startRowIndex}-${endRowIndex} of ${filteredRows.length}`}
        </Typography>

        <IconButton
          disabled={selectedPage - 1 < 0}
          onClick={() => {
            if (!(selectedPage - 1 < 0)) {
              setSelectedPage(selectedPage - 1);
            }
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <IconButton
          disabled={selectedPage + 1 >= pagesCount}
          onClick={() => {
            if (!(selectedPage + 1 >= pagesCount)) {
              setSelectedPage(selectedPage + 1);
            }
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default CommonDataGrid;
