import React, { useEffect } from 'react';
import StatisticRenderer from '../Today/StatisticRenderer/StatisticRenderer';

function LastWeekStatistic({
  statistic = {
    overviewOfReturn: [],
    overviewOfCars: [],
    overviewOfAssessment: [],
  },
  getDashboardLastWeekStatistic = () => {},
}) {
  useEffect(() => {
    getDashboardLastWeekStatistic();
  }, []);

  return <StatisticRenderer statistic={statistic} />;
}

export default LastWeekStatistic;
