import { Box } from '@mui/system';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMounted from '../../../../../common/hooks/useMounted';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';
import { withTranslation } from 'react-i18next';
import { Button, FormHelperText, TextField, Typography } from '@mui/material';

function UpdateMileageAfterReturn({
  t = () => {},
  vehicle = {},
  currentVehicleFlowState = {},
  updateVehicleMileage = () => {},
  switchToNextVehicleFlowState = () => {},
  getVehicleFlowState = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  const mounted = useMounted();

  return (
    <Box
      sx={{
        p: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <Formik
        initialValues={{
          mileage: '',
        }}
        validationSchema={Yup.object().shape({
          mileage: Yup.number().required('Mileage is required'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await updateVehicleMileage(id, {
              id,
              mileage: values.mileage,
            });
            await switchToNextVehicleFlowState(
              id,
              currentStateId,
              SUB_FLOW_STATE_ENUM.READY_FOR_UPLOAD_PHOTO_AFTER_RETURN,
            );
            await getVehicleFlowState(id, contractId);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{
              flex: 1,
              display: 'flex',
              overflow: 'auto',
              minWidth: 0,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 2, fontWeight: 500 }} variant="h6">
                {t('vehiclesList.carStatus.booking.mileageAfterReturn')}:
              </Typography>

              <TextField
                error={Boolean(touched.mileage && errors.mileage)}
                fullWidth
                helperText={touched.mileage && errors.mileage}
                label={t('vehiclesList.carStatus.booking.mileage')}
                name="mileage"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={values.mileage}
                variant="outlined"
              />

              {errors.submit && (
                <Box sx={{ mt: 1 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Button
                sx={{ width: '100%', mt: 'auto' }}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isSubmitting || !values.mileage}
              >
                CONFIRM
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default withTranslation()(UpdateMileageAfterReturn);
