import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import DeleteButton from '../../../../../common/components/DeleteButton/DeleteButton';
import DropDownWithoutLabel from '../../../../../common/components/DropDownInput/DropDownWithoutLabel';
import ImageLoader from '../../../../../common/components/ImageLoader/ImageLoader';
import {
  ASSESSMENT_APPROVAL_STATE,
  ASSESSMENT_SUPPLIER_STATUS,
  ASSESSMENT_SUPPLIER_STATUS_INPUTS,
} from '../../../../../common/constants/assessment';

const EditAssessmentSheet = ({
  isEditingBlocked,
  sheet = {},
  vehicleId,
  repairId,
  approvalState,
  changeDamageDetailName,
  changeDamageDetailPrice,
  changeSheetMeasurements,
  changeDamageDetailStatus,
  createNewDamageDetail,
  deleteDamageDetail,
  addImageToSheet,
  removeImageFromSheet,
  setFsUploadIds,
}) => {
  const fileInputRef = useRef(null);

  const handleChangeMeasurements = (measurements) =>
    changeSheetMeasurements(vehicleId, repairId, sheet.id, measurements);
  const handleChangeDamageDetailPrice = (detailId, price) =>
    changeDamageDetailPrice(vehicleId, repairId, sheet.id, detailId, price);
  const handleChangeDamageDetailName = (detailId, name) =>
    changeDamageDetailName(vehicleId, repairId, sheet.id, detailId, name);
  const handleChangeDamageDetailStatus = (detailId, status) =>
    changeDamageDetailStatus(vehicleId, repairId, sheet.id, detailId, status);

  const [newDamageDetail, setNewDamageDetail] = useState({
    name: '',
    price: 0,
  });
  const handleChangeNewDamageDetailName = (name) =>
    setNewDamageDetail({ ...newDamageDetail, name });
  const handleChangeNewDamageDetailPrice = (price) =>
    setNewDamageDetail({ ...newDamageDetail, price });
  const handleCreateNewDamageDetail = () => {
    if (newDamageDetail.name) {
      createNewDamageDetail(
        vehicleId,
        repairId,
        sheet.id,
        newDamageDetail.name,
        newDamageDetail.price,
      );
      setNewDamageDetail({ ...newDamageDetail, name: '', price: 0 });
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '60%' }}>
            <Typography sx={{ fontSize: '14px', ml: '5px' }}>
              Measurements
            </Typography>
            <TextField
              disabled={isEditingBlocked}
              sx={{ p: '0px', width: '100%' }}
              multiline
              rows={4}
              value={sheet.measurements}
              onChange={(ev) => handleChangeMeasurements(ev.target.value)}
            />
          </Box>
          <Box sx={{ width: '40%', ml: '15px' }}>
            <Typography sx={{ fontSize: '14px' }}>Photos</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                sx={{
                  height: '125px',
                  background: '#F1F2F5',
                  borderRadius: '15px',
                  mr: '15px',
                }}
                disabled={isEditingBlocked}
                onClick={() => fileInputRef.current.click()}
              >
                +
              </Button>
              <input
                style={{ display: 'none' }}
                capture="camera"
                accept="image/*"
                type="file"
                onChange={async (event) => {
                  if (event.target.files.length > 0) {
                    const file = event.target.files[0];
                    event.target.value = '';
                    await addImageToSheet(vehicleId, repairId, sheet.id, file);
                  }
                }}
                id="add-picture-button"
                ref={fileInputRef}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {sheet.images && (
                  <ImageList
                    gap={10}
                    sx={{ m: 0, height: '70%', overflowY: 'clip' }}
                  >
                    {sheet.images.map((image, imgIndex) => {
                      if (imgIndex >= 2) return <React.Fragment key={image} />;

                      return (
                        <Box key={image} sx={{}}>
                          <ImageListItem
                            onClick={() =>
                              setFsUploadIds(sheet.images, (uploadId) =>
                                removeImageFromSheet(
                                  vehicleId,
                                  repairId,
                                  sheet.id,
                                  uploadId,
                                ),
                              )
                            }
                          >
                            <ImageLoader
                              sx={{
                                borderRadius: '6px',
                                height: '125px',
                              }}
                              loading="lazy"
                              uploadId={image}
                            />
                          </ImageListItem>
                          <DeleteOutlined
                            sx={{
                              position: 'relative',
                              bottom: '20%',
                              left: '70%',
                              fill: 'white',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              !isEditingBlocked
                                ? removeImageFromSheet(
                                    vehicleId,
                                    repairId,
                                    sheet.id,
                                    image,
                                  )
                                : null
                            }
                          />
                        </Box>
                      );
                    })}
                  </ImageList>
                )}
                <Typography
                  sx={{
                    visibility:
                      sheet.images && sheet.images.length > 2 ? '' : 'hidden',
                    cursor: 'pointer',
                  }}
                  variant="h9"
                  onClick={() =>
                    setFsUploadIds(sheet.images, (uploadId) =>
                      removeImageFromSheet(
                        vehicleId,
                        repairId,
                        sheet.id,
                        uploadId,
                      ),
                    )
                  }
                >
                  + {sheet.images && sheet.images.length - 2} pictures
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {sheet.damageDetails &&
          sheet.damageDetails.map((damage) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                key={damage.id}
              >
                <Box sx={{ width: '30%' }}>
                  <Typography sx={{ fontSize: '14px' }}>Damage</Typography>
                  <TextField
                    sx={{ width: '100%' }}
                    value={damage.name}
                    onChange={(ev) =>
                      handleChangeDamageDetailName(damage.id, ev.target.value)
                    }
                    disabled={isEditingBlocked}
                  />
                </Box>
                <Box
                  sx={{
                    width: '30%',
                    ml: '20px',
                    mr: '20px',
                  }}
                >
                  <Typography sx={{ fontSize: '14px' }}>Price, kr</Typography>
                  <TextField
                    type="number"
                    sx={{ width: '100%' }}
                    disabled={
                      approvalState === ASSESSMENT_APPROVAL_STATE.APPROVED ||
                      approvalState === ASSESSMENT_APPROVAL_STATE.FORCE_APPROVED
                    }
                    value={damage.price}
                    onChange={(ev) => {
                      if (ev.target.value < 0) return;
                      handleChangeDamageDetailPrice(damage.id, ev.target.value);
                    }}
                  />
                </Box>
                <Box sx={{ width: '30%', mr: '20px' }}>
                  <Typography sx={{ fontSize: '14px' }}>Status</Typography>
                  <DropDownWithoutLabel
                    disabled={isEditingBlocked}
                    values={ASSESSMENT_SUPPLIER_STATUS_INPUTS}
                    value={damage.repairStatus}
                    sx={{ width: '100%' }}
                    onChange={(ev) =>
                      handleChangeDamageDetailStatus(damage.id, ev.target.value)
                    }
                  />
                </Box>
                <DeleteButton
                  disabled={isEditingBlocked}
                  onClick={() =>
                    deleteDamageDetail(vehicleId, repairId, sheet.id, damage.id)
                  }
                  sx={{ mt: 'auto', background: '#F1F2F5' }}
                />
              </Box>
            );
          })}
        {!isEditingBlocked && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: '30%' }}>
              <Typography sx={{ fontSize: '14px' }}>Damage</Typography>
              <TextField
                sx={{ width: '100%' }}
                placeholder="Write a damage"
                value={newDamageDetail.name}
                onChange={(ev) =>
                  handleChangeNewDamageDetailName(ev.target.value)
                }
              />
            </Box>
            <Box sx={{ width: '30%', ml: '20px', mr: '20px' }}>
              <Typography sx={{ fontSize: '14px' }}>Price, kr</Typography>
              <TextField
                sx={{ width: '100%' }}
                placeholder="Write price"
                value={newDamageDetail.price}
                onChange={(ev) => {
                  if (ev.target.value < 0) return;
                  handleChangeNewDamageDetailPrice(ev.target.value);
                }}
              />
            </Box>
            <Box sx={{ width: '30%', mr: '20px' }}>
              <Typography sx={{ fontSize: '14px' }}>Status</Typography>
              <DropDownWithoutLabel
                sx={{ width: '100%' }}
                values={ASSESSMENT_SUPPLIER_STATUS_INPUTS}
                value={ASSESSMENT_SUPPLIER_STATUS.NEED_REPAIR}
              />
            </Box>
            <Button
              sx={{
                height: '56px',
                width: '56px',
                borderRadius: '16px',
                background: '#F1F2F5',
                color: '#42526E',
                fontSize: '20px',
                mt: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleCreateNewDamageDetail()}
            >
              +
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EditAssessmentSheet;
