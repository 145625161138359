import { connect } from 'react-redux';
import CustomerUserDetails from './UserDetails';

const mapStateToProps = (state, ownProps) => {
  const { customerId, customerUserId } = ownProps;
  const {
    customers: { B2BCustomersUsers },
  } = state;
  const customerUser = (B2BCustomersUsers[customerId] || []).find(
    (user) => user.id === customerUserId,
  );

  return {
    customerUser,
  };
};
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerUserDetails);
