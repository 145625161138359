import { setPreviousLocation } from './slice';

export const backPathMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case '@@router/LOCATION_CHANGE': {
        const {
          router: {
            location: { pathname },
          },
        } = getState();

        if (pathname && pathname !== window.location.pathname) {
          await dispatch(setPreviousLocation(pathname));
        }
        break;
      }

      default:
        break;
    }

    return await next(action);
  };
