import { SortCarsEnum, SortEnum } from '../../core/types';

export const getCarsSortEnumByField = (field = '') => {
  switch (field) {
    case 'carBrand': {
      return SortCarsEnum.CarBrand;
    }

    case 'carModel': {
      return SortCarsEnum.CarModel;
    }

    case 'plateNo': {
      return SortCarsEnum.PlateNo;
    }

    case 'endDate': {
      return SortCarsEnum.EndDate;
    }

    case 'aldAssessmentApproveState': {
      return SortCarsEnum.AldAssessmentApproveState;
    }

    case 'lastAssessmentSent': {
      return SortCarsEnum.LastAssessmentSent;
    }

    case 'bookedDate': {
      return SortCarsEnum.BookedDate;
    }

    case 'arrivedToALD': {
      return SortCarsEnum.ArrivedToALD;
    }

    case 'lastUpdate': {
      return SortCarsEnum.LastUpdate;
    }

    case 'timeUsed': {
      return SortCarsEnum.TimeUsed;
    }

    case 'owner': {
      return SortCarsEnum.Owner;
    }

    case 'returnToDealerOtherDate': {
      return SortCarsEnum.ReturnToDealerOtherDate;
    }

    case 'completedDate': {
      return SortCarsEnum.CompletedDate;
    }

    case 'salesChannel': {
      return SortCarsEnum.SalesChannel;
    }

    default:
      return undefined;
  }
};

export const getSortEnumBySortTypeString = (sortType = '') => {
  switch (sortType) {
    case 'asc':
      return SortEnum.OrderByAscending;
    case 'desc':
      return SortEnum.OrderByDescending;

    default:
      return undefined;
  }
};
