import { PhotoCamera } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import ImageLoader from '../ImageLoader/ImageLoader';
import moment from 'moment';
import RestrictErrorModal from './RestrictErrorModal';

function OneByOnePhotosUpload({
  maxImagesCount = 8,
  maxRenderingImagesCount = 6,
  onImageSelected = () => {},
  uploadIds = [],
  canSkipPictures = false,
  onSkip = () => {},
  onClickImage = () => {},
}) {
  const [imagesHeight, setImagesHeight] = useState(0);
  const [hasUploadError, setUploadError] = useState(false);
  const imagesContainerRef = useRef(null);

  useEffect(() => {
    if (!imagesHeight && imagesContainerRef.current) {
      setImagesHeight(imagesContainerRef.current.clientWidth / 4); // to make resolution as 3/4
    }
  }, [uploadIds]);

  return (
    <Box
      sx={{
        width: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <RestrictErrorModal
        open={hasUploadError}
        onClose={() => setUploadError(false)}
      />

      <input
        style={{ display: 'none' }}
        capture="camera"
        accept="image/*"
        type="file"
        onChange={async (event) => {
          if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const threeHoursAgo = moment().add(-3, 'hours');
            if (threeHoursAgo.isAfter(file.lastModified)) {
              setUploadError(true);
              event.target.value = '';
              return;
            }
            await onImageSelected(file);
          }
        }}
        id="upload-picture-button"
      />

      {uploadIds.length === 0 && (
        <Box
          component="label"
          htmlFor="upload-picture-button"
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <Box
            component="img"
            sx={{
              height: 90,
            }}
            src="/static/undraw_add_file2_gvbb.svg"
          />
          <Box>
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: '400',
              }}
            >
              Upload at least 6 photos of the car on the day of return
            </Typography>
            <Typography
              sx={{
                textAlign: 'center',
                color: 'rgba(66, 82, 110, 0.82)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              }}
            >
              Car photos should be created in last 3 hours
            </Typography>
          </Box>

          {canSkipPictures && (
            <Box>
              <Button
                sx={{
                  textAlign: 'center',
                }}
                fullWidth
                variant="outlined"
                onClick={onSkip}
              >
                SKIP PICTURES
              </Button>

              <Typography
                sx={{
                  textAlign: 'center',
                  color: 'rgba(66, 82, 110, 0.82)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                }}
              >
                By not taking the pictures you accept that the state of the
                vehicle at return is not documented
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {uploadIds.length !== 0 && (
        <Box
          ref={imagesContainerRef}
          sx={{
            m: 'auto',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            mb: '10px',
          }}
        >
          {uploadIds.map((uploadId, index) => {
            return index > maxRenderingImagesCount - 1 ? null : (
              <Box
                key={index}
                sx={{
                  width: '33.3%',
                  height: `${imagesHeight}px`,
                  borderRadius: '8px',
                  position: 'relative',
                }}
              >
                <ImageLoader
                  uploadId={uploadId}
                  sx={{
                    ...(index === maxRenderingImagesCount - 1 &&
                    uploadIds.length > maxRenderingImagesCount
                      ? { filter: 'brightness(0.6)' }
                      : {}),
                    cursor: 'pointer',
                    p: '5px',
                  }}
                  onClick={onClickImage}
                />

                {index === maxRenderingImagesCount - 1 &&
                  uploadIds.length > maxRenderingImagesCount && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 'inherit',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={onClickImage}
                    >
                      {`+${uploadIds.length - maxRenderingImagesCount}`}
                    </Box>
                  )}
              </Box>
            );
          })}
        </Box>
      )}

      {uploadIds.length !== 0 && uploadIds.length < maxImagesCount && (
        <>
          <Box
            sx={{ width: '100%', display: 'flex', mt: 'auto' }}
            component="label"
            htmlFor="upload-picture-button"
          >
            <Button
              component="label"
              htmlFor="upload-picture-button"
              color="primary"
              variant="outlined"
              endIcon={<PhotoCamera />}
              sx={{ width: '100%' }}
            >
              Take photo
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default OneByOnePhotosUpload;
