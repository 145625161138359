import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import { getPageBreadcrumbsByLocationPath } from './helpers';
import { withTranslation } from 'react-i18next';

function BreadcrumbsGenerator({ location = {}, t = () => {} }) {
  const breadcrumbs = getPageBreadcrumbsByLocationPath(location.pathname, t);

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Typography
            key={index}
            color={
              index === breadcrumbs.length - 1 ? 'primary.main' : 'text.primary'
            }
          >
            {breadcrumb}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}

export default withTranslation()(BreadcrumbsGenerator);
