import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const ReportsIcon = createSvgIcon(
  <svg viewBox="0 0 15 17">
    <path
      d="M13.3333 1.66667H9.85C9.5 0.7 8.58333 0 7.5 0C6.41667 0 5.5 0.7 5.15 1.66667H1.66667C0.75 1.66667 0 2.41667 0 3.33333V15C0 15.9167 0.75 16.6667 1.66667 16.6667H13.3333C14.25 16.6667 15 15.9167 15 15V3.33333C15 2.41667 14.25 1.66667 13.3333 1.66667ZM7.5 1.66667C7.95833 1.66667 8.33333 2.04167 8.33333 2.5C8.33333 2.95833 7.95833 3.33333 7.5 3.33333C7.04167 3.33333 6.66667 2.95833 6.66667 2.5C6.66667 2.04167 7.04167 1.66667 7.5 1.66667ZM5.83333 13.3333L2.5 10L3.675 8.825L5.83333 10.975L11.325 5.48333L12.5 6.66667L5.83333 13.3333Z"
      fillOpacity="0.86"
    />
  </svg>,
  'Reports',
);

export default ReportsIcon;
