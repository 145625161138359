import { Button, Divider, FormHelperText, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CommonFormTitle from '../../../../../common/components/FormTitle/FormTitle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useMounted from '../../../../../common/hooks/useMounted';
import { SUPPLIERS_TYPES } from '../../../../../common/constants/suppliers';

function CreateNewNAFSupplier({
  t = () => {},
  createNewSupplier = () => {},
  redirectToAppSuppliersNAF = () => {},
}) {
  const mounted = useMounted();

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        postalCode: '',
        city: '',
        address: '',
        email: '',
        phoneNumber: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Name field is required'),
        description: Yup.string().required('Description field is required'),
        postalCode: Yup.string().required('Postal code field is required'),
        city: Yup.string().required('City field is required'),
        address: Yup.string().required('Address field is required'),
        email: Yup.string()
          .email('Email must be valid')
          .required('Email field is required'),
        phoneNumber: Yup.string().required('Phone field is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await createNewSupplier({
            name: values.name,
            description: values.description,
            postCode: values.postalCode,
            city: values.city,
            address: values.address,
            email: values.email,
            phone: values.phoneNumber,
            supplierType: SUPPLIERS_TYPES.NAF,
          });
          await redirectToAppSuppliersNAF();

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <CommonFormTitle
            text={t('controlCategories.suppliers.naf.create.title')}
            icon={<InfoOutlinedIcon />}
            sx={{ mt: 3 }}
          />

          <Divider />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 2.5,
              py: 1,
              paddingBottom: 3,
              backgroundColor: 'background.default',
              borderBottomLeftRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
              overflow: 'hidden',
            }}
          >
            <Box sx={{ display: 'flex', flex: 1, overflow: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label={t('controlCategories.suppliers.naf.create.name')}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label={t(
                    'controlCategories.suppliers.naf.create.description',
                  )}
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.description}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('controlCategories.suppliers.wash.create.email')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  label={t(
                    'controlCategories.suppliers.wash.create.phoneNumber',
                  )}
                  margin="normal"
                  name="phoneNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.phoneNumber}
                  variant="outlined"
                />
              </Box>

              <Box
                sx={{
                  ml: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  fullWidth
                  helperText={touched.postalCode && errors.postalCode}
                  label={t('controlCategories.suppliers.naf.create.postalCode')}
                  margin="normal"
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.postalCode}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  label={t('controlCategories.suppliers.naf.create.city')}
                  margin="normal"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.city}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.address && errors.address)}
                  fullWidth
                  helperText={touched.address && errors.address}
                  label={t('controlCategories.suppliers.naf.create.address')}
                  margin="normal"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.address}
                  variant="outlined"
                />
              </Box>
            </Box>

            {errors.submit && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>

          <Button
            variant="contained"
            color="primary"
            disabled={
              isSubmitting ||
              !values.name ||
              !values.description ||
              !values.postalCode ||
              !values.city ||
              !values.address
            }
            sx={{ width: '25%', ml: 'auto', mt: 3 }}
            type="submit"
          >
            {t('common.buttons.save')}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default withTranslation()(CreateNewNAFSupplier);
