import React from 'react';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
// import AddIcon from '@mui/icons-material/Add';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import {
  Redirect,
  Route,
  Switch /** Link as RouterLink */,
} from 'react-router-dom';
import { ROUTING_PATH_NAMES } from '../../../common/constants/constants';
import VehiclesListTabs, { VEHICLES_LIST_TABS } from './Tabs/Tabs';
import AddNewCarContainer from './AddNewCar/AddNewCar.container';
import InUseCarsContainer from './CustomerLocationCars/CustomerLocationCars.container';
import NewBookedCarsContainer from './NewBookedCars/NewBookedCars.container';
import AlreadyInProcessCarsContainer from './AlreadyInProcessCars/AlreadyInProcessCars.container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BreadcrumbsGeneratorContainer from '../../../common/components/BreadcrubmsGenerator/BreadcrumbsGenerator.container';
import CarDetailsContainer from './CarDetails/CarDetails.container';
import ReadyForSalesCarsContainer from './ReadyForSalesCars/ReadyForSalesCars.container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import CarStatusInfoContainer from './CarStatusInfo/CarStatusInfo.container';
import WithAccessRightsRendererContainer from '../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import SupplierCarsContainer from './SupplierCars/SupplierCars.container';
import CustomerCarsContainer from './CustomerCars/CustomerCars.container';
import DealerCarsContainer from './DealerCars/DealerCars.container';
import { pathTester } from '../../../common/helpers/routing';
import AssessmentApprovesCarsContainer from './AssessmentApprovesCars/AssessmentApprovesCars.container';
import AllCarsContainer from './AllCars/AllCars.container';
import EditAssessmentContainer from './CarDetails/EditAssessment/EditAssessment.container';

const ImportExportMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    marginTop: theme.spacing(1),
    minWidth: 218,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12)',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiDivider-root': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiMenuItem-root': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function VehiclesList({
  t = () => {},
  location = {},
  redirectBack = () => {},
  uploadXlsImport = () => {},
}) {
  const isInUseCarsTabSelected = location.pathname.includes(
    `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}`,
  );
  const isNewReturnedCarsTabSelected = location.pathname.includes(
    `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}`,
  );
  const isInProcessCarsTabSelected = location.pathname.includes(
    `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}`,
  );
  const isRemovedCarsTabSelected = location.pathname.includes(
    `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}`,
  );
  const isAssessmentApprovesCarsTabSelected = location.pathname.includes(
    `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}`,
  );
  const isAddNewCarTabSelected = location.pathname.includes(
    `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ADD_NEW_CAR}`,
  );

  const isCarDetailsOpened =
    location.pathname.includes(
      `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALL}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    ) ||
    location.pathname.includes(
      `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    ) ||
    location.pathname.includes(
      `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    ) ||
    location.pathname.includes(
      `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    ) ||
    location.pathname.includes(
      `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    ) ||
    location.pathname.includes(
      `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}/${ROUTING_PATH_NAMES.CAR_DETAILS}`,
    ) ||
    pathTester.isVehiclesListCustomerCarsCarDetails(location.pathname) ||
    pathTester.isVehiclesListDealerCarsCarDetails(location.pathname) ||
    pathTester.isVehiclesListSupplierCarsCarDetails(location.pathname);

  const isCarStatePageOpened = pathTester.isVehiclesListVehicleState(
    location.pathname,
  );

  let selectedTabId = 0;
  if (isInUseCarsTabSelected) selectedTabId = VEHICLES_LIST_TABS.IN_USE_CARS;
  if (isNewReturnedCarsTabSelected)
    selectedTabId = VEHICLES_LIST_TABS.NEW_BOOKED_CARS;
  if (isInProcessCarsTabSelected)
    selectedTabId = VEHICLES_LIST_TABS.ALREADY_IN_PROCESS_CARS;
  if (isRemovedCarsTabSelected)
    selectedTabId = VEHICLES_LIST_TABS.READY_FOR_SALES_CARS;
  if (isAssessmentApprovesCarsTabSelected)
    selectedTabId = VEHICLES_LIST_TABS.ASSESSMENT_APPROVES_CARS;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isImportExportPopupOpened = Boolean(anchorEl);
  const handleImportExportButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleImportExportPopupClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Box
        sx={{
          display: { xs: isCarStatePageOpened ? 'none' : 'flex', md: 'flex' },
          mb: { xs: 1, md: 2 },
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h4">{t('vehiclesList.title')}</Typography>

          <BreadcrumbsGeneratorContainer />
        </Box>

        <Box
          sx={{
            display: 'flex',
            ml: 'auto',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            {/* <WithAccessRightsRendererContainer
              contentByAccessRights={[
                {
                  accessRights: ['canCreateCars'],
                  content: (
                    <>
                      {!isCarDetailsOpened &&
                        !isAddNewCarTabSelected &&
                        !isCarStatePageOpened && (
                          <Button
                            LinkComponent={RouterLink}
                            to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ADD_NEW_CAR}`}
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            sx={{ mr: 1 }}
                          >
                            {t('vehiclesList.createNewCar.title')}
                          </Button>
                        )}
                    </>
                  ),
                },
              ]}
              fallBackContent={null}
            /> */}

            {(isAddNewCarTabSelected ||
              isCarDetailsOpened ||
              isCarStatePageOpened) && (
              <Button
                color="primary"
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                sx={{ mr: 1 }}
                onClick={() => {
                  redirectBack();
                }}
              >
                {t('vehiclesList.createNewCar.back')}
              </Button>
            )}

            <WithAccessRightsRendererContainer
              contentByAccessRights={[
                {
                  accessRights: ['canCreateCars'],
                  content: (
                    <IconButton
                      disabled={isAddNewCarTabSelected}
                      color="primary"
                      variant={
                        isImportExportPopupOpened
                          ? 'contained-active'
                          : 'contained'
                      }
                      onClick={handleImportExportButtonClick}
                    >
                      <KeyboardCapslockIcon
                        sx={{ transform: 'rotate(180deg)' }}
                      />
                    </IconButton>
                  ),
                },
              ]}
              fallBackContent={null}
            />

            <ImportExportMenu
              anchorEl={anchorEl}
              open={isImportExportPopupOpened}
              onClose={handleImportExportPopupClose}
            >
              <label htmlFor="import-xls-button">
                <input
                  color="primary"
                  accept=".xlsx, .xls, .csv"
                  type="file"
                  onChange={async (event) => {
                    if (event.target.files.length > 0) {
                      const file = event.target.files[0];
                      try {
                        await uploadXlsImport(file);
                        handleImportExportPopupClose();
                        enqueueSnackbar('XLS file uploading succeeded!', {
                          variant: 'success',
                        });
                      } catch (error) {
                        enqueueSnackbar('XLS file uploading failed!', {
                          variant: 'error',
                        });
                      }
                    }
                  }}
                  id="import-xls-button"
                  style={{ display: 'none' }}
                />
                <MenuItem disableRipple>Import XLS</MenuItem>
              </label>

              <Divider sx={{ my: 0 }} />

              <MenuItem
                onClick={handleImportExportPopupClose}
                disableRipple
                disabled
              >
                Export XLS
              </MenuItem>

              <Divider sx={{ my: 0 }} />

              <MenuItem
                onClick={handleImportExportPopupClose}
                disableRipple
                disabled
              >
                Export PDF
              </MenuItem>
            </ImportExportMenu>
          </Box>
        </Box>
      </Box>

      <Switch>
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALL}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: (
                      <>
                        <AllCarsContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALL}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALL}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId/${ROUTING_PATH_NAMES.EDIT_ASSESSMENT}`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canUpdateAldAssessment'],
                    content: <EditAssessmentContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: (
                      <>
                        <VehiclesListTabs selectedTabId={selectedTabId} />

                        <InUseCarsContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId/${ROUTING_PATH_NAMES.EDIT_ASSESSMENT}`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canUpdateAldAssessment'],
                    content: <EditAssessmentContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: (
                      <>
                        <VehiclesListTabs selectedTabId={selectedTabId} />

                        <NewBookedCarsContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId/${ROUTING_PATH_NAMES.EDIT_ASSESSMENT}`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canUpdateAldAssessment'],
                    content: <EditAssessmentContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: (
                      <>
                        <VehiclesListTabs selectedTabId={selectedTabId} />

                        <AlreadyInProcessCarsContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                  {
                    accessRights: ['canViewSupplierCars'],
                    content: (
                      <Redirect
                        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.SUPPLIER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${vehicleId}`}
                      />
                    ),
                  },
                  {
                    accessRights: ['canViewCustomerCars'],
                    content: (
                      <Redirect
                        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${vehicleId}`}
                      />
                    ),
                  },
                  {
                    accessRights: ['canViewDealerCars'],
                    content: (
                      <Redirect
                        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.DEALER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/${vehicleId}`}
                      />
                    ),
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId/${ROUTING_PATH_NAMES.EDIT_ASSESSMENT}`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canUpdateAldAssessment'],
                    content: <EditAssessmentContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: (
                      <>
                        <VehiclesListTabs selectedTabId={selectedTabId} />

                        <ReadyForSalesCarsContainer />
                      </>
                    ),
                  },
                ]}
                fallBackContent={<></>}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
                fallBackContent={<></>}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId/${ROUTING_PATH_NAMES.EDIT_ASSESSMENT}`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canUpdateAldAssessment'],
                    content: <EditAssessmentContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: (
                      <>
                        <VehiclesListTabs selectedTabId={selectedTabId} />

                        <AssessmentApprovesCarsContainer />
                      </>
                    ),
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                      'canViewAssessmentApprovesCars',
                    ],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId/${ROUTING_PATH_NAMES.EDIT_ASSESSMENT}`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canUpdateAldAssessment'],
                    content: <EditAssessmentContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.SUPPLIER_CARS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewSupplierCars'],
                    content: <SupplierCarsContainer />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.SUPPLIER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewSupplierCars'],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_CARS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewCustomerCars'],
                    content: <CustomerCarsContainer />,
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewCustomerCars'],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.DEALER_CARS}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewDealerCars'],
                    content: <DealerCarsContainer />,
                  },
                ]}
              />
            );
          }}
        />
        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.DEALER_CARS}/${ROUTING_PATH_NAMES.CAR_DETAILS}/:vehicleId`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: ['canViewDealerCars'],
                    content: <CarDetailsContainer vehicleId={vehicleId} />,
                  },
                ]}
              />
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/:vehicleId/${ROUTING_PATH_NAMES.STATUS_INFO}`}
          render={({
            match: {
              params: { vehicleId },
            },
          }) => {
            return (
              <>
                <CarStatusInfoContainer vehicleId={vehicleId} />
              </>
            );
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ADD_NEW_CAR}`}
          render={() => {
            return <AddNewCarContainer />;
          }}
        />

        <Route
          exact
          path={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}`}
          render={() => {
            return (
              <WithAccessRightsRendererContainer
                contentByAccessRights={[
                  {
                    accessRights: [
                      'canViewReadyForSalesCars',
                      'canViewDealerOrOtherLocationCars',
                      'canViewInProcessCars',
                      'canViewReadyForSalesCars',
                    ],
                    content: (
                      <Redirect
                        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}`}
                      />
                    ),
                  },
                  {
                    accessRights: ['canViewSupplierCars'],
                    content: (
                      <Redirect
                        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.SUPPLIER_CARS}`}
                      />
                    ),
                  },
                  {
                    accessRights: ['canViewCustomerCars'],
                    content: (
                      <Redirect
                        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_CARS}`}
                      />
                    ),
                  },
                  {
                    accessRights: ['canViewDealerCars'],
                    content: (
                      <Redirect
                        to={`/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.VEHICLES_LIST}/${ROUTING_PATH_NAMES.DEALER_CARS}`}
                      />
                    ),
                  },
                ]}
              />
            );
          }}
        />
      </Switch>
    </>
  );
}

export default withTranslation()(VehiclesList);
