import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE_CODES } from './common/constants/constants';
import languageEN from './locate/en/translate.json';

const resources = {
  [LANGUAGE_CODES.EN]: languageEN,
};

i18n.use(initReactI18next).init({
  resources,
  ns: ['translations'],
  defaultNS: 'translations',
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    nsMode: 'default',
  },
});

export default i18n;
