import { connect } from 'react-redux';
import { redirectBack } from '../../../store/redirect/slice';
import { uploadXlsImport } from '../../../store/upload/slice';
import VehiclesList from './VehiclesList';

const mapStateToProps = (state) => ({
  location: state.router.location,
});

const mapDispatchToProps = { redirectBack, uploadXlsImport };

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesList);
