import { connect } from 'react-redux';
import { getAllDealers } from '../../../../store/dealers/slice';
import { redirectToAppDealersDealerDetailsDealerInfo } from '../../../../store/redirect/slice';
import DealersList from './List';

const mapStateToProps = (state) => ({
  dealers: state.dealers.dealers,
});

const mapDispatchToProps = {
  getAllDealers,
  redirectToAppDealersDealerDetailsDealerInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealersList);
