import React, { useRef, useState } from 'react';
import { Box } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withTranslation } from 'react-i18next';
import DashboardIcon from '../../../icons/Dashboard';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CarsIcon from '../../../icons/Cars';
import StorageIcon from '@mui/icons-material/Storage';
import ReportsIcon from '../../../icons/Reports';
import MailIcon from '../../../icons/Mail';
import { getUserNameAbbreviation } from '../../../utils/strings';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, Typography } from '@mui/material';
import {
  ROUTING_PATH_NAMES,
  ROUTING_URLS,
} from '../../../common/constants/constants';
import WithAccessRightsRendererContainer from '../../../common/components/WithAccessRightsRenderer/WithAccessRightsRenderer.container';
import { useOnOutsideClick } from '../../../common/hooks/useOnOutsideClick';
import { isMobile } from 'react-device-detect';

function Nav({
  t = () => {},
  location,
  userName = '',
  roleDescription = '',
  redirectToAppDashboard = () => {},
  redirectToAppAldUsers = () => {},
  redirectToAppVehiclesList = () => {},
  redirectToAppDealers = () => {},
  redirectToAppCustomers = () => {},
  redirectToAppSuppliers = () => {},
  redirectToAppReports = () => {},
  redirectToAppInbox = () => {},
  redirectToAppB2BAdminCustomers = () => {},
  appropriateSupplier = {},
  hideNav = () => {},
  redirectToAppVehiclesListAllCars = () => {},
}) {
  const isDashboardSelected = location.pathname.includes(
    ROUTING_URLS.APP.DASHBOARD,
  );
  const isAldUsersSelected = location.pathname.includes(
    ROUTING_URLS.APP.ALD_USERS,
  );
  const isVehiclesListSelected = location.pathname.includes(
    ROUTING_URLS.APP.VEHICLES_LIST,
  );
  const isAllVehiclesListSelected = location.pathname.includes(
    `${ROUTING_URLS.APP.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALL}`,
  );
  const isGroupedVehiclesListSelected =
    location.pathname.includes(
      `${ROUTING_URLS.APP.VEHICLES_LIST}/${ROUTING_PATH_NAMES.CUSTOMER_LOCATION_CARS}`,
    ) ||
    location.pathname.includes(
      `${ROUTING_URLS.APP.VEHICLES_LIST}/${ROUTING_PATH_NAMES.NEW_BOOKED_CARS}`,
    ) ||
    location.pathname.includes(
      `${ROUTING_URLS.APP.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ALREADY_IN_PROCESS_CARS}`,
    ) ||
    location.pathname.includes(
      `${ROUTING_URLS.APP.VEHICLES_LIST}/${ROUTING_PATH_NAMES.READY_FOR_SALES_CARS}`,
    ) ||
    location.pathname.includes(
      `${ROUTING_URLS.APP.VEHICLES_LIST}/${ROUTING_PATH_NAMES.ASSESSMENT_APPROVES}`,
    );
  const isCustomerUsersSelected = location.pathname.includes(
    `/${ROUTING_PATH_NAMES.APP}/${ROUTING_PATH_NAMES.B2BADMIN_CUSTOMERS}`,
  );
  const isControlCategoriesSelected = location.pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES,
  );
  const isDealerCompaniesSelected = location.pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES__DEALERS,
  );
  const isCustomersSelected = location.pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS,
  );
  const isSuppliersSelected = location.pathname.includes(
    ROUTING_URLS.APP.CONTROL_CATEGORIES__SUPPLIERS,
  );
  const isReportsSelected = location.pathname.includes(
    ROUTING_URLS.APP.REPORTS,
  );
  const isInboxSelected = location.pathname.includes(ROUTING_URLS.APP.INBOX);

  const [isControlCategoriesExpanded, setControlCategoriesExpanded] = useState(
    isControlCategoriesSelected,
  );
  const [isVehiclesListExpanded, setVehiclesListExpanded] = useState(
    isVehiclesListSelected,
  );

  const navRef = useRef();
  useOnOutsideClick(
    navRef,
    () => {
      if (isMobile) hideNav();
    },
    (event) => {
      const pathElems =
        event.path || (event.composedPath && event.composedPath());

      if (pathElems.some((elem) => elem.id === 'nav-bar-menu-icon'))
        return true;
      return false;
    },
  );

  return (
    <Box
      ref={navRef}
      sx={{
        backgroundColor: 'background.default',
        flex: { xs: 'unset', sm: '0 0 256px' },
        width: { xs: '70%', sm: 'unset' },
        flexDirection: 'column',
        height: '100%',
        display: 'flex',
        position: { xs: 'absolute', md: 'unset' },
        zIndex: 100,
        boxShadow: {
          xs: 'rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px',
          md: 'unset',
        },
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          borderBottom: '2px solid #e0e0e0',
          display: 'flex',
          flexDirection: 'row',
          px: 2,
          py: 3,
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'background.paper',
            borderRadius: '16px',
            display: 'flex',
            p: 2,
            width: '100%',
          }}
        >
          <Avatar sx={{ mr: 1 }}>{getUserNameAbbreviation(userName)}</Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ color: '#3C4693', fontWeight: 600 }}>
              {userName}
            </Typography>

            <Typography sx={{ fontSize: '10px', color: 'secondary' }}>
              {roleDescription}
            </Typography>

            <WithAccessRightsRendererContainer
              contentByAccessRights={[
                {
                  accessRights: ['canViewSupplierCars'],
                  content: (
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 600,
                        color: 'secondary',
                      }}
                    >
                      {(appropriateSupplier.name || '').toUpperCase()}
                    </Typography>
                  ),
                },
              ]}
              fallBackContent={null}
            />
          </Box>
        </Box>
      </Box>

      <List sx={{ height: '100%', p: 2, overflow: 'auto' }}>
        <WithAccessRightsRendererContainer
          contentByAccessRights={[
            {
              accessRights: ['canViewDashboard'],
              content: (
                <ListItem disablePadding sx={{ mb: 1 }}>
                  <ListItemButton
                    sx={{
                      borderRadius: '64px',
                      backgroundColor: isDashboardSelected
                        ? '#F2F3FC'
                        : 'transparent',
                    }}
                    onClick={() => {
                      redirectToAppDashboard();
                    }}
                  >
                    <ListItemIcon>
                      <DashboardIcon
                        {...(isDashboardSelected ? { color: 'primary' } : {})}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('dashboard.title')}
                      primaryTypographyProps={{
                        ...(isDashboardSelected ? { color: 'primary' } : {}),
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
            },
          ]}
          fallBackContent={null}
        />

        <WithAccessRightsRendererContainer
          contentByAccessRights={[
            {
              accessRights: ['canViewAldUsers'],
              content: (
                <ListItem disablePadding sx={{ mb: 1 }}>
                  <ListItemButton
                    sx={{
                      borderRadius: '64px',
                      backgroundColor: isAldUsersSelected
                        ? '#F2F3FC'
                        : 'transparent',
                    }}
                    onClick={() => {
                      redirectToAppAldUsers();
                    }}
                  >
                    <ListItemIcon>
                      <SupervisedUserCircleIcon
                        {...(isAldUsersSelected ? { color: 'primary' } : {})}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('aldUsers.title')}
                      primaryTypographyProps={{
                        ...(isAldUsersSelected ? { color: 'primary' } : {}),
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
            },
          ]}
          fallBackContent={null}
        />

        <WithAccessRightsRendererContainer
          contentByAccessRights={[
            {
              accessRights: [
                'canViewReadyForSalesCars',
                'canViewDealerOrOtherLocationCars',
                'canViewInProcessCars',
                'canViewReadyForSalesCars',
                'canViewAssessmentApprovesCars',
              ],
              content: (
                <>
                  <ListItem disablePadding sx={{ mb: 1 }}>
                    <ListItemButton
                      sx={{
                        borderRadius: '64px',
                        backgroundColor: isVehiclesListExpanded
                          ? '#F2F3FC'
                          : 'transparent',
                      }}
                      onClick={() => {
                        setVehiclesListExpanded(!isVehiclesListExpanded);
                      }}
                    >
                      <ListItemIcon>
                        <CarsIcon
                          {...(isVehiclesListExpanded
                            ? { color: 'primary' }
                            : {})}
                        />
                      </ListItemIcon>

                      <ListItemText
                        primary="Vehicles list"
                        primaryTypographyProps={{
                          ...(isVehiclesListExpanded
                            ? { color: 'primary' }
                            : {}),
                        }}
                      />

                      {isVehiclesListExpanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </ListItem>
                  <Collapse
                    in={isVehiclesListExpanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List sx={{ pl: 2 }} component="div" disablePadding>
                      <ListItemButton
                        sx={{
                          mb: 1,
                          pl: 5,
                          borderRadius: '64px',
                          backgroundColor: isAllVehiclesListSelected
                            ? '#F2F3FC'
                            : 'transparent',
                        }}
                        onClick={() => {
                          redirectToAppVehiclesListAllCars();
                        }}
                      >
                        <ListItemText
                          primary="All"
                          primaryTypographyProps={{
                            ...(isAllVehiclesListSelected
                              ? { color: 'primary' }
                              : {}),
                          }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          mb: 1,
                          pl: 5,
                          borderRadius: '64px',
                          backgroundColor: isGroupedVehiclesListSelected
                            ? '#F2F3FC'
                            : 'transparent',
                        }}
                        onClick={() => {
                          redirectToAppVehiclesList();
                        }}
                      >
                        <ListItemText
                          primary="Grouped"
                          primaryTypographyProps={{
                            ...(isGroupedVehiclesListSelected
                              ? { color: 'primary' }
                              : {}),
                          }}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              ),
            },
          ]}
          fallBackContent={
            <ListItem disablePadding sx={{ mb: 1 }}>
              <ListItemButton
                sx={{
                  borderRadius: '64px',
                  backgroundColor: isVehiclesListSelected
                    ? '#F2F3FC'
                    : 'transparent',
                }}
                onClick={() => {
                  redirectToAppVehiclesList();
                }}
              >
                <ListItemIcon>
                  <CarsIcon
                    {...(isVehiclesListSelected ? { color: 'primary' } : {})}
                  />
                </ListItemIcon>

                <ListItemText
                  primary={t('vehiclesList.title')}
                  primaryTypographyProps={{
                    ...(isVehiclesListSelected ? { color: 'primary' } : {}),
                  }}
                />
              </ListItemButton>
            </ListItem>
          }
        />

        <WithAccessRightsRendererContainer
          contentByAccessRights={[
            {
              accessRights: ['canViewCustomerUsers'],
              content: (
                <ListItem disablePadding sx={{ mb: 1 }}>
                  <ListItemButton
                    sx={{
                      borderRadius: '64px',
                      backgroundColor: isCustomerUsersSelected
                        ? '#F2F3FC'
                        : 'transparent',
                    }}
                    onClick={() => {
                      redirectToAppB2BAdminCustomers();
                    }}
                  >
                    <ListItemIcon>
                      <SupervisedUserCircleIcon
                        {...(isCustomerUsersSelected
                          ? { color: 'primary' }
                          : {})}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('controlCategories.customers.title')}
                      primaryTypographyProps={{
                        ...(isCustomerUsersSelected
                          ? { color: 'primary' }
                          : {}),
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
            },
          ]}
          fallBackContent={null}
        />

        <WithAccessRightsRendererContainer
          contentByAccessRights={[
            {
              accessRights: [
                'canViewDealers',
                'canViewCustomersB2B',
                'canViewCustomersB2C',
                'canViewSuppliers',
              ],
              content: (
                <>
                  <ListItem disablePadding sx={{ mb: 1 }}>
                    <ListItemButton
                      sx={{
                        borderRadius: '64px',
                        backgroundColor: isControlCategoriesExpanded
                          ? '#F2F3FC'
                          : 'transparent',
                      }}
                      onClick={() => {
                        setControlCategoriesExpanded(
                          !isControlCategoriesExpanded,
                        );
                      }}
                    >
                      <ListItemIcon>
                        <StorageIcon
                          {...(isControlCategoriesExpanded
                            ? { color: 'primary' }
                            : {})}
                        />
                      </ListItemIcon>

                      <ListItemText
                        primary={t('controlCategories.title')}
                        primaryTypographyProps={{
                          ...(isControlCategoriesExpanded
                            ? { color: 'primary' }
                            : {}),
                        }}
                      />

                      {isControlCategoriesExpanded ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemButton>
                  </ListItem>
                  <Collapse
                    in={isControlCategoriesExpanded}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List sx={{ pl: 2 }} component="div" disablePadding>
                      <ListItemButton
                        sx={{
                          mb: 1,
                          pl: 5,
                          borderRadius: '64px',
                          backgroundColor: isDealerCompaniesSelected
                            ? '#F2F3FC'
                            : 'transparent',
                        }}
                        onClick={() => {
                          redirectToAppDealers();
                        }}
                      >
                        <ListItemText
                          primary={t('controlCategories.dealers.title')}
                          primaryTypographyProps={{
                            ...(isDealerCompaniesSelected
                              ? { color: 'primary' }
                              : {}),
                          }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          mb: 1,
                          pl: 5,
                          borderRadius: '64px',
                          backgroundColor: isCustomersSelected
                            ? '#F2F3FC'
                            : 'transparent',
                        }}
                        onClick={() => {
                          redirectToAppCustomers();
                        }}
                      >
                        <ListItemText
                          primary={t('controlCategories.customers.title')}
                          primaryTypographyProps={{
                            ...(isCustomersSelected
                              ? { color: 'primary' }
                              : {}),
                          }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        sx={{
                          mb: 1,
                          pl: 5,
                          borderRadius: '64px',
                          backgroundColor: isSuppliersSelected
                            ? '#F2F3FC'
                            : 'transparent',
                        }}
                        onClick={() => {
                          redirectToAppSuppliers();
                        }}
                      >
                        <ListItemText
                          primary={t('controlCategories.suppliers.title')}
                          primaryTypographyProps={{
                            ...(isSuppliersSelected
                              ? { color: 'primary' }
                              : {}),
                          }}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              ),
            },
          ]}
          fallBackContent={null}
        />

        <WithAccessRightsRendererContainer
          contentByAccessRights={[
            {
              accessRights: ['canViewReports'],
              content: (
                <ListItem disablePadding sx={{ mb: 1 }}>
                  <ListItemButton
                    sx={{
                      borderRadius: '64px',
                      backgroundColor: isReportsSelected
                        ? '#F2F3FC'
                        : 'transparent',
                    }}
                    onClick={() => {
                      redirectToAppReports();
                    }}
                  >
                    <ListItemIcon>
                      <ReportsIcon
                        {...(isReportsSelected ? { color: 'primary' } : {})}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('reports.title')}
                      primaryTypographyProps={{
                        ...(isReportsSelected ? { color: 'primary' } : {}),
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
            },
          ]}
          fallBackContent={null}
        />

        <WithAccessRightsRendererContainer
          contentByAccessRights={[
            {
              accessRights: ['canViewInbox'],
              content: (
                <ListItem disablePadding sx={{ mb: 1 }}>
                  <ListItemButton
                    sx={{
                      borderRadius: '64px',
                      backgroundColor: isInboxSelected
                        ? '#F2F3FC'
                        : 'transparent',
                    }}
                    onClick={() => {
                      redirectToAppInbox();
                    }}
                  >
                    <ListItemIcon>
                      <MailIcon
                        {...(isInboxSelected ? { color: 'primary' } : {})}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={t('inbox.title')}
                      primaryTypographyProps={{
                        ...(isInboxSelected ? { color: 'primary' } : {}),
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ),
            },
          ]}
          fallBackContent={null}
        />
      </List>
    </Box>
  );
}

export default withTranslation()(Nav);
