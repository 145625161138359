import { RETURN_BOOKING_TYPE } from '../../../../../common/constants/booking';

export const isDealerOnlyReturn = (locations) =>
  locations.length === 1 &&
  locations.at(0).returnBookingType === RETURN_BOOKING_TYPE.RETURN_TO_DEALER;

export const RETURN_TO_OTHER_BOOKING_STEPS = {
  CHOOSING_AREA: 0,
  BOOKING_DATE_AND_TIME: 1,
};
