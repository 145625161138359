import { connect } from 'react-redux';
import { getDashboardTodayStatistic } from '../../../../store/dashboard/slice';
import Today from './Today';

const mapStateToProps = (state) => {
  const { todayStatistic } = state.dashboard;

  return {
    statistic: todayStatistic,
  };
};

const mapDispatchToProps = { getDashboardTodayStatistic };

export default connect(mapStateToProps, mapDispatchToProps)(Today);
