import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import StatisticRenderer from './StatisticRenderer/StatisticRenderer';

function TodayStatistic({
  statistic = {
    overviewOfReturn: [],
    overviewOfCars: [],
    overviewOfAssessment: [],
  },
  getDashboardTodayStatistic = () => {},
}) {
  useEffect(() => {
    getDashboardTodayStatistic();
  }, []);

  return <StatisticRenderer statistic={statistic} />;
}

export default withTranslation()(TodayStatistic);
