import {
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { StyledExpandersList } from '../../../../../common/components/StyledExpandersList/StyledExpandersList';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

function AldUserInfo({ aldUser = null, t = () => {} }) {
  if (aldUser) {
    const displayName = aldUser.displayName || '-';
    const email = aldUser.email || '-';
    const telephone = aldUser.telephone || '-';
    const registerDate = aldUser.registerDate || '-';
    const address = (aldUser.address && aldUser.address.name) || '-';
    const [isALDUserInfoOpened, setALDUserInfoOpened] = useState(true);

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <StyledExpandersList>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor: isALDUserInfoOpened
                  ? 'primary.main'
                  : 'background.default',
                color: isALDUserInfoOpened
                  ? 'background.default'
                  : 'text.primary',
                ':hover': {
                  backgroundColor: isALDUserInfoOpened
                    ? 'primary.main'
                    : 'background.default',
                },
              }}
              onClick={() => {
                setALDUserInfoOpened(!isALDUserInfoOpened);
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: isALDUserInfoOpened
                    ? '#ffffff'
                    : 'primary.main',
                  color: isALDUserInfoOpened ? 'primary.main' : '#ffffff',
                }}
              >
                <InfoOutlinedIcon />
              </ListItemIcon>

              <ListItemText
                primary={t('aldUsers.aldUserInfo')}
                primaryTypographyProps={{
                  fontWeight: 400,
                }}
              />

              <ListItemIcon className="ExpandIcon">
                {isALDUserInfoOpened ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>

            <Collapse in={isALDUserInfoOpened}>
              <Box className="CollapseContent">
                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('common.titles.name')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {displayName}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('common.titles.email')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {email}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('common.titles.address')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {address}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('common.titles.telephone')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {telephone}
                  </Typography>
                </Box>

                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('common.titles.registerDate')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {moment(registerDate).format('DD/MM/YYYY')}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </ListItem>
        </StyledExpandersList>
      </Box>
    );
  }

  return <></>;
}

export default withTranslation()(AldUserInfo);
