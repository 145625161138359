import { Box, Button, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

function ALDAssessmentInProcess({
  switchToNextVehicleFlowState = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  getVehicleFlowState = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          FLEET assessment in process
        </Typography>
        <LinearProgress sx={{ width: '100%', m: '10px' }} />
        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          To complete the assessment press “Completed”
        </Typography>
      </Box>

      <Button
        sx={{ width: '100%', mt: 'auto' }}
        color="primary"
        variant="outlined"
        onClick={async () => {
          await switchToNextVehicleFlowState(
            id,
            currentStateId,
            SUB_FLOW_STATE_ENUM.READY_FOR_WORKSHOP,
          );
          await getVehicleFlowState(id, contractId);
        }}
      >
        Completed
      </Button>
    </Box>
  );
}

export default ALDAssessmentInProcess;
