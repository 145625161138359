import { connect } from 'react-redux';
import { createNewDealer } from '../../../../store/dealers/slice';
import { redirectToAppDealers } from '../../../../store/redirect/slice';
import CreateNewDealer from './CreateNewUser';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppDealers,
  createNewDealer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewDealer);
