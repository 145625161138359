import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { StyledExpandersList } from '../../../../../../../common/components/StyledExpandersList/StyledExpandersList';
import PersonIcon from '../../../../../../../icons/Person';

function SupplierUserDetails({ t = () => {}, supplierUser = {} }) {
  if (supplierUser) {
    const {
      displayName = '-',
      email = '-',
      address = {
        name: '-',
      },
      telephone = '-',
    } = supplierUser;

    const [isCustomerUserInfoOpened, setCustomerUserInfoOpened] =
      useState(true);

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <StyledExpandersList>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor: isCustomerUserInfoOpened
                  ? 'primary.main'
                  : 'background.default',
                color: isCustomerUserInfoOpened
                  ? 'background.default'
                  : 'text.primary',
                ':hover': {
                  backgroundColor: isCustomerUserInfoOpened
                    ? 'primary.main'
                    : 'background.default',
                },
              }}
              onClick={() => {
                setCustomerUserInfoOpened(!isCustomerUserInfoOpened);
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: isCustomerUserInfoOpened
                    ? '#ffffff'
                    : 'primary.main',
                  color: isCustomerUserInfoOpened ? 'primary.main' : '#ffffff',
                }}
              >
                <PersonIcon />
              </ListItemIcon>

              <ListItemText
                primary={t('controlCategories.suppliers.supplierUserInfo')}
                primaryTypographyProps={{
                  fontWeight: 400,
                }}
              />

              <ListItemIcon className="ExpandIcon">
                {isCustomerUserInfoOpened ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>

            <Collapse in={isCustomerUserInfoOpened}>
              <Box className="CollapseContent">
                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('controlCategories.suppliers.wash.details.users.name')}
                  </Typography>
                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {displayName}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.suppliers.wash.details.users.address',
                    )}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {address.name}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.suppliers.wash.details.users.phoneNumber',
                    )}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {telephone}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('controlCategories.suppliers.wash.details.users.email')}
                  </Typography>

                  <Typography
                    variant="h6"
                    className="CollapseContentRowContent"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {email}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </ListItem>
        </StyledExpandersList>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h5">
        {t('vehiclesList.carDetails.noSpecifiedCarFound')}
      </Typography>
    </Box>
  );
}

export default withTranslation()(SupplierUserDetails);
