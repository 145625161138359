import {
  Collapse,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { StyledExpandersList } from '../../../../../../common/components/StyledExpandersList/StyledExpandersList';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/system';
import { withTranslation } from 'react-i18next';

function WorkshopSupplierInfo({ supplier = null, t = () => {} }) {
  if (supplier) {
    const {
      name = '-',
      description = '-',
      address,
      contactPerson,
      damageTypeGroups = [],
    } = supplier;
    const [isSupplierInfoOpened, setSupplierInfoOpened] = useState(true);
    const addressString = (address && address.name) || '-';
    const email = (contactPerson && contactPerson.email) || '-';
    const phoneNumber = (contactPerson && contactPerson.phone) || '-';

    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <StyledExpandersList>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                backgroundColor: isSupplierInfoOpened
                  ? 'primary.main'
                  : 'background.default',
                color: isSupplierInfoOpened
                  ? 'background.default'
                  : 'text.primary',
                ':hover': {
                  backgroundColor: isSupplierInfoOpened
                    ? 'primary.main'
                    : 'background.default',
                },
              }}
              onClick={() => {
                setSupplierInfoOpened(!isSupplierInfoOpened);
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: isSupplierInfoOpened
                    ? '#ffffff'
                    : 'primary.main',
                  color: isSupplierInfoOpened ? 'primary.main' : '#ffffff',
                }}
              >
                <InfoOutlinedIcon />
              </ListItemIcon>

              <ListItemText
                primary={t('controlCategories.suppliers.supplierInfo')}
                primaryTypographyProps={{
                  fontWeight: 400,
                }}
              />

              <ListItemIcon className="ExpandIcon">
                {isSupplierInfoOpened ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
            </ListItemButton>

            <Collapse in={isSupplierInfoOpened}>
              <Box className="CollapseContent">
                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.suppliers.workshop.details.info.name',
                    )}
                  </Typography>
                  <Typography
                    sx={{ pl: '10px' }}
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {name}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.suppliers.workshop.details.info.description',
                    )}
                  </Typography>
                  <Typography
                    sx={{ pl: '10px' }}
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {description}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('controlCategories.suppliers.wash.details.info.email')}
                  </Typography>
                  <Typography
                    sx={{ pl: '10px' }}
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {email}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('controlCategories.suppliers.wash.details.info.address')}
                  </Typography>
                  <Typography
                    sx={{ pl: '10px' }}
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {addressString}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t(
                      'controlCategories.suppliers.wash.details.info.phoneNumber',
                    )}
                  </Typography>
                  <Typography
                    sx={{ pl: '10px' }}
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {phoneNumber}
                  </Typography>
                </Box>
                <Divider />

                <Box className="CollapseContentRow">
                  <Typography variant="h6" className="CollapseContentRowTitle">
                    {t('common.titles.damageTypeGroups')}
                  </Typography>

                  <Typography
                    sx={{ pl: '10px' }}
                    variant="h6"
                    className="CollapseContentRowContent"
                  >
                    {damageTypeGroups.map((dtg) => dtg.name).join(', ')}
                  </Typography>
                </Box>
              </Box>
            </Collapse>
          </ListItem>
        </StyledExpandersList>
      </Box>
    );
  }

  return <></>;
}

export default withTranslation()(WorkshopSupplierInfo);
