import { Button, Divider, FormHelperText, TextField } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import CommonFormTitle from '../../../../../../common/components/FormTitle/FormTitle';
import useMounted from '../../../../../../common/hooks/useMounted';
import PersonIcon from '../../../../../../icons/Person';
import { Box } from '@mui/system';
import { DEALER_USERS_ROLES } from '../constants';

function CreateNewDealerUser({
  t = () => {},
  dealerId = '',
  createNewDealerUser = () => {},
  redirectBackByBreadcrumb = () => {},
}) {
  const mounted = useMounted();

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        postalCode: '',
        city: '',
        address: '',
        phoneNumber: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Email must be valid')
          .required('Email field is required'),
        name: Yup.string().required('Name field is required'),
        postalCode: Yup.string().required('Postal code field is required'),
        city: Yup.string().required('City field is required'),
        address: Yup.string().required('Address field is required'),
        phoneNumber: Yup.string().required('Phone number field is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await createNewDealerUser({
            dealerId,
            email: values.email,
            displayName: values.name,
            postCode: values.postalCode,
            city: values.city,
            address: values.address,
            telephone: values.phoneNumber,
            role: DEALER_USERS_ROLES.USER,
          });
          redirectBackByBreadcrumb();

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          style={{
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
            overflow: 'hidden',
          }}
        >
          {
            // #region Customer info
          }
          <CommonFormTitle
            icon={<PersonIcon />}
            text={t('controlCategories.dealers.createNewDealerUser')}
          />

          <Divider />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 2.5,
              py: 1,
              paddingBottom: 3,
              backgroundColor: 'background.default',
              borderBottomLeftRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
              overflow: 'hidden',
            }}
          >
            <Box sx={{ display: 'flex', flex: 1, overflow: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label={t(
                    'controlCategories.customers.b2b.details.users.createNewAdmin.name',
                  )}
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.name}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.city && errors.city)}
                  fullWidth
                  helperText={touched.city && errors.city}
                  label={t(
                    'controlCategories.customers.b2b.details.users.createNewAdmin.city',
                  )}
                  margin="normal"
                  name="city"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.city}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.address && errors.address)}
                  fullWidth
                  helperText={touched.address && errors.address}
                  label={t(
                    'controlCategories.customers.b2b.details.users.createNewAdmin.address',
                  )}
                  margin="normal"
                  name="address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.address}
                  variant="outlined"
                />
              </Box>

              <Box
                sx={{
                  ml: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t(
                    'controlCategories.customers.b2b.details.users.createNewAdmin.email',
                  )}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  fullWidth
                  helperText={touched.postalCode && errors.postalCode}
                  label={t(
                    'controlCategories.customers.b2b.details.users.createNewAdmin.postalCode',
                  )}
                  margin="normal"
                  name="postalCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.postalCode}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  fullWidth
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  label={t(
                    'controlCategories.customers.b2b.details.users.createNewAdmin.phoneNumber',
                  )}
                  margin="normal"
                  name="phoneNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.phoneNumber}
                  variant="outlined"
                />
              </Box>
            </Box>

            {errors.submit && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
          {
            // #endregion
          }

          <Button
            variant="contained"
            color="primary"
            disabled={
              isSubmitting ||
              !values.name ||
              !values.email ||
              !values.city ||
              !values.postalCode ||
              !values.address ||
              !values.phoneNumber
            }
            sx={{ width: '25%', ml: 'auto', mt: 3 }}
            type="submit"
          >
            {t('common.buttons.create')}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default withTranslation()(CreateNewDealerUser);
