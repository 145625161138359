import Box from '@mui/system/Box';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import {
  getLocationByStatusFlow,
  getProcessStatusByStatusFlow,
  SUB_FLOW_STATE_ENUM,
} from '../../../../common/constants/vehicles';
import { CustomDataGridToolbar } from '../AllCars/AllCars';
import CustomDataGridV3 from '../../../../common/components/CustomDataGridV3/CustomDataGridV3';
import {
  getCarsSortEnumByField,
  getSortEnumBySortTypeString,
} from '../../../../common/helpers/vehicles';
import { getEstimationColorByEnumValue } from '../CarDetails/SupplierLogs/helpers';
import { getChannelNameByEnum } from '../CarDetails/helpers';

function NewBookedCars({
  t = () => {},
  redirectToAppVehiclesListNewBookedCarsCarDetails = () => {},
  getVehicles = () => {},
}) {
  const [paginatedCars, setPaginatedCars] = useState({
    items: [],
    pagination: { currentPage: 0, totalPages: 0, totalCount: 0 },
  });

  const columns = [
    {
      field: 'carBrand',
      headerName: t('vehiclesList.tabs.newReturnedCars.car'),
    },
    {
      field: 'carModel',
      headerName: t('vehiclesList.tabs.newReturnedCars.model'),
    },
    {
      field: 'plateNo',
      headerName: t('vehiclesList.tabs.newReturnedCars.numbers'),
    },
    {
      field: 'endDate',
      headerName: t('vehiclesList.tabs.newReturnedCars.endDate'),
    },
    {
      field: 'bookedDate',
      headerName: t('common.titles.bookedDate'),
    },
    {
      field: 'returnToDealerOtherDate',
      headerName: 'Returned date',
    },
    {
      field: '_location',
      headerName: t('common.titles.stage'),
      renderCell: (params = {}) => (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',

            minWidth: 0,
            px: 1,
          }}
          title={getLocationByStatusFlow(params.row.currentStateEnum)}
        >
          <Tooltip title={getLocationByStatusFlow(params.row.currentStateEnum)}>
            <Typography
              noWrap
              sx={{
                width: '100%',
              }}
            >
              {getLocationByStatusFlow(params.row.currentStateEnum)}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: '_status',
      headerName: t('vehiclesList.tabs.alreadyInProcessCars.status'),
      renderCell: (params = {}) => {
        const { state } = getProcessStatusByStatusFlow(
          params.row.currentStateEnum,
        );
        return (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              minWidth: 0,
              px: 1,
            }}
          >
            <Button
              color={getEstimationColorByEnumValue(params.row.workshopColor)}
              variant="contained"
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Typography
                noWrap
                sx={{
                  width: '100%',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                {state || ''}
              </Typography>
            </Button>
          </Box>
        );
      },
    },
    {
      field: 'salesChannel',
      headerName: 'Sales channel',
      valueGetter: (params = {}) => {
        return getChannelNameByEnum(params.row.salesChannel);
      },
    },
  ];

  const rows = paginatedCars.items.map((car) => {
    return {
      ...car,
      endDate: moment(car.endDate).format('DD/MM/YYYY'),
      bookedDate: moment(car.bookedDate).format('DD/MM/YYYY'),
      returnToDealerOtherDate: moment(car.ReturnToDealerOtherDate).format(
        'DD/MM/YYYY',
      ),
    };
  });

  const [plateNumberSearchValue, setPlateNumberSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sortModel, setSortModel] = useState({
    field: 'returnToDealerOtherDate',
    sort: 'asc',
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: { sx: 0, md: '16px' },
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CustomDataGridV3
        onRowClick={(params) => {
          redirectToAppVehiclesListNewBookedCarsCarDetails(params.row.id);
        }}
        loading={isLoading}
        rows={rows}
        columns={columns}
        paginationCallback={async (offset, limit, plateNo, _sortModel) => {
          try {
            setIsLoading(true);
            const result = await getVehicles(
              offset,
              limit,
              [
                SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_DEALER,
                SUB_FLOW_STATE_ENUM.READY_FOR_RETURN_TO_ALD_FROM_OTHER_LOCATION,
                SUB_FLOW_STATE_ENUM.IN_TRANSPORT_TO_ALD_LOCATION,
              ],
              undefined,
              plateNo,
              getCarsSortEnumByField(_sortModel.field),
              getSortEnumBySortTypeString(_sortModel.sort),
            );
            setPaginatedCars(result);
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        }}
        paginationData={paginatedCars.pagination}
        components={{
          Toolbar: CustomDataGridToolbar,
        }}
        additionalPaginationCallbackTriggerData={[
          plateNumberSearchValue,
          sortModel,
        ]}
        componentsProps={{
          toolbar: {
            plateNumberSearchValue,
            setPlateNumberSearchValue,
          },
        }}
        onSortModelChange={(sortChangeData) => {
          if (sortChangeData && sortChangeData.length > 0) {
            setSortModel(sortChangeData[0]);
          }
        }}
      />
    </Box>
  );
}

export default withTranslation()(NewBookedCars);
