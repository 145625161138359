import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import store, { history } from './store';
import {
  _appInitialized,
  _prerenderConfigurationsStarted,
} from './store/appCommon/slice';
import AppContainer from './App/App.container';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ConnectedRouter } from 'connected-react-router';
import { _initialJwtRefresh } from './store/auth/slice';

(async () => {
  await store.dispatch(_prerenderConfigurationsStarted());
  await store.dispatch(_initialJwtRefresh());
  await store.dispatch(_appInitialized());
})();

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <ConnectedRouter history={history}>
          <I18nextProvider i18n={i18n}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <AppContainer />
            </LocalizationProvider>
          </I18nextProvider>
        </ConnectedRouter>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root'),
);
