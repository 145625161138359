import { connect } from 'react-redux';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import ReturningToALD from './ReturningToALD';

const mapStateToProps = (state, ownProps) => {
  const {
    bookings: { bookings },
  } = state;
  const {
    vehicle: { id: carId },
  } = ownProps;
  const currentVehicleBooking = bookings[carId];

  return {
    currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
    currentVehicleBooking,
  };
};

const mapDispatchToProps = { switchToNextVehicleFlowState };

export default connect(mapStateToProps, mapDispatchToProps)(ReturningToALD);
