import { Box, Typography } from '@mui/material';

function ReadyForSales() {
  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 'auto',
        }}
      >
        <Typography variant="h6" sx={{ color: 'success.main' }}>
          Car ready for sales channel
        </Typography>
      </Box>
    </Box>
  );
}

export default ReadyForSales;
