import { connect } from 'react-redux';
import {
  redirectToAppSuppliersNAFSupplierDetailsInfo,
  redirectToAppSuppliersNAFSupplierDetailsUsers,
} from '../../../../../../store/redirect/slice';
import NAFSupplierDetailsTabs from './Tabs';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppSuppliersNAFSupplierDetailsInfo,
  redirectToAppSuppliersNAFSupplierDetailsUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NAFSupplierDetailsTabs);
