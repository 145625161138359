import { connect } from 'react-redux';
import { redirectToAppVehiclesListCustomerLocationCarsCarDetails } from '../../../../store/redirect/slice';
import {
  getVehicleFlowStatesLogs,
  getVehicles,
} from '../../../../store/vehicles/slice';
import CustomerLocationCars from './CustomerLocationCars';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListCustomerLocationCarsCarDetails,
  getVehicleFlowStatesLogs,
  getVehicles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerLocationCars);
