import { CHAT_TYPE } from '../../common/constants/messages';
import { appCommonConfig } from '../../config';
import { _authenticateSucceeded, _logoutSucceeded } from '../auth/slice';
import {
  getAllAldCustomerMessages,
  getAllAldSupplierMessages,
} from '../messages/slice';
import {
  getContractImages,
  getVehicleAldAssessment,
  getVehicleById,
  getVehicleContractInfo,
  getVehicleCustomerInfo,
  getVehicleFlowState,
  getVehicleNafAssessment,
  getVehicleRepairStatuses,
  getVehicleReturnInfo,
} from '../vehicles/slice';
import { SignalHub } from './signalHub';

export const realtimeMiddleware =
  ({ dispatch }) =>
  (next) => {
    let hub = null;

    return async (action) => {
      if (action.type === _authenticateSucceeded.type) {
        if (hub) {
          hub.disconnect();
        }
        const { jwtToken } = action.payload;
        hub = new SignalHub(`${appCommonConfig.apiHostUrl}/hub`, jwtToken);

        hub.on('CarUpdated', async (contractId, carId) => {
          await safeCall(
            () => dispatch(getVehicleById(carId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getVehicleFlowState(carId, contractId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getVehicleCustomerInfo(carId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getVehicleContractInfo(carId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getVehicleReturnInfo(carId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getContractImages(contractId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getVehicleAldAssessment(carId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getVehicleNafAssessment(carId)),
            (err) => console.error(err),
          );
          await safeCall(
            () => dispatch(getVehicleRepairStatuses(carId)),
            (err) => console.error(err),
          );
        });
        hub.on('ChatMessageArrived', async (contractId, chatType) => {
          if (chatType === CHAT_TYPE.ALD_CUSTOMER) {
            await dispatch(getAllAldCustomerMessages(contractId));
          }
          if (chatType === CHAT_TYPE.ALD_SUPPLIER) {
            await dispatch(getAllAldSupplierMessages(contractId));
          }
        });
        hub.on('AldAssessmentUpdated', async (contractId, carId) => {
          await safeCall(
            () => dispatch(getVehicleAldAssessment(carId)),
            (err) => console.error(err),
          );
        });

        hub.connect();
      }

      if (action.type === _logoutSucceeded.type) {
        if (hub) {
          hub.disconnect();
        }
      }

      return await next(action);
    };
  };

const safeCall = async (cb, errb) => {
  try {
    await cb();
  } catch (e) {
    errb(e);
  }
};
