import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';
import { HttpRequestClientError } from '../../core/HttpRequestClientError';

export const aldUsersSlice = createSlice({
  name: 'aldUsers',
  initialState: {
    aldUsers: [],
  },
  reducers: {
    _aldUsersFetchSucceeded: (state, action) => {
      state.aldUsers = action.payload;
    },
  },
});

export const { _aldUsersFetchSucceeded } = aldUsersSlice.actions;

export const aldUsersReducer = aldUsersSlice.reducer;

export const getAllAldUsers = () => async (dispatch) => {
  try {
    const response = await api.aldUsers.getAll();
    await dispatch(_aldUsersFetchSucceeded(response));
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};

export const createNewAldUser = (aldUserData) => async () => {
  try {
    await api.aldUsers.createNew(aldUserData);
  } catch (error) {
    if (error instanceof HttpRequestClientError) {
      throw error;
    }
  }
};
