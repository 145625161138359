import { connect } from 'react-redux';
import { createNewB2BCustomerUser } from '../../../../../../store/customers/slice';
import { redirectBackByBreadcrumb } from '../../../../../../store/redirect/slice';
import CreateNewUser from './CreateNewUser';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  createNewB2BCustomerUser,
  redirectBackByBreadcrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewUser);
