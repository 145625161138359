import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { SUB_FLOW_STATE_ENUM } from '../../../../../common/constants/vehicles';

function AssessmentCompleted({
  switchToNextVehicleFlowState = () => {},
  currentVehicleFlowState = {},
  vehicle = {},
  getVehicleFlowState = () => {},
}) {
  const { id, contractId } = vehicle;
  const { id: currentStateId } = currentVehicleFlowState;

  return (
    <Box
      sx={{
        p: 2,
        pt: { xs: 2, xxs: 0.5 },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          pb: { xxs: 0.5, xs: 2 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ color: 'success.main' }}>
          FLEET assessment completed
        </Typography>

        <Typography
          sx={{ fontSize: 14, color: '#42526ED1', textAlign: 'center' }}
        >
          Press “Delivered” when the car arrives for Workshop
        </Typography>
      </Box>

      <Button
        sx={{ width: '100%', mt: 'auto' }}
        color="primary"
        variant="outlined"
        onClick={async () => {
          await switchToNextVehicleFlowState(
            id,
            currentStateId,
            SUB_FLOW_STATE_ENUM.DELIVERED_TO_WORKSHOP,
          );
          await getVehicleFlowState(id, contractId);
        }}
      >
        Delivered
      </Button>
    </Box>
  );
}

export default AssessmentCompleted;
