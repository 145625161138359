import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../core/api';

export const repairsSlice = createSlice({
  name: 'repairs',
  initialState: {
    contractRepairs: {},
  },
  reducers: {
    _repairsFetchSucceeded: (state, action) => {
      const { contractId, repairs } = action.payload;
      state.contractRepairs[contractId] = repairs;
    },
  },
});

export const repairsReducer = repairsSlice.reducer;

export const { _repairsFetchSucceeded } = repairsSlice.actions;

export const fetchAllContractRepairs = (contractId) => async (dispatch) => {
  const response = await api.repairs.getAllByContract(contractId);
  if (response.error) {
    throw new Error(response.errors.message);
  }
  dispatch(_repairsFetchSucceeded({ contractId, repairs: response }));
};
