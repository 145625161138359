import { connect } from 'react-redux';
import MainLayout from './MainLayout';

const mapStateToProps = (state) => ({
  isNavOpened: state.app.isNavOpened,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
