import { withTheme } from '@emotion/react';
import { Box } from '@mui/system';
import React from 'react';

function RoundedIconWithColorBackground({ sx = {}, children, theme = {} }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: theme.palette.background.paper,
        p: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default withTheme(RoundedIconWithColorBackground);
