import { connect } from 'react-redux';
import { setFsUploadIds } from '../../../../../store/appCommon/slice';
import NAFAssessment from './NAFAssessment';

const mapStateToProps = (state) => {
  return {
    damageTypes: state.damageTypes.damageTypesLookup,
    damageTypeGroups: state.damageTypeGroups.groupsLookup,
  };
};

const mapDispatchToProps = { setFsUploadIds };

export default connect(mapStateToProps, mapDispatchToProps)(NAFAssessment);
