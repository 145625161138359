import { connect } from 'react-redux';
import {
  redirectToAppSuppliersWorkshopSupplierDetailsInfo,
  redirectToAppSuppliersWorkshopSupplierDetailsUsers,
} from '../../../../../../store/redirect/slice';
import WorkshopSupplierDetailsTabs from './Tabs';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  redirectToAppSuppliersWorkshopSupplierDetailsInfo,
  redirectToAppSuppliersWorkshopSupplierDetailsUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkshopSupplierDetailsTabs);
