import { connect } from 'react-redux';
import {
  redirectBackByBreadcrumb,
  redirectToAppCustomersB2BCreateNewCustomer,
  redirectToAppCustomersB2CCreateNewCustomer,
  redirectToAppCustomersFlexCreateNewCustomer,
  redirectToAppCustomersRentalCreateNewCustomer,
} from '../../../store/redirect/slice';
import Customers from './Customers';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  redirectBackByBreadcrumb,
  redirectToAppCustomersB2CCreateNewCustomer,
  redirectToAppCustomersB2BCreateNewCustomer,
  redirectToAppCustomersRentalCreateNewCustomer,
  redirectToAppCustomersFlexCreateNewCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
