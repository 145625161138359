import createSvgIcon from '@mui/icons-material/utils/createSvgIcon';

const RepeatIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.91425 5.00025H17.9102C19.8892 5.00025 21.5002 6.58325 21.5002 8.52925V11.0002C21.5002 11.5532 21.0532 12.0002 20.5002 12.0002C19.9473 12.0002 19.5002 11.5532 19.5002 11.0002V8.52925C19.5002 7.68725 18.7873 7.00025 17.9102 7.00025H5.91425L7.20725 8.29325C7.59725 8.68425 7.59725 9.31625 7.20725 9.70725C7.01225 9.90225 6.75625 10.0002 6.50025 10.0002C6.24425 10.0002 5.98825 9.90225 5.79325 9.70725L2.79325 6.70725C2.40225 6.31625 2.40225 5.68425 2.79325 5.29325L5.79325 2.29325C6.18325 1.90225 6.81625 1.90225 7.20725 2.29325C7.59725 2.68425 7.59725 3.31625 7.20725 3.70725L5.91425 5.00025ZM16.793 14.2933C17.1841 13.9023 17.816 13.9023 18.207 14.2933L21.2071 17.2933C21.5981 17.6842 21.5981 18.3162 21.2071 18.7072L18.207 21.7072C18.012 21.9023 17.7561 22.0002 17.5 22.0002C17.2441 22.0002 16.9881 21.9023 16.793 21.7072C16.4021 21.3162 16.4021 20.6842 16.793 20.2933L18.0861 19.0002H6.09005C4.11005 19.0002 2.50005 17.4172 2.50005 15.4712V13.0002C2.50005 12.4473 2.94805 12.0002 3.50005 12.0002C4.05205 12.0002 4.50005 12.4473 4.50005 13.0002V15.4712C4.50005 16.3142 5.21305 17.0002 6.09005 17.0002H18.0861L16.793 15.7072C16.4021 15.3162 16.4021 14.6842 16.793 14.2933Z"
    />
    <mask
      id="mask0_3031_5476"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="20"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91425 5.00025H17.9102C19.8892 5.00025 21.5002 6.58325 21.5002 8.52925V11.0002C21.5002 11.5532 21.0532 12.0002 20.5002 12.0002C19.9473 12.0002 19.5002 11.5532 19.5002 11.0002V8.52925C19.5002 7.68725 18.7873 7.00025 17.9102 7.00025H5.91425L7.20725 8.29325C7.59725 8.68425 7.59725 9.31625 7.20725 9.70725C7.01225 9.90225 6.75625 10.0002 6.50025 10.0002C6.24425 10.0002 5.98825 9.90225 5.79325 9.70725L2.79325 6.70725C2.40225 6.31625 2.40225 5.68425 2.79325 5.29325L5.79325 2.29325C6.18325 1.90225 6.81625 1.90225 7.20725 2.29325C7.59725 2.68425 7.59725 3.31625 7.20725 3.70725L5.91425 5.00025ZM16.793 14.2933C17.1841 13.9023 17.816 13.9023 18.207 14.2933L21.2071 17.2933C21.5981 17.6842 21.5981 18.3162 21.2071 18.7072L18.207 21.7072C18.012 21.9023 17.7561 22.0002 17.5 22.0002C17.2441 22.0002 16.9881 21.9023 16.793 21.7072C16.4021 21.3162 16.4021 20.6842 16.793 20.2933L18.0861 19.0002H6.09005C4.11005 19.0002 2.50005 17.4172 2.50005 15.4712V13.0002C2.50005 12.4473 2.94805 12.0002 3.50005 12.0002C4.05205 12.0002 4.50005 12.4473 4.50005 13.0002V15.4712C4.50005 16.3142 5.21305 17.0002 6.09005 17.0002H18.0861L16.793 15.7072C16.4021 15.3162 16.4021 14.6842 16.793 14.2933Z"
      />
    </mask>
    <g mask="url(#mask0_3031_5476)">
      <rect width="24" height="24" />
    </g>
  </>,
  'Repeat',
);

export default RepeatIcon;
