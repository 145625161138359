import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import CommonDataGrid from '../../../../common/components/DataGrid/DataGrid';
import moment from 'moment';

function AldUsersList({
  t = () => {},
  aldUsers = [],
  redirectToAppAldUsersUserDetailsUserInfo = () => {},
  getAllAldUsers = () => {},
}) {
  useEffect(() => {
    getAllAldUsers();
  }, []);

  const columns = [
    {
      field: 'displayName',
      headerName: t('aldUsers.displayName'),
    },
    {
      field: 'email',
      headerName: t('aldUsers.email'),
    },
    {
      field: 'telephone',
      headerName: t('aldUsers.phoneNumber'),
    },
    {
      field: 'registerDate',
      headerName: t('aldUsers.registerDate'),
    },
  ];

  const rows = aldUsers.map((aldUser) => {
    return {
      ...aldUser,
      telephone: (aldUser.telephone && aldUser.telephone) || '-',
      registerDate: moment(aldUser.registerDate).format('DD/MM/YYYY'),
    };
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        flex: 1,
        width: '100%',
        borderRadius: '16px',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <CommonDataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        noDataToShowMessage={t('common.messages.noDataToShow')}
        selectRowHandler={(rowData) => {
          redirectToAppAldUsersUserDetailsUserInfo(rowData.id);
        }}
      />
    </Box>
  );
}

export default withTranslation()(AldUsersList);
