import { connect } from 'react-redux';
import {
  getVehicleQR,
  switchToNextVehicleFlowState,
} from '../../../../../store/vehicles/slice';
import QRGenerating from './QRGenerating';

const mapStateToProps = (state) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
  qrSrc: state.vehicles.currentVehicleQRSrc,
  currentVehicleQRLoadingInProcess:
    state.vehicles.currentVehicleQRLoadingInProcess,
});

const mapDispatchToProps = { switchToNextVehicleFlowState, getVehicleQR };

export default connect(mapStateToProps, mapDispatchToProps)(QRGenerating);
