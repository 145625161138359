import { FormControl, MenuItem, Select } from '@mui/material';

const DropDownWithoutLabel = ({
  value = '',
  values = [],
  onChange,
  placeholder,
  ...restProps
}) => {
  return (
    <FormControl {...restProps}>
      <Select value={value} onChange={onChange}>
        {values.map((v, idx) => (
          <MenuItem key={idx} value={v.id}>
            {v.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownWithoutLabel;
