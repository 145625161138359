import { connect } from 'react-redux';
import { redirectToAppSuppliersWorkshop } from '../../../../../store/redirect/slice';
import { createNewSupplier } from '../../../../../store/suppliers/slice';
import CreateNewWorkshopSupplier from './Workshop';

const mapStateToProps = (state) => ({
  damageTypeGroups: state.damageTypeGroups.groups,
});

const mapDispatchToProps = {
  createNewSupplier,
  redirectToAppSuppliersWorkshop,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewWorkshopSupplier);
