import { connect } from 'react-redux';
import {
  redirectBackByBreadcrumb,
  redirectToAppDealersCreateNew,
  redirectToAppDealersDealerDetailsDealerInfo,
} from '../../../store/redirect/slice';
import Dealers from './Dealers';

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  redirectToAppDealersCreateNew,
  redirectToAppDealersDealerDetailsDealerInfo,
  redirectBackByBreadcrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dealers);
