import { connect } from 'react-redux';
import { switchToNextVehicleFlowState } from '../../../../../store/vehicles/slice';
import ReadyForNAFAssessment from './ReadyForNAFAssessment';

const mapStateToProps = (state) => ({
  currentVehicleFlowState: state.vehicles.currentVehicleFlowState,
});

const mapDispatchToProps = { switchToNextVehicleFlowState };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReadyForNAFAssessment);
