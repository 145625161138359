import { connect } from 'react-redux';
import { redirectToAppSuppliersNAF } from '../../../../../store/redirect/slice';
import { createNewSupplier } from '../../../../../store/suppliers/slice';
import CreateNewNAFSupplier from './NAF';

const mapStateToProps = () => ({});

const mapDispatchToProps = { createNewSupplier, redirectToAppSuppliersNAF };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateNewNAFSupplier);
