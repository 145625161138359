import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { ROUTING_URLS } from '../../../../common/constants/constants';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

export const CUSTOMERS_TABS = {
  B2C_CUSTOMERS: 0,
  B2B_CUSTOMERS: 1,
  RENTAL: 2,
  FLEX: 3,
};

function CustomersTabs({
  t = () => {},
  selectedTabId = CUSTOMERS_TABS.B2C_CUSTOMERS,
}) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{ borderBottom: '1px solid #E0E0E0', mb: 1 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          LinkComponent={RouterLink}
          to={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2C}
          id={CUSTOMERS_TABS.B2C_CUSTOMERS}
          label={t('controlCategories.customers.b2c.title')}
        />

        <Tab
          LinkComponent={RouterLink}
          to={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__B2B}
          id={CUSTOMERS_TABS.B2B_CUSTOMERS}
          label={t('controlCategories.customers.b2b.title')}
        />

        {/* <Tab
          LinkComponent={RouterLink}
          to={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__RENTAL}
          id={CUSTOMERS_TABS.RENTAL}
          label={t('controlCategories.customers.rental.title')}
        />

        <Tab
          LinkComponent={RouterLink}
          to={ROUTING_URLS.APP.CONTROL_CATEGORIES__CUSTOMERS__FLEX}
          id={CUSTOMERS_TABS.FLEX}
          label={t('controlCategories.customers.flex.title')}
        /> */}
      </Tabs>
    </div>
  );
}

export default withTranslation()(CustomersTabs);
