import { withTheme } from '@emotion/react';
import { useState } from 'react';
import MissingEquipmentCheckContainer from './MissingEquipmentCheck.container';
import { MISSING_EQUIPMENT_VIEW_STEPS } from './steps';
import MissingEquipmentSavedContainer from './MissingEquipmentSaved.container';

const AccessoriesCheckInProcess = ({
  vehicle,
  getVehicleFlowState = () => {},
}) => {
  const [viewState, setViewState] = useState(
    MISSING_EQUIPMENT_VIEW_STEPS.CHECKING,
  );

  const handleSave = () => {
    setViewState(MISSING_EQUIPMENT_VIEW_STEPS.CHECKED);
  };

  return (
    <>
      {viewState === MISSING_EQUIPMENT_VIEW_STEPS.CHECKING && (
        <MissingEquipmentCheckContainer
          vehicle={vehicle}
          onSave={handleSave}
          getVehicleFlowState={getVehicleFlowState}
        />
      )}
      {viewState === MISSING_EQUIPMENT_VIEW_STEPS.CHECKED && (
        <MissingEquipmentSavedContainer
          vehicle={vehicle}
          getVehicleFlowState={getVehicleFlowState}
        />
      )}
    </>
  );
};

export default withTheme(AccessoriesCheckInProcess);
