import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React from 'react';
import { withTranslation } from 'react-i18next';

export const WASH_SUPPLIER_DETAILS_TABS = {
  SUPPLIERS_INFO: 0,
  USERS: 1,
};

function WashSupplierDetailsTabs({
  t = () => {},
  supplierId = '',
  selectedTabId = WASH_SUPPLIER_DETAILS_TABS.SUPPLIERS_INFO,
  redirectToAppSuppliersWashSupplierDetailsInfo = () => {},
  redirectToAppSuppliersWashSupplierDetailsUsers = () => {},
}) {
  return (
    <div>
      <Tabs
        value={selectedTabId}
        sx={{
          borderBottom: '1px solid #E0E0E0',
          mb: 2,
          '& .MuiTab-root': {
            flex: 1,
          },
        }}
      >
        <Tab
          onClick={() => {
            redirectToAppSuppliersWashSupplierDetailsInfo(supplierId);
          }}
          id={WASH_SUPPLIER_DETAILS_TABS.SUPPLIERS_INFO}
          label={t('controlCategories.suppliers.wash.details.info.title')}
        />

        <Tab
          onClick={() => {
            redirectToAppSuppliersWashSupplierDetailsUsers(supplierId);
          }}
          id={WASH_SUPPLIER_DETAILS_TABS.USERS}
          label={t('controlCategories.suppliers.wash.details.users.title')}
        />
      </Tabs>
    </div>
  );
}

export default withTranslation()(WashSupplierDetailsTabs);
