import { connect } from 'react-redux';
import { redirectToAppVehiclesListInProcessCarsCarDetails } from '../../../../../../store/redirect/slice';
import {
  getVehicleFlowStatesLogs,
  getVehicles,
} from '../../../../../../store/vehicles/slice';

import VehiclesList from './VehiclesList';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  redirectToAppVehiclesListInProcessCarsCarDetails,
  getVehicleFlowStatesLogs,
  getVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesList);
